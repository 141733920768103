import { Component, DoCheck, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ChangeDetectorRef, Inject } from '@angular/core';
import { RouteModalPopupModel } from '../../../models/operationsmodels/routemodel';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RouteService } from "../../../services/operationsservices/route.service";
import { TranslateService } from '@ngx-translate/core';

declare var $: any;
@Component({
    selector: 'sessiontimeoutmodal',
    templateUrl: './sessiontimeoutmodal.component.html',
    styleUrls: ['./sessiontimeoutmodal.component.scss']
})
export class SessionTimeOutModalComponent implements OnInit {

	constructor(private cdRef: ChangeDetectorRef,
		public translate: TranslateService,
        public dialogRef: MatDialogRef<SessionTimeOutModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {

        dialogRef.disableClose = true;
    }

    ngOnInit() {

    }

}
