  <!--begin::Modal-->
<div class="col-xl-12">
	<div>
		<h5 mat-dialog-title class="text-right">{{ "modalpopup.sessiontimeoutmodal.sessiontimeOut" | translate }}</h5>
		<div mat-dialog-content>
			{{data.idleState}}
		</div>
		<div mat-dialog-actions style="float:right;">
			<button mat-button [mat-dialog-close]="true">{{ "modalpopup.sessiontimeoutmodal.logout" | translate }}</button>
			<button mat-button [mat-dialog-close]="false" cdkFocusInitial>{{ "modalpopup.sessiontimeoutmodal.stay" | translate }}</button>
		</div>
	</div>
</div>
<!--end::Modal-->
