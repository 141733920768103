import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserModel } from '../../_models/user.model';
import { environment } from '../../../../../environments/environment';
import { AuthModel } from '../../_models/auth.model';

const API_USERS_URL = `${environment.apiUrl}/auth`;

@Injectable({
    providedIn: 'root',
})
export class AuthHTTPService {
    public myAppUrl: string = "";
    constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
        this.myAppUrl = baseUrl;
    }

    // public methods
    login(email: string, password: string): Observable<any> {
        //return this.http.post<AuthModel>(`${API_USERS_URL}/login`, { email, password });
        return this.http.post<AuthModel>(this.myAppUrl + 'api/Authentication/authenticate', { email, password });
    }

    // CREATE =>  POST: add a new user to the server
    createUser(user: UserModel): Observable<UserModel> {
        return this.http.post<UserModel>(API_USERS_URL, user);
    }

    forgotPassword(Email: string): Observable<boolean> {
        return this.http.post<boolean>(this.myAppUrl + 'api/Authentication/ForgotPassword', {
            Email
        });
    }

    resetPassword(AccessToken: string, Password: string): Observable<boolean> {
        return this.http.post<boolean>(this.myAppUrl + 'api/Authentication/ResetPassword', {
            AccessToken, Password
        });
    }

    getUserByToken(token): Observable<UserModel> {
        const httpHeaders = new HttpHeaders({
            Authorization: `Bearer ${token}`,
        });
        //return this.http.get<UserModel>(`${API_USERS_URL}`, {
        //    headers: httpHeaders,
        //});

        return this.http.get<UserModel>(this.myAppUrl + 'api/Authentication/GetUserByToken', {
            headers: httpHeaders,
        });
    }

    getCompanyName(): Observable<string> {
        return this.http.get<string>(this.myAppUrl + 'api/Authentication/GetCompanyName');
    }
}
