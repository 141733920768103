// Angular
import { Injectable } from '@angular/core';
// RxJS
import { Subject } from 'rxjs';
import { PrintReportConfig } from '../../configs/printreport.config';

@Injectable()
export class PrintReportConfigService {
    // Public properties
    onConfigUpdated$: Subject<any>;
    // Private properties
    private printReportConfig: any;

    /**
     * Service Constructor
     */
    constructor() {
        // register on config changed event and set default config
        this.onConfigUpdated$ = new Subject();
        this.loadConfigs();
    }

    /**
     * Returns the menuConfig
     */
    getReports() {
        return this.printReportConfig;
    }

    /**
     * Load config
     *
     * @param config: any
     */
    loadConfigs() {
        this.printReportConfig = new PrintReportConfig().configs;
        this.onConfigUpdated$.next(this.printReportConfig);
    }
}
