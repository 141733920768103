export class PrintReportConfig {
	public defaults: any = {
		singleprintreport: [
			//Inventory
			{ code: 'rpti001', title: 'IST Receipt', reporturl: 'inventory/form/ISTReceiptSingleReport?Id=', fetchpageurl: '/inventory/istreceipt/fetchistreceipt', addpageurl: '/inventory/istreceipt/addistreceipt' },
			{ code: 'rpti002', title: 'IST Indent', reporturl: 'inventory/form/ISTIndentSingleReport?Id=', fetchpageurl: '/inventory/istindent/fetchistindent', addpageurl: '/inventory/istindent/addistindent' },
			{ code: 'rpti002asf', title: 'IST Indent', reporturl: 'inventory/form/ISTIndentAFISASingleReport?Id=', fetchpageurl: '/inventory/istindent/fetchistindent', addpageurl: '/inventory/istindent/addistindent' },
			{ code: 'rpti003', title: 'Consignment Indent', reporturl: 'inventory/form/ConsignmentIndentSingleReport?Id=', fetchpageurl: '/inventory/consignmentindent/fetchconsignmentindent', addpageurl: '/inventory/consignmentindent/addconsignmentindent' },
			{ code: 'rpti004', title: 'Consignment In', reporturl: 'inventory/form/ConsignmentInSingleReport?Id=', fetchpageurl: '/inventory/consignmentin/fetchconsignmentin', addpageurl: '/inventory/consignmentin/addconsignmentin' },
			{ code: 'rpti005', title: 'Consignment Out', reporturl: 'inventory/form/ConsignmentOutSingleReport?Id=', fetchpageurl: '/inventory/consignmentout/fetchconsignmentout', addpageurl: '/inventory/consignmentout/addconsignmentout' },
			{ code: 'rpti006', title: 'Location Transfer', reporturl: 'inventory/form/LocationTransferSingleReport?Id=', fetchpageurl: '/inventory/locationtransfer/fetchlocationtransfer', addpageurl: '/inventory/locationtransfer/addlocationtransfer' },
			{ code: 'rpti006iafs', title: 'Location Transfer Issue', reporturl: 'inventory/form/LocationTransferIssueAFISASingleReport?Id=', fetchpageurl: '/inventory/locationtransferissue/fetchlocationtransferissue', addpageurl: '/inventory/locationtransferissue/addlocationtransferissue' },
			{ code: 'rpti007', title: 'Non Returnable Gate Pass', reporturl: 'inventory/form/NonReturnableGatePassSingleReport?Id=', fetchpageurl: '/inventory/nonreturnablegatepass/fetchnonreturnablegatepass', addpageurl: '/inventory/nonreturnablegatepass/addnonreturnablegatepass' },
			{ code: 'rpti007asf', title: 'Non Returnable Gate Pass', reporturl: 'inventory/form/NonReturnableGatePassAFISASingleReport?Id=', fetchpageurl: '/inventory/nonreturnablegatepass/fetchnonreturnablegatepass', addpageurl: '/inventory/nonreturnablegatepass/addnonreturnablegatepass' },
			{ code: 'rpti008', title: 'ReConditioning Issue', reporturl: 'inventory/form/ReConditioningIssueSingleReport?Id=', fetchpageurl: '/inventory/reconditioningissue/fetchreconditioningissue', addpageurl: '/inventory/reconditioningissue/addreconditioningissue' },
			{ code: 'rpti009', title: 'ReConditioning Order', reporturl: 'inventory/form/ReConditioningOrderSingleReport?Id=', fetchpageurl: '/inventory/reconditioningworkorder/fetchreconditioningworkorderr', addpageurl: '/inventory/reconditioningworkorder/addreconditioningworkorder' },
			{ code: 'rpti010', title: 'ReConditioning Receipt', reporturl: 'inventory/form/ReConditioningReceiptSingleReport?Id=', fetchpageurl: '/inventory/reconditioningreceipt/fetchreconditioningreceipt', addpageurl: '/inventory/reconditioningreceipt/addreconditioningreceipt' },
			{ code: 'rpti011', title: 'Returnable Delivery Note Issue', reporturl: 'inventory/form/ReturnableDeliveryNoteIssueSingleReport?Id=', fetchpageurl: '/inventory/returnabledeliverynoteissue/fetchreturnabledeliverynoteissue', addpageurl: '/inventory/returnabledeliverynoteissue/addreturnabledeliverynoteissue' },
			{ code: 'rpti012', title: 'Returnable Delivery Note Receipt', reporturl: 'inventory/form/ReturnableDeliveryNoteReceiptSingleReport?Id=', fetchpageurl: '/inventory/returnabledeliverynotereceipt/fetchreturnabledeliverynotereceipt', addpageurl: '/inventory/returnabledeliverynotereceipt/addreturnabledeliverynotereceipt' },
			{ code: 'rpti013', title: 'Returnable Gate Pass', reporturl: 'inventory/form/ReturnableGatePassSingleReport?Id=', fetchpageurl: '/inventory/returnablegatepass/fetchreturnablegatepass', addpageurl: '/inventory/returnablegatepass/addreturnablegatepass' },
			{ code: 'rpti013asf', title: 'Returnable Gate Pass', reporturl: 'inventory/form/ReturnableGatePassAFISASingleReport?Id=', fetchpageurl: '/inventory/returnablegatepass/fetchreturnablegatepass', addpageurl: '/inventory/returnablegatepass/addreturnablegatepass' },
			{ code: 'rpti014', title: 'Stock Opening Balance', reporturl: 'inventory/form/StockOpeningBalanceSingleReport?Id=', fetchpageurl: '/inventory/stockopeningbalance/fetchstockopeningbalance', addpageurl: '/inventory/stockopeningbalance/addstockopeningbalance' },
			{ code: 'rpti015', title: 'Stock Reconciliation', reporturl: 'inventory/form/StockReconciliationSingleReport?Id=', fetchpageurl: '/inventory/stockreconciliation/fetchstockreconciliation', addpageurl: '/inventory/stockreconciliation/addstockreconciliation' },
			{ code: 'rpti016', title: 'SubContracting Issue', reporturl: 'inventory/form/SubContractingIssueSingleReport?Id=', fetchpageurl: '/inventory/subcontractingissue/fetchsubcontractingissue', addpageurl: '/inventory/subcontractingissue/addsubcontractingissue' },
			{ code: 'rpti017', title: 'SubContracting Order', reporturl: 'inventory/form/SubContractingOrderSingleReport?Id=', fetchpageurl: '/inventory/subcontractingorder/fetchsubcontractingorder', addpageurl: '/inventory/subcontractingorder/addsubcontractingorder' },
			{ code: 'rpti018', title: 'SubContracting Receipt', reporturl: 'inventory/form/SubContractingReceiptSingleReport?Id=', fetchpageurl: '/inventory/subcontractingreceipt/fetchsubcontractingreceipt', addpageurl: '/inventory/subcontractingreceipt/addsubcontractingreceipt' },
			{ code: 'rpti019', title: 'Delivery Note', reporturl: 'inventory/form/DeliveryNoteSingleReport?Id=', fetchpageurl: '/inventory/deliverynote/fetchdeliverynote', addpageurl: '/inventory/deliverynote/adddeliverynote' },
			{ code: 'rpti020', title: 'Goods Receipt Note', reporturl: 'inventory/form/GRNSingleReport?Id=', fetchpageurl: '/inventory/grn/fetchgrn', addpageurl: '/inventory/grn/addgrn' },
			{ code: 'rpti021', title: 'IST Issue', reporturl: 'inventory/form/ISTIssueSingleReport?Id=', fetchpageurl: '/inventory/istissue/fetchistissue', addpageurl: '/inventory/istissue/addistissue' },
			{ code: 'rpti022', title: 'Purchase Indent', reporturl: 'inventory/form/PurchaseIndentSingleReport?Id=', fetchpageurl: '/inventory/purchaseindent/fetchpurchaseindent', addpageurl: '/inventory/purchaseindent/addpurchaseindent' },
			{ code: 'rpti022asf', title: 'Purchase Indent', reporturl: 'inventory/form/PurchaseIndentAFISASingleReport?Id=', fetchpageurl: '/inventory/purchaseindent/fetchpurchaseindent', addpageurl: '/inventory/purchaseindent/addpurchaseindent' },
			{ code: 'rpti023', title: 'Purchase Return', reporturl: 'inventory/form/PurchaseReturnSingleReport?Id=', fetchpageurl: '/inventory/purchasereturn/fetchpurchasereturn', addpageurl: '/inventory/purchasereturn/addpurchasereturn' },
			{ code: 'rpti024', title: 'Stores Issues', reporturl: 'inventory/form/StoresIssuesSingleReport?Id=', fetchpageurl: '/inventory/storesissue/fetchstoresissue', addpageurl: '/inventory/storesissue/addstoresissue' },
			{ code: 'rpti024asf', title: 'Stores Issues', reporturl: 'inventory/form/StoresIssuesAFISASingleReport?Id=', fetchpageurl: '/inventory/storesissue/fetchstoresissue', addpageurl: '/inventory/storesissue/addstoresissue' },
			{ code: 'rpti025', title: 'Stock Receipts', reporturl: 'inventory/form/StockReceiptsSingleReport?Id=', fetchpageurl: '/inventory/stockreceipts/fetchstockreceipts', addpageurl: '/inventory/stockreceipts/addstockreceipts' },
			{ code: 'rpti025asf', title: 'Stock Receipts', reporturl: 'inventory/form/StockReceiptsAFISASingleReport?Id=', fetchpageurl: '/inventory/stockreceipts/fetchstockreceipts', addpageurl: '/inventory/stockreceipts/addstockreceipts' },
			{ code: 'rpti026', title: 'Pre Gift Delivery', reporturl: 'inventory/form/PreGiftDeliverySingleReport?Id=', fetchpageurl: '/inventory/pregiftdelivery/fetchpregiftdelivery', addpageurl: '/inventory/pregiftdelivery/addpregiftdelivery' },
			{ code: 'rpti026afs', title: 'Pre Gift Delivery', reporturl: 'inventory/form/PreGiftDeliveryAFISASingleReport?Id=', fetchpageurl: '/inventory/pregiftdelivery/fetchpregiftdelivery', addpageurl: '/inventory/pregiftdelivery/addpregiftdelivery' },
			{ code: 'rpti019asf', title: 'Delivery Note', reporturl: 'inventory/form/DeliveryNoteAFISASingleReport?Id=', fetchpageurl: '/inventory/deliverynote/fetchdeliverynote', addpageurl: '/inventory/deliverynote/adddeliverynote' },
			{ code: 'rpti019ackasf', title: 'Acknowledgement', reporturl: 'inventory/form/DeliveryNoteAckAFISASingleReport?Id=', fetchpageurl: '/inventory/deliverynote/fetchdeliverynote', addpageurl: '/inventory/deliverynote/adddeliverynote' },
			{ code: 'rpti021asf', title: 'IST Issue', reporturl: 'inventory/form/ISTIssueAFISASingleReport?Id=', fetchpageurl: '/inventory/istissue/fetchistissue', addpageurl: '/inventory/istissue/addistissue' },
			{ code: 'rpti031asf', title: 'IST Receipt', reporturl: 'inventory/form/ISTReceiptAFISASingleReport?Id=', fetchpageurl: '/inventory/istreceipt/fetchistreceipt', addpageurl: '/inventory/istreceipt/addistreceipt' },
			{ code: 'rpti027', title: 'Lender Delivery Note Issue', reporturl: 'inventory/form/LenderDeliveryNoteIssueSingleReport?Id=', fetchpageurl: '/inventory/lenderdeliverynoteissue/fetchlenderdeliverynoteissue', addpageurl: '/inventory/lenderdeliverynoteissue/addlenderdeliverynoteissue' },
			{ code: 'rpti028', title: 'Lender Delivery Note Receipt', reporturl: 'inventory/form/LenderDeliveryNoteReceiptSingleReport?Id=', fetchpageurl: '/inventory/lenderdeliverynotereceipt/fetchlenderdeliverynotereceipt', addpageurl: '/inventory/lenderdeliverynotereceipt/addlenderdeliverynotereceipt' },
			{ code: 'rpti027asf', title: 'Lender Delivery Note Issue', reporturl: 'inventory/form/LenderDeliveryNoteIssueAFISASingleReport?Id=', fetchpageurl: '/inventory/lenderdeliverynoteissue/fetchlenderdeliverynoteissue', addpageurl: '/inventory/lenderdeliverynoteissue/addlenderdeliverynoteissue' },
			{ code: 'rpti028asf', title: 'Lender Delivery Note Receipt', reporturl: 'inventory/form/LenderDeliveryNoteReceiptAFISASingleReport?Id=', fetchpageurl: '/inventory/lenderdeliverynotereceipt/fetchlenderdeliverynotereceipt', addpageurl: '/inventory/lenderdeliverynotereceipt/addlenderdeliverynotereceipt' },
			{ code: 'rpti029', title: 'Location Transfer Issue', reporturl: 'inventory/form/LocationTransferIssueSingleReport?Id=', fetchpageurl: '/inventory/locationtransferissue/fetchlocationtransferissue', addpageurl: '/inventory/locationtransferissue/addlocationtransferissue' },
			{ code: 'rpti030', title: 'Location Transfer Receipt', reporturl: 'inventory/form/LocationTransferReceiptSingleReport?Id=', fetchpageurl: '/inventory/locationtransferreceipt/fetchlocationtransferreceipt', addpageurl: '/inventory/locationtransferreceipt/addlocationtransferreceipt' },
			{ code: 'rpti030asf', title: 'Location Transfer Receipt', reporturl: 'inventory/form/LocationTransferReceiptAFISASingleReport?Id=', fetchpageurl: '/inventory/locationtransferreceipt/fetchlocationtransferreceipt', addpageurl: '/inventory/locationtransferreceipt/addlocationtransferreceipt' },
			{ code: 'rpti032asf', title: 'Wheat Dispatch Note', reporturl: 'inventory/form/WheatDispatchNoteAFISASingleReport?Id=', fetchpageurl: '/inventory/wheatdispatchnoteafs/fetchwheatdispatchnoteafs', addpageurl: '/inventory/wheatdispatchnoteafs/addwheatdispatchnoteafs' },
			{ code: 'rpti033asf', title: 'Location Transfer Indent', reporturl: 'inventory/form/LocationTransferIndentAFISASingleReport?Id=', fetchpageurl: '/inventory/locationtransferindent/fetchlocationtransferindent', addpageurl: '/inventory/locationtransferindent/addlocationtransferindent' },
			{ code: 'rpti034asf', title: 'Shortage Issue Inventory', reporturl: 'inventory/form/ShortageIssueInventoryAFISASingleReport?Id=', fetchpageurl: '/inventory/shortageissueinventory/fetchshortageissueinventory', addpageurl: '/inventory/shortageissueinventory/addshortageissueinventory' },
			{ code: 'rpti035asf', title: 'Excess Receipt Inventory', reporturl: 'inventory/form/ExcessReceiptInventoryAFISASingleReport?Id=', fetchpageurl: '/inventory/excessreceiptinventory/fetchexcessreceiptinventory', addpageurl: '/inventory/excessreceiptinventory/addexcessreceiptinventory' },
			
			//Commercial
			{ code: 'rptc001', title: 'Manifest Creation', reporturl: 'commercial/form/ManifestCreationSingleReport?Id=', fetchpageurl: '/sales/manifestcreation/fetchmanifestcreation', addpageurl: '/sales/manifestcreation/addmanifestcreation' },
			{ code: 'rptc002', title: 'Manifest Invoice', reporturl: 'commercial/form/ManifestInvoiceSingleReport?Id=', fetchpageurl: '/sales/manifestinvoice/fetchmanifestinvoice', addpageurl: '/sales/manifestinvoice/addmanifestinvoice' },
			{ code: 'rptc003', title: 'Freight Contract', reporturl: 'commercial/form/FreightContractSingleReport?Id=', fetchpageurl: '/commercial/freightcontract/fetchfreightcontract', addpageurl: '/commercial/freightcontract/addfreightcontract' },
			{ code: 'rptc004', title: 'Other Invoice', reporturl: 'commercial/form/OtherInvoiceSingleReport?Id=', fetchpageurl: '/sales/otherinvoice/fetchotherinvoice', addpageurl: '/sales/otherinvoice/addotherinvoice' },
			{ code: 'rptc005', title: 'Trip Invoice', reporturl: 'commercial/form/TripInvoiceSingleReport?Id=', fetchpageurl: '/sales/tripinvoice/fetchtripinvoice', addpageurl: '/sales/tripinvoice/addtripinvoice' },

			//Sales
			{ code: 'rpts001', title: 'Sales Order Indent', reporturl: 'sales/form/SalesOrderIndentSingleReport?Id=', fetchpageurl: '/sales/salesindent/fetchsalesindent', addpageurl: '/sales/salesindent/addsalesindent' },
			{ code: 'rpts002', title: 'Sales Invoice', reporturl: 'sales/form/SalesInvoiceSingleReport?Id=', fetchpageurl: '/sales/salesinvoice/fetchsalesinvoice', addpageurl: '/sales/salesinvoice/addsalesinvoice' },
			{ code: 'rpts003', title: 'Sales Order', reporturl: 'sales/form/SalesOrderSingleReport?Id=', fetchpageurl: '/sales/salesorder/fetchsalesorder', addpageurl: '/sales/salesorder/addsalesorder' },
			{ code: 'rpts003asf', title: 'Sales Order', reporturl: 'sales/form/SalesOrderAFISASingleReport?Id=', fetchpageurl: '/sales/salesorder/fetchsalesorder', addpageurl: '/sales/salesorder/addsalesorder' },
			{ code: 'rpts004', title: 'Sales Proforma Invoice', reporturl: 'sales/form/SalesProformaInvoiceSingleReport?Id=', fetchpageurl: '/sales/salesproformainvoice/fetchsalesproformainvoice', addpageurl: '/sales/salesproformainvoice/addsalesproformainvoice' },
			{ code: 'rpts005', title: 'Sales Quotation', reporturl: 'sales/form/SalesQuotationSingleReport?Id=', fetchpageurl: '/sales/salesquotation/fetchsalesquotation', addpageurl: '/sales/salesquotation/addsalesquotation' },
			{ code: 'rpts006', title: 'Sales Request For Quotation', reporturl: 'sales/form/SalesRequestForQuotationSingleReport?Id=', fetchpageurl: '/sales/salesrequestforquotation/fetchsalesrequestforquotation', addpageurl: '/sales/salesrequestforquotation/addsalesrequestforquotation' },
			{ code: 'rpts007', title: 'Sales Return', reporturl: 'sales/form/SalesReturnSingleReport?Id=', fetchpageurl: '/sales/salesreturn/fetchsalesreturn', addpageurl: '/sales/salesreturn/addsalesreturn' },
			{ code: 'rpts008', title: 'Loading Order', reporturl: 'sales/form/DeliveryNoteScheduleSingleReport?Id=', fetchpageurl: '/sales/deliverynoteschedule/fetchdeliverynoteschedule', addpageurl: '/sales/deliverynoteschedule/adddeliverynoteschedule' },
			{ code: 'rpts008asf', title: 'Loading Order', reporturl: 'sales/form/DeliveryNoteScheduleAFISASingleReport?Id=', fetchpageurl: '/sales/deliverynoteschedule/fetchdeliverynoteschedule', addpageurl: '/sales/deliverynoteschedule/adddeliverynoteschedule' },
			{ code: 'rpts002asf', title: 'Sales Invoice', reporturl: 'sales/form/SalesInvoiceAFISASingleReport?Id=', fetchpageurl: '/sales/salesinvoice/fetchsalesinvoice', addpageurl: '/sales/salesinvoice/addsalesinvoice' },
			{ code: 'rpts009', title: 'Sales Return Sales', reporturl: 'sales/form/SalesReturnSalesSingleReport?Id=', fetchpageurl: '/sales/salesreturnsales/fetchsalesreturnsales', addpageurl: '/sales/salesreturnsales/addsalesreturnsales' },
			{ code: 'rpts009asf', title: 'Sales Return Sales', reporturl: 'sales/form/SalesReturnSalesAFISASingleReport?Id=', fetchpageurl: '/sales/salesreturnsales/fetchsalesreturnsales', addpageurl: '/sales/salesreturnsales/addsalesreturnsales' },

			//Operation
			{ code: 'rpto001', title: 'Fuel Slip', reporturl: 'operation/form/FuelSlipSingleReport?Id=', fetchpageurl: '/operations/fuelslip/fetchfuelslip', addpageurl: '/operations/fuelslip/addfuelslip' },
			{ code: 'rpto002', title: 'Trip Expenses Request', reporturl: 'operation/form/TripExpensesRequestSingleReport?Id=', fetchpageurl: '/operations/tripexpensesrequest/fetchtripexpensesrequest', addpageurl: '/operations/tripexpensesrequest/addtripexpensesrequest' },
			{ code: 'rpto003', title: 'Fuel Slip 2', reporturl: 'operation/form/FuelSlip2SingleReport?Id=', fetchpageurl: '/operations/fuelslips/fetchfuelslips', addpageurl: '/operations/fuelslips/addfuelslips' },

			//Procurement
			{ code: 'rptp001', title: 'Advance Request', reporturl: 'procurement/form/AdvanceRequestSingleReport?Id=', fetchpageurl: '/procurement/advancerequest/fetchadvancerequest', addpageurl: '/procurement/advancerequest/addadvancerequest' },
			{ code: 'rptp002', title: 'Fuel Invoice', reporturl: 'procurement/form/FuelInvoiceSingleReport?Id=', fetchpageurl: '/procurement/fuelinvoice/fetchfuelinvoice', addpageurl: '/procurement/fuelinvoice/addfuelinvoice' },
			{ code: 'rptp003', title: 'Proforma Invoice', reporturl: 'procurement/form/ProformaInvoiceSingleReport?Id=', fetchpageurl: '/procurement/proformainvoice/fetchproformainvoice', addpageurl: '/procurement/proformainvoice/addproformainvoice' },
			{ code: 'rptp004', title: 'Purchase Invoice', reporturl: 'procurement/form/PurchaseInvoiceSingleReport?Id=', fetchpageurl: '/procurement/purchaseinvoice/fetchpurchaseinvoice', addpageurl: '/procurement/purchaseinvoice/addpurchaseinvoice' },
			{ code: 'rptp005', title: 'Purchase Order', reporturl: 'procurement/form/PurchaseOrderSingleReport?Id=', fetchpageurl: '/procurement/purchaseorder/fetchpurchaseorder', addpageurl: '/procurement/purchaseorder/addpurchaseorder' },
			{ code: 'rptp006', title: 'Purchase Quotation', reporturl: 'procurement/form/PurchaseQuotationSingleReport?Id=', fetchpageurl: '/procurement/purchasequotation/fetchpurchasequotation', addpageurl: '/procurement/purchasequotation/addpurchasequotation' },
			{ code: 'rptp007', title: 'Purchase Request For Quotation', reporturl: 'procurement/form/PurchaseRequestForQuotationSingleReport?Id=', fetchpageurl: '/procurement/requestforquotation/fetchrequestforquotation', addpageurl: '/procurement/requestforquotation/addrequestforquotation' },
			{ code: 'rptp008', title: 'Purchase Requisition', reporturl: 'procurement/form/PurchaseRequisitionSingleReport?Id=', fetchpageurl: '/procurement/purchaserequisition/fetchpurchaserequisition', addpageurl: '/procurement/purchaserequisition/addpurchaserequisition' },
			{ code: 'rptp009', title: 'Loading Request', reporturl: 'procurement/form/LoadingRequestSingleReport?Id=', fetchpageurl: '/procurement/loadingrequest/fetchloadingrequest', addpageurl: '/procurement/loadingrequest/addloadingrequest' },
			{ code: 'rptp010', title: 'Transport Charges', reporturl: 'procurement/form/TransportChargesSingleReport?Id=', fetchpageurl: '/procurement/transportcharges/fetchtransportcharges', addpageurl: '/procurement/transportcharges/addtransportcharges' },
			{ code: 'rptp010afs', title: 'Transport Charges', reporturl: 'procurement/form/TransportChargesAFISASingleReport?Id=', fetchpageurl: '/procurement/transportcharges/fetchtransportcharges', addpageurl: '/procurement/transportcharges/addtransportcharges' },
			{ code: 'rptp011', title: 'Vendor Payment Request', reporturl: 'procurement/form/VendorPaymentRequestSingleReport?Id=', fetchpageurl: '/procurement/vendorpaymentrequest/fetchvendorpaymentrequest', addpageurl: '/procurement/vendorpaymentrequest/addvendorpaymentrequest' },
			{ code: 'rptp005afs', title: 'Purchase Order', reporturl: 'procurement/form/PurchaseOrderAFISASingleReport?Id=', fetchpageurl: '/procurement/purchaseorder/fetchpurchaseorder', addpageurl: '/procurement/purchaseorder/addpurchaseorder' },
			{ code: 'rptp012afs', title: 'Payment Terms', reporturl: 'procurement/form/PaymentTermsAFISASingleReport?Id=', fetchpageurl: '/procurement/paymentterms/fetchpaymentterms', addpageurl: '/procurement/paymentterms/addpaymentterms' },
			{ code: 'rptp013', title: 'Miscellaneous Charges', reporturl: 'procurement/form/TransportCharges2SingleReport?Id=', fetchpageurl: '/procurement/transportcharges2/fetchtransportcharges2', addpageurl: '/procurement/transportcharges2/addtransportcharges2' },
			{ code: 'rptp013afs', title: 'Miscellaneous Charges', reporturl: 'procurement/form/TransportCharges2AFISASingleReport?Id=', fetchpageurl: '/procurement/transportcharges2/fetchtransportcharges2', addpageurl: '/procurement/transportcharges2/addtransportcharges2' },
			{ code: 'rptp014', title: 'Transporter Adjustment Invoice', reporturl: 'procurement/form/TransporterAdjustmentInvoiceAFISASingleReport?Id=', fetchpageurl: '/procurement/transporteradjustmentinvoice/fetchtransporteradjustmentinvoice', addpageurl: '/procurement/transporteradjustmentinvoice/addtransporteradjustmentinvoice' },
			{ code: 'rptp015afs', title: 'Transporter Adjustment Invoice', reporturl: 'procurement/form/TransporterAdjustmentInvoiceAFISASingleReport?Id=', fetchpageurl: '/procurement/transporteradjustmentinvoice/fetchtransporteradjustmentinvoice', addpageurl: '/procurement/transporteradjustmentinvoice/addtransporteradjustmentinvoice' },

			//Finance
			{ code: 'rptf001', title: 'Yearly Budget', reporturl: 'finance/form/YearlyBudgetSingleReport?Id=', fetchpageurl: '/finance/budgetyearly/fetchbudgetyearly', addpageurl: '/finance/budgetyearly/addbudgetyearly' },
			{ code: 'rptf002', title: 'Monthly Budget', reporturl: 'finance/form/MonthlyBudgetSingleReport?Id=', fetchpageurl: '/finance/budgetmonthly/fetchbudgetmonthly', addpageurl: '/finance/budgetmonthly/addbudgetmonthly' },
			{ code: 'rptf003', title: 'AP Credit Note', reporturl: 'finance/form/APCreditNoteSingleReport?Id=', fetchpageurl: '/finance/apcreditnote/fetchapcreditnote', addpageurl: '/finance/apcreditnote/addapcreditnote' },
			{ code: 'rptf003afs', title: 'AP Credit Note', reporturl: 'finance/form/APCreditNoteAFISASingleReport?Id=', fetchpageurl: '/finance/apcreditnote/fetchapcreditnote', addpageurl: '/finance/apcreditnote/addapcreditnote' },
			{ code: 'rptf004', title: 'AP Debit Note', reporturl: 'finance/form/APDebitNoteSingleReport?Id=', fetchpageurl: '/finance/apdebitnote/fetchapdebitnote', addpageurl: '/finance/apdebitnote/addapdebitnote' },
			{ code: 'rptf004afs', title: 'AP Debit Note', reporturl: 'finance/form/APDebitNoteAFISASingleReport?Id=', fetchpageurl: '/finance/apdebitnote/fetchapdebitnote', addpageurl: '/finance/apdebitnote/addapdebitnote' },
			{ code: 'rptf005', title: 'AR Credit Note', reporturl: 'finance/form/ARCreditNoteSingleReport?Id=', fetchpageurl: '/finance/arcreditnote/fetcharcreditnote', addpageurl: '/finance/arcreditnote/addarcreditnote' },
			{ code: 'rptf005afs', title: 'AR Credit Note', reporturl: 'finance/form/ARCreditNoteAFISASingleReport?Id=', fetchpageurl: '/finance/arcreditnote/fetcharcreditnote', addpageurl: '/finance/arcreditnote/addarcreditnote' },
			{ code: 'rptf006', title: 'AR Debit Note', reporturl: 'finance/form/ARDebitNoteSingleReport?Id=', fetchpageurl: '/finance/ardebitnote/fetchardebitnote', addpageurl: '/finance/ardebitnote/addardebitnote' },
			{ code: 'rptf006afs', title: 'AR Debit Note', reporturl: 'finance/form/ARDebitNoteAFISASingleReport?Id=', fetchpageurl: '/finance/ardebitnote/fetchardebitnote', addpageurl: '/finance/ardebitnote/addardebitnote' },
			{ code: 'rptf007', title: 'General Payment', reporturl: 'finance/form/GeneralPaymentSingleReport?Id=', fetchpageurl: '/finance/generalpayment/fetchgeneralpayment', addpageurl: '/finance/generalpayment/addgeneralpayment' },
			{ code: 'rptf007afs', title: 'General Payment', reporturl: 'finance/form/GeneralPaymentAFISASingleReport?Id=', fetchpageurl: '/finance/generalpayment/fetchgeneralpayment', addpageurl: '/finance/generalpayment/addgeneralpayment' },
			{ code: 'rptf008', title: 'General Receipt', reporturl: 'finance/form/GeneralReceiptSingleReport?Id=', fetchpageurl: '/finance/generalreceipt/fetchgeneralreceipt', addpageurl: '/finance/generalreceipt/addgeneralreceipt' },
			{ code: 'rptf008afs', title: 'General Receipt', reporturl: 'finance/form/GeneralReceiptAFISASingleReport?Id=', fetchpageurl: '/finance/generalreceipt/fetchgeneralreceipt', addpageurl: '/finance/generalreceipt/addgeneralreceipt' },
			{ code: 'rptf009', title: 'Journal Voucher', reporturl: 'finance/form/JournalVoucherSingleReport?Id=', fetchpageurl: '/finance/journalvoucher/fetchjournalvoucher', addpageurl: '/finance/journalvoucher/addjournalvoucher' },
			{ code: 'rptf009afs', title: 'Journal Voucher', reporturl: 'finance/form/JournalVoucherSLNOAFISASingleReport?Id=', fetchpageurl: '/finance/journalvoucher/fetchjournalvoucher', addpageurl: '/finance/journalvoucher/addjournalvoucher' },
			{ code: 'rptf010', title: 'Petty Cash Payment', reporturl: 'finance/form/PettyCashPaymentSingleReport?Id=', fetchpageurl: '/finance/pettycashpayment/fetchpettycashpayment', addpageurl: '/finance/pettycashpayment/addpettycashpayment' },
			{ code: 'rptf011', title: 'Purchases Service Invoice', reporturl: 'finance/form/PurchaseServiceInvoiceSingleReport?Id=', fetchpageurl: '/finance/purchaseserviceinvoice/fetchpurchaseserviceinvoice', addpageurl: '/finance/purchaseserviceinvoice/addpurchaseserviceinvoice' },
			{ code: 'rptf011afs', title: 'Purchases Service Invoice', reporturl: 'finance/form/PurchaseServiceInvoiceAFISASingleReport?Id=', fetchpageurl: '/finance/purchaseserviceinvoice/fetchpurchaseserviceinvoice', addpageurl: '/finance/purchaseserviceinvoice/addpurchaseserviceinvoice' },
			{ code: 'rptf012', title: 'Sales Service Invoice', reporturl: 'finance/form/SalesServiceInvoiceSingleReport?Id=', fetchpageurl: '/finance/salesserviceinvoice/fetchsalesserviceinvoice', addpageurl: '/finance/salesserviceinvoice/addsalesserviceinvoice' },
			{ code: 'rptf012afs', title: 'Sales Service Invoice', reporturl: 'finance/form/SalesServiceInvoiceAFISASingleReport?Id=', fetchpageurl: '/finance/salesserviceinvoice/fetchsalesserviceinvoice', addpageurl: '/finance/salesserviceinvoice/addsalesserviceinvoice' },
			{ code: 'rptf013', title: 'Vendor Payment', reporturl: 'finance/form/VendorPaymentSingleReport?Id=', fetchpageurl: '/finance/vendorpayment/fetchvendorpayment', addpageurl: '/finance/vendorpayment/addvendorpayment' },
			{ code: 'rptf013fre', title: 'Vendor Payment', reporturl: 'finance/form/VendorPaymentFreReport?Id=', fetchpageurl: '/finance/vendorpayment/fetchvendorpayment', addpageurl: '/finance/vendorpayment/addvendorpayment' },
			{ code: 'rptf013afs', title: 'Vendor Payment', reporturl: 'finance/form/VendorPaymentAFISASingleReport?Id=', fetchpageurl: '/finance/vendorpayment/fetchvendorpayment', addpageurl: '/finance/vendorpayment/addvendorpayment' },
			{ code: 'rptf014', title: 'Customer Receipt', reporturl: 'finance/form/CustomerReceiptSingleReport?Id=', fetchpageurl: '/finance/customerreceipt/fetchcustomerreceipt', addpageurl: '/finance/customerreceipt/addcustomerreceipt' },
			{ code: 'rptf014afs', title: 'Customer Receipt', reporturl: 'finance/form/CustomerReceiptAFISASingleReport?Id=', fetchpageurl: '/finance/customerreceipt/fetchcustomerreceipt', addpageurl: '/finance/customerreceipt/addcustomerreceipt' },
			{ code: 'rptf015', title: 'Petty Cash Receipt', reporturl: 'finance/form/PettyCashReceiptSingleReport?Id=', fetchpageurl: '/finance/pettycashreceipt/fetchpettycashreceipt', addpageurl: '/finance/pettycashreceipt/addpettycashreceipt' },
			{ code: 'rptf016', title: 'Control Account Journal Voucher', reporturl: 'finance/form/ControlAccountJournalVoucherSingleReport?Id=', fetchpageurl: '/finance/cjournalvoucher/fetchcjournalvoucher', addpageurl: '/finance/cjournalvoucher/addcjournalvoucher' },
			{ code: 'rptf016afs', title: 'Control Account Journal Voucher', reporturl: 'finance/form/ControlAccountJournalVoucherSLNOAFISASingleReport?Id=', fetchpageurl: '/finance/cjournalvoucher/fetchcjournalvoucher', addpageurl: '/finance/cjournalvoucher/addcjournalvoucher' },
			{ code: 'rptf017', title: 'Recurring Journal Voucher', reporturl: 'finance/form/RecurringJournalVoucherSingleReport?Id=', fetchpageurl: '/finance/recurringjournalvoucher/fetchrecurringjournalvoucher', addpageurl: '/finance/recurringjournalvoucher/addrecurringjournalvoucher' },
			{ code: 'rptf018', title: 'Employee Payment', reporturl: 'finance/form/EmployeePaymentSingleReport?Id=', fetchpageurl: '/finance/employeepayment/fetchemployeepayment', addpageurl: '/finance/employeepayment/addemployeepayment' },
			{ code: 'rptf018afs', title: 'Employee Payment', reporturl: 'finance/form/EmployeePaymentAFISASingleReport?Id=', fetchpageurl: '/finance/employeepayment/fetchemployeepayment', addpageurl: '/finance/employeepayment/addemployeepayment' },
			{ code: 'rptf019', title: 'Employee Receipt', reporturl: 'finance/form/EmployeeReceiptSingleReport?Id=', fetchpageurl: '/finance/employeereceipt/fetchemployeereceipt', addpageurl: '/finance/employeereceipt/addemployeereceipt' },
			{ code: 'rptf019afs', title: 'Employee Receipt', reporturl: 'finance/form/EmployeeReceiptAFISASingleReport?Id=', fetchpageurl: '/finance/employeereceipt/fetchemployeereceipt', addpageurl: '/finance/employeereceipt/addemployeereceipt' },
			{ code: 'rptf020', title: 'Payment Request', reporturl: 'finance/form/PaymentRequestSingleReport?Id=', fetchpageurl: '/finance/paymentrequest/fetchpaymentrequest', addpageurl: '/finance/paymentrequest/addpaymentrequest' },
			{ code: 'rptf020afs', title: 'Payment Request', reporturl: 'finance/form/PaymentRequestAFISASingleReport?Id=', fetchpageurl: '/finance/paymentrequest/fetchpaymentrequest', addpageurl: '/finance/paymentrequest/addpaymentrequest' },
			{ code: 'rptf021', title: 'Operational Payment Request', reporturl: 'finance/form/OperationalPaymentRequestSingleReport?Id=', fetchpageurl: '/finance/operationalpaymentrequest/fetchoperationalpaymentrequest', addpageurl: '/finance/operationalpaymentrequest/addoperationalpaymentrequest' },
			{ code: 'rptf022', title: 'Opening Balance', reporturl: 'finance/form/OpeningBalanceSingleReport?Id=', fetchpageurl: '/finance/openingbalance/fetchopeningbalance', addpageurl: '/finance/openingbalance/addopeningbalance' },
			{ code: 'rptf023', title: 'Budget Screen', reporturl: 'finance/form/BudgetScreenSingleReport?Id=', fetchpageurl: '/finance/budgetscreen/fetchbudgetscreen', addpageurl: '/finance/budgetscreen/addbudgetscreen' },
			{ code: 'rptf024', title: 'Salary Sheet Preparation', reporturl: 'finance/form/SalarySheetSingleReport?Id=', fetchpageurl: '/finance/salarysheetpreparation/addsalarysheetpreparation', addpageurl: '/finance/salarysheetpreparation/addsalarysheetpreparation' },
			{ code: 'rptf025', title: 'Vendor Receipt', reporturl: 'finance/form/VendorReceiptSingleReport?Id=', fetchpageurl: '/finance/vendorreceipt/fetchvendorreceipt', addpageurl: '/finance/vendorreceipt/addvendorreceipt' },

			//Technical
			{ code: 'rptt001', title: 'Task Details', reporturl: 'technical/form/TaskDetailsSingleReport?Id=', fetchpageurl: '/technical/taskdetails/fetchtaskdetails', addpageurl: '/technical/taskdetails/addtaskdetails' },
			{ code: 'rptt002', title: 'Manpower Details', reporturl: 'technical/form/ManpowerDetailsSingleReport?Id=', fetchpageurl: '/technical/manpowerdetails/fetchmanpowerdetails', addpageurl: '/technical/manpowerdetails/addmanpowerdetails' },
			{ code: 'rptt003', title: 'Job Card', reporturl: 'technical/form/JobCardSingleReport?Id=', fetchpageurl: '/technical/jobcard/fetchjobcard', addpageurl: '/technical/jobcard/addjobcard' },
			{ code: 'rptt004', title: 'Technical Indent', reporturl: 'technical/form/TechnicalIndentSingleReport?Id=', fetchpageurl: '/technical/technicalindent/fetchtechnicalindent', addpageurl: '/technical/technicalindent/addtechnicalindent' },

			

			//Flour Production
			{ code: 'rptfp001', title: 'Flour Production Task Details', reporturl: 'flourproduction/form/FlourProdTaskDetailsSingleReport?Id=', fetchpageurl: '/flourproduction/productiontaskdetails/fetchproductiontaskdetails', addpageurl: '/flourproduction/productiontaskdetails/addproductiontaskdetails' },
			{ code: 'rptfp002', title: 'Flour Production Manpower Details', reporturl: 'flourproduction/form/FlourProdManPowerDetailsSingleReport?Id=', fetchpageurl: '/flourproduction/productionmanpowerdetails/fetchproductionmanpowerdetails', addpageurl: '/flourproduction/productionmanpowerdetails/addproductionmanpowerdetails' },
			{ code: 'rptfp003', title: 'Flour Production Job Card', reporturl: 'flourproduction/form/FlourProdJobCardSingleReport?Id=', fetchpageurl: '/flourproduction/flourprodjobcard/fetchflourprodjobcard', addpageurl: '/flourproduction/flourprodjobcard/addflourprodjobcard' },
			{ code: 'rptfp004', title: 'Flour Production Technical Indent', reporturl: 'flourproduction/form/FlourProdTechnicalIndentSingleReport?Id=', fetchpageurl: '/flourproduction/productiontechnicalindent/fetchproductiontechnicalindent', addpageurl: '/flourproduction/productiontechnicalindent/addproductiontechnicalindent' },

			//TMS
			{ code: 'rpttm001', title: 'Tyre Card', reporturl: 'tms/form/TyreCardSingleReport?Id=', fetchpageurl: '/tms/tyremaster/fetchtyremaster', addpageurl: '/tms/tyremaster/addtyremaster' },
			{ code: 'rpttm002', title: 'Tyre Inspection', reporturl: 'tms/form/TyreInspectionSingleReport?Id=', fetchpageurl: '/tms/tyreinspection/fetchtyreinspection', addpageurl: '/tms/tyreinspection/addtyreinspection' },

			//CMS
			{ code: 'rptcm001', title: 'Container Invoice', reporturl: 'cms/form/ContainerInvoiceSingleReport?Id=', fetchpageurl: '/cms/containerinvoice/fetchcontainerinvoice', addpageurl: '/cms/containerinvoice/addcontainerinvoice' },
			{ code: 'rptcm002', title: 'Container Receipt Note', reporturl: 'cms/form/ContainerReceiptNoteSingleReport?Id=', fetchpageurl: '/cms/containerreceiptnote/fetchcontainerreceiptnote', addpageurl: '/cms/containerreceiptnote/addcontainerreceiptnote' },
			{ code: 'rptcm003', title: 'Container Delivery Note', reporturl: 'cms/form/ContainerDeliveryNoteSingleReport?Id=', fetchpageurl: '/cms/containerdeliverynote/fetchcontainerdeliverynote', addpageurl: '/cms/containerdeliverynote/addcontainerdeliverynote' },
			{ code: 'rptcm004', title: 'Container Contract', reporturl: 'cms/form/ContainerContractSingleReport?Id=', fetchpageurl: '/cms/containercontract/fetchcontainercontract', addpageurl: '/cms/containercontract/addcontainercontract' },
			{ code: 'rptcm005', title: 'Container Proforma Invoice', reporturl: 'cms/form/ContainerProformaInvoiceSingleReport?Id=', fetchpageurl: '/cms/containerproformainvoice/fetchcontainerproformainvoice', addpageurl: '/cms/containerproformainvoice/addcontainerproformainvoice' },
			{ code: 'rptcm006', title: 'Machine Rental Invoice', reporturl: 'cms/form/MachineRentalInvoiceSingleReport?Id=', fetchpageurl: '/cms/machinerentalinvoice/fetchmachinerentalinvoice', addpageurl: '/cms/machinerentalinvoice/addmachinerentalinvoice' },

			//HRM
			{ code: 'rpth001', title: 'Loan Policy', reporturl: 'hrm/form/LoanPolicySingleReport?Id=', fetchpageurl: '/hrm/loanpolicy/fetchloanpolicy', addpageurl: '/hrm/loanpolicy/addloanpolicy' },
			{ code: 'rpth002', title: 'Loan Requisition', reporturl: 'hrm/form/LoanRequisitionSingleReport?Id=', fetchpageurl: '/hrm/loanrequisition/fetchloanrequisition', addpageurl: '/hrm/loanrequisition/addloanrequisition' },
			{ code: 'rpth003', title: 'Loan Issue', reporturl: 'hrm/form/LoanIssueSingleReport?Id=', fetchpageurl: '/hrm/loanissue/fetchloanissue', addpageurl: '/hrm/loanissue/addloanissue' },
			{ code: 'rpth004', title: 'Loan ForeCloser', reporturl: 'hrm/form/LoanForeCloserSingleReport?Id=', fetchpageurl: '/hrm/loanforecloser/fetchloanforecloser', addpageurl: '/hrm/loanforecloser/addloanforecloser' },
			{ code: 'rpth005', title: 'PayrollGroup', reporturl: 'hrm/form/PayrollGroupSingleReport?Id=', fetchpageurl: '/hrm/payrollgroup/fetchpayrollgroup', addpageurl: '/hrm/payrollgroup/addpayrollgroup' },
			{ code: 'rpth006', title: 'Stop Salary', reporturl: 'hrm/form/StopSalarySingleReport?Id=', fetchpageurl: '/hrm/stopsalary/fetchstopsalary', addpageurl: '/hrm/stopsalary/addstopsalary' },
			{ code: 'rpth007', title: 'Medical Insurance', reporturl: 'hrm/form/MedicalInsuranceSingleReport?Id=', fetchpageurl: '/hrm/medicalinsurance/fetchmedicalinsurance', addpageurl: '/hrm/medicalinsurance/addmedicalinsurance' },
			{ code: 'rpth008', title: 'Leave Issue', reporturl: 'hrm/form/LeaveIssueSingleReport?Id=', fetchpageurl: '/hrm/leaveissue/fetchleaveissue', addpageurl: '/hrm/leaveissue/addleaveissue' },
			{ code: 'rpth009', title: 'Leave Encashment Requisition', reporturl: 'hrm/form/LeaveEncashmentRequisitionSingleReport?Id=', fetchpageurl: '/hrm/leaveencashment/fetchleaveencashment', addpageurl: '/hrm/leaveencashment/addleaveencashment' },
			{ code: 'rpth010', title: 'Leave Opening Balance', reporturl: 'hrm/form/LeaveOpeningBalanceSingleReport?Id=', fetchpageurl: '/hrm/leaveopeningbalance/fetchleaveopeningbalance', addpageurl: '/hrm/leaveopeningbalance/addleaveopeningbalance' },
			{ code: 'rpth011', title: 'Leave Policy', reporturl: 'hrm/form/LeavePolicySingleReport?Id=', fetchpageurl: '/hrm/leavepolicy/fetchleavepolicy', addpageurl: '/hrm/leavepolicy/addleavepolicy' },
			{ code: 'rpth012', title: 'Movement Order', reporturl: 'hrm/form/MovementOrderSingleReport?Id=', fetchpageurl: '/hrm/movementorder/fetchmovementorder', addpageurl: '/hrm/movementorder/addmovementorder' },
			{ code: 'rpth013', title: 'Over Time', reporturl: 'hrm/form/OverTimeSingleReport?Id=', fetchpageurl: '/hrm/overtime/fetchovertime', addpageurl: '/hrm/overtime/addovertime' },
			{ code: 'rpth014', title: 'Consolidated Leave', reporturl: 'hrm/form/ConsolidatedLeaveSingleReport?Id=', fetchpageurl: '/hrm/consolidatedleave/fetchconsolidatedleave', addpageurl: '/hrm/consolidatedleave/addconsolidatedleave' },
			{ code: 'rpth015', title: 'Payroll Policy', reporturl: 'hrm/form/PayrollPolicySingleReport?Id=', fetchpageurl: '/hrm/payrollpolicy/fetchpayrollpolicy', addpageurl: '/hrm/payrollpolicy/addpayrollpolicy' },
			{ code: 'rpth016', title: 'Over Time Policy', reporturl: 'hrm/form/OverTimePolicySingleReport?Id=', fetchpageurl: '/hrm/overtimepolicy/fetchovertimepolicy', addpageurl: '/hrm/overtimepolicy/addovertimepolicy' },
			{ code: 'rpth017', title: 'Training Certificate', reporturl: 'hrm/form/TrainingCertificateSingleReport?Id=', fetchpageurl: '/hrm/trainingcertificate/fetchtrainingcertificate', addpageurl: '/hrm/trainingcertificate/addtrainingcertificate' },
			{ code: 'rpth018', title: 'Training Evaluation', reporturl: 'hrm/form/TrainingEvaluationSingleReport?Id=', fetchpageurl: '/hrm/trainingevaluation/fetchtrainingevaluation', addpageurl: '/hrm/trainingevaluation/addtrainingevaluation' },
			{ code: 'rpth019', title: 'Training Execution', reporturl: 'hrm/form/TrainingExecutionSingleReport?Id=', fetchpageurl: '/hrm/trainingexecution/fetchtrainingexecution', addpageurl: '/hrm/trainingexecution/addtrainingexecution' },
			{ code: 'rpth020', title: 'Training Requisition', reporturl: 'hrm/form/TrainingRequisitionSingleReport?Id=', fetchpageurl: '/hrm/trainingrequisition/fetchtrainingrequisition', addpageurl: '/hrm/trainingrequisition/addtrainingrequisition' },
			{ code: 'rpth021', title: 'Leave Requisition', reporturl: 'hrm/form/LeaveRequisitionSingleReport?Id=', fetchpageurl: '/hrm/leaverequisition/fetchleaverequisition', addpageurl: '/hrm/leaverequisition/addleaverequisition' },
			{ code: 'rpth022', title: 'Advance Requisition', reporturl: 'hrm/form/AdvanceRequisitionSingleReport?Id=', fetchpageurl: '/hrm/advancerequisition/fetchadvancerequisition', addpageurl: '/hrm/advancerequisition/addadvancerequisition' },

			//Production
			{ code: 'rptpr001', title: 'Job Card', reporturl: 'production/form/JobCardSingleReport?Id=', fetchpageurl: '/production/jobcard/fetchjobcard', addpageurl: '/production/jobcard/addjobcard' },
			{ code: 'rptpr002', title: 'Routing Bom', reporturl: 'production/form/RoutingBomSingleReport?Id=', fetchpageurl: '/production/routingbom/fetchroutingbom', addpageurl: '/production/routingbom/addroutingbom' },
			{ code: 'rptpr003', title: 'Production Bom', reporturl: 'production/form/ProductionBomSingleReport?Id=', fetchpageurl: '/production/productionbom/fetchproductionbom', addpageurl: '/production/productionbom/addproductionbom' },
			{ code: 'rptpr004', title: 'Production Order', reporturl: 'production/form/ProductionOrderSingleReport?Id=', fetchpageurl: '/production/productionorder/fetchproductionorder', addpageurl: '/production/productionorder/addproductionorder' },
			{ code: 'rptpr005', title: 'Production Scheduling', reporturl: 'production/form/ProductionSchedulingSingleReport?Id=', fetchpageurl: '/production/productionscheduling/fetchproductionscheduling', addpageurl: '/production/productionscheduling/addproductionscheduling' },
			{ code: 'rptpr006', title: 'Production Receipt', reporturl: 'production/form/ProductionReceiptSingleReport?Id=', fetchpageurl: '/production/productionreceipts/fetchproductionreceipts', addpageurl: '/production/productionreceipts/addproductionreceipts' },
			{ code: 'rptpr006asf', title: 'Production Receipt', reporturl: 'production/form/ProductionReceiptAFISASingleReport?Id=', fetchpageurl: '/production/productionreceipts/fetchproductionreceipts', addpageurl: '/production/productionreceipts/addproductionreceipts' },
			{ code: 'rptpr007', title: 'Planned Order Release', reporturl: 'production/form/PlannedOrderReleaseSingleReport?Id=', fetchpageurl: '/production/plannedorderrelease/fetchplannedorderrelease', addpageurl: '/production/plannedorderrelease/addplannedorderrelease' },
			{ code: 'rptpr008', title: 'Production Order Settlement', reporturl: 'production/form/ProductionOrderSettlementSingleReport?Id=', fetchpageurl: '/production/productionordersettlement/fetchproductionordersettlement', addpageurl: '/production/productionordersettlement/addproductionordersettlement' },
			{ code: 'rptpr009', title: 'Production Routing', reporturl: 'production/form/ProductionRoutingSingleReport?Id=', fetchpageurl: '/production/productionrouting/fetchproductionrouting', addpageurl: '/production/productionrouting/addproductionrouting' },

			//FixedAssets
			{ code: 'rptfi001', title: 'Asset Acquisition', reporturl: 'fixedassets/form/AssetAcquisitionSingleReport?Id=', fetchpageurl: '/fixedasset/assetacquisition/fetchassetacquisition', addpageurl: '/fixedasset/assetacquisition/addassetacquisition' },
			{ code: 'rptfi002', title: 'Asset Open Balance', reporturl: 'fixedassets/form/AssetOpenBalanceSingleReport?Id=', fetchpageurl: '/fixedasset/assetopbal/fetchassetopbal', addpageurl: '/fixedasset/assetopbal/addassetopbal' },
			{ code: 'rptfi003', title: 'Asset Revaluation', reporturl: 'fixedassets/form/AssetRevaluationSingleReport?Id=', fetchpageurl: '/fixedasset/assetrevaluation/fetchassetrevaluation', addpageurl: '/fixedasset/assetrevaluation/addassetrevaluation' },
			{ code: 'rptfi004', title: 'Asset Transfer Different Unit', reporturl: 'fixedassets/form/AssetTransferDifferentUnitSingleReport?Id=', fetchpageurl: '/fixedasset/assettransferdiffunit/fetchassettransferdiffunit', addpageurl: '/fixedasset/assettransferdiffunit/addassettransferdiffunit' },
			{ code: 'rptfi005', title: 'Asset Transfer Same Unit', reporturl: 'fixedassets/form/AssetTransferSameUnitSingleReport?Id=', fetchpageurl: '/fixedasset/assettransfersameunit/fetchassettransfersameunit', addpageurl: '/fixedasset/assettransfersameunit/addassettransfersameunit' },
			{ code: 'rptfi006', title: 'Sale or Discard', reporturl: 'fixedassets/form/SaleorDiscardSingleReport?Id=', fetchpageurl: '/fixedasset/salesordiscard/fetchsalesordiscard', addpageurl: '/fixedasset/salesordiscard/addsalesordiscard' },

			//QualityControl
			{ code: 'rptqc001', title: 'Test Definition', reporturl: 'qualitycontrol/form/TestDefinitionSingleReport?Id=', fetchpageurl: '/qualitycontrol/testdefinition/fetchtestdefinition', addpageurl: '/qualitycontrol/testdefinition/addtestdefinition' },
			{ code: 'rptqc002', title: 'Item Wise Test', reporturl: 'qualitycontrol/form/ItemWiseTestSingleReport?Id=', fetchpageurl: '/qualitycontrol/itemwisetest/fetchitemwisetest', addpageurl: '/qualitycontrol/itemwisetest/additemwisetest' },
			{ code: 'rptqc003', title: 'Calibration Definition', reporturl: 'qualitycontrol/form/CalibrationDefinitionSingleReport?Id=', fetchpageurl: '/qualitycontrol/calibrationdefinition/fetchcalibrationdefinition', addpageurl: '/qualitycontrol/calibrationdefinition/addcalibrationdefinition' },
			{ code: 'rptqc004', title: 'Char Sampling Method', reporturl: 'qualitycontrol/form/CharSamplingMethodSingleReport?Id=', fetchpageurl: '/qualitycontrol/charsamplingmethod/fetchcharsamplingmethod', addpageurl: '/qualitycontrol/charsamplingmethod/addcharsamplingmethod' },
			{ code: 'rptqc005', title: 'Bread Baking Test', reporturl: 'qualitycontrol/form/BreadBakingTestSingleReport?Id=', fetchpageurl: '/qualitycontrol/breadbakingtest/fetchbreadbakingtest', addpageurl: '/qualitycontrol/breadbakingtest/addbreadbakingtest' },
			{ code: 'rptqc006', title: 'Doughnut Test', reporturl: 'qualitycontrol/form/DoughnutTestSingleReport?Id=', fetchpageurl: '/qualitycontrol/doughnuttest/fetchdoughnuttest', addpageurl: '/qualitycontrol/doughnuttest/adddoughnuttest' },
			{ code: 'rptqc007', title: 'Lab Test', reporturl: 'qualitycontrol/form/LabTestSingleReport?Id=', fetchpageurl: '/qualitycontrol/labtest/fetchlabtest', addpageurl: '/qualitycontrol/labtest/addlabtest' },
			{ code: 'rptqc008', title: 'Rheology Test', reporturl: 'qualitycontrol/form/RheologyTestSingleReport?Id=', fetchpageurl: '/qualitycontrol/rheologytest/fetchrheologytest', addpageurl: '/qualitycontrol/rheologytest/addrheologytest' },
			{ code: 'rptqc009', title: 'Wheat Cleaning Test', reporturl: 'qualitycontrol/form/WheatCleaningTestSingleReport?Id=', fetchpageurl: '/qualitycontrol/wheatcleaningtest/fetchwheatcleaningtest', addpageurl: '/qualitycontrol/wheatcleaningtest/addwheatcleaningtest' },
			{ code: 'rptqc010', title: 'Milling Test', reporturl: 'qualitycontrol/form/MillingTestSingleReport?Id=', fetchpageurl: '/qualitycontrol/millingtest/fetchmillingtest', addpageurl: '/qualitycontrol/millingtest/addmillingtest' },
		],
		analyticprintreport: [
			//Finance
			{
				code: 'rptatf001', moduleName: 'Finance', title: 'Account Ledger', reporturl: 'finance/AccountLedgerOrgLevelReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,

				params: [
					{ label: 'DocumentType', placeholder: 'DocumentType', fieldName: 'DocTypes', type: '' },
					{ label: 'DocumentNo', placeholder: 'DocumentNo', fieldName: 'DocNos', type: '' },
					{ label: 'AccountCode', placeholder: 'AccountCode', fieldName: 'AccountNos', type: '' },
					{ label: 'AccountName', placeholder: 'AccountName', fieldName: 'AccountNames', type: '' }
				]
			},
			//{
			//	code: 'rptatf002', moduleName: 'Finance', title: 'Account Wise', reporturl: 'finance/AccountWiseBalanceReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
			//	params: [
			//		{ label: 'AccountCode', placeholder: 'AccountCode', fieldName: 'AccountNos', type: '' },
			//		{ label: 'AccountName', placeholder: 'AccountName', fieldName: 'AccountNames', type: '' }
			//	]
			//},
			{
				code: 'rptatf003', moduleName: 'Finance', title: 'Agewise Payable', reporturl: 'finance/AgewisePayableReport?', isFromToDate: false, isAsOnDate: true, isDefaultParam: true,
				params: [
					{ label: 'AgeingField', placeholder: 'AgeingField', fieldName: 'AgeingField', type: '' },
					{ label: 'IntervalFrom1', placeholder: 'IntervalFrom1', fieldName: 'IntervalFrom1', type: '' },
					{ label: 'IntervalTo1', placeholder: 'IntervalTo1', fieldName: 'IntervalTo1', type: '' },
					{ label: 'IntervalFrom2', placeholder: 'IntervalFrom2', fieldName: 'IntervalFrom2', type: '' },
					{ label: 'IntervalTo2', placeholder: 'IntervalTo2', fieldName: 'IntervalTo2', type: '' },
					{ label: 'IntervalFrom3', placeholder: 'IntervalFrom3', fieldName: 'IntervalFrom3', type: '' },
					{ label: 'IntervalTo3', placeholder: 'IntervalTo3', fieldName: 'IntervalTo3', type: '' },
					{ label: 'IntervalFrom4', placeholder: 'IntervalFrom4', fieldName: 'IntervalFrom4', type: '' },
					{ label: 'IntervalTo4', placeholder: 'IntervalTo4', fieldName: 'IntervalTo4', type: '' },
					{ label: 'IntervalFrom5', placeholder: 'IntervalFrom5', fieldName: 'IntervalFrom5', type: '' },
					{ label: 'IntervalTo5', placeholder: 'IntervalTo5', fieldName: 'IntervalTo5', type: '' },
					{ label: 'IntervalFrom6', placeholder: 'IntervalFrom6', fieldName: 'IntervalFrom6', type: '' },
					{ label: 'IntervalTo6', placeholder: 'IntervalTo6', fieldName: 'IntervalTo6', type: '' }
				]
			},
			{
				code: 'rptatf004', moduleName: 'Finance', title: 'Agewise Receivable', reporturl: 'finance/AgewiseReceivableReport?', isFromToDate: false, isAsOnDate: true, isDefaultParam: true,
				params: [
					{ label: 'AgeingField', placeholder: 'AgeingField', fieldName: 'AgeingField', type: '' },
					{ label: 'IntervalFrom1', placeholder: 'IntervalFrom1', fieldName: 'IntervalFrom1', type: '' },
					{ label: 'IntervalTo1', placeholder: 'IntervalTo1', fieldName: 'IntervalTo1', type: '' },
					{ label: 'IntervalFrom2', placeholder: 'IntervalFrom2', fieldName: 'IntervalFrom2', type: '' },
					{ label: 'IntervalTo2', placeholder: 'IntervalTo2', fieldName: 'IntervalTo2', type: '' },
					{ label: 'IntervalFrom3', placeholder: 'IntervalFrom3', fieldName: 'IntervalFrom3', type: '' },
					{ label: 'IntervalTo3', placeholder: 'IntervalTo3', fieldName: 'IntervalTo3', type: '' },
					{ label: 'IntervalFrom4', placeholder: 'IntervalFrom4', fieldName: 'IntervalFrom4', type: '' },
					{ label: 'IntervalTo4', placeholder: 'IntervalTo4', fieldName: 'IntervalTo4', type: '' },
					{ label: 'IntervalFrom5', placeholder: 'IntervalFrom5', fieldName: 'IntervalFrom5', type: '' },
					{ label: 'IntervalTo5', placeholder: 'IntervalTo5', fieldName: 'IntervalTo5', type: '' },
					{ label: 'IntervalFrom6', placeholder: 'IntervalFrom6', fieldName: 'IntervalFrom6', type: '' },
					{ label: 'IntervalTo6', placeholder: 'IntervalTo6', fieldName: 'IntervalTo6', type: '' }
				]
			},
			//{
			//	code: 'rptatf005', moduleName: 'Finance', title: 'APCN', reporturl: 'finance/APCNReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
			//	params: [
			//		{ label: 'AccountCode', placeholder: 'AccountCode', fieldName: 'AccountNos', type: '' },
			//		{ label: 'AccountName', placeholder: 'AccountName', fieldName: 'AccountNames', type: '' },
			//		{ label: 'VendorCode', placeholder: 'VendorCode', fieldName: 'VendorCodes', type: '' },
			//		{ label: 'VendorName', placeholder: 'VendorName', fieldName: 'VendorNames', type: '' }
			//	]
			//},
			{
				code: 'rptatf006', moduleName: 'Finance', title: 'AP Credit Note', reporturl: 'finance/APCreditNoteReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatf007', moduleName: 'Finance', title: 'AP Debit Note', reporturl: 'finance/APDebitNoteReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatf008', moduleName: 'Finance', title: 'AR Credit Note', reporturl: 'finance/ARCreditNoteReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			//{
			//	code: 'rptatf009', moduleName: 'Finance', title: 'APDN', reporturl: 'finance/APDNReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
			//	params: [
			//		{ label: 'AccountCode', placeholder: 'AccountCode', fieldName: 'AccountNos', type: '' },
			//		{ label: 'AccountName', placeholder: 'AccountName', fieldName: 'AccountNames', type: '' },
			//		{ label: 'VendorCode', placeholder: 'VendorCode', fieldName: 'VendorCodes', type: '' },
			//		{ label: 'VendorName', placeholder: 'VendorName', fieldName: 'VendorNames', type: '' }
			//	]
			//},
			{
				code: 'rptatf010', moduleName: 'Finance', title: 'AR Debit Note', reporturl: 'finance/ARDebitNoteReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatf011', moduleName: 'Finance', title: 'Balance Sheet', reporturl: 'finance/BalanceSheetReport?', isFromToDate: false, isAsOnDate: true, isDefaultParam: true,
				params: [
					{ label: 'AccountCode', placeholder: 'AccountCode', fieldName: 'AccountNos', type: '' },
					{ label: 'AccountName', placeholder: 'AccountName', fieldName: 'AccountNames', type: '' }
				]
			},
			{
				code: 'rptatf012', moduleName: 'Finance', title: 'Bank Account Wise Balance', reporturl: 'finance/BankAccountWiseBalanceReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
					{ label: 'AccountCode', placeholder: 'AccountCode', fieldName: 'AccountNos', type: '' },
					{ label: 'AccountName', placeholder: 'AccountName', fieldName: 'AccountNames', type: '' }
				]
			},
			//{
			//	code: 'rptatf013', moduleName: 'Finance', title: 'Bank Bookday Wise', reporturl: 'finance/BankBookdayWiseReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
			//	params: [
			//	]
			//},
			{
				code: 'rptatf014', moduleName: 'Finance', title: 'Bank Book', reporturl: 'finance/BankBookReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
					{ label: 'DocumentType', placeholder: 'DocumentType', fieldName: 'DocTypes', type: '' },
					{ label: 'DocumentNo', placeholder: 'DocumentNo', fieldName: 'DocNos', type: '' },
					{ label: 'AccountCode', placeholder: 'AccountCode', fieldName: 'AccountNos', type: '' },
					{ label: 'AccountName', placeholder: 'AccountName', fieldName: 'AccountNames', type: '' }
				]
			},
			{
				code: 'rptatf015', moduleName: 'Finance', title: 'BRS', reporturl: 'finance/BRSReport?', isFromToDate: false, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatf016', moduleName: 'Finance', title: 'Cash Book', reporturl: 'finance/CashBookReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
					{ label: 'DocumentType', placeholder: 'DocumentType', fieldName: 'DocTypes', type: '' },
					{ label: 'DocumentNo', placeholder: 'DocumentNo', fieldName: 'DocNos', type: '' },
					{ label: 'AccountCode', placeholder: 'AccountCode', fieldName: 'AccountNos', type: '' },
					{ label: 'AccountName', placeholder: 'AccountName', fieldName: 'AccountNames', type: '' }
				]
			},
			{
				code: 'rptatf017', moduleName: 'Finance', title: 'Customer Ledger', reporturl: 'finance/CustomerLedgerOrgLevelReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
					{ label: 'DocumentType', placeholder: 'DocumentType', fieldName: 'DocTypes', type: '' },
					{ label: 'DocumentNo', placeholder: 'DocumentNo', fieldName: 'DocNos', type: '' },
					{ label: 'CustomerCode', placeholder: 'CustomerCode', fieldName: 'CustomerCodes', type: '' },
					{ label: 'CustomerName', placeholder: 'CustomerName', fieldName: 'CustomerNames', type: '' }
				]
			},
			{
				code: 'rptatf018', moduleName: 'Finance', title: 'Customer Wise Balance', reporturl: 'finance/CustomerWiseBalanceReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
					{ label: 'CustomerCode', placeholder: 'CustomerCode', fieldName: 'CustomerCodes', type: '' },
					{ label: 'CustomerName', placeholder: 'CustomerName', fieldName: 'CustomerNames', type: '' }
				]
			},
			{
				code: 'rptatf019', moduleName: 'Finance', title: 'Cash Account Wise Balance', reporturl: 'finance/CashAccountWiseBalanceReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
					{ label: 'AccountCode', placeholder: 'AccountCode', fieldName: 'AccountNos', type: '' },
					{ label: 'AccountName', placeholder: 'AccountName', fieldName: 'AccountNames', type: '' }
				]
			},
			{
				code: 'rptatf020', moduleName: 'Finance', title: 'Customer Receipt', reporturl: 'finance/CustomerReceiptReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatf021', moduleName: 'Finance', title: 'General Ledger Payments', reporturl: 'finance/GeneralLedgerPaymentsReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
					{ label: 'AccountCode', placeholder: 'AccountCode', fieldName: 'AccountNos', type: '' },
					{ label: 'AccountName', placeholder: 'AccountName', fieldName: 'AccountNames', type: '' }
				]
			},
			{
				code: 'rptatf022', moduleName: 'Finance', title: 'General Receipt', reporturl: 'finance/GeneralReceiptReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatf023', moduleName: 'Finance', title: 'Petty Cash Payments', reporturl: 'finance/PettyCashPaymentsReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
					{ label: 'AccountCode', placeholder: 'AccountCode', fieldName: 'AccountNos', type: '' },
					{ label: 'AccountName', placeholder: 'AccountName', fieldName: 'AccountNames', type: '' }
				]
			},
			{
				code: 'rptatf024', moduleName: 'Finance', title: 'Petty Cash Receipt', reporturl: 'finance/PettyCashReceiptReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatf025', moduleName: 'Finance', title: 'Profit And Loss', reporturl: 'finance/ProfitAndLossReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
					{ label: 'AccountCode', placeholder: 'AccountCode', fieldName: 'AccountNos', type: '' },
					{ label: 'AccountName', placeholder: 'AccountName', fieldName: 'AccountNames', type: '' }
				]
			},
			{
				code: 'rptatf026', moduleName: 'Finance', title: 'Trail Balance', reporturl: 'finance/TrailBalanceReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
					{ label: 'AccountCode', placeholder: 'AccountCode', fieldName: 'AccountNos', type: '' },
					{ label: 'AccountName', placeholder: 'AccountName', fieldName: 'AccountNames', type: '' },
					{ label: 'AccountGroupName', placeholder: 'AccountGroupName', fieldName: 'AccountGroupNames', type: '' }
				]
			},
			{
				code: 'rptatf027', moduleName: 'Finance', title: 'Vendor Ledger', reporturl: 'finance/VendorLedgerOrgLevelReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
					{ label: 'DocumentType', placeholder: 'DocumentType', fieldName: 'DocTypes', type: '' },
					{ label: 'DocumentNo', placeholder: 'DocumentNo', fieldName: 'DocNos', type: '' },
					{ label: 'VendorCode', placeholder: 'VendorCode', fieldName: 'VendorCodes', type: '' },
					{ label: 'VendorName', placeholder: 'VendorName', fieldName: 'VendorNames', type: '' }
				]
			},
			{
				code: 'rptatf028', moduleName: 'Finance', title: 'Vendor Payment', reporturl: 'finance/VendorPaymentReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatf029', moduleName: 'Finance', title: 'Vendor Wise Balance', reporturl: 'finance/VendorWiseBalanceReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
					{ label: 'VendorCode', placeholder: 'VendorCode', fieldName: 'VendorCodes', type: '' },
					{ label: 'VendorName', placeholder: 'VendorName', fieldName: 'VendorNames', type: '' }
				]
			},
			{
				code: 'rptatf030', moduleName: 'Finance', title: 'Bank Master', reporturl: 'finance/BankMasterReport?', isFromToDate: false, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatf031', moduleName: 'Finance', title: 'Account Master', reporturl: 'finance/AccountMasterReport?', isFromToDate: false, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatf032', moduleName: 'Finance', title: 'Purchase Service Invoice', reporturl: 'finance/PurchaseServiceInvoiceReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatf033', moduleName: 'Finance', title: 'Bank Cash Account Master', reporturl: 'finance/BankCashAccountMasterReport?', isFromToDate: false, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatf034', moduleName: 'Finance', title: 'Employee Ledger', reporturl: 'finance/EmployeeLedgerReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
					{ label: 'DocumentType', placeholder: 'DocumentType', fieldName: 'DocTypes', type: '' },
					{ label: 'DocumentNo', placeholder: 'DocumentNo', fieldName: 'DocNos', type: '' },
					{ label: 'EmployeeCode', placeholder: 'EmployeeCode', fieldName: 'EmployeeCodes', type: '' },
					{ label: 'EmployeeName', placeholder: 'EmployeeName', fieldName: 'EmployeeNames', type: '' }
				]
			},
			{
				code: 'rptatf035', moduleName: 'Finance', title: 'Employee Wise Balance', reporturl: 'finance/EmployeeWiseBalanceReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			//{
			//	code: 'rptatf036', moduleName: 'Finance', title: 'Vendor Wise Balance_Ledger', reporturl: 'finance/VendorWiseBalanceReport_Ledger?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
			//	params: [
			//		{ label: 'DocumentType', placeholder: 'DocumentType', fieldName: 'DocTypes', type: '' },
			//		{ label: 'DocumentNo', placeholder: 'DocumentNo', fieldName: 'DocNos', type: '' },
			//		{ label: 'VendorCode', placeholder: 'VendorCode', fieldName: 'VendorCodes', type: '' },
			//		{ label: 'VendorName', placeholder: 'VendorName', fieldName: 'VendorNames', type: '' }
			//	]
			//},
			{
				code: 'rptatf037', moduleName: 'Finance', title: 'Manifest Wise Income And Expenditure', reporturl: 'finance/ManifestWiseIncomeAndExpenditureReport?', isFromToDate: false, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatf038', moduleName: 'Finance', title: 'Unit Wise Income And Expenditure', reporturl: 'finance/UnitWiseIncomeAndExpenditureReport?', isFromToDate: false, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatf039', moduleName: 'Finance', title: 'Unit Type Wise Revenue And Expenditure', reporturl: 'finance/UnitTypeWiseRevenueAndExpenditureReport?', isFromToDate: false, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatf040', moduleName: 'Finance', title: 'Driver Wise Revenue And Expenditure', reporturl: 'finance/DriverWiseRevenueAndExpenditureReport?', isFromToDate: false, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatf041', moduleName: 'Finance', title: 'Business Plan vs Achived', reporturl: 'finance/BusinessPlanvsAchivedReport?', isFromToDate: false, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatf042', moduleName: 'Finance', title: 'Manifest Wise Payment Requists', reporturl: 'finance/ManifestWisePaymentRequistsReport?', isFromToDate: false, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatf043', moduleName: 'Finance', title: 'Unit Wise Payment Requists', reporturl: 'finance/UnitWisePaymentRequistsReport?', isFromToDate: false, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatf044', moduleName: 'Finance', title: 'Buget vs Actual', reporturl: 'finance/BugetvsActualReport?', isFromToDate: false, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatf045', moduleName: 'Finance', title: 'User Defined Trail Balance ', reporturl: 'finance/TrailBalance2Report?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
					{ label: 'AccountCode', placeholder: 'AccountCode', fieldName: 'AccountNos', type: '' },
					{ label: 'AccountName', placeholder: 'AccountName', fieldName: 'AccountNames', type: '' },
					{ label: 'AccountGroupName', placeholder: 'AccountGroupName', fieldName: 'AccountGroupNames', type: '' }
				]
			},
			{
				code: 'rptatf046', moduleName: 'Finance', title: 'Trail Balance AHDA', reporturl: 'finance/TrailBalanceAHDAReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
					{ label: 'AccountCode', placeholder: 'AccountCode', fieldName: 'AccountNos', type: '' },
					{ label: 'AccountName', placeholder: 'AccountName', fieldName: 'AccountNames', type: '' },
					{ label: 'AccountGroupName', placeholder: 'AccountGroupName', fieldName: 'AccountGroupNames', type: '' }
				]
			},
			{
				code: 'rptatf047', moduleName: 'Finance', title: 'Lender Ledger', reporturl: 'finance/LenderLedgerOrgLevelReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
					{ label: 'DocumentType', placeholder: 'DocumentType', fieldName: 'DocTypes', type: '' },
					{ label: 'DocumentNo', placeholder: 'DocumentNo', fieldName: 'DocNos', type: '' },
					{ label: 'LenderCode', placeholder: 'LenderCode', fieldName: 'LenderCodes', type: '' },
					{ label: 'LenderName', placeholder: 'LenderName', fieldName: 'LenderNames', type: '' }
				]
			},
			{
				code: 'rptatf048', moduleName: 'Finance', title: 'Lender Wise Balance', reporturl: 'finance/LenderWiseBalanceReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
					{ label: 'LenderCode', placeholder: 'LenderCode', fieldName: 'LenderCodes', type: '' },
					{ label: 'LenderName', placeholder: 'LenderName', fieldName: 'LenderNames', type: '' }
				]
			},
			{
				code: 'rptatf049', moduleName: 'Finance', title: 'Budget Line Account Mapping', reporturl: 'finance/BudgetLineAccountMappingReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatf050', moduleName: 'Finance', title: 'UnApprove Cash Book', reporturl: 'finance/UnApproveCashBookReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatf051', moduleName: 'Finance', title: 'Cash Payment', reporturl: 'finance/CashPaymentReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatf052', moduleName: 'Finance', title: 'Agewise Adjusted Payable', reporturl: 'finance/AgewiseAdjustedPayableReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
					{ label: 'AgeingField', placeholder: 'AgeingField', fieldName: 'AgeingField', type: '' },
					{ label: 'IntervalFrom1', placeholder: 'IntervalFrom1', fieldName: 'IntervalFrom1', type: '' },
					{ label: 'IntervalTo1', placeholder: 'IntervalTo1', fieldName: 'IntervalTo1', type: '' },
					{ label: 'IntervalFrom2', placeholder: 'IntervalFrom2', fieldName: 'IntervalFrom2', type: '' },
					{ label: 'IntervalTo2', placeholder: 'IntervalTo2', fieldName: 'IntervalTo2', type: '' },
					{ label: 'IntervalFrom3', placeholder: 'IntervalFrom3', fieldName: 'IntervalFrom3', type: '' },
					{ label: 'IntervalTo3', placeholder: 'IntervalTo3', fieldName: 'IntervalTo3', type: '' },
					{ label: 'IntervalFrom4', placeholder: 'IntervalFrom4', fieldName: 'IntervalFrom4', type: '' },
					{ label: 'IntervalTo4', placeholder: 'IntervalTo4', fieldName: 'IntervalTo4', type: '' },
					{ label: 'IntervalFrom5', placeholder: 'IntervalFrom5', fieldName: 'IntervalFrom5', type: '' },
					{ label: 'IntervalTo5', placeholder: 'IntervalTo5', fieldName: 'IntervalTo5', type: '' },
					{ label: 'IntervalFrom6', placeholder: 'IntervalFrom6', fieldName: 'IntervalFrom6', type: '' },
					{ label: 'IntervalTo6', placeholder: 'IntervalTo6', fieldName: 'IntervalTo6', type: '' }
				]
			},
			{
				code: 'rptatf053', moduleName: 'Finance', title: 'Agewise Adjusted Receivable', reporturl: 'finance/AgewiseAdjustedReceivableReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
					{ label: 'AgeingField', placeholder: 'AgeingField', fieldName: 'AgeingField', type: '' },
					{ label: 'IntervalFrom1', placeholder: 'IntervalFrom1', fieldName: 'IntervalFrom1', type: '' },
					{ label: 'IntervalTo1', placeholder: 'IntervalTo1', fieldName: 'IntervalTo1', type: '' },
					{ label: 'IntervalFrom2', placeholder: 'IntervalFrom2', fieldName: 'IntervalFrom2', type: '' },
					{ label: 'IntervalTo2', placeholder: 'IntervalTo2', fieldName: 'IntervalTo2', type: '' },
					{ label: 'IntervalFrom3', placeholder: 'IntervalFrom3', fieldName: 'IntervalFrom3', type: '' },
					{ label: 'IntervalTo3', placeholder: 'IntervalTo3', fieldName: 'IntervalTo3', type: '' },
					{ label: 'IntervalFrom4', placeholder: 'IntervalFrom4', fieldName: 'IntervalFrom4', type: '' },
					{ label: 'IntervalTo4', placeholder: 'IntervalTo4', fieldName: 'IntervalTo4', type: '' },
					{ label: 'IntervalFrom5', placeholder: 'IntervalFrom5', fieldName: 'IntervalFrom5', type: '' },
					{ label: 'IntervalTo5', placeholder: 'IntervalTo5', fieldName: 'IntervalTo5', type: '' },
					{ label: 'IntervalFrom6', placeholder: 'IntervalFrom6', fieldName: 'IntervalFrom6', type: '' },
					{ label: 'IntervalTo6', placeholder: 'IntervalTo6', fieldName: 'IntervalTo6', type: '' }
				]
			},
			{
				code: 'rptatf054', moduleName: 'Finance', title: 'Employee Ledger Liquidation', reporturl: 'finance/EmployeeLedgerLiquidationReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
					{ label: 'DocumentType', placeholder: 'DocumentType', fieldName: 'DocTypes', type: '' },
					{ label: 'DocumentNo', placeholder: 'DocumentNo', fieldName: 'DocNos', type: '' },
					{ label: 'EmployeeCode', placeholder: 'EmployeeCode', fieldName: 'EmployeeCodes', type: '' },
					{ label: 'EmployeeName', placeholder: 'EmployeeName', fieldName: 'EmployeeNames', type: '' }
				]
			},
			{
				code: 'rptatf055', moduleName: 'Finance', title: 'Employee Ledger Advances', reporturl: 'finance/EmployeeLedgerAdvancesReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
					{ label: 'DocumentType', placeholder: 'DocumentType', fieldName: 'DocTypes', type: '' },
					{ label: 'DocumentNo', placeholder: 'DocumentNo', fieldName: 'DocNos', type: '' },
					{ label: 'EmployeeCode', placeholder: 'EmployeeCode', fieldName: 'EmployeeCodes', type: '' },
					{ label: 'EmployeeName', placeholder: 'EmployeeName', fieldName: 'EmployeeNames', type: '' }
				]
			},
			//Inventory
			{
				code: 'rptati001', moduleName: 'Inventory', title: 'Current Inventory', reporturl: 'inventory/CurrentInventoryReport?', isFromToDate: false, isAsOnDate: true, isDefaultParam: true,
				params: [
					//{ label: 'CategoryName', placeholder: 'CategoryName', fieldName: 'CategoryNames', type: '' },
					//{ label: 'TypeName', placeholder: 'TypeName', fieldName: 'TypeNames', type: '' },
					{ label: 'ItemCode', placeholder: 'ItemCode', fieldName: 'ItemCodes', type: '' },
					{ label: 'ItemName', placeholder: 'ItemName', fieldName: 'ItemNames', type: '' }
				]
			},
			{
				code: 'rptati002', moduleName: 'Inventory', title: 'Delivery Note', reporturl: 'inventory/DeliveryNoteReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptati003', moduleName: 'Inventory', title: 'GRN', reporturl: 'inventory/GRNReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				/*	{ label: 'VendorCode', placeholder: 'VendorCode', fieldName: 'VendorCodes', type: '' },*/
					{ label: 'VendorName', placeholder: 'VendorName', fieldName: 'VendorNames', type: '' },
					{ label: 'DocumentNo', placeholder: 'DocumentNo', fieldName: 'DocNos', type: 'GRN' }
				]
			},
			{
				code: 'rptati004', moduleName: 'Inventory', title: 'Item Master', reporturl: 'inventory/ItemMasterReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
					{ label: 'CategoryName', placeholder: 'CategoryName', fieldName: 'CategoryNames', type: '' },
					{ label: 'TypeName', placeholder: 'TypeName', fieldName: 'TypeNames', type: '' },
					{ label: 'ItemCode', placeholder: 'ItemCode', fieldName: 'ItemCodes', type: '' },
					{ label: 'ItemName', placeholder: 'ItemName', fieldName: 'ItemNames', type: '' }
				]
			},
			{
				code: 'rptati005', moduleName: 'Inventory', title: 'Purchase Indent', reporturl: 'inventory/PurchaseIndentReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
					{ label: 'ItemCode', placeholder: 'ItemCode', fieldName: 'ItemCodes', type: '' },
					{ label: 'ItemName', placeholder: 'ItemName', fieldName: 'ItemNames', type: '' }
				]
			},
			{
				code: 'rptati006', moduleName: 'Inventory', title: 'Purchase Return', reporturl: 'inventory/PurchaseReturnReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptati007', moduleName: 'Inventory', title: 'Purchase Statistic Invoice', reporturl: 'inventory/PurchaseStatisticInvoiceReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
					{ label: 'DivisionCode', placeholder: 'DivisionCode', fieldName: 'DivisionCodes', type: '' },
					{ label: 'VendorCode', placeholder: 'VendorCode', fieldName: 'VendorCodes', type: '' },
					{ label: 'VendorName', placeholder: 'VendorName', fieldName: 'VendorNames', type: '' },
					{ label: 'DocumentNo', placeholder: 'DocumentNo', fieldName: 'DocNos', type: '' }
				]
			},
			{
				code: 'rptati008', moduleName: 'Inventory', title: 'Stores Issues', reporturl: 'inventory/StoresIssuesReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
					{ label: 'ItemCode', placeholder: 'ItemCode', fieldName: 'ItemCodes', type: '' },
					{ label: 'ItemName', placeholder: 'ItemName', fieldName: 'ItemNames', type: '' },
					{ label: 'DocumentNo', placeholder: 'DocumentNo', fieldName: 'DocNos', type: '' }
				]
			},
			{
				code: 'rptati009', moduleName: 'Inventory', title: 'Stock movement', reporturl: 'inventory/StockmovementReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
					{ label: 'CategoryName', placeholder: 'CategoryName', fieldName: 'CategoryNames', type: '' },
					{ label: 'TypeName', placeholder: 'TypeName', fieldName: 'TypeNames', type: '' },
					{ label: 'ItemCode', placeholder: 'ItemCode', fieldName: 'ItemCodes', type: '' },
					{ label: 'ItemName', placeholder: 'ItemName', fieldName: 'ItemNames', type: '' }
				]
			},
			{
				code: 'rptati010', moduleName: 'Inventory', title: 'Stock Opening Balance', reporturl: 'inventory/StockOpeningBalanceReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptati011', moduleName: 'Inventory', title: 'Day Wise Delivery', reporturl: 'inventory/DayWiseDeliveryReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptati012', moduleName: 'Inventory', title: 'Customer Wise Delivery Details', reporturl: 'inventory/CustomerWiseDeliveryDetailsReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptati013', moduleName: 'Inventory', title: 'Delivery Summary Details', reporturl: 'inventory/DeliverySummaryDetailsReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptati014', moduleName: 'Inventory', title: 'Location Wise Stock Details', reporturl: 'inventory/LocationWiseStockDetailsReport?', isFromToDate: false, isAsOnDate: true, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptati015', moduleName: 'Inventory', title: 'IST Issue', reporturl: 'inventory/ISTIssueReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptati016', moduleName: 'Inventory', title: 'Location Transfer', reporturl: 'inventory/LocationTransferReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptati017', moduleName: 'Inventory', title: 'Stock Receipts', reporturl: 'inventory/StockReceiptsReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptati018', moduleName: 'Inventory', title: 'Stores Issues Summary', reporturl: 'inventory/StoresIssuesSummaryReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptati019', moduleName: 'Inventory', title: 'Stores Issues Item Wise Summary', reporturl: 'inventory/StoresIssuesItemWiseSummaryReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptati020', moduleName: 'Inventory', title: 'Stores Issues Group Wise Summary', reporturl: 'inventory/StoresIssuesGroupWiseSummaryReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptati021', moduleName: 'Inventory', title: 'Stock Ledger', reporturl: 'inventory/StockLedgerReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				
					{ label: 'DocumentType', placeholder: 'DocumentType', fieldName: 'DocTypes', type: '' },
					{ label: 'ItemName', placeholder: 'ItemName', fieldName: 'ItemNames', type: '' }
				]
			},
			{
				code: 'rptati022', moduleName: 'Inventory', title: 'Lender Delivery Note Issue', reporturl: 'inventory/LenderDeliveryNoteIssueReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptati023', moduleName: 'Inventory', title: 'Lender Delivery Note Receipt', reporturl: 'inventory/LenderDeliveryNoteReceiptReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptati024', moduleName: 'Inventory', title: 'Tyres Consumpiton', reporturl: 'inventory/TyresConsumpitonReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptati025', moduleName: 'Inventory', title: 'Spare Parts Consumpiton', reporturl: 'inventory/SparePartsConsumpitonReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptati026', moduleName: 'Inventory', title: 'Group Wise Stores Issues', reporturl: 'inventory/GroupWiseStoresIssuesReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptati027', moduleName: 'Inventory', title: 'Unit Wise Revenue And Expenses', reporturl: 'inventory/UnitWiseRevenueAndExpensesReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			//Commercial
			{
				code: 'rptatc001', moduleName: 'Commercial', title: 'Customer Wise Manifest Status', reporturl: 'commercial/CustomerWiseManifestStatusReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatc002', moduleName: 'Commercial', title: 'Customer Wise Transport Revenue', reporturl: 'commercial/CustomerWiseRevenueReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
					{ label: 'CustomerCode', placeholder: 'CustomerCode', fieldName: 'CustomerCodes', type: '' },
					{ label: 'CustomerName', placeholder: 'CustomerName', fieldName: 'CustomerNames', type: '' }
				]
			},
			{
				code: 'rptatc003', moduleName: 'Commercial', title: 'Fleet Performance Month Wise', reporturl: 'commercial/FleetPerformanceMonthWiseReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatc004', moduleName: 'Commercial', title: 'Fleet Performance Summary', reporturl: 'commercial/FleetPerformanceSummaryReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatc005', moduleName: 'Commercial', title: 'Manifest Creation', reporturl: 'commercial/ManifestCreationReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
					{ label: 'CustomerCode', placeholder: 'CustomerCode', fieldName: 'CustomerCodes', type: '' },
					{ label: 'CustomerName', placeholder: 'CustomerName', fieldName: 'CustomerNames', type: '' },
					/*{ label: 'DocumentNo', placeholder: 'DocumentNo', fieldName: 'DocNos', type: 'ManifestCreation' }*/
				]
			},
			{
				code: 'rptatc006', moduleName: 'Commercial', title: 'Month Wise Revenue Summary', reporturl: 'commercial/MonthWiseRevenueSummaryReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatc007', moduleName: 'Commercial', title: 'Route Wise Revenue', reporturl: 'commercial/RouteWiseRevenueReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
					{ label: 'CustomerCode', placeholder: 'CustomerCode', fieldName: 'CustomerCodes', type: '' },
					{ label: 'CustomerName', placeholder: 'CustomerName', fieldName: 'CustomerNames', type: '' },
					/*{ label: 'DocumentNo', placeholder: 'DocumentNo', fieldName: 'DocNos', type: '' }*/
				]
			},
			{
				code: 'rptatc008', moduleName: 'Commercial', title: 'Route Wise Rate Per KM', reporturl: 'commercial/RouteWiseRatePerKMReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
					{ label: 'CustomerCode', placeholder: 'CustomerCode', fieldName: 'CustomerCodes', type: '' },
					{ label: 'CustomerName', placeholder: 'CustomerName', fieldName: 'CustomerNames', type: '' },
					{ label: 'RouteCode', placeholder: 'RouteCode', fieldName: 'RouteCodes', type: '' },
					{ label: 'RouteName', placeholder: 'RouteName', fieldName: 'RouteNames', type: '' }
				]
			},
			{
				code: 'rptatc009', moduleName: 'Commercial', title: 'Freight Contract', reporturl: 'commercial/FreightContractReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatc010', moduleName: 'Commercial', title: 'Trip Order', reporturl: 'commercial/TripOrderReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatc011', moduleName: 'Commercial', title: 'Quotation', reporturl: 'commercial/QuotationReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatc012', moduleName: 'Commercial', title: 'Trip Invoice', reporturl: 'commercial/TripInvoiceReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatc013', moduleName: 'Commercial', title: 'Manifest Invoice', reporturl: 'commercial/ManifestInvoiceReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatc014', moduleName: 'Commercial', title: 'Trip', reporturl: 'commercial/TripReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatc015', moduleName: 'Commercial', title: 'Commercial Fleet Contract', reporturl: 'commercial/CommercialFleetContractReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatc016', moduleName: 'Commercial', title: 'Commercial Fleet Invoice', reporturl: 'commercial/CommercialFleetInvoiceReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatc017', moduleName: 'Commercial', title: 'UnInvoiced Manifests', reporturl: 'commercial/UnInvoicedManifestsReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatc018', moduleName: 'Commercial', title: 'Manifest Invoice Register', reporturl: 'commercial/ManifestInvoiceRegisterReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatc019', moduleName: 'Commercial', title: 'Daily Commercial', reporturl: 'commercial/CommercialAllReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatc020', moduleName: 'Commercial', title: 'Fuel Consumpiton', reporturl: 'commercial/FuelConsumpitonReport?', isFromToDate: false, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			//General
			{
				code: 'rptatg001', moduleName: 'HRM', title: 'Payroll', reporturl: 'general/RaibaLandTransportPayrollReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatg002', moduleName: 'General', title: 'No Of Transaction', reporturl: 'general/NoOfTransactionReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			//Procurement
			{
				code: 'rptatp001', moduleName: 'Procurement', title: 'Advance Request', reporturl: 'procurement/AdvanceRequestReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatp002', moduleName: 'Procurement', title: 'Proforma Invoice', reporturl: 'procurement/ProformaInvoiceReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatp003', moduleName: 'Procurement', title: 'Purchase Invoice', reporturl: 'procurement/PurchaseInvoiceReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
					{ label: 'ItemCodes', placeholder: 'ItemCodes', fieldName: 'ItemCodes', type: '' },
					{ label: 'DocumentNo', placeholder: 'DocumentNo', fieldName: 'DocNos', type: 'PurchaseInvoice' }
				]
			},
			{
				code: 'rptatp004', moduleName: 'Procurement', title: 'Purchase Order', reporturl: 'procurement/PurchaseOrderReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
					{ label: 'ItemCodes', placeholder: 'ItemCodes', fieldName: 'ItemCodes', type: '' },
					{ label: 'ItemName', placeholder: 'ItemName', fieldName: 'ItemNames', type: '' },
					{ label: 'DocumentNo', placeholder: 'DocumentNo', fieldName: 'DocNos', type: 'PurchaseOrder' }
				]
			},
			{
				code: 'rptatp005', moduleName: 'Procurement', title: 'Purchase Quotation', reporturl: 'procurement/PurchaseQuotationReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatp006', moduleName: 'Procurement', title: 'Purchase Requisition', reporturl: 'procurement/PurchaseRequisitionReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
					{ label: 'DocumentNo', placeholder: 'DocumentNo', fieldName: 'DocNos', type: 'PurchaseRequisition' },
					{ label: 'ItemCodes', placeholder: 'ItemCodes', fieldName: 'ItemCodes', type: '' },
					{ label: 'ItemName', placeholder: 'ItemName', fieldName: 'ItemNames', type: '' }
				]
			},
			{
				code: 'rptatp007', moduleName: 'Procurement', title: 'Purchase Order Line Items', reporturl: 'procurement/PurchaseOrderLineItemsReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
					{ label: 'VendorCode', placeholder: 'VendorCode', fieldName: 'VendorCodes', type: '' },
					{ label: 'VendorName', placeholder: 'VendorName', fieldName: 'VendorNames', type: '' },
					{ label: 'ItemCodes', placeholder: 'ItemCodes', fieldName: 'ItemCodes', type: '' },
					{ label: 'ItemName', placeholder: 'ItemName', fieldName: 'ItemNames', type: '' }
				]
			},
			{
				code: 'rptatp008', moduleName: 'Procurement', title: 'Loading Request', reporturl: 'procurement/LoadingRequestReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatp009', moduleName: 'Procurement', title: 'Transport Charges', reporturl: 'procurement/TransportChargesReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatp010', moduleName: 'Procurement', title: 'Vendor Master', reporturl: 'procurement/VendorMasterReport?', isFromToDate: false, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatp011', moduleName: 'Procurement', title: 'Daywise Purchases', reporturl: 'procurement/DaywisePurchasesReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatp012', moduleName: 'Procurement', title: 'Daywise Tyre Purchases', reporturl: 'procurement/DaywiseTyrePurchasesReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatp013', moduleName: 'Procurement', title: 'Daywise Capex Item Purchase', reporturl: 'procurement/DaywiseCapexItemPurchaseReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatp014', moduleName: 'Procurement', title: 'Purchase Order vs GRN vs Purchase Invoice', reporturl: 'procurement/PurchaseOrdervsGRNvsPurchaseInvoiceReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			//Sales
			{
				code: 'rptats001', moduleName: 'Sales', title: 'Sales Invoice', reporturl: 'sales/SalesInvoiceReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptats002', moduleName: 'Sales', title: 'Sales Order', reporturl: 'sales/SalesOrderReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptats003', moduleName: 'Sales', title: 'Sales Quotation', reporturl: 'sales/SalesQuotationReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptats004', moduleName: 'Sales', title: 'Sales Register', reporturl: 'sales/SalesRegisterReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
					/*{ label: 'CustomerCode', placeholder: 'CustomerCode', fieldName: 'CustomerCodes', type: '' },*/
					{ label: 'CustomerName', placeholder: 'CustomerName', fieldName: 'CustomerNames', type: '' }
				]
			},
			{
				code: 'rptats005', moduleName: 'Sales', title: 'Delivery Schedule Line Item Wise', reporturl: 'sales/DeliveryScheduleLineItemWiseReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptats006', moduleName: 'Sales', title: 'Sales Invoice Line Item Wise', reporturl: 'sales/SalesInvoiceLineItemWiseReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptats007', moduleName: 'Sales', title: 'Sales Quotation Line Item Wise', reporturl: 'sales/SalesQuotationLineItemWiseReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
					{ label: 'CustomerCode', placeholder: 'CustomerCode', fieldName: 'CustomerCodes', type: '' },
					{ label: 'CustomerName', placeholder: 'CustomerName', fieldName: 'CustomerNames', type: '' },
					{ label: 'ItemCodes', placeholder: 'ItemCodes', fieldName: 'ItemCodes', type: '' },
					{ label: 'ItemName', placeholder: 'ItemName', fieldName: 'ItemNames', type: '' }
				]
			},
			{
				code: 'rptats008', moduleName: 'Sales', title: 'Sales Order Line Item Wise', reporturl: 'sales/SalesOrderLineItemWiseReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
					/*{ label: 'CustomerCode', placeholder: 'CustomerCode', fieldName: 'CustomerCodes', type: '' },*/
					{ label: 'CustomerName', placeholder: 'CustomerName', fieldName: 'CustomerNames', type: '' },
					/*{ label: 'ItemCodes', placeholder: 'ItemCodes', fieldName: 'ItemCodes', type: '' },*/
					{ label: 'ItemName', placeholder: 'ItemName', fieldName: 'ItemNames', type: '' }
				]
			},
			{
				code: 'rptats009', moduleName: 'Sales', title: 'SalesOrder vs DN vs SalesInvoice', reporturl: 'sales/SalesOrdervsDNvsSalesInvoiceReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
					{ label: 'CustomerCode', placeholder: 'CustomerCode', fieldName: 'CustomerCodes', type: '' },
					{ label: 'CustomerName', placeholder: 'CustomerName', fieldName: 'CustomerNames', type: '' },
					{ label: 'ItemCodes', placeholder: 'ItemCodes', fieldName: 'ItemCodes', type: '' },
					{ label: 'ItemName', placeholder: 'ItemName', fieldName: 'ItemNames', type: '' }
				]
			},
			{
				code: 'rptats010', moduleName: 'Sales', title: 'Customerwise Delivery Status', reporturl: 'sales/CustomerWiseSalesOrdervsDNvsSalesInvoiceReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
					{ label: 'CustomerCode', placeholder: 'CustomerCode', fieldName: 'CustomerCodes', type: '' },
					{ label: 'CustomerName', placeholder: 'CustomerName', fieldName: 'CustomerNames', type: '' },
					{ label: 'ItemCodes', placeholder: 'ItemCodes', fieldName: 'ItemCodes', type: '' },
					{ label: 'ItemName', placeholder: 'ItemName', fieldName: 'ItemNames', type: '' }
				]
			},
			{
				code: 'rptats011', moduleName: 'Sales', title: 'Productwise Sales vs Delivery Summary', reporturl: 'sales/SalesOrdervsDNvsSalesInvoiceSummaryReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
					{ label: 'ItemCodes', placeholder: 'ItemCodes', fieldName: 'ItemCodes', type: '' },
					{ label: 'ItemName', placeholder: 'ItemName', fieldName: 'ItemNames', type: '' }
				]
			},
			{
				code: 'rptats012', moduleName: 'Sales', title: 'Sales Person Wise Summary', reporturl: 'sales/SalesPersonWiseSummaryReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptats013', moduleName: 'Sales', title: 'Region Wise Revenue Summary', reporturl: 'sales/RegionWiseRevenueSummaryReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptats014', moduleName: 'Sales', title: 'Customer Wise Revenue Summary', reporturl: 'sales/CustomerWiseRevenueSummaryReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptats015', moduleName: 'Sales', title: 'Product Wise Revenue Summary', reporturl: 'sales/ProductWiseRevenueSummaryReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptats016', moduleName: 'Sales', title: 'Month Wise Revenue Details', reporturl: 'sales/MonthWiseRevenueDetailsReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptats017', moduleName: 'Sales', title: 'Customer Wise Month Wise Revenue', reporturl: 'sales/CustomerWiseMonthWiseRevenueReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptats018', moduleName: 'Sales', title: 'Sales Return', reporturl: 'sales/SalesReturnReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptats019', moduleName: 'Sales', title: 'Customer Master', reporturl: 'sales/CustomerMasterReport?', isFromToDate: false, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptats020', moduleName: 'Sales', title: 'Invoice Register Afisa', reporturl: 'sales/InvoiceRegisterAfisaReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptats021', moduleName: 'Sales', title: 'Sales Turnover', reporturl: 'sales/SalesTurnoverReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			//TMS
			{
				code: 'rptattm002', moduleName: 'TMS', title: 'Removed Tyres BrandWise Summary', reporturl: 'tms/RemovedTyresBrandWiseSummaryReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
					{ label: 'SizeId', placeholder: 'SizeId', fieldName: 'SizeId', type: '' }
				]
			},
			{
				code: 'rptattm003', moduleName: 'TMS', title: 'Technical Inspection', reporturl: 'tms/TechnicalInspectionReport?', isFromToDate: false, isAsOnDate: false, isDefaultParam: false,
				params: [
					{ label: 'UnitId', placeholder: 'UnitId', fieldName: 'UnitId', type: '' }
				]
			},
			{
				code: 'rptattm004', moduleName: 'TMS', title: 'Tyre Mounting', reporturl: 'tms/TyreMountingReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptattm005', moduleName: 'TMS', title: 'Tyre Removing', reporturl: 'tms/TyreRemovingReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptattm006', moduleName: 'TMS', title: 'Unit Tyre Details', reporturl: 'tms/UnitTyreDetailsReport?', isFromToDate: false, isAsOnDate: false, isDefaultParam: true,
				params: [
					{ label: 'UnitId', placeholder: 'UnitId', fieldName: 'UnitId', type: '' }
				]
			},
			{
				code: 'rptattm007', moduleName: 'TMS', title: 'Working Tyres Brand Wise Summary', reporturl: 'tms/WorkingTyresBrandWiseSummaryReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
					{ label: 'SizeId', placeholder: 'SizeId', fieldName: 'SizeId', type: '' }
				]
			},
			//Operations
			{
				code: 'rptato001', moduleName: 'Operations', title: 'Fuel Slip', reporturl: 'operation/FuelSlipReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
					{ label: 'UnitNo', placeholder: 'UnitNo', fieldName: 'UnitNo', type: '' },
					{ label: 'ExternalFuelStation', placeholder: 'ExternalFuelStation', fieldName: 'ExternalFuelStation', type: '' },
					{ label: 'RouteName', placeholder: 'RouteName', fieldName: 'RouteName', type: '' },
					{ label: 'DriverCardNo', placeholder: 'DriverCardNo', fieldName: 'DriverCardNo', type: '' }
				]
			},
			{
				code: 'rptato002', moduleName: 'Operations', title: 'Truck Head', reporturl: 'operation/TruckHeadReport?', isFromToDate: false, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptato003', moduleName: 'Operations', title: 'Trailer', reporturl: 'operation/TrailerReport?', isFromToDate: false, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptato004', moduleName: 'Operations', title: 'Unit', reporturl: 'operation/UnitReport?', isFromToDate: false, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptato005', moduleName: 'Operations', title: 'Driver Information', reporturl: 'operation/DriverInformationReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptato006', moduleName: 'Operations', title: 'Driver Information 1', reporturl: 'operation/DriverInformationReport1?', isFromToDate: false, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptato007', moduleName: 'Operations', title: 'Driver Accidents', reporturl: 'operation/DriverAccidentsReport?', isFromToDate: false, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptato008', moduleName: 'Operations', title: 'Truck Insurance', reporturl: 'operation/TruckInsuranceReport?', isFromToDate: false, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptato009', moduleName: 'Operations', title: 'Registration', reporturl: 'operation/RegistrationReport?', isFromToDate: false, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptato010', moduleName: 'Operations', title: 'Driver Shortages', reporturl: 'operation/DriverShortagesReport?', isFromToDate: false, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptato011', moduleName: 'Operations', title: 'Route Master', reporturl: 'operation/RouteMasterReport?', isFromToDate: false, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			//Container Management
			{
				code: 'rptatcm001', moduleName: 'Container Management', title: 'Container Delivery Note', reporturl: 'cms/ContainerDeliveryNoteReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatcm002', moduleName: 'Container Management', title: 'Container Receipt Note', reporturl: 'cms/ContainerReceiptNoteReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatcm003', moduleName: 'Container Management', title: 'Container Invoice', reporturl: 'cms/ContainerInvoiceReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatcm004', moduleName: 'Container Management', title: 'Container Contract', reporturl: 'cms/ContainerContractReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatcm005', moduleName: 'Container Management', title: 'Container Movement Summary', reporturl: 'cms/ContainerMovementSummaryReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatcm006', moduleName: 'Container Management', title: 'Containers List', reporturl: 'cms/ContainersListReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatcm007', moduleName: 'Container Management', title: 'Containers List with No of Days', reporturl: 'cms/ContainersListwithNoofDaysReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatcm008', moduleName: 'Container Management', title: 'Shipping Line Wise Summary', reporturl: 'cms/ShippingLineWiseSummaryReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatcm009', moduleName: 'Container Management', title: 'Container Revenue Charges', reporturl: 'cms/ContainerRevenueChargesReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatcm010', moduleName: 'Container Management', title: 'Shipping Line Wise Revenue Charges', reporturl: 'cms/ShippingLineWiseRevenueChargesReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatcm011', moduleName: 'Container Management', title: 'Current Container Stock', reporturl: 'cms/CurrentContainerStockReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			//Technical
			{
				code: 'rptatt001', moduleName: 'Technical', title: 'Stores Indent', reporturl: 'technical/StoresIndentReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatt002', moduleName: 'Technical', title: 'Daily Technical', reporturl: 'tms/DailyTechnicalReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			//Production
			{
				code: 'rptatpr001', moduleName: 'Production', title: 'Production Scheduling', reporturl: 'production/ProductionSchedulingReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatpr002', moduleName: 'Production', title: 'Planned Order Production Items', reporturl: 'production/PlannedOrderProductionItemsReport?', isFromToDate: false, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatpr003', moduleName: 'Production', title: 'Planned Order Procurement Items', reporturl: 'production/PlannedOrderProcurementItemsReport?', isFromToDate: false, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatpr004', moduleName: 'Production', title: 'Job Card', reporturl: 'production/JobCardReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatpr005', moduleName: 'Production', title: 'Planned Order Release', reporturl: 'production/PlannedOrderReleaseReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatpr006', moduleName: 'Production', title: 'Production Order', reporturl: 'production/ProductionOrderReport?', isFromToDate: false, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatpr007', moduleName: 'Production', title: 'Production Order Settlement', reporturl: 'production/ProductionOrderSettlementReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatpr008', moduleName: 'Production', title: 'Production Receipts', reporturl: 'production/ProductionReceiptsReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},

			//Fixed Assets
			{
				code: 'rptatfa001', moduleName: 'Fixed Assets', title: 'Asset Acquisition', reporturl: 'fixedassets/AssetAcquisitionReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatfa002', moduleName: 'Fixed Assets', title: 'Asset Open Balance', reporturl: 'fixedassets/AssetOpenBalanceReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatfa003', moduleName: 'Fixed Assets', title: 'Asset Transfer Same Unit', reporturl: 'fixedassets/AssetTransferSameUnitReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatfa004', moduleName: 'Fixed Assets', title: 'Asset Transfer Different Unit', reporturl: 'fixedassets/AssetTransferDifferentUnitReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatfa005', moduleName: 'Fixed Assets', title: 'Asset Revaluation', reporturl: 'fixedassets/AssetRevaluationReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatfa006', moduleName: 'Fixed Assets', title: 'Sale or Discard', reporturl: 'fixedassets/SaleorDiscardReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},

			//Quality Control
			{
				code: 'rptatqc001', moduleName: 'QualityControl', title: 'Milling Test', reporturl: 'qualitycontrol/MillingTestReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatqc002', moduleName: 'QualityControl', title: 'Bread Baking Test', reporturl: 'qualitycontrol/BreadBakingTestReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatqc003', moduleName: 'QualityControl', title: 'Doughnut Test', reporturl: 'qualitycontrol/DoughnutTestReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatqc004', moduleName: 'QualityControl', title: 'Lab Test', reporturl: 'qualitycontrol/LabTestReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatqc005', moduleName: 'QualityControl', title: 'Rheology Test', reporturl: 'qualitycontrol/RheologyTestReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatqc006', moduleName: 'QualityControl', title: 'Wheat Cleaning Test', reporturl: 'qualitycontrol/WheatCleaningTestReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			//Flour Production
			{
				code: 'rptatfp001', moduleName: 'FLOUR PRODUCTION', title: 'Bage Weight Registration', reporturl: 'flourproduction/BageWeightRegistrationReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatfp002', moduleName: 'FLOUR PRODUCTION', title: 'Flour Distraction', reporturl: 'flourproduction/FlourDistractionReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatfp003', moduleName: 'FLOUR PRODUCTION', title: 'Flour Production Job Card', reporturl: 'flourproduction/FlourProdJobCardReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatfp004', moduleName: 'FLOUR PRODUCTION', title: 'Flour Prdoduction Stores Indent', reporturl: 'flourproduction/FlourProdStoresIndentReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatfp005', moduleName: 'FLOUR PRODUCTION', title: 'Flour Control', reporturl: 'flourproduction/FlourControlReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptatfp006', moduleName: 'FLOUR PRODUCTION', title: 'Bran Control', reporturl: 'flourproduction/BranControlReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},

			//HRM
			{
				code: 'rptath001', moduleName: 'HRM', title: 'Non Standard Deductions', reporturl: 'hrm/NonStandardDeductionsReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptath002', moduleName: 'HRM', title: 'Advance Issue', reporturl: 'hrm/AdvanceIssueReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptath003', moduleName: 'HRM', title: 'Loan Issue', reporturl: 'hrm/LoanIssueReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptath004', moduleName: 'HRM', title: 'Leave Ledger', reporturl: 'hrm/LeaveLedgerReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptath005', moduleName: 'HRM', title: 'Employee Wise Leave', reporturl: 'hrm/EmployeeWiseLeaveReport?', isFromToDate: false, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptath006', moduleName: 'HRM', title: 'Advance Requisition', reporturl: 'hrm/AdvanceRequisitionReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptath007', moduleName: 'HRM', title: 'Leave Issue', reporturl: 'hrm/LeaveIssueReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptath008', moduleName: 'HRM', title: 'Leave Requisition', reporturl: 'hrm/LeaveRequisitionReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptath009', moduleName: 'HRM', title: 'Employee Wise Attendance', reporturl: 'hrm/EmployeeWiseAttendanceReport?', isFromToDate: false, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
			{
				code: 'rptath010', moduleName: 'HRM', title: 'Job Offer Generation', reporturl: 'hrm/JobOfferGenerationReport?', isFromToDate: true, isAsOnDate: false, isDefaultParam: true,
				params: [
				]
			},
		]
	};

	public get configs(): any {
		return this.defaults;
	}
}
