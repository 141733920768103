import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription } from 'rxjs';
import { map, catchError, switchMap, finalize } from 'rxjs/operators';
import { UserModel } from '../_models/user.model';
import { AuthModel } from '../_models/auth.model';
import { AuthHTTPService } from './auth-http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class AuthService implements OnDestroy {
    // private fields
    private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
    private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

    // public fields
    currentUser$: Observable<UserModel>;
    isLoading$: Observable<boolean>;
    currentUserSubject: BehaviorSubject<UserModel>;
    isLoadingSubject: BehaviorSubject<boolean>;


    get currentUserValue(): UserModel {
        return this.currentUserSubject.value;
    }

    set currentUserValue(user: UserModel) {
        this.currentUserSubject.next(user);
    }

    constructor(
        private authHttpService: AuthHTTPService,
        private router: Router
    ) {
        this.isLoadingSubject = new BehaviorSubject<boolean>(false);
        this.currentUserSubject = new BehaviorSubject<UserModel>(undefined);
        this.currentUser$ = this.currentUserSubject.asObservable();
        this.isLoading$ = this.isLoadingSubject.asObservable();
        const subscr = this.getUserByToken().subscribe();
        this.unsubscribe.push(subscr);
    }

    // public methods
    login(email: string, password: string): Observable<UserModel> {
        this.isLoadingSubject.next(true);
        return this.authHttpService.login(email, password).pipe(
            map((auth: AuthModel) => {
                const result = this.setAuthFromLocalStorage(auth);
                return result;
            }),
            switchMap(() => this.getUserByToken()),
            catchError((err) => {
                console.error('err', err);
                return of(undefined);
            }),
            finalize(() => this.isLoadingSubject.next(false))
        );
    }

    logout() {
        localStorage.removeItem(this.authLocalStorageToken);
        this.router.navigate(['/auth/login'], {
            queryParams: {},
        });
    }

    getUserByToken(): Observable<UserModel> {
        const auth = this.getAuthFromLocalStorage();
        if (!auth || !auth.accessToken) {
            return of(undefined);
        }

        this.isLoadingSubject.next(true);
        return this.authHttpService.getUserByToken(auth.accessToken).pipe(
            map((user: UserModel) => {
                if (user) {
                    this.currentUserSubject = new BehaviorSubject<UserModel>(user);
                } else {
                    this.logout();
                }
                return user;
            }),
            finalize(() => this.isLoadingSubject.next(false))
        );
    }

    // need create new user then login
    registration(user: UserModel): Observable<any> {
        this.isLoadingSubject.next(true);
        return this.authHttpService.createUser(user).pipe(
            map(() => {
                this.isLoadingSubject.next(false);
            }),
            switchMap(() => this.login(user.email, user.password)),
            catchError((err) => {
                console.error('err', err);
                return of(undefined);
            }),
            finalize(() => this.isLoadingSubject.next(false))
        );
    }

    forgotPassword(email: string): Observable<boolean> {
        this.isLoadingSubject.next(true);
        return this.authHttpService
            .forgotPassword(email)
            .pipe(finalize(() => this.isLoadingSubject.next(false)));
    }

    resetPassword(accessToken: string, password: string): Observable<boolean> {
        this.isLoadingSubject.next(true);
        return this.authHttpService
            .resetPassword(accessToken, password)
            .pipe(finalize(() => this.isLoadingSubject.next(false)));
    }

    getCompanyName(): Observable<string> {
        return this.authHttpService
            .getCompanyName();
    }

    // private methods
    //private setAuthFromLocalStorage(auth: AuthModel): boolean {
    //  // store auth authToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    //  if (auth && auth.authToken) {
    //    localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
    //    return true;
    //  }
    //  return false;
    //}
    private setAuthFromLocalStorage(auth: AuthModel): boolean {
        // store auth accessToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
        auth.accessToken = auth.token;
        if (auth && auth.accessToken) {
            localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
            //
            localStorage.setItem('currentUser', JSON.stringify(auth));
            localStorage.setItem('isIdleTimeOut', "0");
            localStorage.setItem('currentUserMenu', JSON.stringify(this.bindCurrentUserManu(auth.moduleList, auth.menuList)));
            return true;
        }
        return false;
    }

    private getAuthFromLocalStorage(): AuthModel {
        try {
            const authData = JSON.parse(
                localStorage.getItem(this.authLocalStorageToken)
            );
            return authData;
        } catch (error) {
            console.error(error);
            return undefined;
        }
    }

    ngOnDestroy() {
        this.unsubscribe.forEach((sb) => sb.unsubscribe());
    }

    bindCurrentUserManu(_moduleList: any[], _menuList: any[]) {
        let arrList = [];
        _moduleList.forEach(item => {
            let moduleName = item.moduleName;
            let arr = _menuList.filter(f => f.moduleName == moduleName && f.menuitem == 'Y' && f.parentMenuId == 0);
            let subMenuArrList = [];
            arr.forEach(subitem => {
                let subarr = _menuList.filter(f => f.parentMenuId == subitem.featureId && f.menuitem == 'Y');
                if (subarr.length > 0)
                    subMenuArrList.push({ title: subitem.menuName, bullet: 'dot', submenu: subarr });
                else
                    subMenuArrList.push({ title: subitem.menuName, bullet: 'dot', page: subitem.page });
            });
            //
            arrList.push({
                title: item.moduleName,
                root: true,
                bullet: 'dot',
                icon: item.cssClassName,
                submenu: subMenuArrList,
                svg: './assets/media/svg/module-icon/' + item.svg,
                translate: 'MODULE.' + [item.moduleName.replace(' ', '_')]
            });
        });
        return { "items": arrList };
    }
}
