// Angular
import { Injectable } from '@angular/core';
// RxJS
import { BehaviorSubject } from 'rxjs';

declare var KTApp: any;

@Injectable()
export class KtDialogService {
	//private ktDialog: any;
	private currentState: BehaviorSubject<boolean> = new BehaviorSubject(false);

	// Public properties
	constructor() {
		//this.ktDialog = new KTDialog({'type': 'loader', 'placement': 'top center', 'message': 'Loading ...'});
	}

	show() {
		//this.currentState.next(true);
		//this.ktDialog.show();
        KTApp.blockPage({
            overlayColor: '#000000',
            type: 'v2',
            state: 'primary',
            message: 'Please wait...'
        });
	}

	hide() {
		//this.currentState.next(false);
		//this.ktDialog.hide();
        KTApp.unblockPage();
	}

	checkIsShown() {
		return this.currentState.value;
	}
}
