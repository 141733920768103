import { SessionTimeOutModalComponent } from './sessiontimeoutmodal.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
 


import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';
 

@NgModule({
    imports: [
		CommonModule,
        MatDialogModule,
		MatButtonModule,
		MatTooltipModule,
		TranslateModule.forChild()
    ],
    exports: [SessionTimeOutModalComponent],
    declarations: [SessionTimeOutModalComponent],
	providers: [
	]
})
export class SessionTimeOutModalModule { }
