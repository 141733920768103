// USA
export const locale = {
    lang: 'en',
    data: {
        TRANSLATOR: {
            SELECT: 'Select your language',
        },
        MODULE: {
            INVENTORY: "INVENTORY",
            COMMERCIAL: "COMMERCIAL",
            REPORTS: "REPORTS",
            TMS: "TMS",
            HRM: "HRM",
            VISA: "VISA",
            SALES: "SALES",
            SUPPORT: "SUPPORT",
            QUALITYCONTROL: "QUALITY CONTROL",
            Quality_Control: "QUALITY CONTROL",
            HIRING_MANAGEMENT: "HIRING MANAGEMENT",
            CONTAINER_MANAGEMENT: "CONTAINER MANAGEMENT",
            FINANCE: "FINANCE",
            TECHNICAL: "TECHNICAL",
            Car_Renting: "Car Renting",
            GENERAL: "GENERAL",
            PROCUREMENT: "PROCUREMENT",
            FIXED_ASSETS: "FIXED ASSETS",
            OPERATIONS: "OPERATIONS",
            PRODUCTION: "PRODUCTION",
            Production: "PRODUCTION",
            FLOUR_PRODUCTION: "PRODUCTION",
            CUSTODY: "CUSTODY",
            AUDIT: "AUDIT",
            CRM: "CRM"
        },
        MENU: {
            NEW: 'new',
            ACTIONS: 'Actions',
            CREATE_POST: 'Create New Post',
            PAGES: 'Pages',
            FEATURES: 'Features',
            APPS: 'Apps',
            DASHBOARD: 'Dashboard',
            REPORTS: 'Reports',
        },
        AUTH: {
            GENERAL: {
                OR: 'Or',
                SUBMIT_BUTTON: 'Submit',
                NO_ACCOUNT: 'Don\'t have an account?',
                SIGNUP_BUTTON: 'Sign Up',
                FORGOT_BUTTON: 'Forgot Password',
                BACK_BUTTON: 'Back',
                PRIVACY: 'Privacy',
                LEGAL: 'Legal',
                CONTACT: 'Contact',
            },
            LOGIN: {
                TITLE: 'Login Account',
                BUTTON: 'Sign In',
            },
            FORGOT: {
                TITLE: 'Forgotten Password?',
                DESC: 'Enter your email to reset your password',
                SUCCESS: 'Your account has been successfully reset.'
            },
            REGISTER: {
                TITLE: 'Sign Up',
                DESC: 'Enter your details to create your account',
                SUCCESS: 'Your account has been successfuly registered.'
            },
            INPUT: {
                EMAIL: 'Email',
                FULLNAME: 'Fullname',
                PASSWORD: 'Password',
                CONFIRM_PASSWORD: 'Confirm Password',
                USERNAME: 'Username'
            },
            VALIDATION: {
                INVALID: '{{name}} is not valid',
                REQUIRED: '{{name}} is required',
                MIN_LENGTH: '{{name}} minimum length is {{min}}',
                AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
                NOT_FOUND: 'The requested {{name}} is not found',
                INVALID_LOGIN: 'The login detail is incorrect',
                REQUIRED_FIELD: 'Required field',
                MIN_LENGTH_FIELD: 'Minimum field length:',
                MAX_LENGTH_FIELD: 'Maximum field length:',
                INVALID_FIELD: 'Field is not valid',
            }
        },
        ECOMMERCE: {
            COMMON: {
                SELECTED_RECORDS_COUNT: 'Selected records count: ',
                ALL: 'All',
                SUSPENDED: 'Suspended',
                ACTIVE: 'Active',
                FILTER: 'Filter',
                BY_STATUS: 'by Status',
                BY_TYPE: 'by Type',
                BUSINESS: 'Business',
                INDIVIDUAL: 'Individual',
                SEARCH: 'Search',
                IN_ALL_FIELDS: 'in all fields'
            },
            ECOMMERCE: 'eCommerce',
            CUSTOMERS: {
                CUSTOMERS: 'Customers',
                CUSTOMERS_LIST: 'Customers list',
                NEW_CUSTOMER: 'New Customer',
                DELETE_CUSTOMER_SIMPLE: {
                    TITLE: 'Customer Delete',
                    DESCRIPTION: 'Are you sure to permanently delete this customer?',
                    WAIT_DESCRIPTION: 'Customer is deleting...',
                    MESSAGE: 'Customer has been deleted'
                },
                DELETE_CUSTOMER_MULTY: {
                    TITLE: 'Customers Delete',
                    DESCRIPTION: 'Are you sure to permanently delete selected customers?',
                    WAIT_DESCRIPTION: 'Customers are deleting...',
                    MESSAGE: 'Selected customers have been deleted'
                },
                UPDATE_STATUS: {
                    TITLE: 'Status has been updated for selected customers',
                    MESSAGE: 'Selected customers status have successfully been updated'
                },
                EDIT: {
                    UPDATE_MESSAGE: 'Customer has been updated',
                    ADD_MESSAGE: 'Customer has been created'
                }
            }
        },
        "user": {
            "management": {
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "Title": "User",
                "User": "User",
                "SubTitle": "SubTitle",
                "UserName": "User Name",
                "PassWord": "Password",
                "EmployeeId": "Employee Id",
                "GridHeaders": {
                    "orgname": "Org Name",
                    "userName": "User Name",
                    "email": "Email",
                    "createdTime": "Created Time",
                    "updatedTime": "Updated Time"
                }
            },
            "create": {
                "SubTitle": "Add User",
                "UserName": "User Name:*",
                "PassWord": "Password:*",
                "Email": "Email:*",
                "EmployeeId": "Employee Id:*",
                "BranchName": "Branch Name:",
                "CompanyName": "Organization:*",
                "SaveButton": "Save",
                "CancelButton": "Cancel",
                "BackToList": "Back to the List"
            },
            "edit": {
                "SubTitle": "SubTitle",
                "UserName": "User Name",
                "PassWord": "Password",
                "EmployeeId": "Employee Id"
            }
        },

        "userresetpassword": {
            "management": {
                "ResetButton": "Reset",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "Title": "User Reset Password",
                "User": "User",
                "SubTitle": "SubTitle",
                "UserName": "User Name",
                "PassWord": "Password",
                "EmployeeId": "Employee Id",
                "GridHeaders": {
                    "orgname": "Org Name",
                    "userName": "User Name",
                    "email": "Email",
                    "NewPassword": "New Password",
                    "ConfirmPassword": "Confirm Password"
                }
            },
        },

        "advancetype": {
            "management": {
                "Title": "Advance Type",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name:",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Advance Type",
                "SubTitle": "SubTitle",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"

            }
        },
        "materialaccounting": {
            "management": {
                "Title": "Material Accounting",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Accountingbase": "Accounting Base",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Material Accounting",
                "SubTitle": "SubTitle",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "AccountingBase": "Accounting Base:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"

            }
        },
        "segmentgroup": {
            "management": {
                "Title": "Segment Group",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Org Name",
                    "Code": "Code",
                    "Name": "Name:",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Segment Group",
                "SubTitle": "SubTitle",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:",
                "Structure": "Structure:",
                "ParentSegmentGroup": "Parent Segment Group:",
                "SegmentDefinition": "Segment Definition:",
                "SaveButton": "Save",
                "PrintButton": "Print",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"

            }
        },

        "coadefinition": {
            "management": {
                "Title": "COA Definition",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "COA Definition",
                "SubTitle": "SubTitle",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:",
                "Name": "Name:",
                "ConsolidationName": "Consolidation Definition",
                "SegmentStructureName": "Segment Structure",
                "SaveButton": "Save",
                "PrintButton": "Print",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"

            }
        },

        "grn": {
            "management": {
                "Title": "Good Receipt Note",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ReverseButton": "Reverse",
                "RePostButton": "RePost",
                "ExportButton": "Export",

                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "VendorName": "Vendor Name",
                    "ReferenceNo": "Reference No",
                    "Location": "Location",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Good Receipt Note",
                "SubTitle": "SubTitle",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DivisionCode": "Division Code:*",
                "DivisionName": "Division Name:",
                "GRNNo": "Document No:*",
                "GRNDate": "Document Date:*",
                "ToWareHouse": "To WareHouse:*",
                "VendorCode": "Vendor Code:*",
                "VendorName": "Vendor Name:",
                "WarehouseCode": "Warehouse Code:*",
                "WarehouseName": "Warehouse Name:*",
                "RefType": "Reference Type:*",
                "RefNo": "Reference Number:*",
                "RefDate": "Reference Date:",
                "VenDNNo": "Vendor DN Number:*",
                "VenDNDate": "Vendor DN Date:*",
                "Currency": "Currency:*",
                "Narration": "Narration:*",
                "DeliveryMode": "Delivery Mode:*",
                "DeliveryPlace": "Delivery Place:*",
                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "ItemPartNo": "Item Part No",
                "UOMCode": "UOM",
                "UOMName": "UOM Name",
                "OrderQty": "Order Quantity",
                "RecQty": "Received Quantity",
                "Location": "Location",
                "BinType": "Bin Type",
                "Bins": "Bins",
                "FreeQty": "Free Quantity",
                "AcceptedQty": "Accepted Quantity",
                "RejectedQty": "Rejected Quantity",
                "BalQty": "Bal.Quantity",
                "Price": "Price",
                "LoadingCharges": "Loading Charges:",
                "OffLoading": "OffLoading Charges:",
                "TransportationCharges": "Transportation Charges",
                "Amount": "Amount",
                "GLoadingCharge": "Loading Charge",
                "GOffLoadingCharge": "OffLoading Charge",
                "GTransportationCharges": "Transportation Charges",
                "ExecQty": "Executed Quantity",
                "LCUnit": "LC/Unit",
                "PrintButton": "Print",

                "GRNDate1": "GRN Date",
                "TruckReferenceNo": "Truck Reference No",
                "EmptyTruckWeight": "Empty Truck Weight",
                "LoadedTruckWeight": "Loaded Truck Weight",
                "PortWeight": "Port Weight",
                "PortReference": "Port Reference",
                "OrgWeight": "Org Weight",
                "OrgReference": "Org Reference",
                "Difference": "Difference",
                "Price1": "Price",
                "PortAmount": "Port Amount",
                "OrgAmount": "Org Amount",
                "TransporterCode": "Transporter Code",
                "TransporterName": "Transporter Name",
                "VessalName": "Vessal Name",
                "Warehouse": "Warehouse",

                "SaveButton": "Save",
                "UpdateButton": "Update Port Details",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"

            }
        },


        "returnabledeliverynotereceipt": {
            "management": {
                "Title": "Returnable Delivery Note Receipt",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Returnable Delivery Note Receipt",
                "SubTitle": "SubTitle",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "ReturnableDeliveryNoteReceiptNo": "Document No.:*",
                "ReturnableDeliveryNoteReceiptDate": "Document Date:*",
                "VendorCode": "Vendor Code:*",
                "VendorName": "Vendor Name:",
                "VendorTypeCode": "Vendor Type Code:*",
                "VendorTypeName": "Vendor Type Name:",
                "ToWareHouse": "To WareHouse:*",
                "DocumentNo": "Document No:",
                "DocumentDate": "Document Date:*",
                "DeliveryMode": "Delivery Mode:",
                "Remarks": "Remarks:",
                "WarehouseCode": "Warehouse Code:*",
                "WarehouseName": "Warehouse Name:*",
                "RefType": "Reference Type:*",
                "RefNo": "Reference Number:*",
                "RefQty": "Reference Quantity:*",
                "RefDate": "Reference Date:",
                "VenDNNo": "Vendor DN Number:*",
                "VenDNDate": "Vendor DN Date:*",
                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "ItemPartNo": "Item Part No",
                "UOMCode": "UOM",
                "UOMName": "UOM Name",
                "OrderQty": "Order Quantity",
                "RecQty": "Rec. Quantity.",
                "Location": "Location",
                "BinType": "Bin Type",
                "Bins": "Bins",
                "FreeQty": "Free Quantity",
                "AcceptedQty": "Accepted Quantity",
                "RejectedQty": "Rejected Quantity",
                "LocationHead": "Location",
                "Qty": "Receipt Quantity",
                "BalQty": "Balance Quantity",
                "Price": "Price",
                "Amount": "Amount",
                "ExecQty": "Executed Quantity",
                "LCUnit": "LC/Unit",
                "PrintButton": "Print",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"

            }
        },

        "lenderdeliverynotereceipt": {
            "management": {
                "Title": "Lender/Borrower Receipt",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ReverseButton": "Reverse",
                "RePostButton": "RePost",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Lender": "Lender/Borrower Name",
                    "ReferenceNo": "Reference No",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Lender Delivery Note Receipt",
                "SubTitle": "SubTitle",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "TabTitle3": "Truck Details",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "ReturnableDeliveryNoteReceiptNo": "Document No.:*",
                "ReturnableDeliveryNoteReceiptDate": "Document Date:*",
                "ToWareHouse": "To WareHouse:*",
                "LenderCode": "Lender/Borrower Code:*",
                "LenderName": "Lender/Borrower Name:",
                "DocumentNo": "Document No:",
                "DocumentDate": "Document Date:*",
                "DeliveryMode": "Delivery Mode:",
                "LoadingCharges": "LoadingCharges",
                "OffLoadingCharges": "OffLoading Charges",
                "TransportationCharges": "Transportation Charges:",
                "Remarks": "Remarks:",
                "WarehouseCode": "Warehouse Code:*",
                "WarehouseName": "Warehouse Name:*",
                "RefType": "Reference Type:*",
                "RefNo": "Reference Number:*",
                "RefQty": "Reference Quantity:*",
                "RefDate": "Reference Date:",
                "VenDNNo": "Vendor DN Number:*",
                "VenDNDate": "Vendor DN Date:*",
                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "ItemPartNo": "Item Part No",
                "UOMCode": "UOM",
                "UOMName": "UOM Name",
                "OrderQty": "Order Quantity",
                "RecQty": "Rec. Quantity.",
                "ThirdPartyQuantity": "Third Party Quantity",
                "Location": "Location",
                "BinType": "Bin Type",
                "Bins": "Bins",
                "FreeQty": "Free Quantity",
                "AcceptedQty": "Accepted Quantity",
                "RejectedQty": "Rejected Quantity",
                "LocationHead": "Location",
                "Qty": "Receipt Quantity",
                "BalQty": "Balance Quantity",
                "Price": "Price",
                "Amount": "Amount",
                "GLoadingCharge": "Loading Charge",
                "GOffLoadingCharge": "OffLoading Charge",
                "GTransportationCharges": "Transportation Charges",
                "ExecQty": "Executed Quantity",
                "LCUnit": "LC/Unit",

                "ReferenceNo": "Reference No",
                "Date": "Date",
                "WeighBridge": "Weigh Bridge",
                "AfisaReferenceNo": "Afisa Reference No",
                "AfisaDate": "Afisa Date",
                "AfisaWeighBridge": "Afisa Weigh Bridge",
                "Difference": "Difference",
                "TruckNo": "Truck No",
                "Transporter": "Transporter",
                "TransportCharges": "Transport Charges",
                "OriginName": "Origin Name",

                "PrintButton": "Print",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"

            }
        },

        "salesconfiguration": {
            "management": {
                "Title": "Sales Configuration",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Organization",
                    "Code": "Product Code",
                    "SalesRegionName": "Sales Region",
                    "Name": "Product Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Sales Configuration",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "FromQuantity": "From Quantity:*",
                "ToQuantity": "To Quantity:*",
                "SalesRegionName": "Sales Region:*",
                "ItemCode": "Item Code:*",
                "ItemName": "Item Name:*",
                "UOM": "UOM:*",
                "Price": "Price:*",
                "Discount": "Sales Discount:*",
                "EarlyPaymentDiscount": "InTime Payment Discount:*",
                "Remarks": "Remarks:",


                "CustomerId": "Customer Name:*",
                "SalesPersonId": "Sales Person Name:*",
                "YEAR": "Year:*",
                "JAN": "January",
                "FEB": "February",
                "MAR": "March",
                "APR": "April",
                "MAY": "May",
                "JUN": "June",
                "JUL": "July",
                "AUG": "August",
                "SEP": "September",
                "OCT": "October",
                "NOV": "November",
                "DEC": "December",



                "UserName": "User Name:*",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "discountconfiguration": {
            "management": {
                "Title": "Sales Configuration",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Organization",
                    "Code": "Product Code",
                    "Name": "Product Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Sales Configuration",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "FromQuantity": "From Quantity:*",
                "ToQuantity": "To Quantity:*",
                "SalesRegionName": "Sales Region:*",
                "ItemCode": "Item Code:*",
                "ItemName": "Item Name:*",
                "UOM": "UOM:*",
                "Price": "Price:*",
                "Discount": "Sales Discount:*",
                "EarlyPaymentDiscount": "InTime Payment Discount:*",
                "Remarks": "Remarks:",

                "UserName": "User Name:*",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "containerconfiguration": {
            "management": {
                "Title": "Container Configuration",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Organization",
                    "Code": "Container Size",
                    "Name": "Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Container Configuration",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "ContainerSizeName": "Container Size:*",
                "FreeTime": "Free Time:*",
                "handlingcharges": "Handling Charges:*",
                "transportcharges": "Transport Charges:*",
                "OverStayCharges": "Over Stay Charges:*",
                "rehandlingcharges": "Rehandling Charges:*",
                "ContainerState": "Container State:*",
                "Remarks": "Remarks:",

                "UserName": "User Name:*",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },


        "salesquotation": {
            "management": {
                "Title": "Sales Quotation",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "CustomerName": "Customer Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Sales Quotation",
                "TabTitle1": "General Info",
                "TabTitle2": "Line Items",
                "TabTitle3": "Customer Info",
                "SubTitle": "SubTitle",

                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DivisionCode": "Division Code:*",
                "DivisionName": "Division Name:",
                "TransNo": "Document No:*",
                "TransDate": "Document Date:*",
                "SalesPersonCode": "Sales Person Code:*",
                "SalesPersonName": "Sales Person Name:",
                "CustomerTypeCode": "Customer Type Code:*",
                "CustomerTypeName": "Customer Type Name:",
                "CustomerCode": "Customer Code:*",
                "CustomerName": "Customer Name:",
                "RefType": "Reference Type:*",
                "ValidUpto": "Valid Upto:*",
                "Currency": "Currency:*",
                "DeliveryPoint": "Delivery Point:*",
                "DeliveryDate": "Delivery Date:*",
                "Narration": "Narration:",
                "SalesRoute": "Sales Route",
                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "ItemPartNo": "Item Part No",
                "UOMCode": "UOM",
                "UOMName": "UOM Name",
                "Qty": "Quantity",
                "SalePrice": "Sale Price",
                "Amount": "Amount",
                "ReferenceId": "Reference No:",
                "ReferenceDate": "Reference Date:",
                "ContactPerson": "Contact Person:*",
                "Phone": "Phone:*",
                "DeliveryType": "Delivery Type:*",
                "DeliveryTypeName": "Delivery Type Name:",
                "ContactAddress": "Contact Address:*",
                "BillingAddress": "Billing Address:*",
                "ShippingAddress": "Shipping Address:*",
                "Packing": "Packing:*",
                "Shipping": "Shipping:*",
                "PaymentTermCode": "Payment Term Code:*",
                "PaymentTermName": "Payment Term Name:",
                "PrintButton": "Print",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "salesorder": {
            "management": {
                "Title": "Sales Order",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "AmendButton": "Amend",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "CustomerName": "Customer Name",
                    "Destination": "Final Destination",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Sales Order",
                "TabTitle1": "General Info",
                "TabTitle2": "Line Items",
                "TabTitle3": "Customer Info",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "SalesDivisionCode": "Sales Division Code:*",
                "SalesDivisionName": "Sales Division Name:",
                "TransNo": "Document No:*",
                "TransDate": "Document Date:*",
                "SalesPersonCode": "Sales Person Code:*",
                "SalesPersonName": "Sales Person Name:",
                "CustomerTypeCode": "Customer Type Code:*",
                "CustomerTypeName": "Customer Type Name:",
                "CustomerCode": "Customer Code:*",
                "CustomerName": "Customer Name:",
                "RefType": "Reference Type:*",
                "DocumentNo": "Document No:*",
                "DocumentDate": "Document Date:*",
                "SalesType": "Sales Type:*",
                "OrderType": "Order Type:*",
                "PartialDelivery": "Partial Delivery:*",
                "Currency": "Currency:*",
                "CustomerStorePhoneNo": "Customer Store Phone No:*",
                "OrderPriority": "Order Priority:*",
                "DeliveryDate": "Delivery Date:*",
                "DueDate": "Due Date:*",
                "salesRegionId": "Sales Region:*",
                "salesDestinationId": "Sales Destination:*",
                "ForexRate": "Forex Rate:*",
                "ForexDate": "Forex Date:*",
                "CreditLimit": "Credit Limit:*",
                "PaymentTermCode": "Payment Terms Code:*",
                "PaymentTermName": "Payment Term Name:",
                "personTelephoneNo": "Receiving Person Tel. No:",
                "FinalDestination": "Final Destination:",
                "Narration": "Narration:",

                "QuotationNo": "Quotation No.",
                "QuotationDate": "Quotation Date",
                "StatusGrid": "Status",
                "SalesRoute": "Sales Route",
                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "ItemPartNo": "Item Part No",
                "UOMCode": "UOM",
                "UOMName": "UOM Name",
                "RefQty": "Reference Quantity",
                "RefBalQty": "Balance Quantity",

                "Qty": "Quantity",
                "FreeQuantity": "Free Quantity",
                "SalePrice": "Sale Price",
                "Amount": "Amount",
                "BalQuantity": "Bal. Quantity",
                "ExeQuantity": "Exe. Quantity",
                "PartialDeliveryGrid": "Partial Delivery",

                "DeliveryType": "Delivery Type:*",
                "DeliveryTypeName": "Delivery Type Name:",
                "ContactAddress": "Contact Address:*",
                "BillingAddress": "Billing Address:*",
                "ShippingAddress": "Shipping Address:*",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },


        "deliverynoteschedule": {
            "management": {
                "Title": "Loading Order",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "AmendButton": "Amend",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Division": "Sales Division",
                    "CustomerName": "Customer Name",
                    "ReferenceNo": "Reference No",
                    "Destination": "Final Destination",
                    "TruckNo": "Truck No",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Loading Order",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "TabTitle3": "Customer Info",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "SalesDivisionCode": "Sales Division Code:*",
                "SalesDivisionName": "Sales Division Name:",
                "TransNo": "Document No:*",
                "TransDate": "Document Date:*",
                "SalesPersonCode": "Sales Person Code:*",
                "SalesPersonName": "Sales Person Name:",
                "CustomerTypeCode": "Customer Type Code:*",
                "CustomerTypeName": "Customer Type Name:",
                "CustomerCode": "Customer Code:*",
                "CustomerName": "Customer Name:",
                "RefType": "Reference Type:*",
                "ReferenceId": "Reference No:*",
                "TruckNo": "Truck No:*",
                "DriverName": "Driver Name:*",
                "DriverPhone": "Driver Phone No.:*",
                "WareHouse": "Ware House:",
                "Transporter": "Transporter:",
                "Remarks": "Remarks:",

                "RefQuantity": "Reference Quantity",
                "Location": "Location",
                "BalanceQuantity": "Balance Quantity",

                "salesDestinationId": "Destination:",
                "Destination": "Final Destination:",

                "Narration": "Narration:",

                "QuotationNo": "Quotation No.",
                "QuotationDate": "Quotation Date",
                "StatusGrid": "Status",
                "SalesRoute": "Sales Route",
                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "ItemPartNo": "Item Part No",
                "UOMCode": "UOM",
                "UOMName": "UOM Name",
                "Qty": "Quantity",
                "FreeQuantity": "Free Quantity",
                "FreeBalanceQuantity": "Free Bal Quantity",
                "SalePrice": "Sale Price",
                "Amount": "Amount",
                "PartialDeliveryGrid": "Partial Delivery",
                "TransportationCharges": "Transportation Charges",

                "DeliveryType": "Delivery Type:*",
                "DeliveryTypeName": "Delivery Type Name:",
                "ContactAddress": "Contact Address:*",
                "BillingAddress": "Billing Address:*",
                "ShippingAddress": "Shipping Address:*",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "salesinvoice": {
            "management": {
                "Title": "Sales Invoice",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "CustomerName": "Customer Name",
                    "TruckNo": "Truck No",
                    "LoadTo": "LoadTo",
                    "Destination": "Destination",
                    "DeliveryNo": "Delivery No",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Sales Invoice",
                "TabTitle1": "General Info",
                "TabTitle2": "Line Items",
                "TabTitle3": "Customer Info",
                "TabTitle4": "Payment Due Datails",
                "TabTitle5": "Segment Details",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "SalesDivisionCode": "Sales Division Code:*",
                "SalesDivisionName": "Sales Division Name:",
                "TransNo": "Document No:*",
                "TransDate": "Document Date:*",
                "SalesPersonCode": "Sales Person Code:*",
                "SalesPersonName": "Sales Person Name:",
                "RefType": "Reference Type:*",
                "FromWareHouse": "From WareHouse:*",
                "CustomerTypeCode": "Customer Type Code:*",
                "CustomerTypeName": "Customer Type Name:",
                "CustomerCode": "Customer Code:*",
                "CustomerName": "Customer Name:",
                "ForexRate": "Forex Rate:*",
                "Commission": "Commission:*",
                "DueDate": "Due Date:*",
                "Currency": "Currency:*",
                "Destination": "Destination:*",
                "TruckNo": "Truck No:*",
                "Narration": "Narration:",
                "Percentage": "Percentage:",

                "DelNoteNo": "Delivery Note No",
                "DelNoteDate": "Delivery Note Date",
                "StatusGrid": "Status",

                "SNO": "SNo",
                "Payment": "Percentage",
                "Amount": "Amount",
                "SalesRoute": "Sales Route",

                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "ItemPartNo": "Item Part No",
                "UOMCode": "UOM",
                "UOMName": "UOM Name",
                "Location": "Location",
                "Qty": "Quantity",
                "SalePrice": "Sale Price",
                "SalePriceExc": "Sale Price Exc",
                "TotalPrice": "Amount",
                "Specification": "Specifications",
                "Insurance": "Insurance",
                "QuickReport": "Quick Report",
                "Quantity": "Quantity",

                "ContactPerson": "Contact Person:*",
                "Phone": "Phone:*",
                "DeliveryType": "Delivery Type:*",
                "DeliveryTypeName": "Delivery Type Name:",
                "ContactAddress": "Contact Address:*",
                "BillingAddress": "Billing Address:*",
                "ShippingAddress": "Shipping Address:*",
                "Packing": "Packing:*",
                "Shipping": "Shipping:*",
                "PaymentTermCode": "Payment Term Code:*",
                "PaymentTermName": "Payment Term Name:",

                "sno": "SNo",
                "Code": "Segment Code",
                "Name": "Segment Name",
                "debitamount": "Debit Amount",
                "creditamount": "Credit Amount",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "lenderInvoice": {
            "management": {
                "Title": "Lender Invoice",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "LenderName": "Lender Name",
                    "TruckNo": "Truck No",
                    "LoadTo": "LoadTo",
                    "Destination": "Destination",
                    "ReferenceNo": "Reference No",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Lender Invoice",
                "TabTitle1": "General Info",
                "TabTitle2": "Line Items",
                "TabTitle3": "Customer Info",
                "TabTitle4": "Payment Due Datails",
                "TabTitle5": "Segment Details",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "SalesDivisionCode": "Sales Division Code:*",
                "SalesDivisionName": "Sales Division Name:",
                "TransNo": "Document No:*",
                "TransDate": "Document Date:*",
                "SalesPersonCode": "Sales Person Code:*",
                "SalesPersonName": "Sales Person Name:",
                "RefType": "Reference Type:*",
                "FromWareHouse": "From WareHouse:*",
                "CustomerTypeCode": "Customer Type Code:*",
                "CustomerTypeName": "Customer Type Name:",
                "LenderCode": "Lender Code:*",
                "LenderName": "Lender Name:",
                "ForexRate": "Forex Rate:*",
                "Commission": "Commission:*",
                "DueDate": "Due Date:*",
                "Currency": "Currency:*",
                "Destination": "Destination:*",
                "TruckNo": "Truck No:*",
                "Narration": "Narration:",
                "Percentage": "Percentage:",

                "LendDelNoteNo": "Lender Delivery Note Issue No",
                "LendDelNoteDate": "Lender Delivery Note Issue Date",
                "StatusGrid": "Status",

                "SNO": "SNo",
                "Payment": "Percentage",
                "Amount": "Amount",
                "SalesRoute": "Sales Route",

                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "ItemPartNo": "Item Part No",
                "UOMCode": "UOM",
                "UOMName": "UOM Name",
                "Location": "Location",
                "Qty": "Quantity",
                "Price": "Price",
                "TotalPrice": "Amount",
                "Specification": "Specifications",
                "Insurance": "Insurance",
                "QuickReport": "Quick Report",
                "Quantity": "Quantity",

                "ContactPerson": "Contact Person:*",
                "Phone": "Phone:*",
                "DeliveryType": "Delivery Type:*",
                "DeliveryTypeName": "Delivery Type Name:",
                "ContactAddress": "Contact Address:*",
                "BillingAddress": "Billing Address:*",
                "ShippingAddress": "Shipping Address:*",
                "Packing": "Packing:*",
                "Shipping": "Shipping:*",
                "PaymentTermCode": "Payment Term Code:*",
                "PaymentTermName": "Payment Term Name:",

                "sno": "SNo",
                "Code": "Segment Code",
                "Name": "Segment Name",
                "debitamount": "Debit Amount",
                "creditamount": "Credit Amount",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "salesrequestforquotation": {
            "management": {
                "Title": "Request for Quotation",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Division": "Sales Division",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Request for Quotation",
                "TabTitle1": "General Info",
                "TabTitle2": "Line Items",
                "TabTitle3": "Customer Info",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DivisionCode": "Sales Division Code:*",
                "DivisionName": "Sales Division Name:",
                "TransNo": "Document No:*",
                "TransDate": "Document Date:*",
                "SalesPersonCode": "Sales Person Code:*",
                "SalesPersonName": "Sales Person Name:",
                "RefType": "Reference Type:*",
                "CustomerTypeCode": "Customer Type Code:*",
                "CustomerTypeName": "Customer Type Name:",
                "CustomerCode": "Customer Code:*",
                "CustomerName": "Customer Name:",
                "ForexRate": "Forex Rate:*",
                "Commission": "Commission:*",
                "DueDate": "Due Date:*",
                "Currency": "Currency:*",
                "Narration": "Narration:",
                "ValidUpto": "Valid Upto:",
                "DeliveryPoint": "Delivery Point:",
                "DeliveryDate": "Delivery Date:",
                "ContactPerson": "Contact Person:",
                "Phone": "Phone",
                "DeliveryType": "Delivery Type",
                "StatusGrid": "Status",

                "DeliveryTypeName": "Delivery Type Name",
                "BillingAddress": "Billing Address",
                "ShippingAddress": "Shipping Address",
                "Packing": "Packing",
                "Shipping": "Shipping",
                "ItemCode": "Item Code",
                "SalesRoute": "Sales Route",
                "ItemName": "Item Name",
                "ItemPartNo": "Item Part No",
                "UOMCode": "UOM Code",
                "UOMName": "UOM Name",
                "Qty": "Quantity",
                "SalePrice": "Sale Price",
                "TotalPrice": "Total Price",
                "Specification": "Specifications",
                "Insurance": "Insurance",
                "QuickReport": "Quick Report",

                "PaymentTermCode": "Payment Term Code:*",
                "PaymentTermName": "Payment Term Name:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "storesissue": {
            "management": {
                "Title": "Stores Issue",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ReverseButton": "Reverse",
                "RePostButton": "RePost",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Stores Issue",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "TabTitle3": "Receipt Details",
                "TabTitle5": "Other Details",
                "SubTitle": "SubTitle",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "IssueNo": "Document No:*",
                "IssueDate": "Document Date:*",
                "DepartmentCode": "Department Code:*",
                "DepartmentName": "Department Name:",
                "RefType": "Reference Type:*",
                "IndentNo": "Indent Number:*",
                "IndentDate": "Indent Date:",
                "IssuedBy": "Issued By:",
                "UnitNo": "Unit No:*",
                "OtherReferenceNo": "Reference No:",
                "FromWareHouse": "From WareHouse:",
                "TruckNo": "Truck No:",
                "DriverName": "Driver Name:",
                "Transporter": "Transporter:",
                "IssueType": "Issue Type:",
                "PhoneNo": "Phone No:",
                "Remarks": "Remarks:",
                "TruckType": "TruckType:",
                "Destination": "Destination:",
                "QtyCalibrated": "QtyCalibrated:",
                "OdometerReading": "OdometerReading:",
                "PumpReading": "PumpReading:",

                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "UOMCode": "UOM",
                "UOMName": "UOM Name",
                "Qty": "Quantity",
                "ReqQty": "Req. Quantity",
                "ReqDate": "Req. Date",
                "LocationHead": "Location",
                "IssueQty": "Issue Quantity",
                "BalQty": "Balance Quantity",
                "Location": "Location",
                "BinType": "Bin Type",
                "Bins": "Bins",
                "PhyQty": "Physical Quantity",
                "SaveButton": "Save",
                "PrintButton": "Print",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "shortageissueinventory": {
            "management": {
                "Title": "Shortage Issue Inventory",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ReverseButton": "Reverse",
                "RePostButton": "RePost",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Shortage Issue Inventory",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "SubTitle",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "IssueNo": "Document No:*",
                "IssueDate": "Document Date:*",
                "DepartmentCode": "Department Code:*",
                "DepartmentName": "Department Name:",
                "ShortageType": "Shortage Type:",
                "RefType": "Reference Type:*",
                "IndentNo": "Indent Number:*",
                "IndentDate": "Indent Date:",
                "IssuedBy": "Issued By:",
                "UnitNo": "Unit No:*",
                "FromWareHouse": "From WareHouse:*",
                "OtherReferenceNo": "Reference No:",
                "Remarks": "Remarks:",
                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "UOMCode": "UOM",
                "UOMName": "UOM Name",
                "Qty": "Quantity",
                "ReqQty": "Receipt Quantity",
                "ReqDate": "Req. Date",
                "LocationHead": "Location",
                "IssueQty": "Issue Quantity",
                "BalQty": "Balance Quantity",
                "Location": "Location",
                "BinType": "Bin Type",
                "Bins": "Bins",
                "PhyQty": "Physical Quantity",
                "SaveButton": "Save",
                "PrintButton": "Print",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "returnabledeliverynoteissue": {
            "management": {
                "Title": "Returnable Delivery Note Issue",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "PrintButton": "Print",
                "SaveButton": "Save",
                "RejectButton": "Reject",
                "ActiveButton": "Approve",
                "InActiveButton": "Reject",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Returnable Delivery Note Issue",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "SubTitle",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "IssueNo": "Document No:*",
                "IssueDate": "Document Date:*",
                "VendorCode": "Vendor Code:*",
                "VendorName": "Vendor Name:",
                "RefType": "Reference Type:*",
                "IndentNo": "Indent Number:*",
                "IndentDate": "Indent Date:",
                "IssuedBy": "Issued By:",
                "UnitNo": "Unit No:*",
                "FromWareHouse": "From WareHouse:*",
                "Remarks": "Remarks:",
                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "UOMCode": "UOM",
                "UOMName": "UOM Name",
                "ReqQty": "Req. Quantity",
                "ReqDate": "Req. Date",
                "LocationHead": "Location",
                "IssueQty": "Issue Quantity",
                "BalQty": "Balance Quantity",
                "Location": "Location",
                "BinType": "Bin Type",
                "Bins": "Bins",
                "PhyQty": "Physical Quantity",

                "PrintButton": "Print",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "lenderdeliverynoteissue": {
            "management": {
                "Title": "Lender/Borrower Issue",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "PrintButton": "Print",
                "SaveButton": "Save",
                "RejectButton": "Reject",
                "ActiveButton": "Approve",
                "InActiveButton": "Reject",
                "ViewButton": "View",
                "ReverseButton": "Reverse",
                "RePostButton": "RePost",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "LenderName": "Lender Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Lender/Borrower Issue",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "TabTitle3": "Truck Details",
                "SubTitle": "SubTitle",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "IssueNo": "Document No:*",
                "IssueDate": "Document Date:*",
                "LenderCode": "Lender/Borrower Code:*",
                "LenderName": "Lender/Borrower Name:",
                "FromWareHouse": "From WareHouse:",
                "RefType": "Reference Type:*",
                "IndentNo": "Indent Number:*",
                "IndentDate": "Indent Date:",
                "IssuedBy": "Issued By:",
                "UnitNo": "Unit No:*",
                "TruckNo": "Truck No:",
                "DriverName": "Driver Name:",
                "TransporterId": "Transporter:",
                "DriverTelephoneNo": "Driver Telephone No:",
                "LoadingCharges": "Loading Charges:",
                "OffLoadingCharges": "OffLoading Charges:",
                "TransportationCharges": "Transportation Charges:",
                "Remarks": "Remarks:",
                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "UOMCode": "UOM",
                "UOMName": "UOM Name",
                "ReqQty": "Req. Quantity",
                "ReqDate": "Req. Date",
                "LocationHead": "Location",
                "IssueQty": "Issue Quantity",
                "BalQty": "Balance Quantity",
                "Location": "Location",
                "BinType": "Bin Type",
                "Bins": "Bins",
                "PhyQty": "Physical Quantity",
                "GLoadingCharge": "Loading Charge",
                "GOffLoadingCharge": "OffLoading Charge",
                "GTransportationCharges": "Transportation Charges",

                "ReferenceNo": "Reference No",
                "Date": "Date",
                "WeighBridge": "Weigh Bridge",
                "AfisaReferenceNo": "Afisa Reference No",
                "AfisaDate": "Afisa Date",
                "AfisaWeighBridge": "Afisa Weigh Bridge",
                "Difference": "Difference",
                "GTruckNo": "Truck No",
                "Transporter": "Transporter",
                "TransportCharges": "Transport Charges",
                "Amount": "Amount",
                "OriginName": "Origin Name",

                "PrintButton": "Print",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "taskmaster": {
            "management": {
                "Title": "Task",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "Code": "Code",
                    "Name": "Name:",
                    "TaskType": "Task Type",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Task",
                "SubTitle": "SubTitle",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "TaskType": "Task Type:*",
                "TaskCategory": "Task Category:*",
                "RepeatTaskTimeFrame": "Repeat Task Time Frame:*",
                "StandardTime": "Standard Time:*",
                "RepeatTaskKM": "Repeat Task KM",
                "Currency": "Currency:*",
                "Remarks": "Remarks:",
                "ActionCode": "Action Item Code",
                "ActionName": "Action Item Name",
                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "Partno": "Part No",
                "UOMCode": "UOM",
                "UOMName": "UOM Name",
                "Quantity": "Quantity",
                "SkillCode": "Skill Code",
                "SkillName": "Skill Name",
                "StandardTimeT": "Standard Time",
                "ActualTime": "Actual Time",
                "CurrencyT": "Currency",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"

            }
        },

        "payrollgroup": {
            "management": {
                "Title": "Payroll Group",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "Code": "Code",
                    "Name": "Name:",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Payroll Group",
                "TabTitle1": "General",
                "TabTitle2": "Employee Details",
                "TabTitle3": "Policy Mapping",
                "SubTitle": "SubTitle",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Department": "Department:",
                "Designation": "Designation:",
                "Category": "Category:",
                "Grade": "Grade:",

                "GrdCode": "Code",
                "Grdname": "Name:",

                "Policy": "Policy",
                "EffectiveFrom": "Effective From",
                "EffectiveTo": "Effective To",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"

            }
        },

        "chequebook": {
            "management": {
                "Title": "Cheque Book",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "Code": "Code",
                    "Name": "Name:",
                    "BankName": "Bank Name",
                    "BankAccNo": "Bank Account No.",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Cheque Book",
                "SubTitle": "SubTitle",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "BankCode": "Bank Code:*",
                "BankAccNo": "Bank Account No.:*",
                "ChequeNoFrom": "Cheque No. From:*",
                "ChequeNoTo": "Cheque No. To:*",
                "NoOfCheques": "No. of Cheques:*",
                "Remarks": "Remarks",
                "SaveButton": "Save",
                "ShowRegisterBtn": "Show Register",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "leavepolicy": {
            "management": {
                "Title": "Leave Policy",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "Code": "Code",
                    "Date": "Date",
                    "EffectiveFrom": "Effective From",
                    "EffectiveTo": "Effective To",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Leave Policy",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "SubTitle",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Date": "Date:*",
                "EffectiveFrom": "Effective From:*",
                "EffectiveTo": "Effective To:*",
                "Narration": "Narration:*",
                "LeaveType": "Leave Type",
                "AccurePeriod": "Accures Period",
                "NoofDays": "No of Days",
                "CarryForward": "Carry Forward",
                "IsEncashable": "Is EnCashable",
                "Min": "Minimum",
                "Max": "Maximum",
                "EncashperYear": "Encash Per Year",
                "SaveButton": "Save",
                "PrintButton": "Print",
                "ShowRegisterBtn": "Show Register",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "payrollpolicy": {
            "management": {
                "Title": "Payroll Policy",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Payroll Policy",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "SubTitle",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No:*",
                "Documentdate": "Document Date:*",
                "FromDate": "Effective From:*",
                "ToDate": "Effective To:*",
                "Numerator": "Numerator:*",
                "Denominator": "Denominator:*",
                "Narration": "Narration:*",
                "IncomeDeduction": "Income Deduction",
                "ExpressionName": "Expression Name",
                "FixedValue": "FixedValue",
                "SaveButton": "Save",
                "PrintButton": "Print",
                "ShowRegisterBtn": "Show Register",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "paymentrequest": {
            "management": {
                "Title": "Payment Request",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "EmployeeName": "Employee Name",
                    "Amount": "Amount",
                    "PaymentTo": "Payment To",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Payment Request",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "TabTitle3": "Employee Details",
                "TabTitle4": "Request Details",
                "SubTitle": "SubTitle",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No:*",
                "DocumentDate": "Document Date:*",
                "Employee": "Employee Code:*",
                "VendorCode": "Vendor Code:*",
                "EmployeeName": "Employee Name:*",
                "VendorName": "Vendor Name*",
                "Amount": "Amount:*",
                "InvoiceAmount": "Invoice Amount:*",
                "PaidAmount": "Paid Amount:*",
                "PendingAmount": "Pending Amount:*",
                "PaymentTo": "Payment To:",
                "Narration": "Narration:*",

                "RequestType": "Request Type:*",
                "ReferenceId": "Manifest No.:*",
                "UnitId": "Unit:",
                "RequestNo": "Request No",
                "DepartmentName": "Department Name",

                "ExpenditureTypeCode": "Expenditure Type Code",
                "ExpenditureTypeName": "Expenditure Type Name",
                "Description": "Description",
                "Amount1": "Amount",
                "Segments": "Segments",
                "EmployeeCodeGrid": "Employee Code",
                "EmployeeNameGrid": "Employee Name",
                "AmountGrid": "Amount",
                "RequestNoGrid": "Request No",
                "DateGrid": "Date",

                "SaveButton": "Save",
                "PrintButton": "Print",
                "ShowRegisterBtn": "Show Register",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "operationalpaymentrequest": {
            "management": {
                "Title": "Operational Payment Request",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Operational Payment Request",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "SubTitle",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No:*",
                "DocumentDate": "Document Date:*",

                "ManifestId": "Manifest No:*",
                "UnitId": "Unit No:",

                "ExpenditureTypeCode": "Expenditure Type Code",
                "ExpenditureTypeName": "Expenditure Type Name",
                "refAmount": "Reference Amount",
                "balAmount": "Balance Amount",
                "exeAmount": "Executed Amount",
                "reqAmount": "Requested Amount",
                "SaveButton": "Save",
                "PrintButton": "Print",
                "ShowRegisterBtn": "Show Register",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "trailer": {
            "management": {
                "Title": "Trailer",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Organization",
                    "Code": "Code ",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Trailer",
                "SubTitle": "SubTitle",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "AssetName": "Asset Name:",
                "DateOfCommission": "Date Of Commission:*",
                "NoOfAxles": "No Of Axles:*",
                "BrandName": "Brand Name:*",
                "ModelName": "Model Name:",
                "TrailerType": "Trailer Type:*",
                "TrailerCategory": "Trailer Category:*",
                "TareWeight": "Tare Weight:*",
                "TonnageCapacity": "Tonnage Capacity:*",
                "Length": "Length:",
                "Breadth": "Breadth:*",
                "Height": "Height:*",
                "KM Run": "KM Run:*",
                "ChassisNo": "Chassis No:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"

            }
        },



        "unit": {
            "management": {
                "Title": "Unit",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Organization",
                    "Code": "Code ",
                    "TruckHeadName": "Truck Head Name",
                    "FirstTrailerName": "First Trailer Name",
                    "SecondTrailerName": "Second Trailer Name",
                    "DollyName": "Dolly Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Unit",
                "SubTitle": "SubTitle",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "CounterReading": "Counter Reading:",
                "TruckHeadName": "Truck Head Name:*",
                "FirstTrailerName": "First Trailer Name:*",
                "DollyName": "Dolly Name:*",
                "SecondTrailerName": "Second Trailer Name:",
                "PlateNo": "Plate No:",
                "Remarks": "Remarks:",
                "PrintButton": "Print",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"

            }
        },

        "drivercard": {
            "management": {
                "Title": "Driver Card",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DriverCardNo": "Driver Card No",
                    "ValidFromDate": "Valid From Date",
                    "Unit": "Unit",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Driver Card",
                "SubTitle": "SubTitle",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DriverCardNo": "Driver Card No:*",
                "PlateNo": "Plate No:",
                "DriverName": "Driver Name:*",
                "UnitNo": "Unit No:*",
                "OperationsOfficerName": "Operations Officer Name:*",
                "UnitTypeName": "Unit Type Name:",
                "ValidFromDate": "Valid From Date:",
                "ValidToDate": "Valid To Date:",
                "Remarks": "Remarks:",

                "TabTitle1": "General",
                "TabTitle2": "Manifest Details",
                "TabTitle3": "Empty Trip Details",
                "TabTitle4": "Fuel Details",
                "TabTitle5": "Summary",

                "manifestno": "Manifest No",
                "manifestdate": "Manifest Date",
                "unitno": "Unit",
                "route": "Route",
                "km": "Distance",
                "acknowledgementDate": "Acknowledgement Date",
                "expectedTripDuration": "Expected Trip Duration",
                "actualTripDuration": "Actual Trip Duration",
                "incentive": "Incentive",
                "daysDifference": "Days Difference",
                "dedAddAmount": "Ded/AddAmount",
                "netIncentive": "Net Incentive",
                "expectedFuel": "Expected Fuel",

                "documentno": "Document No",
                "documentdate": "Document Date",
                "distance": "Distance",

                "fuelSlipNo": "Fuel Slip No",
                "FuelSlipDate": "Fuel Slip Date",
                "fuelstation": "Fuel Station",
                "Route": "Route",
                "quantity": "Quantity",
                "expectedFuelConsumption": "Expected Fuel",


                "TotalFuelRequired": "Total Fuel Required",
                "TotalFuelReceived": "Total Fuel Received",
                "Shortage": "Shortage",
                "ShortageAmount": "Shortage Amount",
                "ShortagePercentage": "Shortage Percentage",
                "TotalIncentive": "Total Incentive",
                "SpeedPenalty": "Speed Penalty",
                "MealAllowance": "Meal Allowance",
                "TotalMealAllowance": "Total Meal Allowance",
                "NetIncentive": "Net Incentive",



                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "PrintButton": "Print",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"

            }
        },

        "fuelconfiguration": {
            "management": {
                "Title": "Fuel Configuration",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "unitType": "Unit Type ",
                    "trailerType": "Trailer Type",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Fuel Configuration",
                "SubTitle": "SubTitle",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "UnitModelNo": "Unit Model No:*",
                "UnitTypeName": "Unit Type Name:",
                "FuelConsumptionOnRoadLoaded": "Fuel Conmn On Road Loaded:*",
                "FuelConsumptionOnRoadEmpty": "Fuel Conmn On Road Empty:*",
                "FuelConsumptionOffRoadLoaded": "Fuel Conmn Off Road Loaded:*",
                "FuelConsumptionOffRoadEmpty": "Fuel Conmn Off Road Empty:",
                "FuelConsumptionForestRoadLoaded": "Fuel Conmn Forest Road Loaded:",
                "FuelConsumptionForestRoadEmpty": "Fuel Conmn Forest Road Empty:",
                "AvgKMperHourOnRoadLoaded": "Avg KM per Hour On Road Loaded:*",
                "AvgKMperHourOnRoadEmpty": "Avg KM per Hour On Road Empty:*",
                "AvgKMperHourOffRoadLoaded": "Avg KM per Hour Off Road Loaded:*",
                "AvgKMperHourOffRoadEmpty": "Avg KM per Hour Off Road Empty:",
                "AvgKMperHourForestRoadLoaded": "Avg KM per Hour Forest Road Loaded:",
                "AvgKMperHourForestRoadEmpty": "Avg KM per Hour Forest Road Empty:",
                "TargetKM": "Target KM:*",
                "FromTonnage": "From Tonnage:*",
                "ToTonnage": "To Tonnage:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "PrintButton": "Print",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"

            }
        },


        "deliverynote": {
            "management": {
                "Title": "Delivery Note",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "PrintAckButton": "AckPrint",
                "ViewButton": "View",
                "ReverseButton": "Reverse",
                "RePostButton": "RePost",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "CustomerName": "Customer Name",
                    "Destination": "Final Destination",
                    "TruckNo": "Truck No",
                    "ReferenceNo": "Reference No",
                    "Status": "Status",
                }
            },
            "create": {
                "Title": "Delivery Note",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "TabTitle3": "Other Details",
                "TabTitle4": "AccountPosting",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "PrintAckButton": "AckPrint",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DivisionCode": "Division Code:*",
                "DivisionName": "Division Name:",
                "TransNo": "Document No.:*",
                "TransDate": "Document Date:*",
                "FromWareHouse": "From WareHouse:",
                "Narration": "Narration:",
                "CustomerTypeCode": "Customer Type Code:*",
                "CustomerTypeName": "Customer Type Name:*",
                "CustomerCode": "Customer Code:*",
                "CustomerName": "Customer Name:",
                "BillingAddress": "Billing Address:*",
                "ShippingAddress": "Shipping Address:*",
                "RefType": "Reference Type:*",
                "SODNo": "Reference No.:*",
                "SODDate": "Reference Date:",
                "Destination": "Final Destination:",
                "Transporter": "Transporter:",

                "Loading": "Loading Charges:",
                "OffLoading": "Unloading Charges:",
                "TransportationCharges": "Transportation Charges:",

                "TruckNo": "Truck No.:",
                "DriverName": "Driver Name:",
                "DriverPhone": "Driver Phone No.:",
                "LoadTo": "Load To:",

                "SalesRoute": "Sales Route",
                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "UOMCode": "UOM",
                "UOMName": "UOM Name",
                "ReqQty": "Reference Quantity",
                "Location": "Location",
                "PhyQty": "Physical Quantity",
                "IssueQty": "Delivery Quantity",
                "BalQty": "Balance Quantity",
                "SalesPrice": "Price",

                "WeightOnQuantity": "Weight Based On Quantity",
                "ActualWeight": "Actual Weight",

                "GLoadingCharge": "Loading Charges",
                "GOffLoadingCharge": "Offloading Charges",
                "GTransportationCharges": "Transportation Charges",
                "BatchNo": "Batch No.",

                "AccountCode": "Account Code",
                "AccountName": "Account Name",
                "Debit": "Debit",
                "Credit": "Credit",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "pregiftdelivery": {
            "management": {
                "Title": "Free Gift Delivery Note",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ReverseButton": "Reverse",
                "RePostButton": "RePost",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "CustomerName": "Party Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Free Gift Delivery Note",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "TabTitle3": "Other Details",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DivisionCode": "Division Code:*",
                "DivisionName": "Division Name:",
                "TransNo": "Document No.:*",
                "TransDate": "Document Date:*",
                "Narration": "Narration:",
                "CustomerTypeCode": "Customer Type Code:*",
                "CustomerTypeName": "Customer Type Name:*",
                "PartyType": "Party Type:*",
                "PartyCode": "Party Code:*",
                "PartyName": "Party Name:",
                "CustomerCode": "Customer Code:*",
                "CustomerName": "Customer Name:",
                "BillingAddress": "Billing Address:*",
                "ShippingAddress": "Shipping Address:*",
                "RefType": "Reference Type:*",
                "SODNo": "Reference No.:*",
                "SODDate": "Reference Date:",
                "FromWareHouse": "From WareHouse:",
                "Transporter": "Transporter:",

                "Loading": "Loading Charges:",
                "OffLoading": "Unloading Charges:",
                "TransportationCharges": "Transportation Charges:",

                "TruckNo": "Truck No.:",
                "DriverName": "Driver Name:",
                "DriverPhone": "Driver Phone No.:",
                "LoadTo": "Load To:",

                "SalesRoute": "Sales Route",
                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "UOMCode": "UOM",
                "UOMName": "UOM Name",
                "ReqQty": "Reference Quantity",
                "Location": "Location",
                "PhyQty": "Physical Quantity",
                "IssueQty": "Delivery Quantity",
                "BalQty": "Balance Quantity",
                "SalesPrice": "Price",

                "WeightOnQuantity": "Weight Based On Quantity",
                "ActualWeight": "Actual Weight",

                "GLoadingCharge": "Loading Charges",
                "GOffLoadingCharge": "Offloading Charges",
                "GTransportationCharges": "Transportation Charges",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "integrationacc": {
            "management": {
                "Title": "Integration Accounts",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "AmendButton": "Amend",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "OrgCode": "Org Code",
                    "OrgName": "Org Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Integration Accounts",
                "SubTitle": "SubTitle",

                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "IntAcc": "Integration Account",
                "Code": "Code",
                "Name": "Name",
                "Segment": "Segment",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "dailyattendance": {
            "management": {
                "Title": "Daily Attendance",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "AmendButton": "Amend",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Org": "Organization",
                    "FromDate": "From Date",
                    "ToDate": "To Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Daily Attendance",
                "SubTitle": "SubTitle",

                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "FTP": "For the Period:*",
                "FromDate": "From Date:",
                "ToDate": "To Date:",
                "PayrollGrpCode": "Payroll Group Code:",
                "PayrollGrpName": "Payroll Group Name:",

                "Code": "Code",
                "Name": "Name:",
                "Day1": "Day1",
                "Day2": "Day2",
                "Day3": "Day3",
                "Day4": "Day4",
                "Day5": "Day5",
                "Day6": "Day6",
                "Day7": "Day7",
                "Day8": "Day8",
                "Day9": "Day9",
                "Day10": "Day10",
                "Day11": "Day11",
                "Day12": "Day12",
                "Day13": "Day13",
                "Day14": "Day14",
                "Day15": "Day15",
                "Day16": "Day16",
                "Day17": "Day17",
                "Day18": "Day18",
                "Day19": "Day19",
                "Day20": "Day20",
                "Day21": "Day21",
                "Day22": "Day22",
                "Day23": "Day23",
                "Day24": "Day24",
                "Day25": "Day25",
                "Day26": "Day26",
                "Day27": "Day27",
                "Day28": "Day28",
                "Day29": "Day29",
                "Day30": "Day30",
                "Day31": "Day31",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "consolidatedattendance": {
            "management": {
                "Title": "Consolidated Attendance",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "AmendButton": "Amend",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Org": "Organization",
                    "FromDate": "From Date",
                    "ToDate": "To Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Consolidated Attendance",
                "SubTitle": "SubTitle",

                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "FTP": "For the Period:*",
                "FromDate": "From Date:",
                "ToDate": "To Date:",
                "PayrollGrpCode": "Payroll Group Code:",
                "PayrollGrpName": "Payroll Group Name:",

                "Code": "Code",
                "Name": "Name:",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "contract": {
            "management": {
                "Title": "Contract",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "CustomerName": "Customer Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Contract",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "ValidFrom": "Valid From:*",
                "ValidTo": "Valid To:*",
                "SalesPersonCode": "Sales Person Code:*",
                "SalesPersonName": "Sales Person Name:",
                "CustomerCode": "Customer Code:*",
                "CustomerName": "Customer Name:",
                "VATAddition": "VAT addition in MF Invoice:*",
                "MultipleInvoice": "Multiple Invoices Allowed:*",
                "DeductShortage": "Deductions:*",
                "DeductRate": "Deduction Rate:*",
                "NoOfInvoice": "No. of Invoice copies:*",
                "Currency": "Currency:*",
                "PaymentTerms": "Payment Terms:*",
                "InvoicingInterval": "Invoicing Interval:*",
                "Narration": "Narration:",

                "RouteCode": "Route Code",
                "RouteName": "Route Name",
                "ConsignType": "Consignment Type",
                "CommodityCode": "Commodity Code",
                "CommodityName": "Commodity Name",
                "Weight": "Weight",
                "Distance": "Distance",
                "UOM": "UOM",
                "Quantity": "Quantity",
                "RateType": "Rate On",
                "MinimumLoad": "Minimum Load",
                "MiscellaneousCharges": "Miscellaneous Charges",

                "CostperTon": "Rate",
                "LoadingCharge": "Loading Charge",
                "UnloadingCharge": "Unloading Charge",
                "CostperTrip": "Rate Per Trip",
                "RateperUOM": "Rate per UOM/Km",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "tripplan": {
            "management": {
                "Title": "Trip Plan",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Trip Plan",
                "Tab1": "General",
                "Tab2": "Details",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No:*",
                "DocumentDate": "Document Date:*",
                "ValidFrom": "Valid From:*",
                "ValidTo": "Valid To:*",
                "PlanCreator": "Planner Code:*",
                "SalesPersonName": "Planner Name:",
                "CustomerCode": "Customer Code:*",
                "CustomerName": "Customer Name:",
                "VATAddition": "VAT addition in MF Invoice:*",
                "MultipleInvoice": "Multiple Invoices Allowed:*",
                "DeductShortage": "Deductions:*",
                "DeductRate": "Deduction Rate:*",
                "NoOfInvoice": "No. of Invoice copies:*",
                "Currency": "Currency:*",
                "Narration": "Narration:",
                "RouteCode": "Route Code",
                "RouteName": "Route Name",
                "Model": "Model",
                "TrailerType": "Trailer Type",
                "Commodity": "Commodity Code",
                "CommodityName": "Commodity Name",
                "NoofTrips": "No of Trips",
                "TripCapacity": "Trip Capacity",
                "TotalTonnage": "Total Tonnage",
                "NoofUnitsRequired": "No of Units Required",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "businessplan": {
            "management": {
                "Title": "Business Plan",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Business Plan",
                "Tab1": "General",
                "Tab2": "Details",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No:*",
                "DocumentDate": "Document Date:*",
                "ValidFrom": "Valid From:*",
                "ValidTo": "Valid To:*",
                "PlanCreator": "Planner Code:*",
                "SalesPersonName": "Planner Name:",
                "CustomerCode": "Customer Code:*",
                "CustomerName": "Customer Name:",
                "VATAddition": "VAT addition in MF Invoice:*",
                "MultipleInvoice": "Multiple Invoices Allowed:*",
                "DeductShortage": "Deductions:*",
                "DeductRate": "Deduction Rate:*",
                "NoOfInvoice": "No. of Invoice copies:*",
                "Currency": "Currency:*",
                "Narration": "Narration:",
                "RouteCode": "Route Code",
                "RouteName": "Route Name",
                "Model": "Model",
                "TrailerType": "Trailer Type",
                "Commodity": "Commodity",
                "CommodityName": "Commodity Name",
                "Distance": "Distance",
                "RatePerTon": "RatePerTon",
                "CumulativeFreightTon": "Cumulative Freight Ton",
                "TotalRevenue": "Total Revenue",

                "Jan": "Jan",
                "Feb": "Feb",
                "Mar": "Mar",
                "Apr": "Apr",

                "May": "May",
                "Jun": "Jun",
                "Jul": "Jul",
                "Aug": "Aug",

                "Sep": "Sep",
                "Oct": "Oct",
                "Nov": "Nov",
                "Dec": "Dec",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "weighbridge": {
            "management": {
                "Title": "Way Bridge",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "ManifestNo": "Manifest No",
                    "Amount": "Amount",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Way Bridge",
                "Tab1": "General",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No:*",
                "DocumentDate": "Document Date:*",
                "WeighBridgeNo": "Weigh Bridge No:*",
                "ManifestId": "Manifest No:*",
                "WeighBridgeSupplierId": "Weigh Bridge Supplier Name:*",
                "Amount": "Amount:",
                "Remarks": "Remarks:",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },
        "weighbridgesupplier": {
            "management": {
                "Title": "Way Bridge Supplier",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "Code": "Code",
                    "Name": "Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Way Bridge Supplier",
                "Tab1": "General",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "waybill": {
            "management": {
                "Title": "Way Bill",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "WayBillNo": "Way Bill No",
                    "ManifestNo": "Manifest No",
                    "Amount": "Amount",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Way Bill",
                "Tab1": "General",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No:*",
                "DocumentDate": "Document Date:*",
                "WayBillNo": "Way Bill No:*",
                "WayBillDate": "Way Bill Date:*",
                "ManifestId": "Manifest No:*",
                "Amount": "Amount:",
                "Remarks": "Remarks:",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "unitmapping": {
            "management": {
                "Title": "Unit Mapping",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "OperationOfficerCode": "Operation Officer Code",
                    "OperationOfficerName": "Operation Officer Name ",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Unit Mapping",
                "TabTitle1": "Unit Mapping",
                "TabTitle2": "Unit Mapping Details",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OperationOfficerCode": "Operation Officer Code:*",
                "OperationOfficerName": "Operation Officer Name:*",
                "Narration": "Narration:",
                "UnitCode": "Unit Code:*",
                "UnitName": "Unit Name:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },


        "triporder": {
            "management": {
                "Title": "Trip Order",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Trip Order",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.*",
                "DocumentDate": "Document Date*",
                "ValidFrom": "Valid From*",
                "ValidTo": "Valid To:*",
                "CustomerCode": "Customer Code*",
                "CustomerName": "Customer Name",
                "ContractNo": "Contract No*",
                "ContractDate": "Contract Date",
                "OpeDivCode": "Operation Division Code*",
                "OpeDivName": "Operation Division Name",
                "Narration": "Narration",

                "RouteCode": "Route Code",
                "RouteName": "Route Name",
                "Distance": "Distance",
                "ConsignType": "Consignment Type",
                "CommodityCode": "Commodity Code",
                "CommodityName": "Commodity Name",
                "Tonnage": "Tonnage",
                "CPTFT": "CPTFT",
                "FreightAmount": "Freight Amount",
                "ExecutedQuantity": "Executed Quantity",
                "BalanceQuantity": "Balance Quantity",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "commercialfleetcontract": {
            "management": {
                "Title": "Commercial Fleet Contract",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Commercial Fleet Contract",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "ValidFrom": "Valid From:*",
                "ValidTo": "Valid To:*",
                "SalesPersonCode": "Sales Person Code:*",
                "SalesPersonName": "Sales Person Name:",
                "VendorCode": "Vendor Code:*",
                "VendorName": "Vendor Name:",
                "VATAddition": "VAT addition in MF Invoice:*",
                "MultipleInvoice": "Multiple Invoices Allowed:*",
                "DeductShortage": "Deductions:*",
                "DeductRate": "Deduction Rate:*",
                "NoOfInvoice": "No. of Invoice copies:*",
                "Currency": "Currency:*",
                "Narration": "Narration:",

                "RouteCode": "Route Code",
                "RouteName": "Route Name",
                "ConsignType": "Consignment Type",
                "CommodityCode": "Commodity Code",
                "CommodityName": "Commodity Name",
                "Weight": "Weight",
                "Distance": "Distance",
                "CostperTon": "Cost Per Ton",
                "LoadingCharge": "Loading Charge",
                "UnloadingCharge": "Unloading Charge",
                "CostperTrip": "Cost Per Trip",
                "RateperUOM": "Rate per UOM",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "manifestcreation": {
            "management": {
                "Title": "Manifest Creation",
                "Title2": "Manifest Acknowledge",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "RecentManifest": "Recent Manifest",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "UnitNo": "Unit No",
                    "CustomerName": "Customer Name",
                    "ManifestInvoiceNo": "Manifest InvoiceNo",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Manifest Creation",
                "Title2": "Manifest Acknowledgement",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "AcknDate": "Acknowledge Date:",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Manifest No.:*",
                "DocumentDate": "Manifest Date:*",
                "TransType": "Reference Type:*",
                "TripStatus": "Trip Status:*",
                "ContractNo": "Contract No.:",
                "IsTrip": "Is Trip Ref:",
                "TripOrderNo": "Trip Order No.:*",
                "DepartureTime": "Departure Time:",
                "VessalName": "Vessal Name:*",
                "BillOfLoading": "Bill Of Lading:",
                "Consignr": "Consignor:*",
                "RegistrationNo": "Registration No.:*",
                "VehicleType": "Vehicle Type:*",
                "TruckOwner": "Commercial Contract:*",
                "TrailerTypeId": "Trailer Type:",
                "WorkOrderNo": "Work Order No:",
                "UnitNo": "Unit No.:*",
                "DriverCardNo": "Driver Card No.:*",
                "DriverName": "Driver Name:",
                "WayBillNo": "Way Bill No.:",
                "PlateNo": "Plate No.:",
                "VATCertificateNo": "VAT Certificate No.:",
                "RouteCode": "Route Code:",
                "RouteName": "Route Name:",
                "Distance": "Distance:",
                "DriveCode": "Drive Code:",
                "DriverId": "Driver Id:",
                "Consignee": "Consignee:",
                "CurrentKMReading": "Current KM Reading:",
                "CommercialTruck": "Commercial Truck No:",
                "PackingNo": "Packing No",
                "ConsignType": "Consignment Type",
                "Description": "Description",
                "Commodity": "Commodity",
                "BalanceQty": "Balance Quantity",
                "Quantity": "Quantity",
                "Length": "Length",
                "Width": "Width",
                "Height": "Height",
                "Volume": "Volume",
                "TrailerNo": "Trailer No:",
                "LoadingPerson": "Loading Person:",
                "GrossWeight": "Gross Weight",
                "Rate_UOM_KM": "Rate per Ton",
                "Amount": "Amount",
                "ContainerNo": "Container No",
                "Remarks": "Remarks",
                "Currency": "Currency:",
                "AckQty": "Acknowledge Quantity",
                "InvQty": "Invoice Quantity",

                "MinimumLoad": "Minimum Load",
                "RateType": "Rate Type",
                "loadingPersonId": "Loading Person:",

                "NoOfItems": "No Of Items:",
                "EmployeeName": "Employee Name:",
                "FreightAmount": "Freight Amount:",
                "VATAmount": "VAT Amount:",
                "WnddStamp": "Tax Component Amount:",
                "Total": "Total:",
                "MonthlyTarget": "Monthly Target:",
                "AchievedTarget": "Achieved Target:",
                "DriverPerformance": "Driver Performance:",
                "DriverIncentive": "Driver Incentive:",
                "FuelReqLoaded": "Fuel Required Loaded:",
                "FuelReqEmpty": "Fuel Required Empty:",
                "ApproxTripExpenses": "Approx Trip Expenses:",
                "RoadFees": "Road Fee:",
                "BridgeFees": "Bridge Fee:",
                "TruckOwnerName": "Truck Owner Name:",
                "Preload": "Preload:",
                "LoadingCompletionDate": "Loading Completion Date:",
                "TotalMiscellaneousCharges": "TotalMiscellaneousCharges:",

                "FinalDestination": "Final Destination:",
                "LoadUnitNumber": "Load Unit No:",
                "ExtraKM": "Extra KM:",
                "MiscellaneousCharges": "Miscellaneous Charges",
                "OtherCharges": "Other Charges",
                "AdjustedAmount": "AdjustedAmount",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "fuelinvoice": {
            "management": {
                "Title": "Fuel Invoice",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Fuel Invoice",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "VendorCode": "Vendor Code:*",
                "VendorName": "Vendor Name:",
                "ExternalFuelStation": "External Fuel Station Code:*",
                "ExternalFuelStationName": "External Fuel Station Name:",
                "Currency": "Currency:*",
                "Remarks": "Remarks:",
                "ExchangeRate": "Exchange Rate:",
                "ExchangeDate": "Exchange Date:",
                "PaymentTermCode": "Payment Term Code:*",
                "PaymentTermName": "Payment Term Name:",

                "FuelSlipNo": "Fuel Slip No",
                "Date": "Date",
                "Quantity": "Quantity",
                "Price": "Price",
                "Amount": "Amount",

                "GrossAmount": "Gross Amount",
                "TaxAmount": "Tax Amount",
                "TotalNetAmount": "Total Net Amount",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },
        "manifestinvoice": {
            "management": {
                "Title": "Manifest Invoice",
                "MiscellaneousTitle": "Miscellaneous Invoice",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "CustomerName": "Customer Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Manifest Invoice",
                "MiscellaneousTitle": "Miscellaneous Invoice",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "TabTitle3": "Due Payment Details",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "CustomerCode": "Customer Code:*",
                "CustomerName": "Customer Name:",
                "ContractNo": "Contract No.:*",
                "ContractDate": "Contract Date:",
                "Currency": "Currency:*",
                "Remarks": "Remarks:",
                "ExchangeRate": "Exchange Rate:",
                "ExchangeDate": "Exchange Date:",
                "PaymentTermCode": "Payment Term Code:*",
                "PaymentTermName": "Payment Term Name:",

                "ManifestNo": "Manifest NO",
                "Date": "Date",
                "Quantity": "Quantity",
                "FreightAmount": "Freight Amount",
                "VATAmount": "VAT Amount",
                "NetAmount": "Net Amount",
                "MiscellaneousCharges": "Miscellaneous Charges",

                "GrossAmount": "Gross Amount:",
                "TaxAmount": "Tax Amount:",
                "TotalNetAmount": "Total Net Amount:",

                "SNO": "Sl.No.",
                "Payment": "Payment %",
                "Amount": "Amount",
                "DueDate": "Due Date",

                "WayBillNo": "Way Bill No",
                "Route": "Route",
                "PlateNo": "PlateNo",
                "TrailerNo": "TrailerNo",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },
        "otherinvoice": {
            "management": {
                "Title": "Other Invoice",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "CustomerName": "Customer Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Other Invoice",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "TabTitle3": "Due Payment Details",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "CustomerCode": "Customer Code:*",
                "CustomerName": "Customer Name:",
                "ContractNo": "Contract No.:*",
                "ContractDate": "Contract Date:",
                "Currency": "Currency:*",
                "Remarks": "Remarks:",
                "ExchangeRate": "Exchange Rate:",
                "ExchangeDate": "Exchange Date:",
                "PaymentTermCode": "Payment Term Code:*",
                "PaymentTermName": "Payment Term Name:",
                "TransType": "Trans Type",

                "documentNo": "Document No",
                "documentDate": "Document Date",
                "wbNo": "Weigh Bridge No",
                "wayBillNo": "Way Bill No",
                "wayBillDate": "Way Bill Date",
                "manifestId": "Manifest No",
                "wbSupplierId": "WB Supplier Name",
                "amount": "Amount",
                "narration": "Narration",

                "GrossAmount": "Gross Amount:",
                "TaxAmount": "Tax Amount:",
                "TotalNetAmount": "Total Net Amount:",

                "SNO": "Sl.No.",
                "Payment": "Payment %",
                "Amount": "Amount",
                "DueDate": "Due Date",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },
        "commercialfleetmanifestinvoice": {
            "management": {
                "Title": "Commercial Fleet Invoice",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Commercial Fleet Invoice",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Invoice No.:*",
                "DocumentDate": "Date:*",
                "VendorCode": "Vendor Code:*",
                "VendorName": "Vendor Name:",
                "ContractNo": "Contract No.:*",
                "ContractDate": "Contract Date:",
                "Currency": "Currency:*",
                "Remarks": "Remarks:",
                "ExchangeRate": "Exchange Rate:",
                "ExchangeDate": "Exchange Date:",
                "PaymentTermCode": "Payment Term Code:*",
                "PaymentTermName": "Payment Term Name:",

                "ManifestNo": "Manifest No",
                "Date": "Date",
                "RouteName": "Route Name",
                "FreightAmount": "Freight Amount",
                "GrossAmount": "Gross Amount",
                "TaxAmount": "Tax Amount",
                "TotalNetAmount": "Total Net Amount",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "pettycashpayment": {
            "management": {
                "Title": "Petty Cash Payment",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Post",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "ReverseButton": "Reverse",
                "RePostButton": "RePost",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "PaymentAmount": "Amount",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Petty Cash Payment",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "SubTitle",

                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "PostingDate": "Posting Date:*",
                "CashCode": "Petty Cash Code:*",
                "CashName": "Petty Cash Name:",
                "BalanceAmount": "Balance Amount:",
                "Payee": "Payee:",
                "Amount": "Amount:",
                "TotalAmount": "TotalAmount:",
                "Currency": "Currency:*",
                "ExchangeRate": "Exchange Rate:",
                "DebitAmount": "Debit Amount:*",
                "Narration": "Narration:",

                "Code": "Code",
                "Name": "Name:",
                "DebitAmountGrd": "Amount",
                "CreditAmountGrd": "Amount",
                "Remarks": "Remarks",
                "Segment": "Segments",
                "PrintButton": "Print",
                "SaveButton": "Save",
                "Save": "Save",
                "BacktotheList": "Back",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "adjustment": {
            "management": {
                "Title": "Adjustment",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Adjustment",
                "SubTitle": "SubTitle",

                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "VendorCode": "Vendor Code:*",
                "VendorName": "Vendor Name:",
                "AccountCode": "Account Code:*",
                "AccountName": "Account Name:",
                "TotalOutstandingAmount": "Total Outstanding Amount:",
                "AdjustmentDate": "Adjustment Date:*",

                "DocNo": "Document No",
                "DocDate": "Document Date",
                "Type": "Type",
                "ValueAmount": "Value Amount",
                "NetAmount": "Adjusted Amount",
                "AdjAmount": "Adj. Amount",
                "BalAmount": "Balance Amount",
                "FS": "FS",
                "DueDate": "Due Date",
                "OrgCode": "Org Code",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },


        "pettycashreceipt": {
            "management": {
                "Title": "Petty Cash Receipt",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Post",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "ReverseButton": "Reverse",
                "RePostButton": "RePost",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "ReceiptAmount": "Amount",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Petty Cash Receipt",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "SubTitle",

                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "PostingDate": "Posting Date:*",
                "CashCode": "Petty Cash Code:*",
                "CashName": "Petty Cash Name:",
                "BalanceAmount": "Balance Amount:",
                "Payee": "Payee:",
                "Amount": "Amount:",
                "TotalAmount": "TotalAmount:",
                "Currency": "Currency:*",
                "ExchangeRate": "Exchange Rate:",
                "DebitAmount": "Debit Amount:*",
                "Narration": "Narration:",

                "Code": "Account Code",
                "Name": "Account Name",
                "DebitAmountGrd": "Amount",
                "CreditAmountGrd": "Amount",
                "Remarks": "Remarks",
                "Segment": "Segments",
                "PrintButton": "Print",
                "SaveButton": "Save",
                "Save": "Save",
                "BacktotheList": "Back",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "controlaccount": {
            "management": {
                "Title": "Control Account",
                "SubTitle1": "General",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "AccountType": "Account Type",
                    "controlAccountCode": "Control Account Code",
                    "controlAccountName": "Control Account Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Control Account",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "SubTitle",

                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.:*",
                "AccountType": "Account Type:*",
                "ControlAccount": "Control Account:*",

                "Code": "Code",
                "Name": "Name:",


                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "openingbalance": {
            "management": {
                "Title": "Opening Balance",
                "SubTitle1": "General",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Opening Balance",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "SubTitle",

                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "AccountType": "Account Type:*",
                "Currency": "Currency:*",
                "ControlAccountId": "ControlAccount:*",
                "ExchangeRate": "Exchange Rate:",
                "ExchangeDate": "Exchange Date:*",
                "Narration": "Narration:",

                "Code": "Code",
                "Name": "Name",
                "ControlAccount": "Control Account",
                "TransType": "Trans Type",
                "TransNo": "Trans No",
                "TransDate": "Trans Date",
                "DueDate": "Due Date",
                "DebitAmountGrd": "Debit",
                "CreditAmountGrd": "Credit",
                "Transaction": "Transaction",
                "Remarks": "Remarks",
                "Segment": "Segments",

                "SaveButton": "Save",
                "PrintButton": "Print",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "ardebitnote": {
            "management": {
                "Title": "AR Debit Note",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Post",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "ReverseButton": "Reverse",
                "RePostButton": "RePost",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "CustomerName": "Customer Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "AR Debit Note",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "SubTitle",

                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "PostingDate": "Posting Date:*",
                "CustomerCode": "Customer Code:*",
                "CustomerName": "Customer Name:",
                "Reason": "Reason:*",
                "InvoiceRef": "Invoice Reference:*",
                "Currency": "Currency:*",
                "ExchangeRate": "Exchange Rate:",
                "ExchangeDate": "Exchange Date:*",
                "DebitAmount": "Debit Amount:*",
                "Narration": "Narration:",

                "Code": "Code",
                "Name": "Name:",
                "DebitAmountGrd": "Debit",
                "CreditAmountGrd": "Credit",
                "Remarks": "Remarks",
                "Segment": "Segments",

                "SaveButton": "Save",
                "PrintButton": "Print",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "discount": {
            "management": {
                "Title": "Discount",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Post",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "ReverseButton": "Reverse",
                "RePostButton": "RePost",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "CustomerName": "Customer Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Discount",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "SubTitle",

                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "PostingDate": "Posting Date:*",
                "CustomerCode": "Customer Code:*",
                "CustomerName": "Customer Name:",
                "Reason": "Reason:*",
                "InvoiceRef": "Invoice Reference:*",
                "Currency": "Currency:*",
                "ExchangeRate": "Exchange Rate:",
                "ExchangeDate": "Exchange Date:*",
                "DebitAmount": "Debit Amount:*",
                "Narration": "Narration:",

                "Code": "Code",
                "Name": "Name:",
                "DebitAmountGrd": "Debit",
                "CreditAmountGrd": "Credit",
                "Remarks": "Remarks",
                "Segment": "Segments",

                "SaveButton": "Save",
                "PrintButton": "Print",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "apdebitnote": {
            "management": {
                "Title": "AP Debit Note",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Post",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "ReverseButton": "Reverse",
                "RePostButton": "RePost",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "VendorName": "Vendor Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "AP Debit Note",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "SubTitle",

                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "PostingDate": "Posting Date:*",
                "VendorCode": "Vendor Code:*",
                "VendorName": "Vendor Name:",
                "Reason": "Reason:*",
                "InvoiceRef": "Invoice Ref.:*",
                "Currency": "Currency:*",
                "ExchangeRate": "Exchange Rate:",
                "ExchangeDate": "Exchange Date:*",
                "DebitAmount": "Debit Amount:*",
                "Narration": "Narration:",

                "Code": "Code",
                "Name": "Name",
                "DebitAmountGrd": "Debit",
                "CreditAmountGrd": "Credit",
                "Remarks": "Remarks",
                "Segment": "Segments",
                "PrintButton": "Print",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "arcreditnote": {
            "management": {
                "Title": "AR Credit Note",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Post",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "ReverseButton": "Reverse",
                "RePostButton": "RePost",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "CustomerName": "Customer Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "AR Credit Note",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "SubTitle",

                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "PostingDate": "Posting Date:*",
                "CustomerCode": "Customer Code:*",
                "CustomerName": "Customer Name:",
                "Reason": "Reason:*",
                "InvoiceRef": "Invoice Ref.:*",
                "Currency": "Currency:*",
                "ExchangeRate": "Exchange Rate:",
                "ExchangeDate": "Exchange Date:*",
                "CreditAmount": "Credit Amount:*",
                "Narration": "Narration:",

                "Code": "Code",
                "Name": "Name",
                "DebitAmountGrd": "Debit",
                "CreditAmountGrd": "Credit",
                "Remarks": "Remarks",
                "Segment": "Segments",
                "PrintButton": "Print",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "apcreditnote": {
            "management": {
                "Title": "AP Credit Note",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Post",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "ReverseButton": "Reverse",
                "RePostButton": "RePost",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "VendorName": "Vendor Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "AP Credit Note",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "SubTitle",

                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "PostingDate": "Posting Date:*",
                "VendorCode": "Vendor Code:*",
                "VendorName": "Vendor Name:",
                "Reason": "Reason:*",
                "InvoiceRef": "Invoice Ref.:*",
                "Currency": "Currency:*",
                "ExchangeRate": "Exchange Rate:",
                "ExchangeDate": "Exchange Date:*",
                "CreditAmount": "Credit Amount:*",
                "Narration": "Narration:",

                "Code": "Code",
                "Name": "Name",
                "DebitAmountGrd": "Debit",
                "CreditAmountGrd": "Credit",
                "Remarks": "Remarks",
                "Segment": "Segments",
                "PrintButton": "Print",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },



        "journalvoucher": {
            "management": {
                "Title": "Journal Voucher",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Post",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "ReverseButton": "Reverse",
                "RePostButton": "RePost",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Amount": "Amount",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Journal Voucher",
                "TabTitle1": "JV",
                "TabTitle2": "JV Details",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "PostingDate": "Posting Date:*",
                "Currency": "Currency:*",
                "JournalType": "Journal Type:*",
                "BillOfExchange": "Bill Of Exchange:*",
                "ExchangeRate": "Exchange Rate:",
                "ExchangeDate": "Exchange Date:*",
                "DebitAmount": "Debit Amount:*",
                "Narration": "Narration:",

                "Code": "Code",
                "Name": "Name",
                "DebitAmountGrd": "Debit",
                "CreditAmountGrd": "Credit",
                "Remarks": "Remarks",
                "Segment": "Segments",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "cjournalvoucher": {
            "management": {
                "Title": "Control Account JV",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Post",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ReverseButton": "Reverse",
                "RePostButton": "RePost",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Amount": "Amount",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": " Control Account JV",
                "TabTitle1": "JV",
                "TabTitle2": "JV Details",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "PostingDate": "Posting Date:*",
                "Currency": "Currency:*",
                "JournalType": "Journal Type:*",
                "BillOfExchange": "Bill Of Exchange:*",
                "ExchangeRate": "Exchange Rate:",
                "ExchangeDate": "Exchange Date:*",
                "DebitAmount": "Debit Amount:*",
                "Narration": "Narration:",

                "AccountType": "Account Type",
                "Code": "Code",
                "Name": "Name",
                "DebitAmountGrd": "Debit",
                "CreditAmountGrd": "Credit",
                "Remarks": "Remarks",
                "Segment": "Segments",
                "ControlAccount": "Control Account",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },


        "recurringjournalvoucher": {
            "management": {
                "Title": "Recurring JV",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "ReverseButton": "Reverse",
                "RePostButton": "RePost",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "FromDate": "From Date",
                    "ToDate": "To Date",
                    "StartDate": "Start Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Recurring JV",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "SubTitle",

                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "FromDate": "From Date:*",
                "ToDate": "To Date:*",

                "StartDate": "Start Date:*",
                "Interval": "Interval:*",
                "LastRunDate": "Last Run Date:*",

                "NextRunDate": "Next Run Date:*",
                "PostingDate": "Posting Date:*",
                "Currency": "Currency:*",
                "ExchangeRate": "Exchange Rate:",
                "ExchangeDate": "Exchange Date:*",
                "DebitAmount": "Debit Amount:*",
                "Narration": "Narration:",

                "Code": "Code",
                "Name": "Name:",
                "DebitAmountGrd": "Debit",
                "CreditAmountGrd": "Credit",
                "Remarks": "Remarks",
                "Segment": "Segments",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },
        "sob": {
            "management": {
                "Title": "Stock Opening Balance",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Stock Opening Balance",
                "SubTitle": "SubTitle",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "SOBNo": "Document No.:*",
                "SOBDate": "Document Date:*",
                "ToWareHouse": "To WareHouse:*",
                "Remarks": "Remarks:",
                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "ItemPartNo": "Item Part No",
                "UOMCode": "UOM Code",
                "UOMName": "UOM Name",
                "LocationHead": "Location",
                "Qty": "Quantity",
                "Unitcost": "Unit Cost",
                "Amount": "Amount",
                "Location": "Location",
                "BinType": "Bin Type",
                "Bins": "Bins",
                "SaveButton": "Save",
                "PrintButton": "Print",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "openingbalancecustody": {
            "management": {
                "Title": "Opening Balance Custody",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Opening Balance Custody",
                "SubTitle": "SubTitle",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No:*",
                "DocumentDate": "Document Date:*",
                "Remarks": "Remarks:",
                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "Qty": "Quantity",
                "Status": "Status",
                "Size": "Size",
                "Unit": "Unit",
                "TruckType": "Truck Type",
                "TuckNo": "Tuck No.",
                "DriverName": "Driver Name",
                "SaveButton": "Save",
                "PrintButton": "Print",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "receiptfromtruck": {
            "management": {
                "Title": "Receipt From Truck",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Receipt From Truck",
                "SubTitle": "SubTitle",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No:*",
                "DocumentDate": "Document Date:*",
                "Remarks": "Remarks:",
                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "Status": "Status",
                "Size": "Size",
                "UnitNo": "Unit No:",
                "TruckType": "Truck Type:",
                "TrailerNo": "Trailer No.:",
                "DriverId": "Driver Name:",
                "ReceiptQuantity": "Receipt Quantity",
                "SaveButton": "Save",
                "PrintButton": "Print",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "issuetotruck": {
            "management": {
                "Title": "Issue To Truck",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Issue To Truck",
                "SubTitle": "SubTitle",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No:*",
                "DocumentDate": "Document Date:*",
                "Remarks": "Remarks:",
                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "Status": "Status",
                "Size": "Size",
                "UnitNo": "Unit No:",
                "TruckType": "Truck Type:",
                "DriverId": "Driver Name:",
                "TrailerNo": "Trailer No:",
                "BalanceQty": "Balance Quantity",
                "IssueQty": "Issue Quantity",
                "SaveButton": "Save",
                "PrintButton": "Print",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "stockreconciliation": {
            "management": {
                "title": "Stock Reconciliation",
                "subtitle": "SubTitle",
                "newbutton": "New",
                "editbutton": "Edit",
                "deletebutton": "Delete",
                "approvebutton": "Approve",
                "rejectbutton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "viewbutton": "View",
                "exportbutton": "Export",
                "gridheaders": {
                    "organisation": "Organization",
                    "docno": "Document No",
                    "docdate": "Document Date",
                    "remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "title": "Stock Reconciliation",
                "tabtitle1": "General",
                "tabtitle2": "Details",
                "subtitle": "SubTitle",

                "orgcode": "Org/SubOrg Code:*",
                "orgname": "Org/SubOrg Name:",
                "recno": "Document No:*",
                "recdate": "Document Date:*",
                "remarks": "Remarks:",
                "WareHouse": "WareHouse:*",

                "itemcode": "Item Code",
                "itemname": "Item Name",
                "uomcode": "UOM Code",
                "uomname": "UOM Name",
                "locationhead": "Location",
                "systemqty": "System Quantity",
                "phyqty": "Physical Quantity",
                "recqty": "Reconcile Quantity",
                "unitcost": "Unit Cost",
                "recvalue": "Reconcile Value",
                "remarkhead": "Remarks",

                "PrintButton": "Print",
                "savebutton": "Save",
                "cancelbutton": "Back to the List",
                "lastupdatedlabel": "Last Updated",
                "lastupdatedby": "Updated By"
            }
        },


        "purchasereturn": {
            "management": {
                "Title": "Purchase Return",
                "SubTitle": "Purchase Return",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "VendorName": "Vendor Name",
                    "RefType": "Reference Type",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Purchase Return",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "TabTitle3": "Segment Details",
                "SubTitle": "SubTitle",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "PaymentType": "Payment Type:*",
                "RefType": "Reference Type:*",
                "VendorCode": "Vendor Code:*",
                "VendorName": "Vendor Name:",
                "Currency": "Currency:*",
                "FromWareHouse": "From WareHouse:*",
                "Narration": "Narration:",
                "grnId": "Reference No.",
                "grnDate": "Reference Date",
                "OffLoadingCharges": "Off Loading Charges:",

                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "ItemPartNo": "Item Part No",
                "UOMCode": "UOM",
                "UOMName": "UOM Name",
                "RefQty": "Reference Quantity",
                "LocationHead": "Location",
                "PhyQty": "Physical Quantity",
                "quantity": "Return Quantity",
                "price": "Price",
                "amount": "Amount",
                "refQty": "Reference Quantity",

                "sno": "SNo",
                "Code": "Segment Code",
                "Name": "Segment Name",
                "debitamount": "Debit Amount",
                "creditamount": "Credit Amount",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "istissue": {
            "management": {
                "Title": "Inter-Stock Transfer Issue",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ReverseButton": "Reverse",
                "RePostButton": "RePost",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Remarks": "Remarks",
                    "ToStation": "To Station",
                    "TruckNo": "Truck No",
                    "ItemCode": "Item Code",
                    "Quantity": "Quantity",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Inter-Stock Transfer Issue",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "SubTitle",
                "FromStationCode": "From Station Code:*",
                "FromStationName": "From Station Name:",
                "ToStationCode": "To Station Code:*",
                "ToStationName": "To Station Name:",
                "ISTNo": "Document No.:*",
                "ISTDate": "Document Date:*",
                "DeliveryMode": "Delivery Mode:*",
                "TransporterCode": "Transporter Code:*",
                "TransporterName": "Transporter Name:*",
                "PaidByStations": "Paid By Stations:*",
                "PaymentMode": "Payment Mode:*",
                "RefType": "Reference Type:*",
                "IndentNo": "Indent No.:*",
                "IndentDate": "Indent Date:",
                "Remarks": "Remarks:",
                "LoadingCharges": "Loading Charges",
                "TransportationCharges": "Transport Charges",
                "TruckNo": "Truck No:",
                "DriverName": "Driver Name:",
                "PhoneNo": "Phone No:",
                "OtherReferenceNo": "Reference No:",
                "FromWareHouse": "From WareHouse:*",
                "ToWareHouse": "To WareHouse:*",

                "LoadingCharges1": "Loading Charges",
                "TransportationCharges1": "Transport Charges",

                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "ItemPartNo": "Item Part No",
                "UOMCode": "UOM",
                "UOMName": "UOM Name",
                "LocationHead": "Location",
                "IssueQty": "Issue Quantity",
                "ReqQty": "Req. Quantity",
                "ReqDate": "Req. Date",
                "PhyQty": "Physical Quantity",
                "BalQty": "Balance Quantity",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "istreceipt": {
            "management": {
                "Title": "Inter-Stock Transfer Receipt",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ReverseButton": "Reverse",
                "RePostButton": "RePost",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "ReferenceNo": "Reference No",
                    "FromStation": "From Station",
                    "TruckNo": "Truck No",
                    "ItemCode": "Item Code",
                    "Quantity": "Quantity",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Inter-Stock Transfer Receipt",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "SubTitle",
                "ToStationCode": "To Station Code:*",
                "ToStationName": "To Station Name:",
                "FromStationCode": "From Station Code:*",
                "FromStationName": "From Station Name:",
                "ISTNo": "Document No.:*",
                "ISTDate": "Document Date:*",
                "DeliveryMode": "Delivery Mode:*",
                "RefType": "Reference Type:*",
                "IssueNo": "Issue Number:*",
                "IssueDate": "Issue Date:",
                "FromWareHouse": "WareHouse:*",
                "Remarks": "Remarks:",
                "OffLoadingCharges": "Off Loading Charges",
                "OffLoadingCharges1": "Off Loading Charges",
                "TransportationCharges": "Transportation Charges",
                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "ItemPartNo": "Item Part No",
                "UOMCode": "UOM",
                "UOMName": "UOM Name",
                "LocationHead": "Location",
                "IssueQty": "Issue Quantity",
                "BalQty": "Balance Quantity",
                "Qty": "Quantity",
                "Price": "Price",
                "Amount": "Amount",
                "PrintButton": "Print",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "subcontractingorder": {
            "management": {
                "title": "ReConditioning Order",
                "newbutton": "New",
                "editbutton": "Edit",
                "deletebutton": "Delete",
                "approvebutton": "Approve",
                "rejectbutton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "viewbutton": "View",
                "exportbutton": "Export",
                "gridheaders": {
                    "organisation": "Organization",
                    "docno": "Document No",
                    "docdate": "Document Date",
                    "VendorName": "Vendor Name",
                    "status": "Status"
                }
            },
            "create": {
                "title": "ReConditioning Order",
                "tabtitle1": "General",
                "tabtitle2": "Line Items",
                "tabtitle3": "Receipt Items",
                "tabtitle4": "Other Detail",
                "subtitle": "SubTitle",
                "PrintButton": "Print",
                "orgcode": "Org/SubOrg Code:*",
                "orgname": "Org/SubOrg Name:",
                "plantcode": "Plant Code:*",
                "plantname": "Plant Name:",
                "orderno": "Document No.:*",
                "orderdate": "Document Date:*",
                "reftype": "With Reference To:*",
                "pic": "Prod Indent Code:*",
                "vendorcode": "Vendor Code:*",
                "vendorname": "Vendor Name:",
                "deliverydate": "Delivery Date:*",
                "contactperson": "Contact Person:*",
                "remarks": "Remarks:",
                "pricebasis": "Price Basis:",
                "specifications": "Specifications:",
                "dnt": "Document Negotiated Through:",
                "tbdt": "To be Dispatched Through:",
                "tngc": "Test & Gurantee Certificate:",
                "inspnote": "Inspection Note:",
                "octroi": "Octroi:",
                "freight": "Freight:",
                "loadcharges": "Loading Charges:",

                "itemcode": "Item Code",
                "itemname": "Item Name",
                "uomcode": "UOM Code",
                "uomname": "UOM Name",
                "quantity": "Quantity",
                "muomname": "MUOM Name",
                "muomqty": "MUOM Quantity",
                "conchrgs": "Conversion Charges",
                "amount": "Amount",
                "melting": "Melting Loss",

                "savebutton": "Save",
                "cancelbutton": "Back to the List",
                "lastupdatedlabel": "Last Updated",
                "lastupdatedby": "Updated By"
            }
        },

        "subcontractingissue": {
            "management": {
                "title": "ReConditioning Issue",
                "newbutton": "New",
                "editbutton": "Edit",
                "deletebutton": "Delete",
                "approvebutton": "Approve",
                "rejectbutton": "Reject",
                "ActiveButton": "Active",
                "PrintButton": "Print",
                "InActiveButton": "InActive",

                "viewbutton": "View",
                "exportbutton": "Export",
                "gridheaders": {
                    "organisation": "Organization",
                    "docno": "Document No",
                    "docdate": "Document Date",
                    "VendorName": "Vendor Name",
                    "status": "Status"
                }
            },
            "create": {
                "title": "ReConditioning Issue",
                "tabtitle1": "General",
                "tabtitle2": "Line Items",
                "tabtitle3": "Receipt Items",
                "subtitle": "SubTitle",

                "orgcode": "Org/SubOrg Code:*",
                "orgname": "Org/SubOrg Name:",
                "issueno": "Document No:*",
                "issuedate": "Document Date:*",
                "vendorcode": "Vendor Code:*",
                "vendorname": "Vendor Name:",
                "plantcode": "Plant Code:*",
                "plantname": "Plant Name:",
                "reftype": "With Reference To:*",
                "refno": "Reference No:*",
                "refdate": "Reference Date:",
                "FromWareHouse": "From WareHouse:*",
                "remarks": "Remarks:",
                "gpno": "GP No.:",
                "gpdate": "GP Date:",
                "vehicleno": "Vehicle No:",
                "PrintButton": "Print",
                "itemcode": "Item Code",
                "itemname": "Item Name",
                "uomcode": "UOM Code",
                "uomname": "UOM Name",
                "qtbi": "Qty to be Issued",
                "qai": "Qty Already Issued",
                "locationgrid": "Location",
                "qbi": "Qty Being Issued",
                "balqty": "Balance Quantity",
                "tolerance": "Tolerance",

                "savebutton": "Save",
                "cancelbutton": "Back to the List",
                "lastupdatedlabel": "Last Updated",
                "lastupdatedby": "Updated By"
            }
        },

        "subcontractingreceipt": {
            "management": {
                "title": "ReConditioning Receipt",
                "newbutton": "New",
                "editbutton": "Edit",
                "deletebutton": "Delete",
                "approvebutton": "Approve",
                "rejectbutton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "viewbutton": "View",
                "exportbutton": "Export",
                "gridheaders": {
                    "organisation": "Organization",
                    "docno": "Document No",
                    "docdate": "Document Date",
                    "VendorName": "Vendor Name",
                    "status": "Status"
                }
            },
            "create": {
                "title": "ReConditioning Receipt",
                "tabtitle1": "General",
                "tabtitle2": "Details",
                "subtitle": "SubTitle",

                "orgcode": "Org/SubOrg Code:*",
                "orgname": "Org/SubOrg Name:",
                "divcode": "Division Code:*",
                "divname": "Division Name:",
                "number": "Document No.:*",
                "date": "Document Date:*",
                "vendorcode": "Vendor Code:*",
                "vendorname": "Vendor Name:",
                "reftype": "With Reference To:*",
                "refno": "Reference No:*",
                "refdate": "Reference Date:",
                "vendordnno": "Vendor DN Number:*",
                "vendordndate": "Vendor DN Date:*",
                "currencycode": "Currency Code:*",
                "currencyname": "Currency Name:",
                "deliverymode": "Delivery Mode:*",
                "deliveryplace": "Delivery Place:*",
                "ToWareHouse": "To WareHouse:*",
                "remarks": "Remarks:",

                "itemcode": "Item Code",
                "itemname": "Item Name",
                "uomname": "UOM Name",
                "muomname": "MUOM Name",
                "muomqty": "MUOM Quantity",
                "expqty": "Expected Quantity",
                "conchrgs": "Conversion Charges",
                "invrecqty": "INV Received Quantity",
                "locationgrid": "Location",
                "accqty": "Accepted Quantity",
                "rejqty": "Rejected Quantity",
                "balqty": "Balance Quantity",
                "actualweight": "Actual Weight",
                "expweight": "Expected Weight",
                "melting": "Melting Loss",
                "amount": "Amount",
                "psi": "Purchase Service Item",
                "tolerance": "Tolerance",
                "PrintButton": "Print",
                "savebutton": "Save",
                "cancelbutton": "Back to the List",
                "lastupdatedlabel": "Last Updated",
                "lastupdatedby": "Updated By"
            }
        },

        "purchaseserviceinvoice": {
            "management": {
                "title": "Purchase Service Invoice",
                "newbutton": "New",
                "editbutton": "Edit",
                "deletebutton": "Delete",
                "approvebutton": "Post",
                "rejectbutton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "viewbutton": "View",
                "exportbutton": "Export",
                "ReverseButton": "Reverse",
                "RePostButton": "RePost",
                "gridheaders": {
                    "organisation": "Organization",
                    "docno": "Document No",
                    "docdate": "Document Date",
                    "billNo": "Vendor Invoice No",
                    "VendorName": "Vendor Name",
                    "amount": "Amount",
                    "Status": "Status"
                }
            },
            "create": {
                "title": "Purchase Service Invoice",
                "tabtitle1": "General",
                "tabtitle2": "Details",
                "TabTitle3": "Segment Details",
                "subtitle": "SubTitle",
                "PrintButton": "Print",
                "orgcode": "Org/SubOrg Code:*",
                "orgname": "Org/SubOrg Name:",
                "number": "Document No.:*",
                "date": "Document Date:*",
                "duedate": "Due Date:*",
                "reftype": "With Reference To:*",
                "vendorcode": "Vendor Code:*",
                "vendorname": "Vendor Name:",
                "vendortype": "Vendor Type:*",
                "vendorgroup": "Vendor Group:*",
                "billnumber": "Vendor Invoice No:*",
                "billdate": "Vendor Invoice Date:*",
                "billingaddress": "Billing Address:*",
                "currency": "Currency:*",
                "excgrate": "Exchange Rate:*",
                "remarks": "Remarks:",

                "refno": "Reference No.",
                "refdate": "Reference Date",
                "refstatus": "Status",

                "itemcode": "Item Code",
                "itemname": "Item Name",
                "uomcode": "UOM Code",
                "uomname": "UOM Name",
                "quantity": "Quantity",
                "price": "Service Price",
                "amount": "Amount",
                "remark": "Remarks",

                "sno": "SNo",
                "Code": "Segment Code",
                "Name": "Segment Name",
                "debitamount": "Debit Amount",
                "creditamount": "Credit Amount",

                "savebutton": "Save",
                "cancelbutton": "Back to the List",
                "lastupdatedlabel": "Last Updated",
                "lastupdatedby": "Updated By"
            }
        },

        "locationtransfer": {
            "management": {
                "Title": "Location Transfer",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Location Transfer",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "SubTitle",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "TransferNo": "Document No.:*",
                "TransferDate": "Document Date.:*",
                "RefType": "Reference Type:*",
                "IndentNo": "Document No:*",
                "IndentDate": "Document Date:*",
                "LoadingCharges": "Loading Charges:",
                "OffLoadingCharges": "Off Loading Charges:",
                "TransportationCharges": "Transportation Charges:",
                "Remarks": "Remarks:",

                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "UOMCode": "UOM",
                "UOMName": "UOM Name",
                "FromLocation": "From Location",
                "FromBinType": "From Bin Type",
                "FromBin": "From Bin",
                "PhyQty": "Physical Quantity",
                "FromLoc": "From Location",
                "FromQty": "Quantity.",
                "ToLocation": "To Location",
                "ToBinType": "To Bin Type",
                "ToBin": "To Bin",
                "transQty": "Transfer Quantity",
                "ToLoc": "To Location",
                "ToQty": "Quantity",
                "PrintButton": "Print",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "locationtransferissue": {
            "management": {
                "Title": "Location Transfer Issue",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ReverseButton": "Reverse",
                "RePostButton": "RePost",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "ToWareHouse": "To WareHouse",
                    "ReferenceNo": "Reference No",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Location Transfer Issue",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "SubTitle",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "TransferNo": "Document No.:*",
                "TransferDate": "Document Date.:*",
                "FromWareHouse": "From WareHouse:*",
                "ToWareHouse": "To WareHouse:*",
                "Transporter": "Transporter:*",
                "RefType": "Reference Type:*",
                "IndentNo": "Indent No:*",
                "IndentDate": "Document Date:*",
                "LoadingCharges": "Loading Charges:",
                "OffLoadingCharges": "Off Loading Charges:",
                "TransportationCharges": "Transportation Charges:",
                "TruckNo": "Truck No:",
                "DriverName": "Driver Name:",
                "PhoneNo": "Phone No:",
                "Remarks": "Remarks:",

                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "UOMCode": "UOM",
                "UOMName": "UOM Name",
                "FromLocation": "From Location",
                "FromBinType": "From Bin Type",
                "FromBin": "From Bin",
                "PhyQty": "Physical Quantity",
                "FromLoc": "From Location",
                "FromQty": "Quantity.",
                "ToLocation": "To Location",
                "ToBinType": "To Bin Type",
                "ToBin": "To Bin",
                "transQty": "Transfer Quantity",
                "ToLoc": "To Location",
                "ToQty": "Quantity",
                "LoadingChargesGrid": "Loading Charges",
                "TransportationChargesGrid": "Transportation Charges",
                "PrintButton": "Print",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "locationtransferindent": {
            "management": {
                "Title": "Location Transfer Indent",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "ToWareHouse": "To WareHouse",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Location Transfer Indent",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "SubTitle",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "TransferNo": "Document No.:*",
                "TransferDate": "Document Date.:*",
                "FromWareHouse": "From WareHouse:*",
                "ToWareHouse": "To WareHouse:*",
                "Transporter": "Transporter:*",
                "RefType": "Reference Type:*",
                "IndentNo": "Document No:*",
                "IndentDate": "Document Date:*",
                "LoadingCharges": "Loading Charges:",
                "OffLoadingCharges": "Off Loading Charges:",
                "TransportationCharges": "Transportation Charges:",
                "TruckNo": "Truck No:",
                "DriverName": "Driver Name:",
                "PhoneNo": "Phone No:",
                "Remarks": "Remarks:",

                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "UOMCode": "UOM",
                "UOMName": "UOM Name",
                "OriginName": "Origin Name",
                "FromLocation": "From Location",
                "FromBinType": "From Bin Type",
                "FromBin": "From Bin",
                "PhyQty": "Physical Quantity",
                "FromLoc": "From Location",
                "FromQty": "Quantity.",
                "ToLocation": "To Location",
                "ToBinType": "To Bin Type",
                "ToBin": "To Bin",
                "transQty": "Transfer Quantity",
                "ToLoc": "To Location",
                "ToQty": "Quantity",
                "PrintButton": "Print",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "locationtransferreceipt": {
            "management": {
                "Title": "Location Transfer Receipt",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ReverseButton": "Reverse",
                "RePostButton": "RePost",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "FromWareHouse": "From WareHouse",
                    "Remarks": "Remarks",
                    "IssueNo": "Issue No",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Location Transfer Receipt",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "SubTitle",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "TransferNo": "Document No.:*",
                "TransferDate": "Document Date.:*",
                "FromWareHouse": "From WareHouse:*",
                "ToWareHouse": "To WareHouse:*",
                "RefType": "Reference Type:*",
                "IndentNo": "Reference No:*",
                "IndentDate": "Reference Date:*",
                "LoadingCharges": "Loading Charges:",
                "OffLoadingCharges": "Off Loading Charges:",
                "TransportationCharges": "Transportation Charges:",
                "Remarks": "Remarks:",

                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "UOMCode": "UOM",
                "UOMName": "UOM Name",
                "FromLocation": "From Location",
                "FromBinType": "From Bin Type",
                "FromBin": "From Bin",
                "PhyQty": "Physical Quantity",
                "FromLoc": "From Location",
                "FromQty": "Quantity.",
                "RefQuantity": "Ref Quantity",
                "RefBalQuantity": "Ref Bal Quantity",
                "ToLocation": "To Location",
                "ToBinType": "To Bin Type",
                "ToBin": "To Bin",
                "transQty": "Transfer Quantity",
                "ToLoc": "To Location",
                "ToQty": "Quantity",
                "GridTransportationCharges": "Transportation Charges",
                "PrintButton": "Print",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "segment": {
            "management": {
                "Title": "Segment",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organisation",
                    "Code": "Code",
                    "Name": "Name:",
                    "Description": "Description",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Segment",
                "SubTitle": "SubTitle",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Structure": "Structure:*",
                "Definition": "Definition:*",
                "Group": "Group:*",
                "SegmentClass": "Segment Class:",
                "SegmentCode": "Segment Code",
                "SegmentName": "Segment Name",
                "Description": "Description",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"

            }
        },

        "importexpensemapping": {
            "management": {
                "Title": "Import Expense Mapping",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organisation",
                    "ItemCode": "Item Code",
                    "ItemName": "Item Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Import Expense Mapping",
                "SubTitle": "SubTitle",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "ItemCode": "Item Code:*",
                "ItemName": "Item Name:*",
                "ServiceItemCode": "Service Item Code",
                "ServiceItemName": "Service Item Name",
                "Type": "Type",
                "Value": "Value",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"

            }
        },

        "purchaserequisition": {
            "management": {
                "Title": "Purchase Requisition",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organisation",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Ref": "Reference",
                    "ExpectedDate": "Expected Date",
                    "ValidityDate": "Validity Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Purchase Requisition",
                "SubTitle": "SubTitle",
                "Tab1": "General",
                "Tab2": "Details",
                "Tab3": "Details",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DivisionCode": "Division Code:*",
                "DivisionName": "Division Name:",
                "RequisitionNo": "Document No.:*",
                "RequisitionDate": "Document Date:*",
                "PurchaseCategory": "Purchase Category:*",
                "RequisitionCategory": "Requisition Category:*",
                "WithRefTo": "With Ref To:*",
                "DepartmentCode": "Department Code:*",
                "DepartmentName": "Department Name:",
                "VendorCode": "Vendor Code:*",
                "VendorName": "Vendor Name:",
                "RequisitionBy": "Requisition By:*",
                "Currency": "Currency:*",
                "ExpectedDate": "Expected Date:*",
                "ValidityDate": "Validity Date:*",
                "Remarks": "Remarks:",
                "PrintButton": "Print",
                "ReferenceNo": "Reference No.",
                "ReferenceDate": "Reference Date",
                "IndentType": "Indent Type",
                "Status": "Status",

                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "UOMCode": "UOM Code",
                "VendorCodeGrid": "Vendor Code",
                "RefQty": "Reference Quantity",
                "RefExeQty": "Ref.Exe.Quantity",
                "RefBalQty": "Ref.Bal.Quantity",
                "Quantity": "Quantity",
                "EstimatedPrice": "Estimated Price",
                "CurrencyGrid": "Currency",
                "RemarksGrid": "Remarks",
                "ItemStatus": "Item Status",
                "ExecutedQuantity": "Executed Quantity",
                "BalanceQuantity": "Balance Quantity",
                "AvailableQuantity": "Available Quantity",
                "RequisitionQuantity": "Requisition Quantity",
                "ExpectedDateGrid": "Expected Date",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"

            }
        },

        "purchaseorder": {
            "management": {
                "Title": "Purchase Order",
                "SubTitle": "SubTitle",

                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organisation",
                    "OrderNo": "Order No",
                    "OrderDate": "Order Date",
                    "Division": "Division",
                    "VendorName": "Vendor Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Purchase Order",
                "SubTitle": "SubTitle",
                "Tab1": "General",
                "Tab2": "Details",
                "Tab3": "LC Details",
                "Tab4": "Other Details",
                "Tab5": "Payment Terms",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DivisionCode": "Division Code:*",
                "DivisionName": "Division Name:",
                "OrderNo": "Order No.:*",
                "OrderDate": "Order Date:*",
                "PurchaseCategory": "Purchase Category:*",
                "PurchaseType": "Purchase Type:*",
                "OrderCategory": "Order Category:*",
                "WithRefTo": "With Ref To:*",
                "VendorTypeCode": "Vendor Type Code:*",
                "VendorTypeName": "Vendor Type Name:",
                "VendorCode": "Vendor Code:*",
                "VendorName": "Vendor Name:",
                "IMFormNo": "IM Form No.:",
                "IMFormIssueDate": "IM Form Issue Date:",
                "Status": "Status:",
                "UpldScanCopy": "Upload Scan Copy:*",
                "UpldScanCopyPath": "Uploaded Scanned Copy Path:*",
                "Remarks": "Remarks",
                "SplitTag": "Split Tag:*",
                "Currency": "Currency:*",
                "ExchangeRate": "Exchange Rate:*",
                "VessalName": "Vessal Name:*",

                "RequisitionNo": "Reference No.",
                "RequisitionDate": "Reference Date",
                "StatusGrid": "Status",

                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "ItemPartNo": "Item Part No",
                "UOMCode": "UOM",
                "RefQty": "Ref. Quantity",
                "RefExeQty": "Ref.Exe. Quantity",
                "RefBalQty": "Ref.Bal. Quantity",
                "Quantity": "Quantity",
                "FreeQty": "Free Quantity",
                "Price": "Price",
                "MRP": "MRP",
                "Discount": "Discount %",
                "AmountGrid": "Amount",
                "Specification": "Specification",
                "ExeQty": "Executed Qty",
                "BalQty": "Balance Qty",
                "ReqQty": "Requisition Qty",

                "LCNo": "LC No.:",
                "LCDate": "LC Date:",
                "BankName": "Bank Name:",
                "BankAccNo": "Bank A/c No:",
                "ExpiryDate": "Expiry Date:",
                "Amount": "Amount:",
                "RepaymentType": "Repayment Type:",
                "ROI": "Rate of Interest:",
                "RepaymentTenure": "Repayment Tenure:",
                "EMI": "EMI:",
                "UpldScanCopyofLC": "Upload Scan Copy of LC",
                "UpldScanCopyPathofLC": "Upload Scan Copy Path of LC",
                "Terms": "TnC:*",

                "ComponentCode": "Component Code",
                "ComponentName": "Component Name",
                "ComponentType": "Component Type",
                "VendorCodeGrid": "Vendor Code",
                "RemarksGrid": "Remarks",
                "PaymentTerm": "Payment Terms:",

                "DeliveryFrom": "Delivery From:",
                "DeliveryMode": "Delivery Mode:",
                "DeliveryDate": "Delivery Date:",
                "shippingAddress": "Shipping Address:",
                "BillingAddress": "Billing Address:",
                "PaymentTerms": "Payment Terms:",
                "CreditDays": "Credit Days:",
                "TermsTemplate": "Terms Template:",
                "IcnoTerms": "Icno Terms:",

                "SINo": "SI No",
                "Payment": "% Payment",


                "Date": "Date",
                "Percentage": "Percentage(%)",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "purchaseinvoice": {
            "management": {
                "Title": "Purchase Invoice",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "VendorName": "Vendor Name",
                    "VendorInvoiceNo": "Vendor Invoice No",
                    "Amount": "Amount",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Purchase Invoice",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "TabTitle3": "Payment Due Details",
                "TabTitle4": "Segment Details",
                "TabTitle5": "Account/Supplier Details",
                "SubTitle": "SubTitle",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DivisionCode": "Division Code:*",
                "DivisionName": "Division Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "ToWareHouse": "To WareHouse:*",
                "PurchaseCategory": "Purchase Category:*",
                "PurchaseType": "Purchase Type:*",
                "RefType": "Reference Type:*",
                "VendorTypeCode": "Vendor Type Code:*",
                "VendorTypeName": "Vendor Type Name:",
                "VendorCode": "Vendor Code:*",
                "VendorName": "Vendor Name:",
                "VendorInvoiceNo": "Vendor Invoice No:*",
                "VendorInvoiceDate": "Vendor Invoice Date:*",
                "FormType": "Form Type:*",
                "CreditDays": "Credit Days:*",
                "Currency": "Currency:*",
                "PaymentTerm": "Payment Term:*",
                "DueDate": "Due Date:*",
                "Narration": "Narration:*",
                "ExchangeRate": "Exchange Rate:*",


                "Amount": "Amount:*",
                "ComponentType": "Component Type:*",
                "ExchRate": "Exc. Rate:*",

                "ReferenceNo": "Reference No.",
                "ReferenceDate": "Reference Date",
                "StatusGrid": "Status",

                "SNO": "Serial No.",
                "Payment": "Payment %",

                "sno": "SNo",
                "Code": "Segment Code",
                "Name": "Segment Name",
                "debitamount": "Debit Amount",
                "creditamount": "Credit Amount",

                "AccountType": "Account Type",
                "AccountCode": "Account Code",
                "AccountName": "Account Name",

                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "ItemPartNo": "Item Part No",
                "UOMCode": "UOM",
                "RefQty": "Reference Quantity",
                "RefPrice": "Reference Price",
                "Quantity": "Quantity",
                "BalQty": "Balance Quantity",
                "FreeQty": "Free Quantity",
                "Price": "Price",
                "AmountGrid": "Amount",
                "Location": "Location",
                "PrintButton": "Print",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "proformainvoice": {
            "management": {
                "Title": "Proforma Invoice",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "RefType": "Reference",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Proforma Invoice",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "TabTitle3": "Details",
                "SubTitle": "SubTitle",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DivisionCode": "Division Code:*",
                "DivisionName": "Division Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "PurchaseCategory": "Purchase Category:*",
                "PurchaseType": "Purchase Type:*",
                "RefType": "Reference Type:*",
                "VendorTypeCode": "Vendor Type Code:*",
                "VendorTypeName": "Vendor Type Name:",
                "VendorCode": "Vendor Code:*",
                "VendorName": "Vendor Name:",
                "VendorInvoiceNo": "Vendor Invoice No:*",
                "VendorInvoiceDate": "Vendor Invoice Date:*",
                "FormType": "Form Type:*",
                "CreditDays": "Credit Days:*",
                "Currency": "Currency:*",
                "PaymentTerm": "Payment Term:*",
                "DueDate": "Due Date:*",
                "Narration": "Narration:*",

                "Amount": "Amount:*",
                "ComponentType": "Component Type:*",
                "ExchRate": "Exc. Rate:*",

                "ReferenceNo": "Reference No.",
                "ReferenceDate": "Reference Date",
                "StatusGrid": "Status",

                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "ItemPartNo": "Item Part No",
                "UOMCode": "UOM Code",
                "RefQty": "Reference Quantity",
                "RefPrice": "Reference Price",
                "Quantity": "Quantity",
                "BalQty": "Balance Quantity",
                "FreeQty": "Free Quantity",
                "Price": "Price",
                "AmountGrid": "Amount",
                "PrintButton": "Print",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "department": {
            "management": {
                "Title": "Department",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name:",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Department",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },
        "models": {
            "management": {
                "Title": "Models",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name:",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Models",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },
        "iso": {
            "management": {
                "Title": "ISO",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name:",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "ISO",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },


        "itemgroup": {
            "management": {
                "Title": "Item Group",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name:",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Item Group",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "ItemState": "Item State:*",
                "ParentItemGroup": "Name:",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "invoicetype": {
            "management": {
                "Title": "Invoice Type",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name:",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Invoice Type",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },


        "expressiondefinition": {
            "management": {
                "Title": "Expression Definition",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "Code": "Code",
                    "Name": "Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Expression Definition",
                "SubTitle": "SubTitle",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Date": "Date:*",
                "Remarks": "Remarks:",
                "IncomeandDeductionCode": "Head Code",
                "IncomeandDeductionName": "Head Name",
                "Percentage": "Percentage",
                "MinimumValue": "Minimum Value",
                "MaximumValue": "Maximum Value",
                "CompanyShare": "Company Share",
                "ExemptionLimit": "Exemption Limit",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "report": {
                "Title": "Medical Insurance",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Date": "Date:*",
                "Remarks": "Remarks:",
                "IncomeandDeductionCode": "Head Code",
                "IncomeandDeductionName": "Head Name",
                "Percentage": "Percentage",
                "MinimumValue": "Minimum Value",
                "MaximumValue": "Maximum Value",
                "CompanyShare": "Company Share",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },


        "servicetermination": {
            "management": {
                "Title": "Separation ",
                "SubTitle": "Address",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "EmployeeCode": "Employee Code",
                    "EmployeeName": "Employee Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Separation",
                "SubTitle": "Address",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "Employee": "Employee:*",
                "SeparationType": "Separation Type:*",
                "LastWorkingDate": "Last Working Date:*",
                "SettlementDate": "Settlement Date:*",
                "SettlementAmount": "Settlement Amount:*",
                "SettlementofDues": "Settlement of Dues:*",
                "Reason": "Reason:",
                "Description": "Description:",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "SaveButton": "Save",
                "CancelButton": "Back",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Last Updated By"
            }
        },


        "MedicalInsurance": {
            "management": {
                "Title": "Medical Insurance",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No ",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Medical Insurance",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.:*",
                "Documentdate": "Document Date:*",
                "FromDate": "From Date:",
                "ToDate": "To Date:",
                "EmployeeCode": "Employee Code",
                "EmployeeName": "Employee Name",
                "Amount": "Amount",
                "CompanyShare": "Company Share",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "report": {
                "Title": "Medical Insurance",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No:*",
                "Documentdate": "Document Date:*",
                "FromDate": "From Date:",
                "ToDate": "To Date:",
                "EmployeeCode": "Employee Code",
                "EmployeeName": "Employee Name",
                "Amount": "Amount",
                "CompanyShare": "Company Share",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },
        "consolidatedleave": {
            "management": {
                "Title": "Consolidated Leave",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No ",
                    "DocumentDate": "Document Date",
                    "Narration": "Narration",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Consolidated Leave",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.:*",
                "Documentdate": "Document Date:*",
                "FromDate": "From Date:",
                "ToDate": "To Date:",
                "EmployeeCode": "Employee Code",
                "EmployeeName": "Employee Name",
                "LeaveTypeName": "Leave Type",
                "NoofDays": "NoofDays",
                "Narration": "Narration",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "report": {
                "Title": "Medical Insurance",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No:*",
                "Documentdate": "Document Date:*",
                "FromDate": "From Date:",
                "ToDate": "To Date:",
                "EmployeeCode": "Employee Code",
                "EmployeeName": "Employee Name",
                "Amount": "Amount",
                "CompanyShare": "Company Share",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "leaveopeningbalance": {
            "management": {
                "Title": "Leave Opening Balance",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No ",
                    "DocumentDate": "Document Date",
                    "Narration": "Narration",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Leave Opening Balance",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.:*",
                "Documentdate": "Document Date:*",
                "FromDate": "From Date:",
                "ToDate": "To Date:",
                "EmployeeCode": "Employee Code",
                "EmployeeName": "Employee Name",
                "LeaveTypeName": "Leave Type",
                "NoofDays": "No of Days",
                "Narration": "Narration",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "report": {
                "Title": "Medical Insurance",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.:*",
                "Documentdate": "Document Date:*",
                "EmployeeCode": "Employee Code",
                "EmployeeName": "Employee Name",
                "Amount": "Amount",
                "CompanyShare": "Company Share",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },


        "medicalinsurance": {
            "management": {
                "Title": "Medical Insurance",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No ",
                    "DocumentDate": "Document Date",
                    "Narration": "Narration",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Medical Insurance",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.:*",
                "Documentdate": "Document Date:*",
                "FromDate": "From Date:",
                "ToDate": "To Date:",
                "EmployeeCode": "Employee Code",
                "EmployeeName": "Employee Name",
                "Amount": "Amount",
                "CompanyShare": "Company Share",
                "Narration": "Narration",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "report": {
                "Title": "Medical Insurance",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.:*",
                "Documentdate": "Document Date:*",
                "EmployeeCode": "Employee Code",
                "EmployeeName": "Employee Name",
                "Amount": "Amount",
                "CompanyShare": "Company Share",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "taxes": {
            "management": {
                "Title": "Taxes",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No ",
                    "DocumentDate": "Document Date",
                    "Narration": "Narration",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Taxes",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.:*",
                "Documentdate": "Document Date:*",
                "FromDate": "From Value",
                "ToDate": "To Value",
                "Fee": "Fee",
                "GrossSalaryExemptionLimit": "Gross Salary Exemption Limit:",
                "AudioVisualTax": "Audio Visual Tax",
                "LocalDevelopmentTax": "Local Development Tax",
                "IncomeTax": "Income Tax",
                "Narration": "Narration",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "report": {
                "Title": "Medical Insurance",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.:*",
                "Documentdate": "Document Date:*",
                "EmployeeCode": "Employee Code",
                "EmployeeName": "Employee Name",
                "Amount": "Amount",
                "CompanyShare": "Company Share",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "stopsalary": {
            "management": {
                "Title": "Stop Salary",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Stop Salary",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.:*",
                "Documentdate": "Document Date:*",
                "ForMonth": "For Month:",
                "EmployeeCode": "Employee Code",
                "EmployeeName": "Employee Name",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "report": {
                "Title": "Stop Salary",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.:*",
                "Documentdate": "Document Date:*",
                "ForMonth": "For Month:",
                "EmployeeCode": "Employee Code",
                "EmployeeName": "Employee Name",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "purchaseindent": {
            "management": {
                "Title": "Purchase Indent",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "IndentNo": "Indent No",
                    "IndentDate": "Indent Date",
                    "Department": "Department",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Purchase Indent",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DepartmentCode": "Department Code:*",
                "DepartmentName": "Department Name:",
                "IndentNo": "Document No.:*",
                "IndentDate": "Document Date:*",
                "Remarks": "Remarks:",
                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "UOMCode": "UOM",
                "UOMName": "UOM Name",
                "ExpDate": "Expiry Date",
                "Quantity": "Quantity",
                "ExeQuantity": "Executed Quantity",
                "BalQuantity": "Balance Quantity",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "report": {
                "Title": "Purchase Indent",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DepartmentCode": "Department Code",
                "DepartmentName": "Department Name",
                "IndentNo": "Document No.",
                "IndentDate": "Document Date",
                "Remarks": "Remarks",
                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "UOMCode": "UOM Code",
                "UOMName": "UOM Name",
                "ExpDate": "Expiry Date",
                "Quantity": "Quantity",
                "ExeQuantity": "Executed Quantity",
                "BalQuantity": "Balance Quantity",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },


        "salesindent": {
            "management": {
                "Title": "Sales Order Indent",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "IndentNo": "Indent No",
                    "IndentDate": "Indent Date",
                    "Department": "Department",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Sales Order Indent",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DepartmentCode": "Department Code:*",
                "DepartmentName": "Department Name:",
                "IndentNo": "Document No.:*",
                "IndentDate": "Document Date:*",
                "Remarks": "Remarks:",
                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "UOMCode": "UOM Code",
                "UOMName": "UOM Name",
                "ExpDate": "Expiry Date",
                "Quantity": "Quantity",
                "ExeQuantity": "Executed Quantity",
                "BalQuantity": "Balance Quantity",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "report": {
                "Title": "Sales Order Indent",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DepartmentCode": "Department Code",
                "DepartmentName": "Department Name",
                "IndentNo": "Document No.",
                "IndentDate": "Document Date",
                "Remarks": "Remarks",
                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "UOMCode": "UOM Code",
                "UOMName": "UOM Name",
                "ExpDate": "Expiry Date",
                "Quantity": "Quantity",
                "ExeQuantity": "Executed Quantity",
                "BalQuantity": "Balance Quantity",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },



        "returnablegatePass": {
            "management": {
                "Title": "Returnable Gate Pass Issue",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "IndentNo": "Document No",
                    "IndentDate": "Document Date",
                    "Vendor": "Vendor",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Returnable Gate Pass Issue",
                "SubTitle": "SubTitle",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "VendorCode": "Vendor Code:*",
                "VendorName": "Vendor Name:",
                "PartyCode": "Party Code:*",
                "PartyName": "Party Name:",
                "RefType": "Party Type:",
                "TruckNo": "Truck No:",
                "DriverName": "Driver Name:",
                "DriverPhone": "Driver Phone:",
                "IndentNo": "Document No.:*",
                "IndentDate": "Document Date:*",
                "Remarks": "Remarks:",
                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "UOMCode": "UOM Code",
                "UOMName": "UOM Name",
                "ExpDate": "Expiry Date",
                "Quantity": "Quantity",
                "ExeQuantity": "Executed Quantity",
                "BalQuantity": "Balance Quantity",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "report": {
                "Title": "Returnable Gate Pass",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "VendorCode": "Vendor Code",
                "VendorName": "Vendor Name",
                "IndentNo": "Document No.",
                "IndentDate": "Document Date",
                "Remarks": "Remarks",
                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "UOMCode": "UOM Code",
                "UOMName": "UOM Name",
                "ExpDate": "Expiry Date",
                "Quantity": "Quantity",
                "ExeQuantity": "Executed Quantity",
                "BalQuantity": "Balance Quantity",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },


        "nonreturnablegatepass": {
            "management": {
                "Title": "Non Returnable Gate Pass",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "IndentNo": "Document No",
                    "IndentDate": "Document Date",
                    "Vendor": "Vendor",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Non Returnable Gate Pass",
                "SubTitle": "SubTitle",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "VendorCode": "Vendor Code:*",
                "VendorName": "Vendor Name:",
                "IndentNo": "Document No.:*",
                "IndentDate": "Document Date:*",
                "PartyCode": "Party Code:*",
                "PartyName": "Party Name:",
                "RefType": "Party Type:",
                "TruckNo": "Truck No:",
                "DriverName": "Driver Name:",
                "DriverPhone": "Driver Phone:",
                "Remarks": "Remarks:",
                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "UOMCode": "UOM",
                "UOMName": "UOM Name",
                "ExpDate": "Expiry Date",
                "Quantity": "Quantity",
                "ExeQuantity": "Executed Quantity",
                "BalQuantity": "Balance Quantity",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "report": {
                "Title": "Non Returnable Gate Pass",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "VendorCode": "Vendor Code",
                "VendorName": "Vendor Name",
                "IndentNo": "Document No.",
                "IndentDate": "Document Date",
                "Remarks": "Remarks",
                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "UOMCode": "UOM",
                "UOMName": "UOM Name",
                "ExpDate": "Expiry Date",
                "Quantity": "Quantity",
                "ExeQuantity": "Exe.Quantity",
                "BalQuantity": "Bal.Quantity",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },


        "consignmentindent": {
            "management": {
                "Title": "Consignment Indent",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "IndentNo": "Indent No",
                    "IndentDate": "Indent Date",
                    "Customer": "Customer",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Consignment Indent",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "CustomerCode": "Customer Code:*",
                "CustomerName": "Customer Name:",
                "IndentNo": "Document No.:*",
                "IndentDate": "Document Date:*",
                "Remarks": "Remarks:",
                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "UOMCode": "UOM Code",
                "UOMName": "UOM Name",
                "ExpDate": "Expiry Date",
                "Quantity": "Quantity",
                "ExeQuantity": "Executed Quantity",
                "BalQuantity": "Balance Quantity",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "report": {
                "Title": "Purchase Indent",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DepartmentCode": "Customer Code",
                "DepartmentName": "Customer Name",
                "IndentNo": "Document No",
                "IndentDate": "Document Date",
                "Remarks": "Remarks",
                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "UOMCode": "UOM Code",
                "UOMName": "UOM Name",
                "ExpDate": "Expiry Date",
                "Quantity": "Quantity",
                "ExeQuantity": "Executed Quantity",
                "BalQuantity": "Balalance Quantity",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },


        "tyreistissue": {
            "management": {
                "Title": "Tyre IST Issue",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "IndentNo": "Document No",
                    "IndentDate": "Document Date",
                    "ToBranch": "To Branch",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Tyre IST Issue",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DepartmentCode": "Department Code:*",
                "DepartmentName": "Department Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "ToBranch": "To SubOrg Code:",
                "ToBranchName": "To SubOrg Name:",
                "Remarks": "Remarks:",
                "TyreCode": "Tyre Id",
                "TyreName": "Tyre Sl No",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "report": {
                "Title": "Tyre IST Issue",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DepartmentCode": "Department Code",
                "DepartmentName": "Department Name",
                "IndentNo": "Document No.",
                "IndentDate": "Document Date",
                "Remarks": "Remarks",
                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "UOMCode": "UOM Code",
                "UOMName": "UOM Name",
                "ExpDate": "Expiry Date",
                "Quantity": "Quantity",
                "ExeQuantity": "Executed Quantity",
                "BalQuantity": "Balance Quantity",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },
        "tyrerethreadingissue": {
            "management": {
                "Title": "Tyre Rethreading Issue",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "IndentNo": "Document No",
                    "IndentDate": "Document Date",
                    "Vendor": "To Vendor",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Tyre Rethreading Issue",
                "SubTitle": "SubTitle",
                "Tab1": "General",
                "Tab2": "Details",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DepartmentCode": "Department Code:*",
                "DepartmentName": "Department Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "Vendor": "Vendor Name:",
                "Remarks": "Remarks:",
                "TyreCode": "Tyre Id",
                "TyreName": "Tyre SlNo",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "report": {
                "Title": "Tyre Rethreading Issue",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DepartmentCode": "Department Code",
                "DepartmentName": "Department Name",
                "IndentNo": "Document No.",
                "IndentDate": "Document Date",
                "Remarks": "Remarks",
                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "UOMCode": "UOM Code",
                "UOMName": "UOM Name",
                "ExpDate": "Expiry Date",
                "Quantity": "Quantity",
                "ExeQuantity": "Executed Quantity",
                "BalQuantity": "Balance Quantity",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },
        "tyrerethreadingreceipt": {
            "management": {
                "Title": "Tyre Rethreading Receipt",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "IndentNo": "Document No",
                    "IndentDate": "Document Date",
                    "Vendor": "To Vendor",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Tyre Rethreading Receipt",
                "SubTitle": "SubTitle",
                "Tab1": "General",
                "Tab2": "Details",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DepartmentCode": "Department Code:*",
                "DepartmentName": "Department Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "Vendor": "Vendor Name:",
                "IssueNo": "Issue No:",
                "TyreLocation": "Reveicing Tyre Location:",
                "TyrePattern": "New Tyre Pattern:",
                "ThreadDepth": "Thread Depth:",
                "Remarks": "Remarks:",
                "TyreCode": "Tyre Id",
                "TyreName": "Tyre SlNo",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "report": {
                "Title": "Tyre Rethreading Receipt",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DepartmentCode": "Department Code",
                "DepartmentName": "Department Name",
                "IndentNo": "Document No.",
                "IndentDate": "Document Date",
                "Remarks": "Remarks",
                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "UOMCode": "UOM Code",
                "UOMName": "UOM Name",
                "ExpDate": "Expiry Date",
                "Quantity": "Quantity",
                "ExeQuantity": "Executed Quantity",
                "BalQuantity": "Balance Quantity",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "tyreistreceipt": {
            "management": {
                "Title": "Tyre IST Receipt",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "IndentNo": "Document No",
                    "IndentDate": "Document Date",
                    "FromBranch": "To FromBranch",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Tyre IST Receipt",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DepartmentCode": "Department Code:*",
                "DepartmentName": "Department Name:",
                "DocumentNo": "Document No:*",
                "DocumentDate": "Document Date:*",
                "Branch": "From Branch",
                "IssueNo": "Issue No:",
                "TyreLocation": "Reveicing Tyre Location:",
                "Remarks": "Remarks:",
                "TyreCode": "Tyre Id",
                "TyreName": "Tyre Serial No",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "report": {
                "Title": "Tyre Rethreading Receipt",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DepartmentCode": "Department Code",
                "DepartmentName": "Department Name",
                "IndentNo": "Document No.",
                "IndentDate": "Document Date",
                "Remarks": "Remarks",
                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "UOMCode": "UOM Code",
                "UOMName": "UOM Name",
                "ExpDate": "Expiry Date",
                "Quantity": "Quantity",
                "ExeQuantity": "Executed Quantity",
                "BalQuantity": "Balance Quantity",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "tyrelocationtransfer": {
            "management": {
                "Title": "Tyre Location Transfer Issue",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "IndentNo": "Document No",
                    "IndentDate": "Document Date",
                    "ToBranch": "To Branch",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Tyre Location Transfer Issue",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DepartmentCode": "Department Code:*",
                "DepartmentName": "Department Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "ToBranch": "To SubOrg Code:",
                "ToBranchName": "To SubOrg Name:",
                "TyreLocation": "From Location Code:",
                "TyreLocationName": "From Location Name:",
                "ToTyreLocation": "To Location Code:",
                "ToTyreLocationName": "To Location Name:",
                "Remarks": "Remarks:",
                "TyreCode": "Tyre Id",
                "TyreName": "Tyre SlNo",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "report": {
                "Title": "Tyre IST Issue",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DepartmentCode": "Department Code",
                "DepartmentName": "Department Name",
                "IndentNo": "Document No.",
                "IndentDate": "Document Date",
                "Remarks": "Remarks",
                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "UOMCode": "UOM Code",
                "UOMName": "UOM Name",
                "ExpDate": "Expiry Date",
                "Quantity": "Quantity",
                "ExeQuantity": "Executed Quantity",
                "BalQuantity": "Balance Quantity",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "advancepolicy": {
            "management": {
                "Title": "Advance Policy",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Advance Policy",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.:*",
                "Documentdate": "Document Date:*",
                "FromDate": "From Date:",
                "ToDate": "To Date:",
                "Remarks": "Narration:",
                "AdvanceTypeCode": "Advance Type Code",
                "AdvanceTypeName": "Advance Type Name",
                "NoofInstallments": "No of Installments",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "report": {
                "Title": "Advance Policy",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.",
                "Documentdate": "Document Date",
                "FromDate": "From Date:",
                "ToDate": "To Date:",
                "Remarks": "Narration:",
                "AdvanceTypeCode": "Advance Type Code",
                "AdvanceTypeName": "Advance Type Name",
                "NoofInstallments": "No of Installments",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Narration:"
            }
        },
        "loanpolicy": {
            "management": {
                "Title": "Loan Policy",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Loan Policy",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.:*",
                "Documentdate": "Document Date:*",
                "FromDate": "From Date:",
                "ToDate": "To Date:",
                "Remarks": "Narration:",
                "LoanTypeCode": "Loan Type Code",
                "LoanTypeName": "Loan Type Name",
                "NoofInstallments": "No of Installments",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "report": {
                "Title": "Loan Policy",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No",
                "Documentdate": "Document Date",
                "FromDate": "FromDate:",
                "ToDate": "ToDate:",
                "Remarks": "Narration:",
                "LoanTypeCode": "Loan Type Code",
                "LoanTypeName": "Loan Type Name",
                "NoofInstallments": "No of Installments",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Narration:"
            }
        },

        "objectivekpis": {
            "management": {
                "Title": "Objective Kpis",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentDate": "Document Date",
                    "Appraisee": "Appraisee Code",
                    "Appraiser": "Appraiser Code",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Objective Kpis",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Period": "Period:*",
                "Documentdate": "Document Date:*",
                "AppraiseeCode": "Appraisee Code:",
                "AppraiseePositionCode": "Appraisee Position Code:",
                "AppraiserCode": "Appraiser Code:",
                "AppraiserPositionCode": "Appraiser Position Code:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By",

                "Objective": "Objective",
                "DescriptionObjective": "Description Objective",
                "Kpi": "KPI",
                "Target": "Target",
                "Weight": "Weight",
                "PercentageVal": "Percentage"
            },
        },

        "DynamicObjectives": {
            "management": {
                "Title": "Dynamic Objectives",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentDate": "Document Date",
                    "EmployeeName": "Employee Name",
                    "Level": "Level",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Dynamic Objectives",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Period": "Period:*",
                "Documentdate": "Document Date:*",
                "EmployeeName": "Employee Name:*",
                "Level": "Level:*",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By",

                "Objective": "Objectives",
                "Grade": "Grade"
            },
        },

        "CompetenciesObjectives": {
            "management": {
                "Title": "Competencies Objectives",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentDate": "Document Date",
                    "EmployeeName": "Employee Name",
                    "Level": "Level",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Competencies Objectives",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Period": "Period:*",
                "Documentdate": "Document Date:*",
                "EmployeeName": "Employee Name:*",
                "Level": "Level:*",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By",

                "Objective": "Objectives",
                "Grade": "Grade"
            },
        },

        "ConstantObjectives": {
            "management": {
                "Title": "Constant Objectives",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentDate": "Document Date",
                    "EmployeeName": "Employee Name",
                    "Level": "Level",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Constant Objectives",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Period": "Period:*",
                "Documentdate": "Document Date:*",
                "EmployeeName": "Employee Name:*",
                "Level": "Level:*",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By",

                "Objective": "Objectives",
                "Grade": "Grade"
            },
        },

        "EmployeePerformance": {
            "management": {
                "Title": "Employee Performance",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentDate": "Document Date",
                    "EmployeeName": "Employee Name",
                    "Period": "Period",
                    "Quarter": "Quarter",
                    "Level": "Level",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Employee Performance",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Period": "Period:*",
                "Quarter": "Quarter:*",
                "DivisionName": "Division:*",
                "EmployeeName": "Employee Name:*",
                "Company": "Company:*",
                "Department": "Department:*",
                "ReportingTo": "ReportingTo:*",
                "DateOfApprisal": "Date of Apprisal:*",
                "Level": "Level:*",
                "comments": "Comments:*",
                "feedback": "Feedback:*",
                "directManagerComments": "Direct Manager Comments:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By",

                "Objective": "Objectives",
                "Grade": "Grade",
                "Score": "Score"
            },
        },


        "employeeselfapprasal": {
            "management": {
                "Title": "Employee Self Apprasal",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "AppraiserPeriod": "Appraiser Period",
                    "Appraisee": "Appraisee Code",
                    "Appraiser": "Appraiser Code",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Employee Self Apprasal",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "AppraiseeCode": "Appraisee Code:",
                "AppraiseePositionCode": "Appraisee Position Code:",
                "ReportingTo": "Reporting To:",
                "Department": "Department:",
                "RoleType": "Role Type:",
                "AppraiserPeriod": "Appraiser Period:",
                "SeniorityInPosition": "Seniority In Position:",
                "AppraiserCode": "Appraiser Code:",

                "OverallAssessmentObjectives": "Overall Assessment Objectives:",
                "ObjectivesConclusion": "Objectives Conclusion:",
                "ObjectivesScore": "Objectives Score:",
                "OverallAssessmentValues": "Overall Assessment Values:",
                "ValuesConclusion": "Values Conclusion:",
                "ValuesScore": "Values Score:",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By",

                "Objectives": "Objectives",
                "Weight": "Weight",
                "Score": "Score",
            },
        },

        "employeeapprasalmanager": {
            "management": {
                "Title": "Employee Apprasal Manager",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "Employee": "Employee Code",
                    "Designation": "Designation Code",
                    "AppraiserPeriod": "Appraiser Period",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Employee Apprasal Manager",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "EmployeeCode": "Employee Code:",
                "DesignationCode": "Designation Code:",
                "AppraiserPeriod": "Appraiser Period:",
                "DateOfApprasal": "Date Of Apprasal:",

                "OverallAssessmentValues": "Overall Assessment Values:",
                "ValuesConclusion": "Values Conclusion:",
                "ValuesOption": "Values Option:",
                "LeaderOfOthers": "Leader Of Others:",
                "Question1": "1. To what extend does the leader select and optimize the team?",
                "Question2": "2. To what extend does the leader clearly prioritize and involve direct reports in setting objectives?",
                "Question3": "3. To what extend does the leader deligate in order for direct reports to effectively deal with their responsibilities?",
                "Question4": "4. To what extend does the leader consistently coach and develop direct reports personally and professionally",
                "Question5": "5. To what extend does the leader hold direct reports accountable for their performance?",
                "OverallAssessmentLeadership": "Overall Assessment Leadership:",
                "LeadershipConclusion": "Leadership Conclusion:",
                "LeadershipOption": "Leadership Option:",
                "Promotability": "Promotability:",
                "PromotabilityConclusion": "Promotability Conclusion:",
                "PromotabilityOption": "Promotability Option:",
                "P2": "Within the next 12 months the employee is capable of assuming a job in the next job grade band. If the score is given, at least one specific position has to be mentioned below.",
                "P1": "The employee can further develop and assume a higher responsibility in the current job grade band. Further responsibility should be described below if relevant.",
                "P0": "The employee is not suited for the current position and job grade band.",
                "NotAble": "Not able to evaluate (only to be used if leader is less than 6 months to position).",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By",
            },
        },

        "personaldevelopmentplan": {
            "management": {
                "Title": "Personal Development Plan",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "Employee": "Employee Code",
                    "Designation": "Designation Code",
                    "AppraiserPeriod": "Appraiser Period",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Personal Development Plan",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "EmployeeCode": "Employee Code:",
                "DesignationCode": "Designation Code:",
                "AppraiserPeriod": "Appraiser Period:",
                "DateOfApprasal": "Date Of Apprasal:",

                "AddResponsibility": "Additional Responsibilities Within 12 Months:",
                "PersonalJob": "Personal Job Aspirations > 12 Months:",
                "PersonalDevelopment": "Personal Development Plan:",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By",
            },
        },

        "overallassessment": {
            "management": {
                "Title": "Overall Assessment",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "Employee": "Employee Code",
                    "Designation": "Designation Code",
                    "AppraiserPeriod": "Appraiser Period",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Overall Assessment",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "EmployeeCode": "Employee Code:",
                "DesignationCode": "Designation Code:",
                "AppraiserPeriod": "Appraiser Period:",

                "OverallAssessment": "Overall Assessment:",
                "ObjectivesConclusion": "Objectives Conclusion:",
                "ValuesConclusion": "Values Conclusion:",
                "LeadershipConclusion": "Leadership Conclusion:",
                "PromotabilityConclusion": "Promotability Conclusion:",
                "Conclusion": "Overall Conclusion:",
                "Score": "Score:",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By",
            },
        },

        "daycalculation": {
            "management": {
                "Title": "Day Calculation",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Day Calculation",
                "SubTitle": "SubTitle",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No:*",
                "Documentdate": "Document Date:*",
                "Remarks": "Narration:",
                "DayTypeCode": "Day Type Code",
                "DayTypeName": "Day Type Name",
                "Percentage": "Percentage",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "report": {
                "Title": "Day Policy",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No",
                "Documentdate": "Document Date",
                "FromDate": "FromDate:",
                "ToDate": "ToDate:",
                "Remarks": "Narration:",
                "AdvanceTypeCode": "Day Type Code",
                "AdvanceTypeName": "Day Type Name",
                "Percentage": "Percentage",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Narration:"
            }
        },
        "technicalindent": {
            "management": {
                "Title": "Stores Indent",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "IndentNo": "Indent No",
                    "IndentDate": "Indent Date",
                    "JobCard": "Job Card No",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Stores Indent",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "JobCardNo": "Job Card No.:*",
                "IndentNo": "Document No.:*",
                "IndentDate": "Document Date:*",
                "reftype": "Reference Type:*",
                "prodIndentCode": "Reference No.:*",
                "truckType": "Truck Type:",
                "truckNo": "Truck No",
                "UnitNo": "Unit No",
                "Remarks": "Remarks:",
                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "UOMCode": "UOM",
                "UOMName": "UOM Name",
                "ExpDate": "Expiry Date",
                "Quantity": "Quantity",
                "ExeQuantity": "Executed Quantity",
                "BalQuantity": "Balance Quantity",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "report": {
                "Title": "Technical Indent",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "JobCardNo": "Job Card No",
                "IndentNo": "Document No.",
                "IndentDate": "Document Date",
                "Remarks": "Remarks",
                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "UOMCode": "UOM Code",
                "UOMName": "UOM Name",
                "ExpDate": "Expiry Date",
                "Quantity": "Quantity",
                "ExeQuantity": "Executed Quantity",
                "BalQuantity": "Balalance Quantity",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },



        "codegenerate": {
            "management": {
                "Title": "Code Generate",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "CancelButton": "Back to the List",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "FeatureCode": "Feature Code",
                    "FeatureName": "Feature Name",
                    "MenuName": "Menu",
                    "ModuleName": "Module",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Code Generate",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "Organisation": "Organization:*",
                "NoofParts": "No of Parts:*",
                "VariableType": "Variable Type:*",
                "Variable": "Variable:*",
                "ResetFrequency": "Reset Frequency:*",
                "CodeSeparator": "Code Separator:*",
                "NoofChar": "No of Characters:*",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },


        "istindent": {
            "management": {
                "Title": "IST Indent",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "IndentNo": "Document No",
                    "IndentDate": "Document Date",
                    "Department": "Department",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "IST Indent",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode1": "To Org Code:*",
                "DepartmentName": "To Org Name:",
                "IndentNo": "Document No.:*",
                "IndentDate": "Document Date:*",
                "Transporter": "Transporter:",
                "FromWareHouse": "FromWareHouse:",
                "ToWareHouse": "ToWareHouse:",
                "TruckNo": "Truck No.:",
                "DriverName": "Driver Name:",
                "PhoneNo": "Phone No.:",
                "Remarks": "Remarks:",
                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "UOMCode": "UOM",
                "UOMName": "UOM Name",
                "OriginName": "Origin Name",
                "ExpDate": "Expiry Date",
                "Quantity": "Quantity",
                "ExeQuantity": "Executed Quantity",
                "BalQuantity": "Balance Quantity",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "report": {
                "Title": "Purchase Indent",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DepartmentCode": "Department Code",
                "DepartmentName": "Department Name",
                "IndentNo": "Document No.",
                "IndentDate": "Document Date",
                "Remarks": "Remarks",
                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "UOMCode": "UOM Code",
                "UOMName": "UOM Name",
                "ExpDate": "Expiry Date",
                "Quantity": "Quantity",
                "ExeQuantity": "Executed Quantity",
                "BalQuantity": "Balalance Quantity",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "componentprofile": {
            "management": {
                "Title": "Component Profile",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organisation",
                    "Code": "Code",
                    "Name": "Name:",
                    "TaxComponent": "Tax Component",
                    "Operation": "Operation",
                    "ValueType": "Value Type",
                    "Value": "Value",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Component Profile",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:*",
                "Name": "Name:*",
                "TaxDefinition": "Tax Definition:*",
                "Operation": "Operation:*",
                "Absolute": "Absolute (or) Relative:*",
                "Value": "Value:*",
                "TaxCredit": "Tax Credit:*",
                "SVICV": "Sales value inclusion of Component Value:*",
                "InclusiveLC": "Inclusive LC Profile:*",
                "ServiceItem": "Service Item:*",
                "BaseOnQty": "BaseOnQty:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "defineformula": {
            "management": {
                "Title": "Define Formula",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "Code": "Code",
                    "Name": "Name",
                    "Formula": "Formula",
                    "FormulaTemplateCode": "Formula Template Code",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Define Formula",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Sno": "Sl.No",
                "Taxes": "Taxes",
                "DefineTax": "Define Tax",
                "AppliedOn": "Applied On",
                "Formula": "Formula",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "itemmeasure": {
            "management": {
                "Title": "Item Measures",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organisation",
                    "ItemCode": "Item Code",
                    "ItemName": "Item Name",
                    "Alias": "Alias",
                    "UOMCode": "UOM Code",
                    "UOMName": "UOM Name"
                }
            },
            "create": {
                "Title": "Item Measures",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "ItemCode": "Item Code:*",
                "ItemName": "Item Name:",
                "Alias": "Alias:",
                "BaseUOM": "Base UOM:",
                "BaseUOMName": "Base UOM Name:",
                "UOMCode": "UOM Code",
                "UOMName": "UOM Name",
                "ConversionQty": "Conversion Quantity",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "vendortype": {
            "management": {
                "Title": "Vendor Type",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name:",
                    "SalespersonName": "Sales Person Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Vendor Type",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Salespersonname": "Sales Person Name:",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },


        "vendorgroup": {
            "management": {
                "Title": "Vendor Group",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "SalespersonName": "Sales Person Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Vendor Group",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "ParentVendorGroup": "Parent Vendor Group:",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "banktype": {
            "management": {
                "Title": "Bank Type",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Bank Type",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "expendituretype": {
            "management": {
                "Title": "Expenditure Type",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Expenditure Type",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "AccountCode": "Account Code:*",
                "AccountName": "Account Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },


        "actionitems": {
            "management": {
                "Title": "Action Items",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Action Items",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },




        "benefits": {
            "management": {
                "Title": "Benefits",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Benefits",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "questiongroup": {
            "management": {
                "Title": "Question Group",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Question Group",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
        },

        "question": {
            "management": {
                "Title": "Question",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Question",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Group": "Group:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
        },


        "survey": {
            "management": {
                "Title": "Survey",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Period": "Period",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Survey",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Period": "Period:*",

                "QuestionCode": "Question Code",
                "QuestionName": "Question Name",

                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
        },

        "employeesurvey": {
            "management": {
                "Title": "Employee Survey",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Employee": "Employee",
                    "Period": "Period",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Employee Survey",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "EmployeeName": "Employee Name:*",
                "Period": "Period:*",

                "QuestionName": "Question Name",
                "Answer1": "Strongly Agree",
                "Answer2": "Agree",
                "Answer3": "Somewhat Agree",
                "Answer4": "Somewhat Disagree",
                "Answer5": "Disagree",
                "Answer6": "Strongly Disagree",

                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
        },

        "bintype": {
            "management": {
                "Title": "Bin Type",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Bin Type",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "WareHouse": "Warehouse:*",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "agent": {
            "management": {
                "Title": "Agent",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Agent",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
        },

        "vessaldetails": {
            "management": {
                "Title": "Vessal Details",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "VessalName": "Vessal Name",
                    "Date": "Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Vessal Details",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "VessalName": "Vessal Name:*",
                "Date": "Date:*",
                "Hold1Tonnage": "Hold 1 Tonnage:*",
                "Hold2Tonnage": "Hold 2 Tonnage:*",
                "Hold3Tonnage": "Hold 3 Tonnage:*",
                "Hold4Tonnage": "Hold 4 Tonnage:*",
                "Hold5Tonnage": "Hold 5 Tonnage:*",
                "TotalTonnage": "Total Tonnage:*",
                "TypeOfWheat": "Type Of Wheat:*",
                "Origin": "Origin:",
                "Agent1": "Agent1:",
                "Agent2": "Agent2:",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
        },

        "itemstockcriteria": {
            "management": {
                "Title": "Item Stock Criteria",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "ItemCode": "Item Code",
                    "ItemName": "Item Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Item Stock Criteria",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "ItemCode": "Item Code:*",
                "ItemName": "Item Name:*",
                "Warehouse": "Warehouse:",
                "SafetyStock": "Safety Stock:",
                "MinimumStock": "Minimum Stock:",
                "MaximumStock": "Maximum Stock:",
                "LeadTime": "Lead Time:",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
        },

        "truckregistration": {
            "management": {
                "Title": "Truck Registration",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Truck Registration",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No:*",
                "DocumentDate": "Document Date:*",
                "TruckNo": "Truck No:*",
                "DriverName": "Driver Name:*",
                "NameOfTheVessal": "Name Of The Vessal:*",
                "EmptyWeight": "Empty Weight:*",
                "TransporterName": "Transporter Name:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
        },

        "wheatdispatchnote": {
            "management": {
                "Title": "Wheat Dispatch Note",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Wheat Dispatch Note",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No:*",
                "DocumentDate": "Document Date:*",
                "TruckNo": "Truck No:*",
                "DriverName": "Driver Name:*",
                "VessalName": "Vessal Name:*",
                "HoldNo": "Hold No:*",
                "WDNNo": "WDN No:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
        },

        "wheatdispatchnoteafs": {
            "management": {
                "Title": "Wheat Dispatch Note",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "FromWarehouse": "From WareHouse",
                    "ToWarehouse": "To WareHouse",
                    "TruckNo": "Truck No",
                    "DriverName": "Driver Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Wheat Dispatch Note",
                "SubTitle": "SubTitle",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No:*",
                "Date": "Document Date:*",
                "FromWareHouse": "From WareHouse:*",
                "ToWareHouse": "To WareHouse:*",
                "TruckNo": "Truck No:*",
                "DriverName": "Driver Name:*",
                "DriverPhone": "Driver Phone:*",
                "Transporter": "Transporter:*",

                "TypeOfWheat": "Type Of Wheat",
                "Origin": "Origin",
                "WeighBridgeTicketNo": "Weigh Bridge Ticket No",
                "Quantity": "Quantity",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
        },

        "vessalweighbridge": {
            "management": {
                "Title": "Vessal Weigh Bridge",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Vessal Weigh Bridge",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "TruckNo": "Truck No:*",
                "WeighBridge": "Weigh Bridge:*",
                "DocumentNo": "Document No:*",
                "DocumentDate": "Document Date:*",
                "FullWeight": "Full Weight:*",
                "FullPortWeighBridgeWeight": "Full Port Weigh Bridge Weight:*",
                "EmptyWeight": "EmptyWeight:*",
                "Warehouse": "Warehouse:*",
                "Location": "Location:*",
                "PortEmptyTruckWeight": "Port Empty Truck Weight:*",
                "AfisaNetWeight": "Afisa Net Weight:*",
                "PortWeighBridgeNet": "Port Weigh Bridge Net:*",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
        },

        "bins": {
            "management": {
                "Title": "Bins",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Org": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "WareHouse": "WareHouse",
                    "BinType": "Bin Type",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Bins",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "WareHouse": "WareHouse",
                "BinType": "Bin Type",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "WareHouse": "WareHouse",
                "BinType": "BinType",
                "Remarks": "Remarks:"
            }
        },

        "unitset": {
            "management": {
                "Title": "Unit Set",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Unit Set",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },



        "categories": {
            "management": {
                "Title": "Categories",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Categories",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },


        "containertype": {
            "management": {
                "Title": "Container Type",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Container Type",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "contracttype": {
            "management": {
                "Title": "Contract Type",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Contract Type",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "daytype": {
            "management": {
                "Title": "Day Type",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Day Type",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "deliverymodes": {
            "management": {
                "Title": "Delivery Modes",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Delivery Modes",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },


        "deliveryterms": {
            "management": {
                "Title": "Delivery Terms",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Delivery Terms",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },



        "deliverytype": {
            "management": {
                "Title": "Delivery Type",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Delivery Type",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },


        "dimention": {
            "management": {
                "Title": "Dimension",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Dimension",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },


        "division": {
            "management": {
                "Title": "Division",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Division",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },


        "documenttype": {
            "management": {
                "Title": "Document Type",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Document Type",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },


        "fueltank": {
            "management": {
                "Title": "Fuel Tank",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Fuel Tank",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },



        "gradegroup": {
            "management": {
                "Title": "Grade Group",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Grade Group",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "grade": {
            "management": {
                "Title": "Grade",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "GradeGroup": "Grade Group",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Grade",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Gross": "Gross:*",
                "Basic": "Basic:*",
                "Cola": "Cola:*",
                "HourRate": "Hour Rate:",
                "GradeGroup": "Grade Group:*",
                "StructureIncentive": "Structure Incentive:*",
                "OtherIncentive": "Other Incentive:*",
                "GovernmentMinha": "Government Minha:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "incomeanddeduction": {
            "management": {
                "Title": "Income and Deduction",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "TypeName": "Type Name",
                    "CalculationModeName": "Calculation Mode",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Income and Deduction",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Type": "Type:*",
                "CalculationMode": "Calculation Mode:*",
                "ContrSide": "Control Side:*",
                "Contributory": "Contributory:*",
                "Remarks": "Remarks:",
                "Prorata": "Prorata",
                "LeaveAllowance": "LeaveAllowance",
                "TextType": "Tax Type:*",
                "Percentage": "Percentage:*",
                "BaseId": "Base:",
                "IsMandatory": "Is Mandatory:*",
                "BenefitOfKind": "Display BenefitOfKind:*",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name",
                "Remarks": "Remarks:"
            }
        },

        "designation": {
            "management": {
                "Title": "Designation",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "ShortName": "Short Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Designation",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Department": "Department:*",
                "ShortName": "Short Name:*",
                "ParentDesignation": "Parent Designation:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },


        "leaveencashmentrequisition": {
            "management": {
                "Title": "Leave Encashment Requisition",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Org Name",
                    "Code": "Code",
                    "Date": "Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Leave Encashment Requisition",
                "SubTitle": "SubTitle",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Document No.:*",
                "Date": "Document Date:*",
                "Employee": "Employee:*",
                "Narration": "Remarks:",
                "LeaveType": "Leave Type",
                "NoofDays": "No of Days",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "extraleaves": {
            "management": {
                "Title": "Extra Leaves",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Org Name",
                    "Code": "Document No",
                    "Date": "Document Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Extra Leaves",
                "SubTitle": "SubTitle",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "EmployeeCode": "Employee Code:*",
                "EmployeeName": "Employee Name:*",
                "Narration": "Remarks:",
                "LeaveTypeCode": "Leave Type Code",
                "LeaveTypeName": "Leave Type Name",
                "NoofLeaves": "No of Leaves",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
        },

        "interviewphase": {
            "management": {
                "Title": "Interview Phase",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Interview Phase",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },



        "itemspecification": {
            "management": {
                "Title": "Item Specification",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Item Specification",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "ParentVendorGroup": "Parent Vendor Group:",
                "ShortName": "Short Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },



        "itemtype": {
            "management": {
                "Title": "Item Type",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Item Type",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },



        "languages": {
            "management": {
                "Title": "Languages",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Languages",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },



        "leavetype": {
            "management": {
                "Title": "Leave Type",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Leave Type",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },



        "loantype": {
            "management": {
                "Title": "Loan Type",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Loan Type",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },



        "make": {
            "management": {
                "Title": "Make",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Make",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },


        "variant": {
            "management": {
                "Title": "Variant",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Variant",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "internalfuelstation": {
            "management": {
                "Title": "Internal Fuel Station",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Internal Fuel Station",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },
        "action": {
            "management": {
                "Title": "Action",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Action",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },


        "vendorcategory": {
            "management": {
                "Title": "Vender Category",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Vender Category",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },


        "itemcategory": {
            "management": {
                "Title": "Item Category",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "ParentItemCategory": "Parent Item Category",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Item Category",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "ParentItemCategory": "Parent Item Category:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "externalfuelstation": {
            "management": {
                "Title": "External Fuel Station",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org/SubOrg Name:",
                    "Code": "Code",
                    "Name": "Name",
                    "VendorName": "Vender Name",
                    "Remarks": "Remarks",
                    "Vendor": "Vender:",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "External Fuel Station",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "Vendorname": "Vender Name",
                "Price": "Price:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name",
                "Remarks": "Remarks:"
            }
        },


        "consolidationdefinition": {
            "management": {
                "Title": "Consolidation Definition",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "CurrencyName": "Currency Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Consolidation Definition",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "Currencyname": "Currency Name",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Currencyname": "Currency Name",
                "Remarks": "Remarks:"
            }
        },

        "budgetline": {
            "management": {
                "Title": "Budget Line",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "CurrencyName": "Currency Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Budget Line",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "Currencyname": "Currency Name",
                "Remarks": "Remarks:",
                "BudgetLineGroup": "Budget Line Group:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Currencyname": "Currency Name",
                "Remarks": "Remarks:"
            }
        },

        "budgetlinegroup": {
            "management": {
                "Title": "Budget Line Group",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Budget Line Group",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
        },

        "tyrelocation": {
            "management": {
                "Title": "Tyre Location",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "TyreLocationTypeName": "Location Type",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Tyre Location",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "Tyrelocationtypename": "Location Type",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "locationtype": "Location Type",
                "Remarks": "Remarks:"
            }
        },
        "tyremaster": {
            "management": {
                "Title": "Tyre Master",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "TyreId": "Tyre Id",
                    "TyreSlNo": "Tyre SlNo",
                    "TyreCardNo": "Tyre Card No",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Tyre Master",
                "SubTitle": "SubTitle",
                "General": "General",
                "Details": "Details",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "TyreID": "Tyre ID:*",
                "TyreSlNo": "Tyre Serial No:*",
                "TyreBrand": "Tyre Brand:",
                "TyrePattern": "Tyre Pattern:",
                "TyreType": "Tyre Type:",
                "TyreSize": "Tyre Size:",
                "TyreCategory": "Tyre Category:",
                "TyreBatch": "Tyre Batch:",
                "RethreadStatus": "Rethread Status",
                "TyreCountry": "Tyre Country:",
                "TyreDepth": "Tyre Depth:",
                "PlyRating": "Ply Rating:",
                "LoadIndex": "Load Index:",
                "Price": "Price:",
                "WarrentyKM": "Warrenty KM:",
                "AverageLifeKM": "Average Life KM:",
                "MfgWeek": "Mfg Week:",
                "MfgYear": "Mfg Year",
                "Groovable": "Groovable:",
                "Rethreading": "Rethreading:",
                "OKM": "OKM:",
                "CardNo": "Tyre Card No",
                "FirstImpDate": "First Implementation Date:",
                "R1KM": "R1 KM:",
                "R1Price": "R1 Price:",
                "R1ImpDate": "R1 Implementation Date:",
                "R2KM": "R2 KM:",
                "R2Price": "R2 Price:",
                "R2ImpDate": "R2 Implementation Date:",
                "R3KM": "R3 KM:",
                "R3Price": "R3 Price:",
                "R3ImpDate": "R3 Implementation Date:",
                "RGKM": "RG KM:",
                "RGPrice": "RG Price:",
                "RGImpDate": "RG Implementation Date:",
                "PresentTD": "Present TD:",
                "TyreLocation": "Tyre Location:",
                "Currency": "Currency:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "locationtype": "Location Type",
                "Remarks": "Remarks:"
            }
        },


        "fuelslip": {
            "management": {
                "Title": "Fuel Slip",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "FuelSlipNo": "Fuel Slip No",
                    "FuelSlipDate": "Fuel Slip Date",
                    "FuelStation": "Fuel Station",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Fuel Slip",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "fuelslipno": "Fuel Slip No:*",
                "FuelSlipDate": "Fuel Slip Date:*",
                "DriverCardNo": "Driver Card No:",
                "DriverCode": "Driver Code:",
                "DriverName": "Driver Name:",
                "Route": "Route:",
                "FuelStation": "Fuel Station:",
                "Quantity": "Quantity:",
                "IssuedQuantity": "Issued Quantity:",
                "ReqQuantity": "Fuel Required Quantity:",
                "BalQuantity": "Balance Quantity:",
                "ManifestNo": "Manifest No:",
                "FuelNo": "Fuel No:",
                "FuelStationType": "Fuel Station Type:",
                "TripType": "Trip Type:",
                "UnitNo": "Unit No:",
                "PlateNo": "Plate No",
                "Odometer": "Odometer:",
                "RoadFeesLoaded": "Road Fees Loaded:",
                "RoadFeesEmpty": "Road Fees Empty:",
                "BridgeFeesLoaded": "Bridge Fees Loaded:",
                "BridgeFeesEmpty": "Bridge Fees Empty:",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "locationtype": "Location Type",
                "Remarks": "Remarks:"
            }
        },

        "fuelslip2": {
            "management": {
                "Title": "Fuel Slip",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "FuelSlipNo": "Fuel Slip No",
                    "FuelSlipDate": "Fuel Slip Date",
                    "FuelStation": "Fuel Station",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Fuel Slip",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "FuelSlipNo": "Fuel Slip No:*",
                "FuelSlipDate": "Fuel Slip Date:*",
                "Route": "Route:",
                "UnitNo": "Unit No:",
                "Quantity": "Quantity:",
                "Amount": "Amount:",
                "DriverCode": "Driver Code:",
                "DriverName": "Driver Name:",
                "FuelStation": "Fuel Station:",
                "FuelStationType": "Fuel Station Type:",
                "ManifestNo": "Manifest No:",
                "TripType": "Trip Type:",
                "TripStatus": "Trip Status:",
                "Odometer": "Odometer:",

                "FromDestinationId": "From Destination:",
                "ToDestinationId": "To Destination:",
                "TrailerId": "Trailer:",
                "KM": "KM:",
                "FuelConsumption": "Fuel Consumption:",
                "FuelType": "Fuel Type:",
                "TripCardNumber": "Trip Card Number:",

                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
        },

        "fuelissue": {
            "management": {
                "Title": "Fuel Issue",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "FuelSlipNo": "Fuel Slip No",
                    "FuelSlipDate": "Fuel Slip Date",
                    "FuelStation": "Fuel Station",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Fuel Issue",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "fuelslipno": "Document No.:*",
                "FuelSlipDate": "Document Date:*",
                "DriverCardNo": "Driver Card No:",
                "Route": "Route:",
                "FuelStation": "Fuel Station:",
                "Quantity": "Quantity:",
                "ManifestNo": "Manifest No:",
                "FuelNo": "Fuel No:",
                "UnitNo": "Unit No:",
                "PlateNo": "Plate No",
                "Odometer": "Odometer:",
                "RoadFeesLoaded": "Road Fees Loaded:",
                "RoadFeesEmpty": "Road Fees Empty:",
                "BridgeFeesLoaded": "Bridge Fees Loaded:",
                "BridgeFeesEmpty": "Bridge Fees Empty:",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "locationtype": "Location Type",
                "Remarks": "Remarks:"
            }
        },

        "operationsofficer": {
            "management": {
                "Title": "Operations Officer",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Operations Officer",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "driveraccidents": {
            "management": {
                "Title": "Driver Accidents",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Org Name",
                    "DateofAccident": "Date of Accident ",
                    "DriverName": "Driver Name",
                    "Unit": "Unit",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Driver Accidents",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DriverName": "Driver Name:*",
                "DateOfAccident": "Date and Time Of Accident:*",
                "PlaceofAccident": "Place of Accident:",
                "LoadType": "Load Type:",
                "UnitTypeName": "Unit Type Name:",
                "UnitName": "Unit Name:",
                "OperationsOfficerName": "Operations OfficerName:",
                "AccidentClassification": "Accident Classification:",
                "RootCause": "Root Cause:",
                "InjuredDays": "Injured Days:",
                "Consequences": "Consequences:",
                "AccidentInvestigation": "Accident Investigation:",
                "RemidialCorrectiveAction": "Remidial Corrective Action:",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "operationsdivision": {
            "management": {
                "Title": "Operations Division",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Operations Division",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "destination": {
            "management": {
                "Title": "Destination",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Destination",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "SalesRegion": "SalesRegion:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "paymentterms": {
            "management": {
                "Title": "Payment Terms",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Payment Terms",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "VendorDueFrom": "Vendor Due Date From:*",
                "ShortName": "Short Name:*",
                "PaymentIn": "Payment In %:*",
                "CreditDays": "Credit Days:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "segmentdefinition": {
            "management": {
                "Title": "Segment Definition",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "DefTypeName": "Def Type Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Segment Definition",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SegmentDefType": "Segment Def Type:*",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "definecomponents": {
            "management": {
                "Title": "Tax Component",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "TypesName": "Types Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Tax Component",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Typesname": "Type Name:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "dolly": {
            "management": {
                "Title": "Dolly",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Dolly",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "AssetName": "Asset Name:*",
                "DateOfCommission": "Date Of Commission:*",
                "NoOfAxles": "No Of Axles:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "manifestbook": {
            "management": {
                "Title": "Manifest Master",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "TransactionDate": "Transaction Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Manifest Master",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Document No.:*",
                "TransactionDate": "Document Date:*",
                "ManifestNoFrom": "Manifest No From:*",
                "ManifestNoTo": "Manifest No To:*",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },



        "visaapplication": {
            "management": {
                "Title": "Visa Application",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "PassportNo": "Passport No",
                    "Name": "Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Visa Application",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Name": "Name:*",
                "PassportNo": "Passport No:*",
                "TypeofVisa": "Type of Visa:*",
                "ApplicationDate": "Application Date:*",
                "ApplicationFee": "Visa Fee:*",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:"
            }
        },
        "visa": {
            "management": {
                "Title": "Visa",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "VisaNo": "Visa No",
                    "Name": "Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Visa",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Name": "Name:*",
                "PassportNo": "Passport No:*",
                "Sex": "Gender:*",
                "Place": "Place of Issue:*",
                "ValidFrom": "Valid From:*",
                "ValidTo": "Valid To:*",
                "TypeofVisa": "Type of Visa:*",
                "Category": "Category:*",
                "DateofBirth": "Date of Birth:*",
                "NoofEntry": "No of Entry:*",
                "Nationality": "Nationality:*",
                "VisaNo": "VisaNo:*",
                "FullNameDateOfBirth": "FullName Date Of Birth:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },


        "physicaldisability": {
            "management": {
                "Title": "Physical Disability",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Physical Disability",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },




        "qualification": {
            "management": {
                "Title": "Qualification",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Qualification",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },




        "registrationcategory": {
            "management": {
                "Title": "Registration Category",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Registration Category",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },




        "responsibility": {
            "management": {
                "Title": "Responsibility",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Responsibility",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },




        "salesdivision": {
            "management": {
                "Title": "Sales Division",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Sales Division",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },


        "containersize": {
            "management": {
                "Title": "Container Size",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Container Size",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "hiringmachines": {
            "management": {
                "Title": "Machines",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Machines",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },


        "containeractivity": {
            "management": {
                "Title": "Container Activity",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Container Activity",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "containerinfo": {
            "management": {
                "Title": "Container Info",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Container Info",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },
        "containerexportstatus": {
            "management": {
                "Title": "Container Export Status",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Container Export Status",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },
        "shippingline": {
            "management": {
                "Title": "Shipping Line",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Shipping Line",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },


        "storagereason": {
            "management": {
                "Title": "Storage Reason",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Storage Reason",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },


        "storagelocation": {
            "management": {
                "Title": "Storage Location",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Storage Location",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },


        "customertype": {
            "management": {
                "Title": "Customer Type",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "SalespersonName": "Sales Person Name ",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Customer Type",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "Salespersonname": "Sales Person Name:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "customergroup": {
            "management": {
                "Title": "Customer Group",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "SalespersonName": "Sales Person Name ",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Customer Group",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "Salespersonname": "Sales Person Name:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },


        "salutation": {
            "management": {
                "Title": "Salutation",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Salutation",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },




        "skills": {
            "management": {
                "Title": "Skills",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Skills",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },




        "stocktype": {
            "management": {
                "Title": "Stock Type",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Stock Type",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },
        "unitsetuommapping": {
            "management": {
                "Title": "UnitSet UOM Mapping",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "UnitSet UOM Mapping",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "UomCode": "Uom Code",
                "UomName": "Uom Name",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:"
            }
        },
        "taskdetails": {
            "management": {
                "Title": "Task Details",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Org Name",
                    "TaskdetailsNo": "Task Details No",
                    "TaskdetailsDate": "Task Details Date",
                    "JobCard": "Job Card",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Task Details",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "TaskDetailsNo": "Document No.:*",
                "TaskDetailsDate": "Document Date:*",
                "JobCardNo": "Job Card No:*",
                "Remarks": "Remarks:",
                "TaskCode": "Task Code",
                "TaskName": "Task Name",
                "ExpectedCompletionDate": "Expected Completion Date:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "manpowerdetails": {
            "management": {
                "Title": "Manpower Details",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Org Name",
                    "ManPowerdetailsNo": "Document No",
                    "ManPowerdetailsDate": "Document Date",
                    "JobCard": "Job Card",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Manpower Details",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "ManPowerDetailsNo": "Document No.:*",
                "ManPowerDetailsDate": "Document Date:*",
                "JobCardNo": "Job Card No:*",
                "Hrs": "Hrs",
                "Remarks": "Remarks:",
                "MechanicCode": "Mechanic Code",
                "MechanicName": "Mechanic Name",
                "taskDetailsNo": "Task Details No:",
                "taskId": "Task Code",
                "SkillCode": "Skill Code",
                "SkillName": "Skill Name",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "productiontaskdetails": {
            "management": {
                "Title": "Production Task Details",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Org Name",
                    "TaskdetailsNo": "Task Details No",
                    "TaskdetailsDate": "Task Details Date",
                    "JobCard": "Job Card",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Production Task Details",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "TaskDetailsNo": "Document No.:*",
                "TaskDetailsDate": "Document Date:*",
                "JobCardNo": "Job Card No:*",
                "Remarks": "Remarks:",
                "TaskCode": "Task Code",
                "TaskName": "Task Name",
                "ExpectedCompletionDate": "Expected Completion Date:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
        },

        "productionmanpowerdetails": {
            "management": {
                "Title": "Production Manpower Details",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Org Name",
                    "ManPowerdetailsNo": "Document No",
                    "ManPowerdetailsDate": "Document Date",
                    "JobCard": "Job Card",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Production Manpower Details",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "ManPowerDetailsNo": "Document No.:*",
                "ManPowerDetailsDate": "Document Date:*",
                "JobCardNo": "Job Card No:*",
                "Hrs": "Hrs",
                "Remarks": "Remarks:",
                "MechanicCode": "Mechanic Code",
                "MechanicName": "Mechanic Name",
                "taskDetailsNo": "Task Details No:",
                "taskId": "Task Code",
                "SkillCode": "Skill Code",
                "SkillName": "Skill Name",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
        },

        "productiontechnicalindent": {
            "management": {
                "Title": "Production Technical Indent",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "IndentNo": "Indent No",
                    "IndentDate": "Indent Date",
                    "JobCard": "Job Card No",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Production Technical Indent",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "JobCardNo": "Job Card No.:*",
                "IndentNo": "Document No.:*",
                "IndentDate": "Document Date:*",
                "reftype": "Reference Type:*",
                "prodIndentCode": "Reference No.:*",
                "Remarks": "Remarks:",
                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "UOMCode": "UOM",
                "UOMName": "UOM Name",
                "ExpDate": "Expiry Date",
                "Quantity": "Quantity",
                "ExeQuantity": "Executed Quantity",
                "BalQuantity": "Balance Quantity",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
        },

        "segmentstructure": {
            "management": {
                "Title": "Segment Structure",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Org Name",
                    "Code": "Segment Structure Code",
                    "Name": "Segment Structure Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Segment Structure",
                "SubTitle": "SubTitle",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "SegmentStructureCode": "Code",
                "SegmentStructureName": "Name:",
                "SegmentDefinitionCode": "Segment Definition Code",
                "SegmentDefinitionName": "Segment Definition Name",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "jobcard": {
            "management": {
                "Title": "Job Card",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "WorkinProgress": "Work in Progress",
                "PrintButton": "Print",
                "ViewButton": "View",
                "WaitingforSpareParts": "Waiting for SpareParts",
                "CloseJobCard": "Close JobCard",
                "WaitingforInspection": "Waiting for Inspection",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Org Name",
                    "JobCardNo": "Document No",
                    "JobCardDate": "Document Date",
                    "Unit": "Unit",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Job Card",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "TechnicalDivision": "Technical Division:*",
                "TechnicalManager": "Technical Manager:*",
                "Supervisor": "Supervisor:*",
                "JobCardNo": "Document No.:*",
                "JobCardDate": "Document Date:*",
                "TruckType": "Truck Type:*",
                "Unit": "Unit:*",
                "FirstTrailer": "First Trailer:*",
                "SecondTrailer": "Second Trailer:",
                "Dolly": "Dolly:",
                "JobCardDateOut": "JobCard Closing Date:",
                "TruckHead": "Truck Head:*",
                "ActualCounterReading": "Actual Counter Reading:*",
                "LogicalCounterReading": "Logical Counter Reading:*",
                "Observation1": "Observation1:",
                "Observation2": "Observation2:",
                "Observation3": "Observation3:",
                "Observation4": "Observation4:",
                "WorkShop": "Work Shop:*",
                "MaintenanceType": "Maintenance Type:",
                "Remarks": "Remarks:",
                "Seviority": "Seviority:*",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },


        "timezone": {
            "management": {
                "Title": "Time Zone",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Time Zone",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },


        "trailercategory": {
            "management": {
                "Title": "Trailer Category",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Trailer Category",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },



        "trailertype": {
            "management": {
                "Title": "Trailer Type",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Trailer Type",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },


        "trainingtype": {
            "management": {
                "Title": "Training Type",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Training Type",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },



        "tyrebatch": {
            "management": {
                "Title": "Tyre Batch",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Tyre Batch",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },



        "tyretype": {
            "management": {
                "Title": "Tyre Type",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Tyre Type",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },


        "tyrebrand": {
            "management": {
                "Title": "Tyre Brand",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Tyre Brand",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },


        "tyrefailure": {
            "management": {
                "Title": "Tyre Failure",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Tyre Failure",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },



        "tyrelocationtype": {
            "management": {
                "Title": "Tyre Location Type",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Tyre Location Type",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },


        "tyrepattern": {
            "management": {
                "Title": "Tyre Pattern",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Tyre Pattern",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },
        "tyreposition": {
            "management": {
                "Title": "Tyre Position",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Tyre Position",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "tyresize": {
            "management": {
                "Title": "Tyre Size",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Tyre Size",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },


        "tyretechnician": {
            "management": {
                "Title": "Tyre Technician",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Tyre Technician",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },


        "usergroup": {
            "management": {
                "Title": "User Group",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "User Group",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },



        "vacancytype": {
            "management": {
                "Title": "Vacancy Type",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Vacancy Type",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },


        "warehouse": {
            "management": {
                "Title": "WareHouse",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "WareHouse",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "SalesRegionName": "RegionName:",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "transporter": {
            "management": {
                "Title": "Transporter",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Transporter",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "PhoneNo": "PhoneNo:*",
                "Address": "Address:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
        },

        "salesperson": {
            "management": {
                "Title": "Sales Person",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Sales Person",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },


        "salesregion": {
            "management": {
                "Title": "Sales Region",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Sales Region",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },












        "commodity": {
            "management": {
                "Title": "Commodity",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Commodity",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "ItemCode": "Item Code:*",
                "UOMId": "UOM Name:*",
                "WeightInKG": "Weight In KG:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },



        "country": {
            "management": {
                "Title": "Country",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Organization",
                    "Code": "Code",
                    "Name": "Name",
                    "Currencyname": "Currency",
                    "Languagename": "Language",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Country",
                "SubTitle": "SubTitle",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Currencyname": "Currency:*",
                "Languagename": "Language:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },



        "city": {
            "management": {
                "Title": "City",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Org": "Organization",
                    "Code": "Code",
                    "Name": "Name",
                    "Country": "Country",
                    "State": "State",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "City",
                "SubTitle": "SubTitle",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Country": "Country:*",
                "State": "State:*",
                "Remarks": "Remarks",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "calendar": {
            "management": {
                "Title": "Calendar",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "Code": "Code",
                    "Name": "Name",
                    "Countryname": "Country",
                    "Statename": "State",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Calendar",
                "SubTitle": "SubTitle",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:",
                "Name": "Name:",
                "PeriodType": "Period Type:",
                "Year": "Year:",
                "FromMonth": "From Month:",
                "ToMonth": "To Month:",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "oKbutton": "OK",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "state": {
            "management": {
                "Title": "State",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Code": "State Code",
                    "Name": "State Name",
                    "Country": "Country",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "State",
                "SubTitle": "SubTitle",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "State Code:*",
                "Name": "State Name:*",
                "Country": "Country:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Last Updated By"
            }
        },





        "brand": {
            "management": {
                "Title": "Brand",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Orgname",
                    "Code": "Brand Code",
                    "Name": "Brand Name",
                    "MakeName": "Make Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Brand",
                "SubTitle": "SubTitle",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Makename": "Make Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Last Updated By"
            }
        },


        "currency": {
            "management": {
                "Title": "Currency",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Orgname",
                    "Code": "Code",
                    "Name": "Name",
                    "IsoName": "ISO Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Currency",
                "SubTitle": "SubTitle",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Isoname": "ISO Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Last Updated By"
            }
        },

        "driverinformation": {
            "management": {
                "Title": "Driver Information",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Organization",
                    "Code": "Code",
                    "Name": "Name",
                    "DOB": "Date of Birth",
                    "DateOfJoining": "Date of Joining",
                    "Citizenshipno": "Citizenship No",
                    "Licenseno": "License No",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Driver Information",
                "SubTitle": "SubTitle",
                "Orgname": "Org/SubOrg:*",
                "Unit": "Unit No:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "DOB": "Date of Birth:*",
                "DOJ": "Date of Joining:*",
                "Citizenshipno": "Citizenship No:*",
                "Issuedate": "Issue Date:*",
                "Licenseno": "Driving License No:*",
                "Validupto": "Valid Upto:*",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "drivershortages": {
            "management": {
                "Title": "Driver Shortages",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "DriverShortagesNo": "Driver Shortages No",
                    "DriverShortagesDate": "Date",
                    "Commodity": "Commodity",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Driver Shortages",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "drivershortagesno": "Document No.:*",
                "DriverShortagesDate": "Document Date:*",
                "DriverCode": "Driver Code:",
                "Route": "Route:",
                "Commodity": "Commodity:",
                "UOM": "UOM:",
                "Quantity": "Quantity:",
                "ManifestNo": "Manifest No:",
                "FuelNo": "Fuel No:",
                "UnitNo": "Unit No:",
                "PlateNo": "Plate No",
                "Price": "Price:",
                "Amount": "Amount:",
                "DriverName": "Driver Name:",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "locationtype": "Location Type",
                "Remarks": "Remarks:"
            }
        },

        "conaccountgroup": {
            "management": {
                "Title": "Consolidated Account Group",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "Code": "Code",
                    "Name": "Name",
                    "ShortName": "Short Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Consolidated Account Group",
                "PrintButton": "Print",
                "SubTitle": "SubTitle",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "ShortName": "Short Name:*",
                "AccountType": "Account Type:*",
                "ParentAccountGroup": "Parent Account Group:*",
                "ConDefinition": "Consolidated Definition:*",
                "Level": "Level:*",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "ShortName": "Short Name:",
                "AccountType": "Account Type:",
                "ParentAccountGroup": "Parent Account Group::"
            }
        },
        "coaaccountgroup": {
            "management": {
                "Title": "COA Account Group",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "Code": "Code",
                    "Name": "Name",
                    "ShortName": "Short Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "COA Account Group",
                "PrintButton": "Print",
                "SubTitle": "SubTitle",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "ShortName": "Short Name:",
                "AccountType": "Account Type:*",
                "COADefinition": "COA Definition:*",
                "ParentAccountGroup": "Parent Account Group:*",
                "CoaDefinition": "Definition:*",
                "Level": "Level:*",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "ShortName": "Short Name:",
                "AccountType": "Account Type:",
                "ParentAccountGroup": "Parent Account Group:",
                "CoaDefinition": "Definition::"
            }
        },


        "accountgroup": {
            "management": {
                "Title": "Account Group",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "TreeviewButton": "Treeview",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "Code": "Code",
                    "Name": "Name",
                    "ShortName": "Short Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Account Group",
                "PrintButton": "Print",
                "SubTitle": "SubTitle",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "ShortName": "Short Name:",
                "AccountType": "Account Type:*",
                "ParentAccountGroup": "Parent Account Group:*",
                "Level": "Level:*",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "ShortName": "Short Name:",
                "AccountType": "Account Type:",
                "ParentAccountGroup": "Parent Account Group:"
            }
        },

        "organisation": {
            "management": {
                "Title": "Organization",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Tab1": "General",
                    "Tab2": "Address",
                    "Tab3": "Logo",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks:",
                    "TimeZone": "Time Zone:",
                    "Language": "Language:",
                    "OrgType": "Organisation Type",
                    "Currency": "Currency",
                    "OrgSubOrgCode": "Org/SubOrg Code:",
                    "OrgSubOrgName": "Org/SubOrg Name:",
                    "ParentOrgId": "Parent Organisation:",
                    "ParentOrgName": "Parent Organization Name",
                    "VatNo": "VAT No.:",
                    "RegistrationNo": "Registration No.:",
                    "TaxIdNo": "TaxId No.:",
                    "RegCityId": "Reg. City:",
                    "RegDate": "Reg. Date:",
                    "TimeApplicability": "Time Applicability:",
                    "Address1": "Address1:",
                    "Address2": "Address2:",
                    "Country": "Country:",
                    "State": "State:",
                    "City": "City:",
                    "Zipcode": "Zip Code:",
                    "PhoneNo1": "Phone No.1:",
                    "PhoneNo2": "Phone No.2:",
                    "Mobile": "Mobile:",
                    "Fax": "Fax:",
                    "Email": "Email:",
                    "Website": "Website:",
                    "Logo": "Logo:",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Organization",
                "SubTitle": "SubTitle",
                "Tab1": "General",
                "Tab2": "Address",
                "Tab3": "Logo",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "TimeZone": "Time Zone:*",
                "TimeZoneName": "Time Zone Name:*",
                "Language": "Language:*",
                "LanguageName": "Language Name:*",
                "OrgType": "Org type:*",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "ParentOrgId": "Parent Organisation:*",
                "ParentOrgName": "Parent Organisation Name:",
                "VatNo": "VAT No:*",
                "RegistrationNo": "Registration No:*",
                "TaxIdNo": "TaxId No:*",
                "RegCityId": "Reg. City Id:*",
                "RegDate": "Reg. Date:*",
                "CurrencyId": "Currency:*",
                "DecimalFormat": "Decimal Format:*",
                "TimeApplicability": "Time Applicability:*",
                "Address1": "Address1:*",
                "Address2": "Address2:",
                "Country": "Country:*",
                "CountryName": "Country Name:*",
                "State": "State:*",
                "StateName": "State Name:*",
                "City": "City:*",
                "CityName": "City Name:*",
                "Zipcode": "Zip Code:*",
                "PhoneNo1": "PhoneNo1:*",
                "PhoneNo2": "PhoneNo2:",
                "Mobile": "Mobile:*",
                "Fax": "Fax:",
                "Email": "Email:*",
                "Website": "Website:",
                "NSSIFEmployer": "NSSIF Employer:",
                "Logo": "Logo:*",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "generalpayment": {
            "management": {
                "Title": "General Payments",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Post",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "ReverseButton": "Reverse",
                "RePostButton": "RePost",
                "GridHeaders": {
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "FavouringPayee": "Favouring Payee",
                    "ReferenceNo": "Reference No",
                    "Amount": "Amount",
                    "Bank": "Bank",
                    "DriverCardNo": "DriverCardNo",
                    "PaymentType": "Payment Type",
                    "PaymentMode": "Payment Mode",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "General Payments",
                "PrintButton": "Print",
                "SubTitle": "SubTitle",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "PaymentRequestType": "Ref. Type:",
                "PaymentRequestNo": "Ref. No.:",
                "DocumentNo": "Document No.:",
                "DocumentDate": "Document Date:",
                "PaymentType": "Payment Type:",
                "ModePayment": "Mode Payment:",
                "BankCashCode": "Bank/Cash Code:",
                "BankCashName": "Bank/Cash Name:",
                "InstNo": "Instrument No.:",
                "InstDate": "Instrument Date:",
                "ChequeType": "Cheque Type:",
                "FavoringPayee": "Favoring Payee:",
                "Amount": "Amount:",
                "TotalAmount": "Total Amount:",
                "IntraBranchTransaction": "Is IBT:",
                "IntraBranch": "Intra Branch:",
                "PrettyCashReplenishment": "PC Replenishment:",
                "PostingDate": "Posting Date:",
                "Currency": "Currency:",
                "ExchangeRate": "Exchange Rate:",
                "ExchTransDate": "Exch. Trans. Date:",
                "Narration": "Narration::",

                "Code": "Code",
                "Name": "Name",
                "Debit": "Debit",
                "Credit": "Credit",
                "Segments": "Cost Center/Segment",
                "NarrationDet": "Narration",

                "ShortName": "Short Name:*",
                "AccountType": "Account Type:*",
                "ParentAccountGroup": "Parent Account Group:*",
                "Level": "Level:*",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By",

                "TabTitle1": "General",
                "TabTitle2": "Details"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "ShortName": "Short Name:",
                "AccountType": "Account Type:",
                "ParentAccountGroup": "Parent Account Group::"
            }
        },


        "employeepayment": {
            "management": {
                "Title": "Employee Payments",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Post",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "FavouringPayee": "Favouring Payee",
                    "ReferenceNo": "Reference No",
                    "Amount": "Amount",
                    "Bank": "Bank",
                    "EmployeeCode": "EmployeeCode",
                    "PaymentType": "Payment Type",
                    "EmployeeName": "Employee Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Employee Payments",
                "PrintButton": "Print",
                "SubTitle": "SubTitle",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "PaymentRequestType": "Ref. Type:",
                "PaymentRequestNo": "Ref. No.:",
                "DocumentNo": "Document No.:",
                "DocumentDate": "Document Date:",
                "PaymentType": "Payment Type:",
                "ModePayment": "Mode Payment:",
                "BankCashCode": "Bank/Cash Code:",
                "BankCashName": "Bank/Cash Name:",
                "InstNo": "Instrument No.:",
                "InstDate": "Instrument Date:",
                "ChequeType": "Cheque Type:",
                "FavoringPayee": "Favoring Payee:",
                "Amount": "Amount:",
                "TotalAmount": "Total Amount:",
                "IntraBranchTransaction": "Is IBT:",
                "IntraBranch": "Intra Branch:",
                "PrettyCashReplenishment": "PC Replenishment:",
                "PostingDate": "Posting Date:",
                "Currency": "Currency:",
                "ExchangeRate": "Exchange Rate:",
                "ExchTransDate": "Exch. Trans. Date:",

                "EmployeeCode": "Employee Code:",
                "ControlAccountId": "Control Account Code:",
                "AdvanceAmount": "Debit Amount:",


                "Narration": "Narration:",

                "Code": "Code",
                "Name": "Name",
                "Debit": "Debit",
                "Credit": "Credit",
                "Segments": "Segments",
                "NarrationDet": "Narration",

                "ShortName": "Short Name:*",
                "AccountType": "Account Type:*",
                "ParentAccountGroup": "Parent Account Group:*",
                "Level": "Level:*",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By",

                "TabTitle1": "General",
                "TabTitle2": "Details"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "ShortName": "Short Name:",
                "AccountType": "Account Type:",
                "ParentAccountGroup": "Parent Account Group::"
            }
        },


        "bankpayment": {
            "management": {
                "Title": "Bank Payments",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Post",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "DocumentNo": "Document No",
                    "Bank": "Bank",
                    "DriverCardNo": "Driver Card No",
                    "PaymentType": "Payment Type",
                    "PaymentMode": "Payment Mode",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Bank Payments",
                "PrintButton": "Print",
                "SubTitle": "SubTitle",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "PaymentRequestType": "Ref. Type:",
                "PaymentRequestNo": "Ref. No.:",
                "DocumentNo": "Document No.:",
                "DocumentDate": "Document Date:",
                "PaymentType": "Payment Type:",
                "ModePayment": "Mode Payment:",
                "BankCode": "Bank Code:",
                "BankName": "Bank Name:",
                "InstNo": "Instrument No.:",
                "InstDate": "Instrument Date:",
                "ChequeType": "Cheque Type:",
                "FavoringPayee": "Favoring Payee:",
                "Amount": "Amount:",
                "TotalAmount": "Total Amount:",
                "IntraBranchTransaction": "Is IBT:",
                "IntraBranch": "Intra Branch:",
                "PrettyCashReplenishment": "PC Replenishment:",
                "PostingDate": "Posting Date:",
                "Currency": "Currency:",
                "ExchangeRate": "Exchange Rate:",
                "ExchTransDate": "Exch. Trans. Date:",
                "Narration": "Narration::",

                "Code": "Code",
                "Name": "Name",
                "Debit": "Debit",
                "Credit": "Credit",
                "Segments": "Segments",
                "NarrationDet": "Narration",

                "ShortName": "Short Name:*",
                "AccountType": "Account Type:*",
                "ParentAccountGroup": "Parent Account Group:*",
                "Level": "Level:*",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By",

                "TabTitle1": "General",
                "TabTitle2": "Details"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "ShortName": "Short Name:",
                "AccountType": "Account Type:",
                "ParentAccountGroup": "Parent Account Group::"
            }
        },


        "cashpayment": {
            "management": {
                "Title": "Cash Payments",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Post",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "DocumentNo": "Document No",
                    "Bank": "Bank",
                    "DriverCardNo": "Driver Card No",
                    "PaymentType": "Payment Type",
                    "PaymentMode": "Payment Mode",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Cash Payments",
                "PrintButton": "Print",
                "SubTitle": "SubTitle",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "PaymentRequestType": "Ref. Type:",
                "PaymentRequestNo": "Ref. No.:",
                "DocumentNo": "Document No.:",
                "DocumentDate": "Document Date:",
                "PaymentType": "Payment Type:",
                "ModePayment": "Mode Payment:",
                "CashCode": "Cash Account Code:",
                "CashName": "Cash Account Name:",
                "InstNo": "Inst. No.:",
                "InstDate": "Inst. Date:",
                "ChequeType": "Cheque Type:",
                "FavoringPayee": "Favoring Payee:",
                "Amount": "Amount:",
                "TotalAmount": "Total Amount:",
                "IntraBranchTransaction": "Is IBT:",
                "IntraBranch": "Intra Branch:",
                "PrettyCashReplenishment": "PC Replenishment:",
                "PostingDate": "Posting Date:",
                "Currency": "Currency:",
                "ExchangeRate": "Exchange Rate:",
                "ExchTransDate": "Exch. Trans. Date:",
                "Narration": "Narration::",

                "Code": "Code",
                "Name": "Name",
                "Debit": "Debit",
                "Credit": "Credit",
                "Segments": "Segments",
                "NarrationDet": "Narration",

                "ShortName": "Short Name:*",
                "AccountType": "Account Type:*",
                "ParentAccountGroup": "Parent Account Group:*",
                "Level": "Level:*",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By",

                "TabTitle1": "General",
                "TabTitle2": "Details"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "ShortName": "ShortName:",
                "AccountType": "Account Type:",
                "ParentAccountGroup": "Parent Account Group::"
            }
        },


        "vendorpayment": {
            "management": {
                "Title": "Vendor Payment",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Post",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "ReverseButton": "Reverse",
                "RePostButton": "RePost",
                "GridHeaders": {
                    "Orgname": "Organization",
                    "Bank": "Bank",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "SLNo": "SLNo",
                    "VendorName": "Vendor Name",
                    "Amount": "Amount",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Vendor Payment",
                "PrintButton": "Print",
                "SubTitle": "SubTitle",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "VendorPaymentNo": "Document No.:*",
                "VendorPaymentDate": "Document Date:*",
                "VendorCode": "Vendor Code:*",
                "VendorName": "Vendor Name:*",
                "PaymentType": "Payment Type:*",
                "PaymentMode": "Payment Mode:*",
                "Amount": "Amount:*",
                "Currency": "Currency:*",
                "ExchangeRate": "Exchange Rate:*",
                "ExchTransDate": "ExchTrans Date:*",
                "PostingDate": "Posting Date:*",
                "BankCashCode": "Bank/Cash Code:*",
                "BankCashName": "Bank Cash Name:*",
                "InstNo": "Instrument No:*",
                "InstDate": "Instrument Date:*",
                "AdvanceApplicability": "Advance Applicability:",
                "AdvanceAccount": "Advance Account:*",
                "AdvanceAmount": "Advance Amount:*",
                "BillOfExchangeId": "Bill Of Exchange:*",
                "Narration": "Narration:*",

                "Level": "Level:*",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "ShortName": "ShortName:",
                "AccountType": "Account Type:",
                "ParentAccountGroup": "Parent Account Group::"
            }
        },

        "billofexchange": {
            "management": {
                "Title": "Bill Of Exchange",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Post",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Bill Of Exchange",
                "PrintButton": "Print",
                "SubTitle": "SubTitle",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "FromDate": "From Date:*",
                "ToDate": "To Maturity Date:*",
                "Type": "Type:*",
                "PartyCode": "Party Name:*",
                "Amount": "Amount:*",
                "BankAccountNo": "Bank Account Name:*",
                "BillOfExchangeAcceptedOn": "Bill Of Exchange Accepted On:*",
                "BankRefNo": "BOE Ref No:*",
                "Currency": "Currency:",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
        },

        "audittransaction": {
            "management": {
                "Title": "Audit Transaction",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Post",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Trans No",
                    "DocumentDate": "Trans Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Audit Transaction",
                "PrintButton": "Print",
                "SubTitle": "SubTitle",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Trans No.:*",
                "DocumentDate": "Trans Date:*",
                "Module": "Module:*",
                "FeatureList": "Feature List:*",
                "AuditReport": "Audit Report:*",
                "Comment": "Comment:*",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
        },

        "transactionamendment": {
            "management": {
                "Title": "Transaction Amendment",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Post",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Trans No",
                    "DocumentDate": "Trans Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Transaction Amendment",
                "PrintButton": "Print",
                "SubTitle": "SubTitle",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Trans No.:*",
                "DocumentDate": "Trans Date:*",
                "Module": "Module:*",
                "FeatureList": "Feature List:*",
                "WantTo": "Want To:*",
                "Reason": "Reason:",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
        },

        "transwisesegmentupdate": {
            "management": {
                "Title": "Trans Wise Segment Update",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Post",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Trans No",
                    "DocumentDate": "Trans Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Trans Wise Segment Update",
                "PrintButton": "Print",
                "SubTitle": "SubTitle",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Trans No.:*",
                "DocumentDate": "Trans Date:*",
                "Module": "Module:*",
                "FeatureList": "Feature List:*",

                "sno": "SNo",
                "Code": "Segment Code",
                "Name": "Segment Name",
                "debitamount": "Debit Amount",
                "creditamount": "Credit Amount",
                "Remarks": "Remarks",
                "Segment": "Segment",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
        },

        "travelrequest": {
            "management": {
                "Title": "Travel Request",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Post",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "EmployeeName": "Employee Name",
                    "Date": "Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Travel Request",
                "PrintButton": "Print",
                "SubTitle": "SubTitle",
                "TabTitle1": "General",
                "TabTitle2": "Expenses",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "EmployeeName": "Employee Name.:*",
                "Date": "Date:*",
                "Position": "Position:*",
                "Department": "Department:*",
                "ToWhere": "To Where:*",
                "PurposeOfMission": "Purpose Of Mission:*",
                "MissionStartDate": "Mission Start Date:*",
                "MissionEndDate": "Mission End Date:*",
                "PersonActJob": "Person Acting in Job Whilst Away:*",
                "PerDay": "Perdiem:*",
                "MealAllowance": "Meal Allowance:*",
                "TravelMode": "Travel Mode:*",
                "TravelClass": "Travel Class:*",
                "Accommodation": "Accommodation:*",
                "Visa": "Visa:*",
                "VIPLounge": "VIP Lounge:*",
                "PROfficer": "PR Officer:*",
                "HRComments": "HR Comments:*",
                "NoOfDays": "No Of Days:*",
                "NoOfNights": "No Of Nights:*",
                "PerdiemAmount": "Perdiem Amount:*",
                "MealAllowanceAmount": "MealAllowance Amount:*",
                "AccommodationAmount": "Accommodation Amount:*",
                "TransportationAmount": "Transportation Amount:*",
                "VisaFees": "Visa Fees:*",
                "VipLoungeFees": "Vip Lounge Fees:*",
                "TotalAmount": "Total Amount:*",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
        },


        "laptopreceiptacknowledgement": {
            "management": {
                "Title": "Receipt Acknowledgement",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Receipt Acknowledgement",
                "PrintButton": "Print",
                "SubTitle": "SubTitle",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "EmployeeCode": "Employee Code.:*",
                "EmployeeName": "Employee Name.:*",
                "Department": "Department:*",
                "Designation": "Designation:*",
                "LaptopMark": "Laptop Mark:*",
                "Mode": "Mode:*",
                "SlNo": "SlNo:*",
                "ManufacturingDate": "Manufacturing Date:*",
                "PossessionDate": "Possession Date:*",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
        },


        "medicalconsultation": {
            "management": {
                "Title": "Medical Consultation",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Medical Consultation",
                "PrintButton": "Print",
                "SubTitle": "SubTitle",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "EmployeeCode": "Employee Code.:*",
                "EmployeeName": "Employee Name.:*",
                "Position": "Position:*",
                "Department": "Department:*",
                "ConsultationType": "Consultation Type:*",
                "HospitalName": "Hospital Name:*",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
        },

        "ticketbookinginformation": {
            "management": {
                "Title": "Ticket Booking Information",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Ticket Booking Information",
                "PrintButton": "Print",
                "SubTitle": "SubTitle",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "TicketNo": "Ticket No:*",
                "PassengerName": "Passenger Name:*",
                "VendorCode": "Vendor Code:*",
                "VendorName": "Vendor Name:*",
                "Origin": "Origin:*",
                "Destination": "Destination:*",
                "TicketValue": "Ticket Value:*",
                "PONo": "PO No:*",
                "TripType": "Trip Type:*",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
        },

        "flourcontrol": {
            "management": {
                "Title": "Flour Control",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Flour Control",
                "PrintButton": "Print",
                "SubTitle": "SubTitle",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "FlourType": "Flour Type:*",
                "Start": "Start:*",
                "Finish": "Finish:*",
                "TotalBags": "Total Bags:*",
                "LostBags": "Lost Bags:*",
                "GoodBags": "Good Bags:*",
                "MillCode": "Mill Code:*",
                "MillerName": "Miller Name:*",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
        },

        "brancontrol": {
            "management": {
                "Title": "Bran Control",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Bran Control",
                "PrintButton": "Print",
                "SubTitle": "SubTitle",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "Start": "Start:*",
                "Finish": "Finish:*",
                "TotalBags": "Total Bags:*",
                "LostBags": "Lost Bags:*",
                "GoodBags": "Good Bags:*",
                "RemovalAge": "Removal Age:*",
                "MillCode": "Mill Code:*",
                "MillerName": "Miller Name:*",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
        },

        "bageweightregistration": {
            "management": {
                "Title": "Bags Weight Registration",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Bags Weight Registration",
                "PrintButton": "Print",
                "SubTitle": "SubTitle",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "Shift": "Shift.:*",
                "MillerName": "Miller Name:*",
                "Hour": "Hour:*",
                "Flour": "Flour:*",
                "Bran": "Bran:*",
                "RemovalAge": "Removal Age:*",
                "Waste": "Waste:*",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
        },

        "flourdistraction": {
            "management": {
                "Title": "Flour Distraction",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Flour Distraction",
                "PrintButton": "Print",
                "SubTitle": "SubTitle",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "Hour": "Hour:*",
                "NoOfBags": "NoOfBags.:*",
                "Bran": "Bran:*",
                "RemovalAge": "Removal Age:*",
                "Waste": "Waste:*",
                "Tons": "Tons:*",
                "ExtractionRate": "Extraction Rate:*",
                "BatchCode": "Batch Code:*",
                "FlourBrandCode": "Flour Brand Code:*",
                "SoftWheatV3": "Soft Wheat V3:*",
                "HardWheatV4": "Hard Wheat V4:*",
                "HumidityB1": "Humidity B1:*",
                "HumidityFlour": "Humidity Flour:*",
                "B1Wheat": "B1 Wheat:*",
                "StartIndexFlour": "Start Index Flour:*",
                "EndIndexFlour": "End Index Flour:*",
                "BadFlourBags": "Bad Flour Bags:*",
                "GoodFlourBags": "Good Flour Bags:*",
                "BranStartIndex": "Bran Start Index:*",
                "BranEndIndex": "Bran End Index:*",
                "StopTime": "Stop Time:*",
                "Reason": "Reason:*",
                "BagWeightFlour": "Bag Weight Flour:*",
                "BagWeightBran": "Bag Weight Bran:*",
                "BagWeightRemovalAge": "Bag Weight Removal Age:*",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
        },

        "flourprodjobcard": {
            "management": {
                "Title": "Flour Prod Job Card",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Flour Prod Job Card",
                "PrintButton": "Print",
                "SubTitle": "SubTitle",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "MillCode": "Mill Code:*",
                "MillName": "Mill Name.:*",
                "Technician": "Technician:*",
                "JobCardClosingDate": "Job Card Closing Date:*",
                "Equipment": "Equipment:*",
                "TimeTaken": "Time Taken For Fixing Problem(Hrs):*",
                "Observation1": "Observation 1:*",
                "Observation2": "Observation 2:*",
                "Observation3": "Observation 3:*",
                "Observation4": "Observation 4:*",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
        },

        "customerreceipt": {
            "management": {
                "Title": "Customer Receipt",
                "MutipleCustomerReceiptTitle": "Multiple Customer Receipt",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ReverseButton": "Reverse",
                "RePostButton": "RePost",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Organization",
                    "Bank": "Bank",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "CustomerName": "Customer Name",
                    "Amount": "Amount",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Customer Receipt",
                "MutipleCustomerReceiptTitle": "Multiple Customer Receipt",
                "PrintButton": "Print",
                "SubTitle": "SubTitle",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "TabTitle3": "Account Details",
                "TabTitle4": "Customer Details",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "CustomerReceiptNo": "Document No.:*",
                "CustomerReceiptDate": "Document Date:*",
                "PaymentType": "Payment Type:*",
                "PaymentMode": "Payment Mode:*",
                "Amount": "Amount:*",
                "Currency": "Currency:*",
                "ExchangeRate": "Exchange Rate:*",
                "ExchTransDate": "ExchTrans Date:*",
                "PostingDate": "Posting Date:*",
                "BankCashCode": "Bank/Cash Code:*",
                "BankCashName": "Bank Cash Name:*",
                "InstNo": "Instrument No:*",
                "InstDate": "Instrument Date:*",
                "AdvanceApplicability": "Advance Applicability:*",
                "AdvanceAccount": "Advance Account:*",
                "AdvanceAmount": "Advance Amount:*",
                "BillOfExchangeId": "Bill Of Exchange:*",
                "Narration": "Narration:*",

                "CustomerCode": "Customer Code",
                "CustomerName": "Customer Name",

                "Code": "Account Code",
                "Name": "Account Name",
                "DebitAmountGrd": "Debit Amount",
                "CreditAmount": "Credit Amount",
                "CreditAmountGrd": "Credit Amount",
                "Remarks": "Remarks",

                "CustomerCreditAmount": "Customer Credit Amount",
                "Level": "Level:*",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "ShortName": "ShortName:",
                "AccountType": "Account Type:",
                "ParentAccountGroup": "Parent Account Group::"
            }
        },
        "vendorreceipt": {
            "management": {
                "Title": "Vendor Receipt",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "ReverseButton": "Reverse",
                "RePostButton": "RePost",
                "GridHeaders": {
                    "Orgname": "Organization",
                    "Bank": "Bank",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "VendorName": "Vendor Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Vendor Receipt",
                "PrintButton": "Print",
                "SubTitle": "SubTitle",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "TabTitle3": "Account Details",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "VendorReceiptNo": "Document No.:*",
                "VendorReceiptDate": "Document Date:*",
                "VendorCode": "Vendor Code:*",
                "VendorName": "Vendor Name:*",
                "PaymentType": "Payment Type:*",
                "PaymentMode": "Payment Mode:*",
                "Amount": "Amount:*",
                "Currency": "Currency:*",
                "ExchangeRate": "Exchange Rate:*",
                "ExchTransDate": "ExchTrans Date:*",
                "PostingDate": "Posting Date:*",
                "BankCashCode": "Bank/Cash Code:*",
                "BankCashName": "Bank Cash Name:*",
                "InstNo": "Instrument No:*",
                "InstDate": "Instrument Date:*",
                "AdvanceApplicability": "Advance Applicability:*",
                "AdvanceAccount": "Advance Account:*",
                "AdvanceAmount": "Advance Amount:*",
                "Narration": "Narration:*",

                "Code": "Account Code",
                "Name": "Account Name",
                "DebitAmountGrd": "Debit Amount",
                "CreditAmountGrd": "Credit Amount",
                "Remarks": "Remarks",

                "CreditAmount": "Credit Amount",
                "Level": "Level:*",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "ShortName": "ShortName:",
                "AccountType": "Account Type:",
                "ParentAccountGroup": "Parent Account Group::"
            }
        },
        "generalreceipt": {
            "management": {
                "Title": "General Receipt",
                "SubTitle": "General",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "ReverseButton": "Reverse",
                "RePostButton": "RePost",
                "GridHeaders": {
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "FavouringPayee": "Favouring Payee",
                    "Amount": "Amount",
                    "Bank": "Bank",
                    "DriverCardNo": "Driver Card No",
                    "ReceiptType": "Receipt Type",
                    "ReceiptMode": "Receipt Mode",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "General Receipt",
                "PrintButton": "Print",
                "CancelButton": "Back",
                "TabTitle1": "General",
                "TabTitle2": "Receipt",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "PostingDate": "Posting Date:*",
                "DriverCardNo": "Driver Card No:*",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "ReceiptType": "Receipt Type:*",
                "Mode": "Receipt Mode:*",
                "BankCashCode": "Bank/Cash Code:*",
                "BankCashName": "Bank/Cash Name:*",
                "InstrumentNo": "Instrument No:*",
                "InstrumentDate": "Instrument Date:*",
                "Currency": "Currency:*",
                "FavouringPayee": "Favouring Payee:*",
                "PaymentRequestType": "Ref Type:*",
                "PaymentRequestNo": "Ref No:*",
                "Amount": "Amount:*",
                "TotalAmount": "Base Amount:*",
                "IntraBranchTransaction": "is IBT:",
                "IntraBranch": "Intra Branch:",
                "ExchangeRate": "Exchange Rate:",
                "ExchangeDate": "Exchange Date:",
                "Narration": "Narration:*",

                "GeneralPayment": "Transfer Payment No:*",
                "Code": "Account Code",
                "Name": "Account Name",
                "Debit": "Debit",
                "Credit": "Credit",
                "Segments": "CostCenter/Segment",
                "Narration1": "Narration",


                "Level": "Level:*",
                "SaveButton": "Save",
                "BacktotheList": "Back",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "ShortName": "Short Name:",
                "AccountType": "Account Type:",
                "ParentAccountGroup": "Parent Account Group::"
            }
        },

        "employeereceipt": {
            "management": {
                "Title": "Employee Receipt",
                "SubTitle": "General",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Post",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ReverseButton": "Reverse",
                "RePostButton": "RePost",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "FavouringPayee": "Favouring Payee",
                    "Amount": "Amount",
                    "Bank": "Bank",
                    "DriverCardNo": "Driver Card No",
                    "ReceiptType": "Receipt Type",
                    "EmployeeName": "Employee Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Employee Receipt",
                "PrintButton": "Print",
                "CancelButton": "Back",
                "TabTitle1": "General",
                "TabTitle2": "Receipt",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "PostingDate": "Posting Date:*",
                "DriverCardNo": "Driver Card No:*",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "ReceiptType": "Receipt Type:*",
                "Mode": "Receipt Mode:*",
                "BankCashCode": "Bank/Cash Code:*",
                "BankCashName": "Bank/Cash Name:*",
                "InstrumentNo": "Instrument No:*",
                "InstrumentDate": "Instrument Date:*",
                "Currency": "Currency:*",
                "FavouringPayee": "Favouring Payee:*",
                "PaymentRequestType": "Ref Type:*",
                "PaymentRequestNo": "Ref No:*",
                "Amount": "Amount:*",
                "TotalAmount": "Base Amount:*",
                "IntraBranchTransaction": "is IBT:",
                "IntraBranch": "Intra Branch:",
                "ExchangeRate": "Exchange Rate:",
                "ExchangeDate": "Exchange Date:",
                "Narration": "Narration:*",

                "EmployeeCode": "Employee Code:",
                "ControlAccountId": "Control Account Code:",
                "AdvanceAmount": "Credit Amount:",

                "GeneralPayment": "Transfer Payment No:*",
                "Code": "Account Code",
                "Name": "Account Name",
                "Debit": "Debit",
                "Credit": "Credit",
                "Segments": "Segments",
                "Narration1": "Narration",


                "Level": "Level:*",
                "SaveButton": "Save",
                "BacktotheList": "Back",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "ShortName": "Short Name:",
                "AccountType": "Account Type:",
                "ParentAccountGroup": "Parent Account Group::"
            }
        },


        "bankreceipt": {
            "management": {
                "Title": "Bank Receipt",
                "SubTitle": "General",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Post",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "DocumentNo": "Document No",
                    "Bank": "Bank",
                    "DriverCardNo": "Driver Card No",
                    "ReceiptType": "Receipt Type",
                    "ReceiptMode": "Receipt Mode",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Bank Receipt",
                "PrintButton": "Print",
                "CancelButton": "Back",
                "TabTitle1": "General",
                "TabTitle2": "Receipt",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "PostingDate": "Posting Date:*",
                "DriverCardNo": "Driver Card No:*",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "ReceiptType": "Receipt Type:*",
                "Mode": "Receipt Mode:*",
                "BankCode": "Bank Code:*",
                "BankName": "Bank Name:*",
                "InstrumentNo": "Instrument No:*",
                "InstrumentDate": "Instrument Date:*",
                "Currency": "Currency:*",
                "FavouringPayee": "Favouring Payee:*",
                "PaymentRequestType": "Ref Type:*",
                "PaymentRequestNo": "Ref No:*",
                "Amount": "Amount:*",
                "TotalAmount": "Base Amount:*",
                "IntraBranchTransaction": "is IBT:",
                "IntraBranch": "Intra Branch:",
                "ExchangeRate": "Exchange Rate:",
                "ExchangeDate": "Exchange Date:",
                "Narration": "Narration:*",


                "Code": "Account Code",
                "Name": "Account Name",
                "Debit": "Debit",
                "Credit": "Credit",
                "Segments": "Segments",
                "Narration1": "Narration",


                "Level": "Level:*",
                "SaveButton": "Save",
                "BacktotheList": "Back",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "ShortName": "ShortName:",
                "AccountType": "Account Type:",
                "ParentAccountGroup": "Parent Account Group::"
            }
        },

        "cashreceipt": {
            "management": {
                "Title": "Cash Receipt",
                "SubTitle": "General",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Post",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "DocumentNo": "Document No",
                    "Cash": "Cash Account",
                    "DriverCardNo": "Driver Card No",
                    "ReceiptType": "Receipt Type",
                    "ReceiptMode": "Receipt Mode",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Cash Receipt",
                "PrintButton": "Print",
                "CancelButton": "Back",
                "TabTitle1": "General",
                "TabTitle2": "Receipt",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "PostingDate": "Posting Date:*",
                "DriverCardNo": "Driver Card No:*",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "ReceiptType": "Receipt Type:*",
                "Mode": "Receipt Mode:*",
                "CashCode": "Cash Account Code:*",
                "CashName": "Cash Account Name:*",
                "InstrumentNo": "Instrument No:*",
                "InstrumentDate": "Instrument Date:*",
                "Currency": "Currency:*",
                "FavouringPayee": "Favouring Payee:*",
                "PaymentRequestType": "Ref Type:*",
                "PaymentRequestNo": "Ref No:*",
                "Amount": "Amount:*",
                "TotalAmount": "Base Amount:*",
                "IntraBranchTransaction": "is IBT:",
                "IntraBranch": "Intra Branch:",
                "ExchangeRate": "Exchange Rate:",
                "ExchangeDate": "Exchange Date:",
                "Narration": "Narration:*",


                "Code": "Account Code",
                "Name": "Account Name",
                "Debit": "Debit",
                "Credit": "Credit",
                "Segments": "Segments",
                "Narration1": "Narration",


                "Level": "Level:*",
                "SaveButton": "Save",
                "BacktotheList": "Back",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "ShortName": "Short Name:",
                "AccountType": "Account Type:",
                "ParentAccountGroup": "Parent Account Group::"
            }
        },

        "financeaccount": {
            "management": {
                "Title": "Account",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "TreeviewButton": "Treeview",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "Code": "Code",
                    "Name": "Name",
                    "ShortName": "Short Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Account",
                "PrintButton": "Print",
                "SubTitle": "SubTitle",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "ShortName": "Short Name:*",
                "intraBranchId": "Intra Branch Name:*",
                "AccountType": "Account Type:*",
                "AccountGroup": "Account Group:*",
                "COADefinition": "COA Definition",
                "ConsolidateAccountGroup": "Consolidate Account Group:",
                "ControlAccount": "Control Account:",
                "CostCenterApplicability": "Cost Center Applicability:",
                "ControlAccountType": "Control Account Type:*",
                "Class": "Class:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "ShortName": "Short Name:",
                "AccountType": "Account Type:",
                "ParentAccountGroup": "Parent Account Group:"
            }
        },

        "bank": {
            "management": {
                "Title": "Bank",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Code": "Code",
                    "Name": "Name",
                    "Country": "Country",
                    "State": "State",
                    "City": "City",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Bank",
                "SubTitle": "SubTitle",
                "Code": "Code:*",
                "Name": "Name:*",
                "ShortName": "Short Name:*",
                "ContactPerson": "Contact Person:*",
                "Address": "Address:*",
                "Country": "Country:*",
                "CountryName": "Country Name:*",
                "State": "State:*",
                "StateName": "State Name:*",
                "City": "City:*",
                "CityName": "City Name:*",
                "Pincode": "Pin Code:*",
                "PhoneNo": "Phone No:*",
                "Mobile": "Mobile No:*",
                "Fax": "Fax:",
                "Email": "Email:*",
                "Website": "Website:",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Last Updated By"
            }
        },



        "bankcashaccount": {
            "management": {
                "Title": "Bank Account",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Code": "Code",
                    "Name": "Name",
                    "Country": "Country",
                    "State": "State",
                    "City": "City",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Bank Account",
                "SubTitle": "SubTitle",
                "Code": "Code:*",
                "Name": "Name:*",
                "Bank": "Bank:*",
                "AccountNo": "Account No.:*",
                "AccountType": "Account Type:*",
                "BankAccountType": "Bank Account Type:*",
                "AccountGroupId": "Account Group:*",
                "Currency": "Currency:*",
                "MinimumBalance": "Minimum Balance:*",
                "CreditLimit": "Credit Limit:*",
                "BranchName": "Branch Name:*",
                "ContactPerson": "Contact Person:*",
                "Address": "Address:*",
                "Country": "Country:*",
                "State": "State:*",
                "City": "City:*",
                "Type": "Type:*",
                "Pincode": "Pin Code:*",
                "PhoneNo": "Phone No:",
                "Mobile": "Mobile No.:",
                "Fax": "Fax:",
                "Email": "Email:",
                "Website": "Website:",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Last Updated By"
            }
        },



        "cashaccount": {
            "management": {
                "Title": "Cash Account",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Code": "Code",
                    "Name": "Name",
                    "Country": "Country",
                    "State": "State",
                    "City": "City",
                    "Type": "Type",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Cash Account",
                "SubTitle": "SubTitle",
                "Code": "Code:*",
                "Name": "Name:*",
                "Bank": "Bank:*",
                "AccountNo": "Account No.:*",
                "AccountType": "Account Type:*",
                "BankAccountType": "Bank Account Type:*",
                "AccountGroupId": "Account Group:*",
                "Currency": "Currency:*",
                "MinimumBalance": "Minimum Balance:*",
                "CreditLimit": "Credit Limit:*",
                "BranchName": "Branch Name:*",
                "ContactPerson": "Contact Person:*",
                "Address": "Address:*",
                "Country": "Country:*",
                "State": "State:*",
                "City": "City:*",
                "Type": "Type:*",
                "Pincode": "Pin Code:*",
                "PhoneNo": "Phone No:*",
                "Mobile": "Mobile No.:*",
                "Fax": "Fax:",
                "Email": "Email:*",
                "Website": "Website:",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Last Updated By"
            }
        },

        "advancerequisition": {
            "management": {
                "Title": "Advance Requisition",
                "SubTitle": "Address",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "EmployeeCode": "Employee Code",
                    "EmployeeName": "Employee Name",
                    "Amount": "Amount",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Advance Requisition",
                "SubTitle": "Address",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "Employee": "Employee:*",
                "AdvanceType": "Advance Type:*",
                "AdvanceAmount": "Advance Amount:*",
                "NoofInstallments": "No of Installments:*",
                "PaymentMode": "Payment Mode:*",
                "Guarentor": "Guarantor:*",
                "EffectiveFromDate": "Effective From Date:*",
                "Narration": "Narration:*",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "PrintButton": "Print",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Last Updated By"
            }
        },

        "leaverequisition": {
            "management": {
                "Title": "Leave Requisition",
                "SubTitle": "Address",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "EmployeeCode": "Employee Code",
                    "EmployeeName": "Employee Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Leave Requisition",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "Address",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "Employee": "Employee:*",
                "LeaveType": "Leave Type",
                "FromDate": "From Date",
                "ToDate": "To Date",
                "NoofDays": "No of Days",
                "NoofLeaves": "No of Leaves:*",
                "ReplacementBy": "Replacement By:*",
                "Narration": "Narration:*",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "PrintButton": "Print",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Last Updated By"
            }
        },


        "forexrates": {
            "management": {
                "Title": "Forex Rates",
                "SubTitle": "Address",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "CurrencyCode": "Currency Code",
                    "CurrencyName": "Currency Name",
                    "BuyyingRate": "Buyying Rate",
                    "SellingRate": "Selling Rate",
                    "AverageRate": "Average Rate",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Forex Rates",
                "SubTitle": "Address",
                "Currency": "Currency:*",
                "BuyingRate": "Buying Rate:*",
                "SellingRate": "Selling Rate:*",
                "AverageRate": "Average Rate:*",
                "ValueDate": "Value Date:*",
                "DefaultRate": "Default Rate:*",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Last Updated By"
            }
        },

        "loanrequisition": {
            "management": {
                "Title": "Loan Requisition",
                "SubTitle": "Address",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "EmployeeCode": "Employee Code",
                    "EmployeeName": "Employee Name",
                    "Amount": "Amount",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Loan Requisition",
                "SubTitle": "Address",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "Employee": "Employee:*",
                "LoanType": "Loan Type:*",
                "LoanAmount": "Loan Amount:*",
                "NoofInstallments": "No of Installments:*",
                "PaymentMode": "Payment Mode:*",
                "Guarentor": "Guarantor:*",
                "EffectiveFromDate": "Effective From Date:*",
                "ValidUptoDate": "Valid Upto Date:*",
                "Narration": "Narration:*",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "PrintButton": "Print",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Last Updated By"
            }
        },
        "loanissue": {
            "management": {
                "Title": "Loan Issue",
                "SubTitle": "Address",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Organization",
                    "LoanIssueNo": "Document No",
                    "LoanIssueDate": "Document Date",
                    "EmployeeCode": "Employee Code",
                    "EmployeeName": "Employee Name",
                    "Amount": "Amount",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Loan Issue",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "Address",
                "PrintButton": "Print",
                "LoanIssueNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "Employee": "Employee:*",
                "LoanRequisitionNo": "Loan Requisition No:*",
                "LoanAmount": "Loan Amount:*",
                "LoanIssueDate": "Loan Issue Date:*",
                "EmployeeNo": "Employee No:*",
                "NoofInstallments": "No of Installments:*",
                "EffectiveFromDate": "Effective From Date:*",
                "Remarks": "Narration:*",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "MonthYear": "Month Year",
                "MonthlyInstallment": "Monthly Installment",
                "PaidAmount": "Paid Amount:",
                "BalanceAmount": "Balance Amount:",
                "Status": "Status",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Last Updated By"
            }
        },
        "advanceissue": {
            "management": {
                "Title": "Advance Issue",
                "SubTitle": "Address",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Organization",
                    "AdvanceIssueNo": "Document No",
                    "AdvanceIssueDate": "Document Date",
                    "EmployeeCode": "Employee Code",
                    "EmployeeName": "Employee Name",
                    "Amount": "Amount",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Advance Issue",
                "SubTitle": "Address",
                "AdvanceIssueNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "Employee": "Employee:*",
                "AdvanceRequisitionNo": "Advance Requisition No:*",
                "AdvanceAmount": "Advance Amount:*",
                "AdvanceIssueDate": "Advance Issue Date:*",
                "EmployeeNo": "Employee No:*",
                "NoofInstallments": "No of Installments:*",
                "EffectiveFromDate": "Effective From Date:*",
                "Remarks": "Narration:*",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Last Updated By"
            }
        },

        "employee": {
            "management": {
                "Title": "Employee Master",
                "SubTitle": "Address",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Code": "Code",
                    "Name": "Name",
                    "City": "City",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Employee Master",
                "SubTitle": "Address",
                "OrgSubOrgCode": "OrgSubOrgCode:*",
                "OrgSubOrgName": "OrgSubOrgName:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "SalutationId": "Salutation Id:*",
                "MotherName": "Mother Name:*",
                "FatherName": "Father Name:*",
                "DateofBirth": "Date of Birth:*",
                "Gender": "Gender:*",
                "Nationality": "Nationality:*",
                "IdentificationMarks": "Identification Marks:*",
                "JoiningDate": "Joining Date:*",
                "Grade": "Grade:*",
                "Department": "Department:*",
                "Designation": "Designation:*",
                "Address": "Address:*",
                "Pincode": "Pin code:*",
                "ContactDetails": "Contact Details:*",
                "Country": "Country:*",
                "State": "State:*",
                "City": "City:*",
                "PhoneNo": "PhoneNo:*",
                "Mobile": "Mobile:*",
                "Fax": "Fax:",
                "Category": "Category:*",
                "Website": "Website:*",
                "Email": "Email:*",
                "Currency": "Currency:*",
                "ReportingTo": "ReportingTo:*",
                "CNPSNo": "CNPS No:",
                "NationalIdNo": "National Id No:",
                "NationalIdValidFrom": "National Id Valid From:",
                "NationalIdValidTo": "National Id Valid To:",
                "PassportNo": "Passport No:",
                "PassportNoValidFrom": "Passport No Valid From:",
                "PassportNoValidTo": "Passport No Valid To:",
                "MaritalStatus": "Marital Status:",
                "SpouseName": "Spouse Name:",
                "SpouseDate": "Spouse Date:",
                "SpousePlaceOfBirth": "Spouse Place Of Birth:",
                "NoOfChildren": "No Of Children:",
                "ChildName": "Child Name:",
                "ChildDOB": "Child DOB:",
                "ChildGender": "Child Gender:",
                "DriverLicenceNo": "Driver Licence No:",
                "DriverLicenceValidFrom": "Driver Licence Valid From:",
                "DriverLicenceValidTo": "Driver Licence Valid To:",
                "ContactPerson": "Contact Person:",
                "EmergencyContactNo": "Emergency Contact No:",
                "LatestDiploma": "Latest Diploma:",
                "YearOfGraduation": "Year Of Graduation:",
                "InstituteAttended": "Institute Attended:",
                "OtherQualification": "Other Qualification:",
                "PayrollPolicyId": "Payroll Policy:",
                "DayPolicyId": "Day Policy:",
                "LeavePolicyId": "Leave Policy:",
                "PayrollApplicability": "Payroll Applicability:",
                "Photograph": "Photograph:",
                "basicSalary": "Basic Salary:",
                "longevityBasicSalary": "Longevity Basic Salary:",
                "cashAllowance": "Cash Allowance:",
                "housingAllowance": "Housing Allowance:",
                "carAllowance": "Car Allowance:",
                "transportAllowance": "Transport Allowance:",
                "SoilingAllowance": "Soiling Allowance:",
                "Maid": "Maid:",
                "Electricity": "Electricity:",
                "Water": "Water:",
                "RepresentaionAllowance": "Representaion Allowance:",
                "SubSalary": "Sub Salary:",
                "OT": "OT:",
                "Technicity": "Technicity:",
                "Desplacement": "Desplacement:",
                "Responsibility": "Responsibility:",
                "NightFoodSubsidy": "Night Food Subsidy:",
                "FoodSubsidy": "Food Subsidy:",
                "HourlyRate": "Hourly Rate:",
                "BankName": "Bank Name:",
                "AccountNo": "Account No:",
                "NSSIFNumber": "NSSIF Number:",
                "BankCounter": "Bank Counter:",
                "BankBranch": "Bank Branch:",
                "BankCode": "Bank Code:",
                "RIBKey": "RIB Key:",
                "AuxillairyAccount": "Auxillairy Account:",
                "PlaceofBirth": "Place of Birth:",
                "EmployeeStatus": "Employee Status:",
                "EndContract": "End Contract:",
                "DrivingLicenceCategory": "Driving Licence Category:",
                "Role": "Role:",

                "IncomeDeduction": "Income Deduction",
                "ExpressionName": "Expression Name",
                "FixedValue": "FixedValue",
                "SLNo": "SL No.",
                "EmployeeName": "EmployeeName",
                "Level": "Level",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Last Updated By"
            }
        },
        "vendermaster": {
            "management": {
                "Title": "Vender Master",
                "SubTitle": "Address",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Code": "Code",
                    "Name": "Name",
                    "Country": "Country",
                    "State": "State",
                    "City": "City",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Vender Master",
                "SubTitle": "Address",
                "Tab1": "General",
                "Tab2": "Address Details",
                "Code": "Code:*",
                "Name": "Name:*",
                "Currency": "Currency:*",
                "CustAlso": "Vendor Also:*",
                "ContactPerson": "Contact Person:*",
                "ContactTimings": "Contact Timings:*",
                "CreditDays": "Credit Days:*",
                "CreditLimit": "Credit Limit:*",
                "VendorCategoryId": "Vendor Category:*",
                "VendorGroupId": "Vendor Group:*",
                "VendorTypeId": "Vendor Type:*",
                "Website": "Website:",
                "Email": "Email:*",
                "BankDetails": "Bank Details:*",
                "Remarks": "Remarks:",
                "RegistrationNo": "Registration No.:*",
                "VendorOrgId": "Vendor Org:*",
                "PaymentTermsId": "Payment Terms:*",
                "ControlAccountId": "Control Account:",
                "DeliveryTermsId": "Delivery Terms:*",
                "VendorCode": "Vendor Code:*",
                "Sno": "Sno:*",
                "Type": "Type:*",
                "Address": "Address:*",
                "Country": "Country:*",
                "State": "State:*",
                "City": "City:*",
                "Pincode": "Pin Code:*",
                "PhoneNo": "Phone No:*",
                "Mobile": "Mobile:*",
                "Fax": "Fax:",
                "ContactDetails": "Contact Details:*",
                "TinNo": "Tin No:",

                "BeneficiaryName": "Beneficiary Name:",
                "BankAccountNo": "Bank Account No:",
                "IBan": "IBan:",
                "Swift": "Swift:",

                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Last Updated By"
            }
        },

        "landermaster": {
            "management": {
                "Title": "Lender Master",
                "SubTitle": "Address",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Code": "Code",
                    "Name": "Name",
                    "Currency": "Currency",
                    "ContactPerson": "Contact Person",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Lender Master",
                "SubTitle": "Address",
                "Code": "Code:*",
                "Name": "Name:*",
                "Currency": "Currency:*",
                "ContactPerson": "Contact Person:*",
                "Address": "Address:*",

                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Last Updated By"
            }
        },

        "vendormaster": {
            "management": {
                "Title": "Vendor Master",
                "SubTitle": "Address",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Code": "Code",
                    "Name": "Name",
                    "Country": "Country",
                    "State": "State",
                    "City": "City",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Vendor Master",
                "SubTitle": "Address",
                "Code": "Code:*",
                "Name": "Name:*",
                "Currency": "Currency:*",
                "ContactPerson": "Contact Person:*",
                "CreditDays": "Credit Days:*",
                "CreditLimit": "Credit Limit:*",
                "VendorGroupId": "Vendor Group:*",
                "VendorTypeId": "Vendor Type:*",
                "RegistrationNo": "Registration No.:*",
                "Remarks": "Remarks:",
                "Address1": "Address1:",
                "Address2": "Address2:",

                "Tab1": "General",
                "Tab2": "Address Details",
                "Website": "Website:",
                "Email": "Email:*",
                "BankDetails": "Bank Details:*",
                "Sno": "Sno:*",
                "Type": "Type:*",
                "Address": "Address:*",
                "Country": "Country:*",
                "State": "State:*",
                "City": "City:*",
                "Pincode": "Pin Code:*",
                "PhoneNo": "Phone No:*",
                "Mobile": "Mobile No:*",
                "Fax": "Fax:",
                "ContactDetails": "Contact Details:*",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Last Updated By"
            }

        },

        "customermaster": {
            "management": {
                "Title": "Customer Master",
                "SubTitle": "Address",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Code": "Code",
                    "Name": "Name",
                    "Country": "Country",
                    "State": "State",
                    "City": "City",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Customer Master",
                "SubTitle": "Address",
                "Code": "Code:*",
                "Name": "Name:*",
                "Currency": "Currency:*",
                "ContactPerson": "Contact Person:*",
                "AltContactPerson": "Second Contact Person:",
                "CreditDays": "Credit Days:*",
                "SalesPerson": "SalesPerson:*",
                "CreditLimit": "Credit Limit:*",
                "Overrun": "Overrun:",
                "AccountingBalance": "Accounting Balance at ",
                "BalanceIn30Days": "For which balance due in 30 days:",
                "BalanceIn60Days": "For which balance due in 60 days:",
                "BalanceIn90Days": "For which balance due in 90 days:",
                "BalanceNotDue": "For which balance not due:",
                "CustomerGroupId": "Customer Group:*",
                "CustomerTypeId": "Customer Type:*",
                "RegistrationNo": "Registration No:*",
                "Remarks": "Remarks:",
                "PaymentTermCode": "Payment Term Code:",
                "ControlAccountId": "Control Account:",
                "Address1": "Address1:",
                "Address2": "Address2:",
                "SalesRegionName": "Sales Region Name:",
                "IsMovingCustomer": "IsMoving Customer:",
                "TaxFormula": "Tax Formula:",
                "TaxCompPecentage": "Tax Comp Pecentage:",
                "Tab1": "General",
                "Tab2": "Address Details",
                "Tab3": "Summary",

                "Website": "Website:",
                "Email": "Email:*",
                "BankDetails": "Bank Details:*",
                "Sno": "Sno:*",
                "Type": "Type:*",
                "Address": "Address:*",
                "Country": "Country:*",
                "State": "State:*",
                "City": "City:*",
                "PinCode": "Pin Code:*",
                "PhoneNo": "Phone No:*",
                "Mobile": "Mobile:*",
                "AltMobile": "Altanative Mobile:",
                "Fax": "Fax:",
                "TaxNo": "Tax No:",
                "ContactDetails": "Contact Details:*",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Last Updated By"
            }

        },

        "itemmaster": {
            "management": {
                "Title": "Item Master",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Alias": "Alias",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Item Master",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "RFId": "RFId:*",
                "SKUApplicability": "SKU Applicability:*",
                "SKUId": "SKUId:*",
                "Alias": "Alias:*",
                "NegativeAllow": "Negative Allow:*",
                "Tolarance": "Tolarance:*",
                "ExpiryApplicability": "Expiry Applicability:*",
                "ItemSetApplicable": "Item Set Applicable:*",
                "BarcodeApplicable": "Barcode Applicable:*",
                "ItemGroup": "Item Group:*",
                "ItemType": "Item Type:*",
                "ItemCategory": "Item Category:*",
                "ItemPartNo": "Item Part No.:*",
                "BrandCode": "Brand Code:*",
                "BrandName": "Brand Name:*",
                "UnitSetCode": "Unit Set Code:*",
                "GrossWeight": "Gross Weight :*",
                "NetWeight": "Net Weight :*",
                "Discontinued": "Discontinued:*",
                "NetQtyApplicability": "Net Quantity Applicability:*",
                "SpecApplicability": "Spec. Applicability:*",

                "InvBranchId": "Inv. Branch:*",
                "UnitSetId": "Unit Set:*",
                "StorageUOM": "Storage UOM:*",
                "InventoryUOM": "Inventory UOM:*",
                "LeadTime": "Lead Time:*",
                "LotControl": "Lot Control:*",
                "LocationControl": "Location Control:*",
                "SafetyStock": "Safety Stock:*",
                "MinimumStock": "Minimum Stock:*",
                "MaximumStock": "Maximum Stock:*",
                "ValidationMethod": "Validation Method:*",
                "ABCRank": "ABC Rank:*",

                "MFGStrategy": "MFG Strategy:*",
                "Phantom": "Phantom:*",

                "PurchaseUOMId": "Purchase UOM:",
                "PurchasePrice": "Purchase Price:",
                "RevisionNo": "Revision No.:*",
                "ScrapPercentage": "Scrap Percentage:*",
                "ECONo": "ECO No.:*",

                "UOMId": "UOM:*",
                "PriceUOMId": "Price UOM:*",
                "CurrencyId": "Currency:*",
                "Price": "Price:*",
                "Inspection": "Inspection:*",
                "Guaranty": "Guarantee:*",
                "PurchaseDecisionId": "Purchase Decision:*",
                "UpperMargin": "Upper Margin:*",
                "LowerMargin": "Lower Margin:*",

                "TyreBrand": "Tyre Brand:*",
                "TyrePattern": "Tyre Pattern:*",
                "TypeSize": "Tyre Size:*",
                "TyreCategory": "Tyre Category:*",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "location": {
            "management": {
                "Title": "Location",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Warehouse": "Warehouse",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Location",
                "SubTitle": "SubTitle",
                "Tab1": "General",
                "Tab2": "Details",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "WarehouseCode": "Warehouse Code:*",
                "WarehouseName": "Warehouse Name:",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "tyrespostemp": {
            "management": {
                "Title": "Tyres Position Template",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Tyres Position Template",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "slno": "SLNo",
                "axleno": "Axle No",
                "tyreposition": "Tyre Position",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "truchtyremap": {
            "management": {
                "Title": "Truck Tyre Mapping",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "DocumentNo": "Document No",
                    "TruckTypeName": "Truck Type Name",
                    "TruckNo": "Truck No",
                    "TyresTemplateName": "Tyres Template Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Truck Tyre Mapping",
                "SubTitle": "SubTitle",
                "Tab1": "General",
                "Tab2": "Details",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "TruckHeadNo": "Truck No:*",
                "truckType": "Truck Type:*",
                "kmmovedreading": "KM Moved Reading:",
                "tyretemp": "Tyre Template",
                "slno": "Sl No",
                "axleno": "Axle No",
                "tyrePositionId": "Position",
                "tyreSLNumberId": "Sl Number",
                "tyreKM": "Tyre KM",
                "td": "Thread Depth",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "tyreremoval": {
            "management": {
                "Title": "Tyre Removal",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "UnitNo": "Unit No",
                    "TruckType": "Truck Type",
                    "TruckNo": "Truck No",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Tyre Removal",
                "SubTitle": "SubTitle",
                "Tab1": "General",
                "Tab2": "Details",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "documentNo": "Document No.:*",
                "documentDate": "Document Date:*",
                "UnitId": "Unit:*",
                "truckType": "Truck Type:",
                "truckNo": "Truck No",
                "TruckStatus": "Truck Status",
                "previousKMReading": "Odometer Reading",
                "presentKMReading": "Present KM Reading",
                "lastUpdated": "Last Updated",
                "driverId": "Driver",
                "tyreTechnicianId": "Tyre Technician",
                "slno": "SlNo",
                "axleno": "Axle No",
                "tyrePositionId": "Position",
                "tyreSLNumberId": "Sl Number",
                "tyreKM": "Tyre KM",
                "brand": "Brand",
                "tyreLife": "Tyre Life",
                "pattern": "Pattern",
                "size": "Size",
                "td": "TD",
                "reason": "Reason",
                "location": "Location",
                "remarks": "Remarks",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "tyremounting": {
            "management": {
                "Title": "Tyre Mounting",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "UnitNo": "Unit No",
                    "TruckType": "Truck Type",
                    "TruckNo": "Truck No",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Tyre Mounting",
                "SubTitle": "SubTitle",
                "Tab1": "General",
                "Tab2": "Details",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "documentNo": "Document No.:*",
                "documentDate": "Document Date:*",
                "UnitId": "Unit:*",
                "truckType": "Truck Type:",
                "truckNo": "Truck No",
                "TruckStatus": "Truck Status",
                "kMReading": "kM Reading",
                "lastUpdated": "Last Updated",
                "driverId": "Driver",
                "tyreTechnicianId": "Tyre Technician",
                "slno": "Sl No",
                "axleno": "Axle No",
                "tyrePositionId": "Position",
                "tyreSLNumberId": "Sl Number",
                "pattern": "Pattern",
                "size": "Size",
                "td": "Thread Depth",
                "location": "Location",
                "tyreSLNumberId1": "Sl Number",
                "brand": "Brand",
                "pattern1": "Pattern",
                "size1": "Size",
                "td1": "TD",
                "remarks": "Remarks",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "tyremountingmodal": {
            "create": {
                "Title": "Tyre Mounting Selection",
                "sizeId": "Size",
                "brandId": "Brand",
                "patternId": "Pattern",
                "searchSLNO": "Tyre Serial Number",
                "tyreCode": "Tyre ID",
                "tyreName": "Tyre SLNo",
                "status": "Status",
                "otd": "OTD",
                "ptd": "PTD",
                "km": "KM",
                "CloseButton": "Close",
                "OkButton": "Ok"
            }
        },

        "tyreinspection": {
            "management": {
                "Title": "Tyre Inspection",
                "SubTitle": "Tyre Inspection",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "UnitNo": "Unit No",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Tyre Inspection",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "documentNo": "Document No.:*",
                "documentDate": "Document Date:*",
                "UnitId": "Unit:*",
                "driverId": "Driver:",
                "previousKM": "Previous KM:",
                "presentKM": "Present KM:",
                "differenceKM": "Difference KM:",
                "lastUpdated": "LastUpdated",
                "remarks": "Remarks:",
                "slno": "SlNo",
                "truckNo": "Truck",
                "axle": "Axle",
                "tyrePositionId": "Position",
                "tyreSLNumberId": "Tyre SL No",
                "previousTyreKM": "Previous Tyre KM",
                "presentTyreKM": "present Tyre KM",
                "td": "TD",
                "pressure": "Pressure",
                "remarks1": "Remarks",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "tabs": {
                "General": "General",
                "TruckHead": "Truck Head",
                "FirstTrailer": "First Trailer",
                "Dolly": "Dolly",
                "SecondTrailer": "Second Trailer"
            }
        },

        "itemlocationmapping": {
            "management": {
                "Title": "Item Location Mapping",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "ItemName": "Item Name",
                    "ItemCode": "Item Code",
                    "Warehouse": "Warehouse",
                    "LocationName": "Location"
                }
            },
            "create": {
                "Title": "Item Location Mapping",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "WarehouseCode": "Warehouse Code:*",
                "WarehouseName": "Warehouse Name:",
                "ItemCode": "Item Code:*",
                "ItemName": "Item Name:",
                "UOMCode": "UOM Code:",
                "UOMName": "UOM Name:",
                "LocationCode": "Location Code:",
                "LocationName": "Location Name:",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "binmapping": {
            "management": {
                "Title": "Bin Mapping",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Location": "Location",
                    "Bin": "Bin",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Bin Mapping",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "LocationCode": "Location Code:*",
                "LocationName": "Location Name:*",
                "BinCode": "Bin Code:*",
                "BinName": "Bin Name:*",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "technicaldivision": {
            "management": {
                "Title": "Technical Division",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Technical Division",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },
        "skilllevel": {
            "management": {
                "Title": "Skill Level",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Skill Level",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "trade": {
            "management": {
                "Title": "Trade",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Trade",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },
        "taskstoppagereason": {
            "management": {
                "Title": "Task Stoppage Reason",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Task Stoppage Reason",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },
        "supervisor": {
            "management": {
                "Title": "Supervisor",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Supervisor",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },
        "workshop": {
            "management": {
                "Title": "Work Shop",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Work Shop",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "WorkShopType": "Work Shop Type:*",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },


        "workshoptype": {
            "management": {
                "Title": "Workshop Type",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Workshop Type",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },
        "technicalmanager": {
            "management": {
                "Title": "Technical Manager",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Technical Manager",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },
        "tasktype": {
            "management": {
                "Title": "Task Type",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Task Type",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "failurecode": {
            "management": {
                "Title": "Failure Code",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Failure Code",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "mechanic": {
            "management": {
                "Title": "Mechanic",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Mechanic",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "uom": {
            "management": {
                "Title": "UOM",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "UOM",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },


        "assets": {
            "management": {
                "Title": "Assets",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Assets",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "IsSubSet": "IsSubSet:*",
                "ParentAsset": "Parent Asset:",
                "Category": "Category:",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "truckhead": {
            "management": {
                "Title": "Truck Head Master",
                "SubTitle": "Address",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Code": "Code",
                    "Organisation": "Organization",
                    "Name": "Name",
                    "AssetName": "Asset Name ",
                    "VarientName": "Varient Name",
                    "BrandName": "Brand Name",
                    "ModelName": "Model Name",
                    "Status": "Status",
                    "FuelTankName  ": "Fuel Tank Name",
                    "ChassisNo": "Chassis No",
                    "Remarks": "Remarks",
                    "GVW": "GVW"
                }
            },
            "create": {
                "Title": "Truck Head Master",
                "SubTitle": "Address",
                "Code": "Code",
                "Organisation": "Organization",
                "Name": "Name:",
                "AssetName": "Asset Name ",
                "IsHeavyDuty": "Is Heavy Duty",
                "DateOfCommission": "Date Of Commission",
                "CounterReading": "Counter Reading",
                "VarientName": "Varient Name",
                "BrandName": "Brand Name",
                "ModelName": "Model Name",
                "TareWeight": "Tare Weight",
                "PullingCapacity": "Pulling Capacity",
                "TruckHeadType": "Truck Head Type",
                "TruckHeadCategory": "Truck Head Category",
                "Status": "Status",
                "NoOfAxles": "No of Axles",
                "FueltankName": "Fuel Tank Name",
                "KM Run": "KM Run",
                "ChassisNo": "Chassis No",
                "Remarks": "Remarks:",
                "GVW": "GVW",
                "Year": "Model Year",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Last Updated By"
            }
        },


        "route": {
            "management": {
                "Title": "Route",
                "SubTitle": "Address",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Code": "Code",
                    "Orgname": "OrgName",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Route",
                "SubTitle": "Address",
                "Code": "Code:",
                "Name": "Name:",
                "OriginName": "Origin Name:",
                "DestinationName": "Destination Name:",
                "Distance": "Total Distance:",
                "OnRoad": "On Road KM:",
                "OffRoad": "Off Road KM:",
                "ForestRoad": "Forest Road KM:",
                "RoadFee": "Road Fee:",
                "BridgeFee": "Bridge Fee:",
                "TripDuration": "Trip Duration:",
                "MealAllowance": "Meal Allowance:",
                "MilageAllowance": "Milage Allowance:",
                "WeighBridge": "Weigh Bridge:",
                "CouncilFee": "Council Fee:",
                "FuelTax": "Fuel Tax:",
                "Document/ToalGate": "Document/ToalGate:",
                "EscortFees": "Escort Fees:",
                "WeighBridgeBeloco": "Weigh Bridge Beloco:",
                "CrossBorder": "Is Cross Border:",
                "CrossBorderIncentive": "Cross Border Incentive:",
                "Remarks": "Remarks:",
                "Longitude": "Longitude:",
                "Latitude": "Latitude:",
                "AxleTax": "Axle Tax:",
                "Lashing": "Lashing:",
                "Incentive": "Driver Incentive:",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "PrintButton": "Print",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Last Updated By"
            }
        },


        "codegeneratemapping": {
            "management": {
                "Title": "Code Generate Mapping",
                "SubTitle": "Code Generate Mapping",
                "NewButton": "New",
                "ExportButton": "Export",
                "GridHeaders": {
                    "IsActive": "Is Active",
                    "Orgname": "Org Name",
                    "ModuleName": "Module Name",
                    "Name": "Name",
                    "Code": "Code",
                    "PartValue": "Part Value",
                    "PartType": "Part Type"
                }
            }
        },


        "definecomponents1": {
            "management": {
                "Title": "Define Components",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "TypesName": "Types Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Define Components",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:",
                "Name": "Name:",
                "Typesname": "Types Name:",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Typesname": "Types Name:",
                "Remarks": "Remarks:"
            }
        },


        "truckinsurance": {
            "management": {
                "Title": "Truck Insurance",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "DocumentFile": "Document File",
                    "Unit": "Unit",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Truck Insurance",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Organisation": "Organization:",
                "InsuranceNo": "Document No:",
                "InsuranceDate": "Document Date:",
                "VehicleType": "Vehicle Type:",
                "PlateNo": "Truck No:",
                "UnitNo": "Unit No:",
                "InsuranceType": "Insurance Type:",
                "InsuranceCompany": "Insurance Company:",
                "InsuranceAmount": "Insurance Amount:",
                "ValidFromDate": "Valid From Date:",
                "ValidToDate": "Valid To Date:",

                "grayCard": "Gray Card:",
                "blueCard": "Blue Card:",
                "certificateOfFitness": "Certificate Of Fitness:",
                "insurance": "Insurance:",
                "windScreenLicense": "Wind Screen License:",
                "transportLicense": "Transport License:",
                "transportAgreement": "Transport Agreement:",
                "businessLicense": "Business License:",
                "portAccess": "Port Access:",
                "publicityTax": "Publicity Tax:",
                "trailerAxleTax": "Trailer Axle Tax:",
                "woodAxleTax": "Wood Axle Tax:",

                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }

        },


        "registration": {
            "management": {
                "Title": "Truck Registration",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "RegistrationNo": "Document No",
                    "RegistrationDate": "Document Date",
                    "DocumentFile": "Document File",
                    "Unit": "Unit",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Truck Registration",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Organisation": "Organization:",
                "RegistrationNo": "Document No:",
                "RegistrationDate": "Document Date:",
                "VehicleType": "Vehicle Type:",
                "PlateNo": "Truck No:",
                "UnitNo": "Unit No:",
                "UnitModel": "Unit Model:",
                "Amount": "Amount:",
                "ChassisNo": "Chassis No:",
                "ValidFromDate": "Valid From Date:",
                "ValidToDate": "Valid To Date:",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }

        },

        "reportcurrentinventory": {
            "management": {
                "title": "Report Current Inventory",
                "exportpdf": "Export as PDF",
                "exportcsv": "Export as CSV",
                "gridheaders": {
                    "org": "Organization",
                    "itemcode": "Item Code",
                    "itemname": "Item Name",
                    "uomcode": "UOM",
                    "quantity": "Quantity",
                    "unitrate": "Unit Rate",
                    "stockvalue": "Stock Value"
                }
            }
        },

        "reportstockmovement": {
            "management": {
                "title": "Report Stock Movement",
                "exportpdf": "Export as PDF",
                "exportcsv": "Export as CSV",
                "gridheaders": {
                    "org": "Organization",
                    "itemcode": "Item Code",
                    "itemname": "Item Name",
                    "uomcode": "UOM",
                    "openbal": "Opening Balance",
                    "recqty": "Received QTY",
                    "issueqty": "Issued QTY",
                    "closebal": "Closing Balance",
                    "itmtype": "Item Type"
                }
            }
        },

        "emptytrip": {
            "management": {
                "Title": "Empty Trip",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "EmptyTripNo": "Document No",
                    "EmptyTripDate": "Document Date",
                    "Route": "Route",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Empty Trip",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "EmptyTripNo": "Document No:*",
                "EmptyTripDate": "Document Date:*",
                "DriverCardNo": "Driver Card No:",
                "Route": "Route:",
                "FuelRequired": "Fuel Required:",
                "Distance": "Distance:",
                "ManifestNo": "Manifest No",
                "UnitNo": "Unit No:",
                "PlateNo": "Plate No:",
                "Odometer": "Odometer:",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "EmptyTripNo": "Empty Trip No:",
                "Name": "Name:",
                "locationtype": "Location Type",
                "Remarks": "Remarks:"
            }
        },


        "trip": {
            "management": {
                "Title": "Trip",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "TripNo": "Trip No",
                    "TripDate": "Trip Date",
                    "Route": "Route",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Trip",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "TripNo": "Trip No:*",
                "TripDate": "Trip Date:*",
                "DriverCardNo": "Driver Card No",
                "Route": "Route:",
                "Commodity": "Commodity:",
                "CustomerCode": "Customer Code:",
                "CustomerName": "Customer Name:",
                "Currency": "Currency:",
                "FuelRequired": "Fuel Required:",
                "Distance": "Distance:",
                "ManifestNo": "Manifest No:",
                "UnitNo": "Unit No:",
                "PlateNo": "Plate No:",
                "Weight": "Weight:",
                "FreightRate": "Freight Rate:",
                "FreightAmount": "Freight Amount:",
                "WaybillNo": "Waybill No:",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "EmptyTripNo": "EmptyTrip No:",
                "Name": "Name:",
                "locationtype": "Location Type",
                "Remarks": "Remarks:"
            }
        },


        "tripexpensesrequest": {
            "management": {
                "Title": "Trip Expenses Request",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "RequestNo": "Document No.",
                    "RequestDate": "Document Date",
                    "Route": "Route",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Trip Expenses Request",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "TripExpensesRequestNo": "Document No:*",
                "TripExpensesRequestDate": "Document Date:*",
                "DriverCardNo": "Driver Code",
                "Route": "Route:",
                "UnitNo": "Unit No:",
                "PlateNo": "Plate No:",
                "ManifestNo": "Manifest No:",
                "DriverName": "Driver Name:",
                "RoadFee": "Road Fee:",
                "BridgeFee": "Bridge Fee:",
                "MealAllowance": "Meal Allowance:",
                "MilageAllowance": "Milage Allowance:",
                "WeighBridgeFee": "Weigh Bridge Fee:",
                "CouncilFee": "Council Fee:",
                "FuelTax": "Fuel Tax:",
                "Tollgate": "Tollgate Fee:",
                "EscortFee": "Escort Fee:",
                "WeighBridgeBoloko": "Weigh Bridge/Boloko:",
                "Total": "Total:",
                "TransferCharges": "Transfer Charges:",
                "OtherAdvance": "Other Advance:",
                "GrandTotal": "Grand Total:",
                "Currency": "Currency:",
                "AxleTax": "Axle Tax:",
                "Lashing": "Lashing:",
                "CrossBorder": "Cross Border:",
                "CrossBorderIncentive": "Cross Border Incentive:",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "EmptyTripNo": "Empty Trip No:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "tripexpensessettlement": {
            "management": {
                "Title": "Trip Expenses Settlement",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "SettlementNo": "Document No.",
                    "SettlementDate": "Document Date",
                    "Route": "Route",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Trip Expenses Settlement",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "TripExpensesSettlementNo": "Document No:*",
                "TripExpensesSettlementDate": "Document Date:*",
                "DriverCardNo": "Driver Code:",
                "Route": "Route:",
                "UnitNo": "Unit No:",
                "PlateNo": "Plate No:",
                "ManifestNo": "Manifest No:",
                "DriverName": "Driver Name:",
                "RoadFee": "Road Fee:",
                "BridgeFee": "Bridge Fee:",
                "MealAllowance": "Meal Allowance:",
                "MilageAllowance": "Milage Allowance:",
                "WeighBridgeFee": "Weigh Bridge Fee:",
                "CouncilFee": "Council Fee:",
                "FuelTax": "Fuel Tax:",
                "Tollgate": "Tollgate Fee:",
                "EscortFee": "Escort Fee",
                "WeighBridgeBoloko": "Weigh Bridge/Boloko Fee:",
                "Total": "Total:",
                "OtherExpenses": "Other Expenses:",
                "GrandTotal": "Grand Total:",
                "Currency": "Currency:",
                "AxleTax": "Axle Tax:",
                "Lashing": "Lashing:",
                "CrossBorder": "Cross Border:",
                "CrossBorderIncentive": "Cross Border Incentive:",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "EmptyTripNo": "Empty Trip No:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "loanforecloser": {
            "management": {
                "Title": "Loan Forecloser",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Organisation",
                    "LoanForeCloserNo": "Document No",
                    "LoanForeCloserDate": "Document Date",
                    "EmployeeName": "Employee Name",
                    "LoanAmount": "Loan Amount",
                    "PaidAmount": "Paid Amount",
                    "NoofInstallments": "No of Installments",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Loan Forecloser",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.:*",
                "Documentdate": "Document Date:*",
                "FromDate": "From Date:",
                "ToDate": "To Date:",
                "Remarks": "Narration:",
                "AdvanceTypeCode": "Advance Type Code",
                "AdvanceTypeName": "Advance Type Name",
                "NoofInstallments": "No of Installments",
                "LoanIssueNo": "Loan Issue No:",
                "PaidAmount": "Paid Amount:",
                "EmployeeNo": "Employee No:",
                "LoanAmount": "Loan Amount:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "report": {
                "Title": "Advance Policy",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "EmployeeNo": "Employee No",
                "LoanIssueNo": "Loan Issue No",
                "LoanAmount": "Loan Amount:",
                "NoofInstallments": "No of Installments:",
                "Remarks": "Remarks:",
                "PaidAmount": "PaidAmount",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Narration:"
            }
        },

        "advanceforecloser": {
            "management": {
                "Title": "Advance Forecloser",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Organisation",
                    "AdvanceForeCloserNo": "Document No",
                    "AdvanceForeCloserDate": "Document Date",
                    "EmployeeName": "Employee Name",
                    "AdvanceAmount": "Advance Amount",
                    "PaidAmount": "Paid Amount",
                    "NoofInstallments": "No of Installments",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Advance Forecloser",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.:*",
                "Documentdate": "Document Date:*",
                "FromDate": "From Date:",
                "ToDate": "To Date:",
                "Remarks": "Narration:",
                "AdvanceTypeCode": "Advance Type Code",
                "AdvanceTypeName": "Advance Type Name",
                "NoofInstallments": "No of Installments",
                "AdvanceIssueNo": "Advance Issue No:",
                "PaidAmount": "Paid Amount:",
                "EmployeeNo": "Employee No:",
                "AdvanceAmount": "Advance Amount:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "report": {
                "Title": "Advance Policy",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "EmployeeNo": "Employee No",
                "AdvanceIssueNo": "Advance Issue No",
                "AdvanceAmount": "Advance Amount:",
                "NoofInstallments": "No of Installments:",
                "Remarks": "Remarks:",
                "PaidAmount": "PaidAmount",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Narration:"
            }
        },

        "reportpurchaseinvoice": {
            "management": {
                "title": "Report Purchase Statistics By Invoice",
                "exportpdf": "Export as PDF",
                "exportcsv": "Export as CSV",
                "gridheaders": {
                    "org": "Organization",
                    "division": "Division",
                    "vencode": "Vendor Code",
                    "venname": "Vendor Name",
                    "vengroup": "Vendor Group",
                    "docno": "Document No.",
                    "docdate": "Document Date",
                    "grossamt": "Gross Amount",
                    "taxamt": "Tax Amount",
                    "netamt": "Net Amount"
                }
            }
        },
        "productgroup": {
            "management": {
                "Title": "Product Group",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No.",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Product Group",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "Remarks": "Remarks:",
                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "UOMCode": "UOM Code",
                "UOMName": "UOM Name",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "report": {
                "Title": "Product Group",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "JobCardNo": "Job Card No",
                "DocumentNo": "Document No.",
                "DocumentDate": "Document Date",
                "Remarks": "Remarks",
                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "UOMCode": "UOM Code",
                "UOMName": "UOM Name",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "TaxOnSales": {
            "management": {
                "Title": "TaxOnSales",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No.",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "TaxOnSales",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "Remarks": "Remarks:",
                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "UOMCode": "UOM Code",
                "UOMName": "UOM Name",
                "TaxOnSales": "TaxOnSales",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },


        "fleetgroup": {
            "management": {
                "Title": "Fleet Group",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No.",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Fleet Group",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "Remarks": "Remarks:",
                "UnitCode": "Unit Code",
                "UnitName": "Unit Name",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "report": {
                "Title": "Fleet Group",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.",
                "DocumentDate": "Document Date",
                "Remarks": "Remarks",
                "UnitCode": "Unit Code",
                "UnitName": "Unit Name",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "customeradvancedetails": {
            "management": {
                "Title": "Customer Advance Details",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Customer Advance Details",
                "SubTitle": "SubTitle",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "CustomerCode": "Customer Code:",
                "CustomerName": "Customer Name:",
                "CustomerReceiptNo": "Customer Receipt No:",
                "CustomerReceiptDate": "Customer Receipt Date:",
                "Amount": "Amount:",
                "CustomerAdvanceBalance": "Customer Advance Balance:",

                "ManifestNo": "Manifest No",
                "ManifestAmount": "Manifest Amount",
                "AdvanceAmount": "Advance Amount",
                "BalanceAmount": "Balance Amount",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
        },

        "rodcompact": {
            "management": {
                "Title": "Rates of Depreciation - As Per Companies Act",
                "SubTitle": "Rates of Depreciation - As Per Companies Act",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "AccountingYear": "Accounting Year",
                    "DocumentNo": "Document No",
                    "RODMethod": "ROD Method",
                    "DepreciationRate": "Depreciation Rate",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Rates of Depreciation - As Per Companies Act",
                "SubTitle": "Rates of Depreciation - As Per Companies Act",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "accountingYearId": "Accounting Year",
                "documentNo": "Document No.",
                "rodMethodId": "Method:",
                "depreciationRate": "Depreciation Rate:",
                "description": "Description:",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "roditact": {
            "management": {
                "Title": "Rates of Depreciation - As Per IT Act",
                "SubTitle": "Rates of Depreciation - As Per IT Act",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "AccountingYear": "Accounting Year",
                    "DocumentNo": "Document No",
                    "RODMethod": "ROD Method",
                    "DepreciationRate": "Depreciation Rate",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Rates of Depreciation - As Per IT Act",
                "SubTitle": "Rates of Depreciation - As Per IT Act",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "accountingYearId": "Accounting Year",
                "documentNo": "Document No:",
                "DocumentDate": "Document Date:",
                "rodMethodId": "Method:",
                "depreciationRate": "Depreciation Rate:",
                "description": "Description:",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "assetcategory": {
            "management": {
                "Title": "Asset Category",
                "SubTitle": "Asset Category",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "code": "Code",
                    "name": "Name:",
                    "rodITAct": "As per IT Act",
                    "rodCompAct": "As per Books",
                    "description": "Description",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Asset Category",
                "SubTitle": "Asset Category",
                "Tab1": "General",
                "Tab2": "Details",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "code": "Code",
                "name": "Name:",
                "shortName": "Short Name:",
                "description": "Description:",
                "assetTypeId": "Asset Type:",
                "rodITAct": "As per IT Act:",
                "rodCompAct": "As per Books:",
                "prefixLabel": "Prefix-Label:",
                "account": "Account",
                "typeOfAccount": "Type Of Account",
                "accountCode": "Account Code",
                "accountName": "Account Name",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "costcenter": {
            "management": {
                "Title": "Cost Center",
                "SubTitle": "Cost Center",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "DocumentNo": "Code",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Cost Center",
                "SubTitle": "Cost Center",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "documentNo": "Code:",
                "DocumentDate": "Document Date:",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "budgetscreen": {
            "management": {
                "Title": "Budget Screen",
                "SubTitle": "Budget Screen",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "DocumentNo": "Document No",
                    "Year": "Year",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Budget Screen",
                "SubTitle": "Budget Screen",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No:*",
                "Year": "Year:*",
                "Type": "Type:*",
                "Remarks": "Remarks:",

                "Tab1": "General",
                "Tab2": "Details",

                "BudgetLineCode": "Budget Line Code",
                "BudgetLineName": "Budget Line Name",
                "SegmentCode": "Segment Code",
                "SegmentName": "Segment Name",
                "january": "January",
                "february": "February",
                "march": "March",
                "april": "April",
                "may": "May",
                "june": "June",
                "july": "July",
                "august": "August",
                "september": "September",
                "october": "October",
                "november": "November",
                "december": "December",
                "Total": "Total",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "forecasting": {
            "management": {
                "Title": "Forecasting",
                "SubTitle": "Budget Screen",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "DocumentNo": "Document No",
                    "Year": "Year",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Forecasting",
                "SubTitle": "Budget Screen",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No:*",
                "Year": "Year:*",
                "Remarks": "Remarks:",

                "Tab1": "General",
                "Tab2": "Details",

                "BudgetLineCode": "Budget Line Code",
                "BudgetLineName": "Budget Line Name",
                "january": "January",
                "february": "February",
                "march": "March",
                "april": "April",
                "may": "May",
                "june": "June",
                "july": "July",
                "august": "August",
                "september": "September",
                "october": "October",
                "november": "November",
                "december": "December",
                "Total": "Total",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "budgetyearly": {
            "management": {
                "Title": "Budget Yearly",
                "SubTitle": "Budget Yearly",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "AnnualYear": "Annual Year",
                    "FromMonth": "From Month",
                    "ToMonth": "To Month",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Budget Yearly",
                "SubTitle": "Budget Yearly",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "annualYear": "Annual Year:*",
                "fromMonth": "From Month:*",
                "toMonth": "To Month:",
                "ForeCast": "ForeCast:*",
                "Remarks": "Remarks:",
                "documentNo": "Document No:*",
                "documentDate": "Document Date:",

                "Tab1": "General",
                "Tab2": "Details",

                "accountCode": "Account Code",
                "accountName": "Account Name:",
                "BudgetLineCode": "Budget Line Code",
                "BudgetLineName": "Budget Line Name",
                "amount": "Amount",
                "foreCast": "ForeCast",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "budgetmonthly": {
            "management": {
                "Title": "Budget Monthly",
                "SubTitle": "Budget Monthly",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "BudgetYearly": "Yearly Budget",
                    "FromMonth": "Month",
                    "AnnualYear": "Annual Year",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Budget Monthly",
                "SubTitle": "Budget Monthly",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "fromMonth": "Month:*",
                "annualYear": "Annual Year:*",
                "ForeCast": "ForeCast:*",
                "Remarks": "Remarks",

                "tab1": "General",
                "tab2": "Details",

                "accountCode": "Account Code",
                "accountName": "Account Name",
                "BudgetLineCode": "Budget Line Code",
                "BudgetLineName": "Budget Line Name",
                "budgetyearly": "Yearly Budget",
                "budgeted": "Budgeted",
                "balance": "Balance",
                "currentBudget": "Current Budget",
                "foreCast": "ForeCast",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "bankreconciliation": {
            "management": {
                "Title": "Bank Reconciliation",
                "SubTitle": "Bank Reconciliation",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "orgName": "Org Name",
                    "documentNo": "Document No.",
                    "docDt": "Document Date",
                    "recoStatementDate": "Reco. Statement Date ",
                    "bankAccountCode": "Bank Account Code",
                    "amount": "Amount ",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Bank Reconciliation",
                "SubTitle": "Bank Reconciliation",
                "Tab1": "General",
                "Tab2": "Details",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "bankAccountCode": "Bank Account Code:*",
                "bankAccountName": "Bank Account Name:",
                "recoStatementDate": "Reconciliation Date:*",
                "amount": "Amount:",

                "docType": "Doc.Type",
                "DocumentNo": "Document No.",
                "documentDate": "Document Date",
                "partyName": "Party Name",
                "debitAmount": "Debit",
                "creditAmount": "Credit",
                "realisedDate": "Realised Date",
                "noOfDays": "No.Of Days",
                "instNo": "Inst.No",
                "instrumentDt": "Instrument Date",

                "docNo": "Document No",
                "docDt": "Document Date",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "fyclosure": {
            "management": {
                "Title": "FY Closure",
                "SubTitle": "FY Closure",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "FromDate": "From Date",
                    "ToDate": "To Date",
                    "FYName": "FY Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "FY Closure",
                "SubTitle": "FY Closure",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "fYName": "FY Name",
                "fromDate": "From Date",
                "toDate": "To Date",
                "isClose": "Is Close",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "periodclosure": {
            "management": {
                "Title": "Period Closure",
                "SubTitle": "Period Closure",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "AnnualYear": "Annual Year",
                    "FromMonth": "Period",
                    "IsClose": "Is Close",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Period Closure",
                "SubTitle": "Period Closure",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "annualYear": "Annual Year",
                "fromMonth": "From Month",
                "isClose": "Is Close",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "salarysheetpreparation": {
            "management": {
                "Title": "Salary Sheet",
                "SubTitle": "Salary Sheet",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Period": "Period",
                    "FromDate": "From Date",
                    "ToDate": "To Date",
                    "PayrollGroupName": "Payroll Group",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Salary Sheet",
                "SubTitle": "Salary Sheet",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "periodClosureId": "For the Period",
                "fromDate": "From Date",
                "toDate": "To Date",
                "PayrollGrpCode": "Payroll Group Code",
                "PayrollGrpName": "Payroll Group Name",

                "CalculateSalaryButton": "Calculate",
                "DownloadButton": "Download",
                "PrintButton": "Print",

                "EmployeeCode": "Employee Code",
                "EmployeeName": "Employee Name",
                "GrossSalary": "Gross Salary",
                "EarnedSalary": "Earned Salary",
                "TotalDeductions": "Total Deductions",
                "NetSalary": "Net Salary",


                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "LeaveReceipt": {
            "management": {
                "Title": "Leave Receipt",
                "SubTitle": "Leave Receipt",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Period": "Period",
                    "FromDate": "From Date",
                    "ToDate": "To Date",
                    "PayrollGroupName": "Payroll Group",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Leave Receipt",
                "SubTitle": "Leave Receipt",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "periodClosureId": "For the Period",
                "CalculateSalaryButton": "Calculate",

                "EmployeeCode": "Employee Code",
                "EmployeeName": "Employee Name",
                "LeaveType": "Leave Type",
                "DebitLeaves": "Debit Leaves",
                "CreditLeaves": "Credit Leaves",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "driverincentive": {
            "management": {
                "Title": "Driver Incentive",
                "SubTitle": "Driver Incentive",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Org Name",
                    "DocumentNo": "Document No.",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Driver Incentive",
                "SubTitle": "Driver Incentive",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "periodClosureId": "For the Period:",
                "DocumentNo": "Document No:",
                "DocumentDate": "Document Date:",
                "DriverCardNo": "Driver Card No:",
                "Currency": "Currency:",
                "ExchangeRate": "Exchange Rate:",
                "ExchangeDate": "Exchange Date:",
                "PaymentTermCode": "Payment Term Code:",
                "Remarks": "Remarks:",

                "refNo": "Document No",
                "refDate": "Date",
                "driverIncentive": "Driver Incentive",
                "fuelRequired": "Fuel Required",
                "mFKM": "MF KM",
                "incentiveKM": "Incentive KM",
                "amount": "Amount",
                "odometer": "Odometer",
                "fuelQuantity": "Fuel Quantity",

                "TabTitle1": "General",
                "TabTitle2": "Manifests",
                "TabTitle3": "Empty Trips",
                "TabTitle4": "Fuel Issues",
                "TabTitle5": "Payments - Advance",
                "TabTitle6": "Receipts",
                "TabTitle7": "Calculation",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By",



                "TotalMFKM": "Total Manifest KM:*",
                "TotalIncentiveKM": "Total Incentive KM:",
                "TotalFuelReceived": "Total Fuel Received:",
                "RequiredFuel": "Required Fuel:",
                "FuelinTank": "Fuel in Tank:",
                "ActualFuelConsumption": "Actual Fuel Consumption:",
                "AdjustmentFuel": "Adjustment Fuel:",
                "FuelShortageQuantity": "Fuel Shortage Quantity:",
                "FuelPrice": "Fuel Price:",
                "ShortageAmount": "Shortage Amount:",
                "TotalDriverIncentive": "Total Driver Incentive:",
                "AdditionalIncentive": "Additional Incentive:",
                "TotalAdvances": "Total Advances:",
                "TotalExpenses": "Total Expenses:",
                "TotalReceipts": "Total Receipts:",
                "GoodsShortages": "Goods Shortages:",
                "TyreDeductions": "Tyre Deductions:",
                "OtherDeductions": "Other Deductions:",
                "TotalIncentive": "Total Incentive:",
                "TotalDeduction": "Total Deduction:",
                "NetIncentive": "Net Incentive:*"

            }
        },
        "assettype": {
            "management": {
                "Title": "Asset Type",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Asset Type",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },
        "assetlocation": {
            "management": {
                "Title": "Asset Location",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Asset Location",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },
        "activitytype": {
            "management": {
                "Title": "Activity Type",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Activity Type",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "activitygoal": {
            "management": {
                "Title": "Activity Goal",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Activity Goal",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },


        "addresstype": {
            "management": {
                "Title": "Address Type",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Address Type",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "industries": {
            "management": {
                "Title": "Industries",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Industries",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "relationshiptype": {
            "management": {
                "Title": "Relationship Type",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Relationship Type",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "costtype": {
            "management": {
                "Title": "Cost Type",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Cost Type",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },


        "revenuetype": {
            "management": {
                "Title": "Revenue Type",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Revenue Type",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },



        "expensetype": {
            "management": {
                "Title": "Expense Type",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Expense Type",
                "SubTitle": "Sub Title",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },


        "membershiptype": {
            "management": {
                "Title": "Membership Type",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Membership Type",
                "SubTitle": "Sub Title",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },


        "campaigntype": {
            "management": {
                "Title": "Campaign Type",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Campaign Type",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },


        "eventtype": {
            "management": {
                "Title": "Event Type",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Event Type",
                "SubTitle": "Sub Title",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "sessionformat": {
            "management": {
                "Title": "Session Format",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Session Format",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "venuetype": {
            "management": {
                "Title": "Venue Type",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Venue Type",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "partnerrole": {
            "management": {
                "Title": "Partner Role",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Partner Role",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "partnershiptype": {
            "management": {
                "Title": "Partnership Type",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Partnership Type",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },


        "commitmenttype": {
            "management": {
                "Title": "Commitment Type",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Commitment Type",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },


        "inviteetype": {
            "management": {
                "Title": "Invitee Type",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Invitee Type",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "decisionlevel": {
            "management": {
                "Title": "Decision Level",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Decision Level",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "dealhorizons": {
            "management": {
                "Title": "Deal Horizons",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Deal Horizons",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "solutionrequested": {
            "management": {
                "Title": "Solution Requested",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Solution Requested",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },


        "responsemethods": {
            "management": {
                "Title": "Response Methods",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Response Methods",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "appointment": {
            "management": {
                "Title": "Appointment",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Appointment",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "opportunitygroup": {
            "management": {
                "Title": "Opportunity Group",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Opportunity Group",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "salesstage": {
            "management": {
                "Title": "Sales Stage",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Sales Stage",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "threatlevel": {
            "management": {
                "Title": "Threat Level",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Threat Level",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "territory": {
            "management": {
                "Title": "Territory",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Territory",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "activity": {
            "management": {
                "Title": "Activity",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "ActivityTypeName": "Activity Type",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Activity",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "ActivityTypename": "Activity Type:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "hrcategory": {
            "management": {
                "Title": "Category",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Category",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "assetacquisition": {
            "management": {
                "Title": "Asset Acquisition",
                "SubTitle": "Asset Acquisition",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "DocumentNo": "Document No.",
                    "DocumentDate": "Document Date",
                    "assetName": "Asset",
                    "assetTypeName": "Type",
                    "assetCategoryName": "Category",
                    "locationName": "Location",
                    "costCenterCode": "Cost Center",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Asset Acquisition",
                "SubTitle": "Asset Acquisition",
                "Tab1": "General",
                "Tab2": "Details",
                "PrintButton": "Print",
                "SubOrgCode": "Org/SubOrg Code:*",
                "orgName": "Org/SubOrg Name:",
                "acquiredAs": "Acquired As:",
                "purchaseType": "Purchase Type:",
                "documentNo": "Document No:",
                "documentDate": "Document Date:",
                "assetId": "Asset:",
                "assetDescription": "Description:",
                "assetTypeId": "Asset Type:",
                "assetCategoryId": "Asset Category:",
                "bankCashAccountId": "Bank/Cash Account:",
                "subAssetApplicable": "SubAsset Applicable:",
                "assetPhysicalId": "Asset Physical Id:",
                "parentId": "Parent Id:",
                "datePutToUse": "Date Put To Use:",
                "locationId": "Location:",
                "purchasePrice": "Purchase Price:",
                "quantity": "quantity:",
                "assetCost": "Asset Cost:",
                "lifInYears": "Life In Years:",
                "costCenterId": "Cost Center:",
                "departmentId": "Department:",
                "vendorId": "Vendor Name:",
                "assetImage": "Asset Image:",
                "poNo": "PO No:",
                "poDate": "PO Date:",
                "vendorBillNo": "Vendor Bill No:",
                "vendorBillDate": "Vendor Bill Date:",
                "modelNo": "Model No:",
                "serialNo": "Serial No:",
                "compActDepRate": "Comp Act Dep Rate:",
                "compActDepMethod": "Comp Act Dep Method:",
                "itActDepRate": "IT Act Dep Rate:",
                "itActDepMethod": "IT Act Dep Method:",
                "subAssetNo": "Sub Asset No:",
                "subAssetDescription": "SubAsset Description:",
                "subAssetValue": "SubAsset Value",
                "salvageValue": "Salvage Value:",
                "accumulatedDepreciation": "Accumulated Depreciation:",
                "bookValue": "Book Value:",
                "Currency": "Currency:",
                "remarks": "Remarks:",


                "SNo": "SNo",
                "AssetNo": "Asset No",
                "Category": "Category",
                "Location": "Location",
                "Department": "Department",
                "CostCenter": "Cost Center",
                "BookValue": "BookValue",
                "AccumulatedDep": "Accumulated Depreciation",
                "SalvageValue": "Salvage Value",
                "AssetLife": "Asset Life",
                "InServiceDate": "InService Date",
                "Remarks": "Remarks",


                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },


        "AssetRevaluation": {
            "management": {
                "Title": "Asset Revaluation",
                "SubTitle": "Asset Revaluation",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "DocumentNo": "Document No.",
                    "DocumentDate": "Document Date",
                    "assetName": "Asset",
                    "assetTypeName": "Type",
                    "assetCategoryName": "Category",
                    "locationName": "Location",
                    "costCenterCode": "Cost Center",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Asset Revaluation",
                "SubTitle": "Asset Revaluation",
                "Tab1": "General",
                "Tab2": "Details",
                "PrintButton": "Print",
                "SubOrgCode": "Org/SubOrg Code:*",
                "orgName": "Org/SubOrg Name:",
                "acquiredAs": "Acquired As:",
                "purchaseType": "Purchase Type:",
                "documentNo": "Document No:",
                "documentDate": "Document Date:",
                "assetId": "Asset:",
                "assetDescription": "Description:",
                "assetTypeId": "Asset Type:",
                "assetCategoryId": "Asset Category:",
                "bankCashAccountId": "Bank/Cash Account:",
                "subAssetApplicable": "SubAsset Applicable:",
                "assetPhysicalId": "Asset Physical Id:",
                "parentId": "Parent Id:",
                "datePutToUse": "Date Put To Use:",
                "locationId": "Location:",
                "purchasePrice": "Purchase Price:",
                "quantity": "quantity:",
                "assetCost": "Asset Cost:",
                "lifInYears": "Life In Years:",
                "costCenterId": "Cost Center:",
                "departmentId": "Department:",
                "vendorId": "Vendor Name:",
                "assetImage": "Asset Image:",
                "poNo": "PO No:",
                "poDate": "PO Date:",
                "vendorBillNo": "Vendor Bill No:",
                "vendorBillDate": "Vendor Bill Date:",
                "modelNo": "Model No:",
                "serialNo": "Serial No:",
                "compActDepRate": "Comp Act Dep Rate:",
                "compActDepMethod": "Comp Act Dep Method:",
                "itActDepRate": "IT Act Dep Rate:",
                "itActDepMethod": "IT Act Dep Method:",
                "subAssetNo": "Sub Asset No:",
                "subAssetDescription": "SubAsset Description:",
                "subAssetValue": "SubAsset Value",
                "salvageValue": "Salvage Value:",
                "accumulatedDepreciation": "Accumulated Depreciation:",
                "bookValue": "Book Value:",
                "Currency": "Currency:",
                "remarks": "Remarks:",


                "SNo": "SNo",
                "AssetNo": "Asset No",
                "Category": "Category",
                "Location": "Location",
                "Department": "Department",
                "CostCenter": "Cost Center",
                "BookValue": "BookValue",
                "AccumulatedDep": "Accumulated Depreciation",
                "SalvageValue": "Salvage Value",
                "AssetLife": "Asset Life",
                "InServiceDate": "InService Date",
                "Remarks": "Remarks",


                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "Assettrasameunit": {
            "management": {
                "Title": "Asset Transfer Same Unit",
                "SubTitle": "Asset Transfer Same Unit",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "AssetNo": "Asset No",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Asset Transfer Same Unit",
                "SubTitle": "Asset Transfer Same Unit",
                "PrintButton": "Print",
                "subOrgId": "SubOrg Code:",
                "subOrgName": "SubOrg Name:",

                "documentNo": "Document No:",
                "documentDate": "Document Date:",
                "assetno": "Asset No:",
                "category": "Category:",
                "location": "Current Location:",
                "department": "Current Department:",
                "costcenter": "Current Cost Center:",
                "currentqty": "Current  Quantity:",
                "tolocation": "To Location:",
                "todepartment": "To Department:",
                "tocostcenter": "To Cost Center:",
                "qty": "Quantity:",
                "assetvalue": "Asset Value:",
                "remarks": "Remarks:",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },
        "Assettradiffunit": {
            "management": {
                "Title": "Asset Transfer Different Unit",
                "SubTitle": "Asset Transfer Different Unit",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "AssetNo": "Asset No",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Asset Transfer Different Unit",
                "SubTitle": "Asset Transfer Different Unit",
                "PrintButton": "Print",
                "subOrgId": "SubOrg Code:",
                "subOrgName": "SubOrg Name:",

                "documentNo": "Document No:",
                "documentDate": "Document Date:",
                "assetno": "Asset No:",
                "category": "Category:",
                "location": "Current Location:",
                "department": "Current Department:",
                "costcenter": "Current Cost Center:",
                "currentqty": "Current  Quantity:",
                "tosubOrgId": "To SubOrg Code:",
                "tosubOrgName": "To SubOrg Name:",
                "tolocation": "To Location:",
                "todepartment": "To Department:",
                "tocostcenter": "To Cost Center:",
                "qty": "Quantity:",
                "assetvalue": "Asset Value:",
                "remarks": "Remarks:",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },
        "AssetOpenBal": {
            "management": {
                "Title": "Asset Open Balance",
                "SubTitle": "Asset Open Balance",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Asset Open Balance",
                "Tab1": "General",
                "Tab2": "Details",
                "SubTitle": "Asset Open Balance",
                "PrintButton": "Print",
                "SubOrgCode": "Org/SubOrg Code:*",
                "orgName": "Org/SubOrg Name:",

                "documentNo": "Document No:",
                "documentDate": "Document Date:",
                "remarks": "Remarks:",
                "assetType": "Asset Type:",
                "assetCategory": "Asset Category:",
                "fromAssetNo": "Asset No:",
                "toAssetNo": "Asset No:",
                "fromSubAssetNo": "SubAsset No:",
                "toSubAssetNo": "SubAsset No:",
                "location": "Location:",
                "department": "Departmnent:",

                "SNo": "SNo",
                "AssetNo": "Asset No",
                "Category": "Category",
                "Location": "Location",
                "Department": "Department",
                "CostCenter": "Cost Center",
                "BookValue": "Book Value",
                "AccumulatedDep": "Accumulated Depreciation",
                "SalvageValue": "Salvage Value",
                "AssetLife": "Asset Life",
                "InServiceDate": "InService Date",
                "Remarks": "Remarks",

                "GetDetailsButton": "Get Details",
                "CloseButton": "Close",
                "OkButton": "Ok",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },
        "Assetrevaluation": {
            "management": {
                "Title": "Asset Re-Valuation",
                "SubTitle": "Asset Re-Valuation",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Asset Re-Valuation",
                "Tab1": "General",
                "Tab2": "Details",
                "SubTitle": "Asset Re-Valuation",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",

                "documentNo": "Document No:",
                "documentDate": "Document Date:",
                "Currency": "Currency:",
                "assetCategory": "Asset Category:",
                "fromAssetNo": "Asset No:",
                "toAssetNo": "Asset No:",
                "fromSubAssetNo": "SubAsset No:",

                "SNo": "SNo",
                "AssetNo": "Asset No",
                "AssetName": "Asset Name",
                "Category": "Category",
                "Location": "Location",
                "Department": "Department",
                "CostCenter": "Cost Center",
                "BookValue": "Book Value",
                "SalvageValue": "Salvage Value",
                "AssetLife": "Asset Life",
                "Remarks": "Remarks",

                "assetDesc": "Asset Desc",
                "subAssetNo": "Sub Asset No",
                "subAssetDesc": "Sub Asset Desc",
                "AssetCategory": "Asset Category",
                "subAssetValue": "Sub Asset Value",
                "previousRevaluationAmount": "Previous Revaluation Amount",
                "accumDeprn": "Accumulated Depreciation",
                "carryingAmount": "Carrying Amount",
                "revalued": "Revalued Value",
                "revaluationAmount": "Revaluation Amount",
                "remarks": "Remarks",

                "GetDetailsButton": "Get Details",
                "CloseButton": "Close",
                "OkButton": "Ok",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },
        "Saleordiscard": {
            "management": {
                "Title": "Sale or Discard",
                "SubTitle": "Sale OR Discard",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Sale or Discard",
                "SubTitle": "Sale or discard",
                "Tab1": "General",
                "Tab2": "Details",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",

                "documentNo": "Document No:",
                "documentDate": "Document Date:",
                "salesType": "Asset Type:",
                "customerCashBankId": "Customer/Cash/Bank:",
                "Currency": "Currency:",
                "ExchangeRate": "Exchange Rate:",
                "assetCategory": "Asset Category:",
                "fromAssetNo": "Asset No:",
                "toAssetNo": "Asset No:",
                "fromSubAssetNo": "Sub Asset No:",

                "SNo": "SNo",
                "AssetNo": "Asset No",
                "assetDesc": "Asset Description",
                "AssetName": "Sub Asset No",
                "subAssetDesc": "Sub Asset Description",
                "Category": "Asset Category",
                "Location": "Asset Location",
                "Department": "Department",
                "CostCenter": "Cost Center",
                "SaleValue": "Sale Value",
                "subAssetValue": "Sub Asset Value",
                "revaluationAmount": "Revaluation Amount",
                "accumDeprn": "Accum Depreciation",
                "carryingAmount": "Carrying Amount",
                "disposalType": "Disposal Type",
                "tax": "Remarks",
                "taxvalue": "Tax Value",
                "totalSales": "Total Sales",
                "profitLoss": "Profit Loss",
                "glAccount": "GL Account",
                "glAccountTaxValue": "GL Account Tax Value",
                "remarks": "Remarks",

                "GetDetailsButton": "Get Details",
                "CloseButton": "Close",
                "OkButton": "Ok",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "Depreciationpro": {
            "management": {
                "Title": "Depreciation Process",
                "SubTitle": "Depreciation Process",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "code": "Code",
                    "name": "Name:",
                    "rodITAct": "As per IT-Act",
                    "rodCompAct": "As per Books",
                    "description": "Description",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Depreciation Process",
                "SubTitle": "Depreciation Process",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",

                "actfordepreciation": "Act For Depreciation:",
                "processrunno": "Process Run No:",
                "processrundate": "Process Run Date:",
                "fycode": "FY Code:",
                "prodepuptofinper": "Process Deprec.UptoFin.Period:",
                "prodepfromdate": "Process Depr.From Date:",
                "prodeptodate": "Process Depr.from Date:",


                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "Commissioning": {
            "management": {
                "Title": "Commissioning",
                "SubTitle": "Commissioning",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "documentDate": "Document Date",
                    "unitCode": "Unit",
                    "previousKMReading": "Previous KM Reading",
                    "presentKMReading": "Present KM Reading",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Commissioning",
                "SubTitle": "Commissioning",
                "PrintButton": "Print",
                "orgId": "Station:",
                "unitId": "Unit:",
                "documentDate": "Document Date:",
                "truckHead": "Truck Head:",
                "previousKm": "Previous Km Reading:",
                "presentKm": "Present Km Reading:",
                "lastUpdateDate": "Last Update Date:",
                "firstTrailer": "First Trailer:",
                "dolly": "Dolly:",
                "secoundTrailer": "Secound Trailer:",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },
        "Decommissioning": {
            "management": {
                "Title": "Decommissioning",
                "SubTitle": "Decommissioning",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "documentDate": "Document Date",
                    "unitCode": "Unit",
                    "previousKMReading": "Previous KM Reading",
                    "presentKMReading": "Present KM Reading",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Decommissioning",
                "SubTitle": "Decommissioning",
                "PrintButton": "Print",
                "orgId": "Station:",
                "unitId": "Unit:",
                "documentDate": "Document Date:",
                "truckHead": "Truck Head:",
                "previousKm": "Previous KM Reading:",
                "presentKm": "Present KM Reading:",
                "lastUpdateDate": "Last Update Date:",
                "firstTrailer": "First Trailer:",
                "dolly": "Dolly:",
                "secoundTrailer": "Secound Trailer:",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "tripinvoice": {
            "management": {
                "Title": "Trip Invoice",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "CustomerName": "Customer Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Trip Invoice",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No:*",
                "DocumentDate": "Document Date:*",
                "CustomerCode": "Customer Code:*",
                "CustomerName": "Customer Name:",
                "ContractNo": "Contract No.:*",
                "ContractDate": "Contract Date:",
                "Currency": "Currency:*",
                "Remarks": "Remarks:",
                "ExchangeRate": "Exchange Rate:",
                "ExchangeDate": "Exchange Date:",
                "PaymentTermCode": "Payment Term Code:*",
                "PaymentTermName": "Payment Term Name:",

                "TripNo": "Trip No",
                "Date": "Date",
                "Weight": "Weight",
                "FreightRate": "Freight Rate",
                "FreightAmount": "Freight Amount",
                "NetAmount": "Net Amount",

                "GrossAmount": "Gross Amount",
                "TaxAmount": "Tax Amount",
                "TotalNetAmount": "Total Net Amount",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "nonstandarddeductions": {
            "management": {
                "Title": "Non Standard Deductions",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "EmployeeCode": "Employee Code",
                    "EmployeeName": "Employee Name",
                    "FromDate": "From Date",
                    "ToDate": "To Date",
                    "EffectiveDate": "Effective Date",
                    "Amount": "Amount",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Non Standard Deductions",
                "TabTitle1": "Credit Note Head",
                "TabTitle2": "Credit Note Details",
                "SubTitle": "SubTitle",

                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Employee": "Employee Code:*",
                "EffectiveDate": "Effective Date:*",
                "IncomeandDeductionId": "Income/Deduction Id:*",
                "Amount": "Amount:*",
                "EffectiveFromDate": "Effective From Date:",
                "EffectiveToDate": "Effective To Date:",
                "Remarks": "Remarks:",
                "file": "Upload File:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "intrabranchtransactions": {
            "management": {
                "Title": "Intra Branch Transactions",
                "SubTitle": "Intra Branch Transactions",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "transType": "Trans. Type:",
                "ExchangeRate": "Exchange Rate:*",
                "Date": "Date:",
                "SearchButton": "Search",
                "NewButton": "New",

                "GridHeaders": {
                    "Branch": "Branch",
                    "DocumentNo": "Document No.",
                    "Amount": "Amount",
                    "ToBranch": "To Branch",
                    "PostingDate": "Posting Date",
                    "TransType": "Trans. Type",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "General Receipts",
                "SubTitle": "General Receipts",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "BankCashCode": "Bank/Cash Code:",
                "BankCashName": "Bank/Cash Name:",
                "Amount": "Amount:",
                "TotalAmount": "Total Amount:",
                "IntraBranchTransaction": "Intra Branch Transaction:",
                "IntraBranch": "Intra Branch:",
                "Currency": "Currency:",
                "ExchangeRate": "Exchange Rate:",
                "ExchangeDate": "Exchange Date:",
                "Narration": "Narration::",
                "Code": "Code",
                "Name": "Name",
                "Debit": "Debit",
                "Credit": "Credit",
                "Segments": "Segments",
                "NarrationDet": "Narration",

                "ShortName": "Short Name:*",
                "AccountType": "Account Type:*",
                "ParentAccountGroup": "Parent Account Group:*",
                "Level": "Level:*",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By",

                "TabTitle1": "General",
                "TabTitle2": "Details"
            }
        },
        "requestforquotation": {
            "management": {
                "Title": "Request For Quotation",
                "SubTitle": "Request For Quotation",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Ref": "Reference",
                    "ValidityDate": "Validity Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Request For Quotation",
                "SubTitle": "Request For Quotation",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DivisionCode": "Division Code:*",
                "DivisionName": "Division Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "PurchaseCategory": "Purchase Category:*",
                "RFQCategory": "RFQ Category:*",
                "RefType": "Ref Type:*",
                "DeliveryBy": "Delivery By:*",
                "ByHand": "By Hand:",
                "TripRequired": "Trip Required:",
                "DeliveryMode": "Delivery Mode:*",
                "Currency": "Currency:*",
                "RequirementStatus": "Requirement Status:",
                "ValidityDate": "Validity Date:*",
                "Remarks": "Remarks:",

                "VendorTypeCode": "Vendor Type Code:",
                "VendorTypeName": "Vendor Type Name:",
                "VendorCode": "Vendor Code:",
                "VendorName": "Vendor Name:",

                "PrintButton": "Print",

                "tab1": "General Info",
                "tab2": "Item Details",
                "tab3": "Vendor Details",

                "ReferenceNo": "Reference No.",
                "ReferenceDate": "Reference Date",
                "Status": "Status",

                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "UOMCode": "UOM Code",
                "RefQty": "Ref.Quantity",
                "RefExeQty": "Ref. Exe. Quantity",
                "RefBalQty": "Ref. Bal. Quantity",
                "ExecutedQuantity": "Executed Quantity",
                "Quantity": "Quantity",
                "BalanceQuantity": "Balance Quantity",
                "ExpectedDateGrid": "Expected Date",
                "requestforQuotationQuantity": "Quantity",
                "RequestforQuotationQuantity": "Quantity",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"

            }
        },
        "purchasequotation": {
            "management": {
                "Title": "Purchase Quotation",
                "SubTitle": "Purchase Quotation",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Ref": "Reference",
                    "VendorName": "Vendor",
                    "ValidityDate": "Validity Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Purchase Quotation",
                "SubTitle": "Purchase Quotation",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DivisionCode": "Division Code:*",
                "DivisionName": "Division Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "type": "Vendor Type Code:*",
                "typeName": "Vendor Type Name:*",
                "VendorCode": "Vendor Code:*",
                "VendorName": "Vendor Name:",
                "vendorQuotationNo": "Vendor Quotation No.:*",
                "vendorQuotationDate": "Vendor Quotation Date:*",
                "quotationCategory": "Quotation Category:*",
                "WithRefTo": "With Ref To:*",
                "ReferenceId": "Reference:",
                "RefDate": "Ref Date:",
                "quotationValidity": "Quotation Validity:*",
                "Currency": "Currency:*",
                "deliveryBy": "Delivery By:*",
                "RequisitionBy": "Requisition By:*",
                "Remarks": "Remarks:",

                "deliveryFrom": "Delivery From:",
                "deliveryTo": "Delivery To:",
                "deliveryDate": "Delivery Date:",
                "deliveryMode": "Delivery Mode:",
                "paymentTerms": "Payment And Terms:",
                "creditDays": "Credit Days:",

                "PrintButton": "Print",
                "Status": "Status",

                "tab1": "General",
                "tab2": "Details",
                "tab3": "Other Details",

                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "UOMCode": "UOM Code",
                "refQty": "Quantity",
                "minOrder": "Min Order",
                "maxOrder": "Max Order",
                "freeQuantity": "Free Quantity",
                "price": "Price",
                "MRP": "MRP",
                "Discount": "Discount",
                "brandId": "Brand",
                "RemarksGrid": "Remarks",
                "brandScore": "Brand Score",
                "amount": "Amount",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"

            }
        },
        "salesreturn": {
            "management": {
                "Title": "Sales Return",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ActiveButton": "Approve",
                "InActiveButton": "Reject",
                "ViewButton": "View",
                "PrintButton": "Print",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "CustomerName": "Customer Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Sales Return",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "SubTitle",
                "ToStationCode": "Org/SubOrg Code:*",
                "ToStationName": "Org/SubOrg Name:*",
                "CustomerTypeCode": "Customer Type Code:*",
                "CustomerTypeName": "Customer Type Name:",
                "CustomerCode": "Customer Code:*",
                "CustomerName": "Customer Name:",
                "ToWareHouse": "To WareHouse:*",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "PaymentType": "Payment Type:*",
                "RefType": "Reference Type:*",
                "IssueNo": "Reference No:*",
                "BalQty": "Balance Quantity",
                "IssueDate": "Reference Date:",
                "Remarks": "Remarks:",
                "Currency": "Currency:",
                "TruckNo": "Truck No:",
                "DriverName": "Driver Name:",
                "PrintButton": "Print",
                "SalesRoute": "Sales Route",
                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "ItemPartNo": "Item Part No",
                "UOMCode": "UOM",
                "UOMName": "UOM Name",
                "LocationHead": "Location",
                "IssueQty": "Issue Quantity",
                "Qty": "Quantity",
                "Price": "Price",
                "Amount": "Amount",

                "OffLoadingCharges": "OffLoading Charges",

                "SaveButton": "Save",
                "CancelButton": "Back",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },
        "salesreturnsales": {
            "management": {
                "Title": "Sales Return Sales",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ActiveButton": "Approve",
                "InActiveButton": "Reject",
                "ViewButton": "View",
                "PrintButton": "Print",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "CustomerName": "Customer Name",
                    "ReferenceNo": "Reference No",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Sales Return Sales",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "TabTitle3": "Segment Details",
                "SubTitle": "SubTitle",
                "ToStationCode": "Org/SubOrg Code:*",
                "ToStationName": "Org/SubOrg Name:*",
                "CustomerTypeCode": "Customer Type Code:*",
                "CustomerTypeName": "Customer Type Name:",
                "CustomerCode": "Customer Code:*",
                "CustomerName": "Customer Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "PaymentType": "Payment Type:*",
                "RefType": "Reference Type:*",
                "IssueNo": "Reference No:*",
                "BalQty": "Balance Quantity",
                "IssueDate": "Reference Date:",
                "Remarks": "Remarks:",
                "Currency": "Currency:",
                "PrintButton": "Print",
                "SalesRoute": "Sales Route",
                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "ItemPartNo": "Item Part No",
                "UOMCode": "UOM",
                "UOMName": "UOM Name",
                "LocationHead": "Location",
                "IssueQty": "Issue Quantity",
                "Qty": "Quantity",
                "Price": "Price",
                "SalePriceExc": "SalePriceExc",
                "Amount": "Amount",

                "OffLoadingCharges": "OffLoading Charges",

                "sno": "SNo",
                "Code": "Segment Code",
                "Name": "Segment Name",
                "debitamount": "Debit Amount",
                "creditamount": "Credit Amount",

                "SaveButton": "Save",
                "CancelButton": "Back",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },
        "consignmentin": {
            "management": {
                "Title": "Consignment In",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Consignment In",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "SubTitle",
                "ToStationCode": "Org/SubOrg Code:*",
                "ToStationName": "Org/SubOrg Name:*",
                "VendorTypeCode": "Vendor Type Code:*",
                "VendorTypeName": "Vendor Type Name:",
                "VendorCode": "Vendor Code:*",
                "VendorName": "Vendor Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "DeliveryMode": "Delivery Mode:*",
                "ToWareHouse": "To WareHouse:*",
                "RefType": "Reference Type:*",
                "IssueNo": "Issue Number:*",
                "BalQty": "Balance Quantity:*",
                "IssueDate": "Issue Date:",
                "Remarks": "Remarks:",
                "PrintButton": "Print",
                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "ItemPartNo": "Item Part No",
                "UOMCode": "UOM Code",
                "UOMName": "UOM Name",
                "LocationHead": "Location",
                "IssueQty": "Issue Quantity",
                "Qty": "Quantity",
                "Price": "Price",
                "Amount": "Amount",

                "SaveButton": "Save",
                "CancelButton": "Back",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },
        "reconditioningorder": {
            "management": {
                "title": "ReConditioning Work Order",
                "newbutton": "New",
                "editbutton": "Edit",
                "deletebutton": "Delete",
                "PrintButton": "Print",

                "approvebutton": "Approve",
                "rejectbutton": "Reject",
                "viewbutton": "View",
                "exportbutton": "Export",
                "gridheaders": {
                    "organisation": "Organization",
                    "docno": "Document No",
                    "docdate": "Document Date",
                    "remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "title": "ReConditioning Order",
                "tabtitle1": "General",
                "tabtitle2": "Issue Items",
                "tabtitle3": "Receipt Items",
                "tabtitle4": "Other Detail",
                "subtitle": "SubTitle",

                "orgcode": "Org/SubOrg Code:*",
                "orgname": "Org/SubOrg Name:",
                "technicalDivisioncode": "Technical Division Code:*",
                "technicalDivisionname": "Technical Division Name:",
                "orderno": "Document No.:*",
                "orderdate": "Document Date:*",
                "reftype": "With Reference To:*",
                "pic": "Prod Indent Code:*",
                "workShopcode": "WorkShop Code:*",
                "workShopname": "WorkShop Name:",
                "deliverydate": "Delivery Date:*",
                "contactperson": "Contact Person:*",
                "remarks": "Remarks:",
                "pricebasis": "Price Basis:",
                "specifications": "Specifications:",
                "dnt": "Document Negotiated Through:",
                "tbdt": "To be Dispatched Through:",
                "tngc": "Test & Gurantee Certificate:",
                "inspnote": "Inspection Note:",
                "octroi": "Octroi:",
                "freight": "Freight:",
                "loadcharges": "Loading Charges:",

                "itemcode": "Item Code",
                "itemname": "Item Name",
                "uomcode": "UOM",
                "uomname": "UOM Name",
                "quantity": "Quantity",
                "muomname": "MUOM Name",
                "muomqty": "MUOM Quantity",
                "conchrgs": "Conversion Charges",
                "amount": "Amount",
                "melting": "Melting Loss",
                "PrintButton": "Print",
                "savebutton": "Save",
                "cancelbutton": "Back",
                "lastupdatedlabel": "Last Updated",
                "lastupdatedby": "Updated By"
            }
        },

        "reconditioningissue": {
            "management": {
                "title": "ReConditioning Issue",
                "newbutton": "New",
                "editbutton": "Edit",
                "deletebutton": "Delete",
                "approvebutton": "Approve",
                "rejectbutton": "Reject",
                "PrintButton": "Print",
                "viewbutton": "View",
                "exportbutton": "Export",
                "gridheaders": {
                    "organisation": "Organisation",
                    "docno": "Document No",
                    "docdate": "Document Date",
                    "remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "title": "ReConditioning Issue",
                "tabtitle1": "General",
                "tabtitle2": "Issue Items",
                "tabtitle3": "Receipt Items",
                "subtitle": "SubTitle",

                "orgcode": "Org/SubOrg Code:*",
                "orgname": "Org/SubOrg Name:",
                "issueno": "Document No.:*",
                "issuedate": "Document Date:*",
                "workShopcode": "WorkShop Code:*",
                "workShopname": "WorkShop Name:",
                "reftype": "With Reference To:*",
                "refno": "Reference No:*",
                "refdate": "Reference Date:",
                "FromWareHouse": "From WareHouse:*",
                "remarks": "Remarks:",

                "itemcode": "Item Code",
                "itemname": "Item Name",
                "uomcode": "UOM",
                "uomname": "UOM Name",
                "qtbi": "Qty to be Issued",
                "qai": "Qty Already Issued",
                "locationgrid": "Location",
                "qbi": "Qty Being Issued",
                "balqty": "Balance Quantity",
                "tolerance": "Tolerance",

                "savebutton": "Save",
                "cancelbutton": "Back",
                "lastupdatedlabel": "Last Updated",
                "lastupdatedby": "Updated By"
            }
        },

        "reconditioningreceipt": {
            "management": {
                "title": "ReConditioning Receipt",
                "newbutton": "New",
                "editbutton": "Edit",
                "deletebutton": "Delete",
                "approvebutton": "Approve",
                "rejectbutton": "Reject",
                "viewbutton": "View",
                "exportbutton": "Export",
                "gridheaders": {
                    "organisation": "Organization",
                    "docno": "Document No",
                    "docdate": "Document Date",
                    "remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "title": "ReConditioning Receipt",
                "tabtitle1": "General",
                "tabtitle2": "Receipt Items",
                "subtitle": "SubTitle",

                "orgcode": "Org/SubOrg Code:*",
                "orgname": "Org/SubOrg Name:",
                "divcode": "Division Code:*",
                "divname": "Division Name:",
                "number": "Document No.:*",
                "date": "Document Date:*",
                "workshopcode": "WorkShop Code:*",
                "workShopname": "WorkShop Name:",
                "reftype": "With Reference To:*",
                "refno": "Reference No:*",
                "refdate": "Reference Date:",
                "ToWareHouse": "To WareHouse:*",
                "remarks": "Remarks:",

                "itemcode": "Item Code",
                "itemname": "Item Name",
                "uomname": "UOM Name",
                "muomname": "MUOM Name",
                "muomqty": "MUOM Quantity",
                "expqty": "Expected Quantity",
                "conchrgs": "Conversion Charges",
                "invrecqty": "INV Received Quantity",
                "locationgrid": "Location",
                "accqty": "Accepted Quantity",
                "rejqty": "Rejected Quantity",
                "balqty": "Balance Quantity",
                "actualweight": "Actual Weight",
                "expweight": "Expected Weight",
                "melting": "Melting Loss",
                "amount": "Amount",
                "psi": "Purchase Service Item",
                "tolerance": "Tolerance",

                "savebutton": "Save",
                "cancelbutton": "Back",
                "lastupdatedlabel": "Last Updated",
                "lastupdatedby": "Updated By"
            }
        },


        "productionorder": {
            "management": {
                "title": "Production Work Order",
                "newbutton": "New",
                "editbutton": "Edit",
                "deletebutton": "Delete",
                "approvebutton": "Approve",
                "rejectbutton": "Reject",
                "viewbutton": "View",
                "exportbutton": "Export",
                "gridheaders": {
                    "organisation": "Organization",
                    "docno": "Document No",
                    "docdate": "Document Date",
                    "remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "title": "Production Order",
                "tabtitle1": "General",
                "tabtitle2": "Issue Items",
                "tabtitle3": "Receipt Items",
                "tabtitle4": "Other Detail",
                "subtitle": "SubTitle",

                "orgcode": "Org/SubOrg Code:*",
                "orgname": "Org/SubOrg Name:",
                "technicalDivisioncode": "Technical Division Code:*",
                "technicalDivisionname": "Technical Division Name:",
                "orderno": "Document No.:*",
                "orderdate": "Document Date:*",
                "reftype": "With Reference To:*",
                "pic": "Prod Indent Code:*",
                "workShopcode": "WorkShop Code:*",
                "workShopname": "WorkShop Name:",
                "deliverydate": "Delivery Date:*",
                "contactperson": "Contact Person:*",
                "remarks": "Remarks:",
                "pricebasis": "Price Basis:",
                "specifications": "Specifications:",
                "dnt": "Document Negotiated Through:",
                "tbdt": "To be Dispatched Through:",
                "tngc": "Test & Gurantee Certificate:",
                "inspnote": "Inspection Note:",
                "octroi": "Octroi:",
                "freight": "Freight:",
                "loadcharges": "Loading Charges:",
                "workCenterCode": "Work Center Code:*",
                "workCenterName": "Work Center Name:",

                "plantCode": "Plant Code:*",
                "plantName": "Plant Name:",

                "itemcode": "Item Code",
                "itemname": "Item Name",
                "uomcode": "UOM Code",
                "uomname": "UOM Name",
                "quantity": "Quantity",
                "muomname": "MUOM Name",
                "muomqty": "MUOM Quantity",
                "conchrgs": "Conversion Charges",
                "amount": "Amount",
                "melting": "Melting Loss",

                "savebutton": "Save",
                "cancelbutton": "Back",
                "lastupdatedlabel": "Last Updated",
                "lastupdatedby": "Updated By"
            }
        },

        "productionissue": {
            "management": {
                "title": "Production Issue",
                "newbutton": "New",
                "editbutton": "Edit",
                "deletebutton": "Delete",
                "approvebutton": "Approve",
                "rejectbutton": "Reject",
                "viewbutton": "View",
                "exportbutton": "Export",
                "gridheaders": {
                    "organisation": "Organisation",
                    "docno": "Document No",
                    "docdate": "Document Date",
                    "remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "title": "Production Issue",
                "tabtitle1": "General",
                "tabtitle2": "Issue Items",
                "tabtitle3": "Receipt Items",
                "subtitle": "SubTitle",

                "orgcode": "Org/SubOrg Code:*",
                "orgname": "Org/SubOrg Name:",
                "issueno": "Document No.:*",
                "issuedate": "Document Date:*",
                "workShopcode": "WorkShop Code:*",
                "workShopname": "WorkShop Name:",
                "reftype": "With Reference To:*",
                "refno": "Reference No:*",
                "refdate": "Reference Date:",
                "remarks": "Remarks:",

                "itemcode": "Item Code",
                "itemname": "Item Name",
                "uomcode": "UOM Code",
                "uomname": "UOM Name",
                "qtbi": "Qty to be Issued",
                "qai": "Qty Already Issued",
                "locationgrid": "Location",
                "qbi": "Qty Being Issued",
                "balqty": "Balance Quantity",
                "tolerance": "Tolerance",

                "savebutton": "Save",
                "cancelbutton": "Back",
                "lastupdatedlabel": "Last Updated",
                "lastupdatedby": "Updated By"
            }
        },

        "productionreceipt": {
            "management": {
                "title": "Production Receipt",
                "newbutton": "New",
                "editbutton": "Edit",
                "deletebutton": "Delete",
                "approvebutton": "Approve",
                "rejectbutton": "Reject",
                "viewbutton": "View",
                "ReverseButton": "Reverse",
                "RePostButton": "RePost",
                "exportbutton": "Export",
                "gridheaders": {
                    "organisation": "Organization",
                    "docno": "Document No",
                    "docdate": "Document Date",
                    "remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "title": "Production Receipt",
                "tabtitle1": "General",
                "tabtitle2": "Receipt Items",
                "subtitle": "SubTitle",

                "orgcode": "Org/SubOrg Code:*",
                "orgname": "Org/SubOrg Name:",
                "divcode": "Division Code:*",
                "divname": "Division Name:",
                "number": "Document No.:*",
                "date": "Document Date:*",
                "workshopcode": "WorkShop Code:*",
                "workShopname": "WorkShop Name:",
                "reftype": "With Reference To:*",
                "refno": "Reference No:*",
                "refdate": "Reference Date:",
                "ToWareHouse": "To WareHouse:*",
                "remarks": "Remarks:",

                "itemcode": "Item Code",
                "itemname": "Item Name",
                "uomname": "UOM Name",
                "muomname": "MUOM Name",
                "muomqty": "MUOM Quantity",
                "plantcode": "Plant Code:",
                "plantname": "Plant Name:",
                "expqty": "Expected Quantity",
                "conchrgs": "Conversion Charges",
                "invrecqty": "INV Received Quantity",
                "locationgrid": "Location",
                "accqty": "Accepted Quantity",
                "rejqty": "Rejected Quantity",
                "balqty": "Balance Quantity",
                "actualweight": "Actual Weight",
                "expweight": "Expected Weight",
                "melting": "Melting Loss",
                "amount": "Amount",
                "psi": "Purchase Service Item",
                "tolerance": "Tolerance",

                "savebutton": "Save",
                "cancelbutton": "Back",
                "lastupdatedlabel": "Last Updated",
                "lastupdatedby": "Updated By"
            }
        },





        "leaveissue": {
            "management": {
                "Title": "Leave Issue",
                "SubTitle": "Address",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "Date": "Document Date",
                    "Code": "Document No",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Leave Issue",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "Code": "Document No.:*",
                "Date": "Document Date:*",
                "Employee": "Employee:*",
                "RequisitionNo": "Requisition No:*",
                "LeaveType": "Leave Type:*",
                "FromDate": "From Date:*",
                "ToDate": "To Date:*",
                "NoofDays": "No of Leaves:*",
                "Remarks": "Remarks:",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "SaveButton": "Save",
                "PrintButton": "Print",
                "CancelButton": "Back",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Last Updated By"
            }
        },

        "consolidatedovertime": {
            "management": {
                "Title": "Consolidated Over Time",
                "SubTitle": "Address",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "EmployeeCode": "Employee Code",
                    "EmployeeName": "Employee Name",
                    "Month": "Month",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Consolidated Over Time",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "EmployeeCode": "Employee Code:*",
                "EmployeeName": "Employee Name:*",
                "Month": "Month:*",

                "OverTimeType": "Over Time Type",
                "NoofHours": "No Of Hours",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "SaveButton": "Save",
                "PrintButton": "Print",
                "CancelButton": "Back",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Last Updated By"
            }
        },

        "consignmentout": {
            "management": {
                "Title": "Consignment Out",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "PrintButton": "Print",
                "ApproveButton": "Approve",

                "RejectButton": "Reject",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "CustomerName": "Customer Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Consignment Out",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "SubTitle",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "IssueNo": "Document No.:*",
                "IssueDate": "Document Date:*",
                "CustomerCode": "Customer Code:*",
                "CustomerName": "Customer Name:",
                "RefType": "Reference Type:*",
                "IndentNo": "Indent Number:*",
                "IndentDate": "Indent Date:",
                "IssuedBy": "Issued By:",
                "UnitNo": "Unit No:*",
                "FromWareHouse": "From WareHouse:*",
                "Remarks": "Remarks:",
                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "UOMCode": "UOM",
                "UOMName": "UOM Name",
                "ReqQty": "Req. Quantity",
                "ReqDate": "Req.Date",
                "LocationHead": "Location",
                "IssueQty": "Issue Quantity",
                "BalQty": "Balance Quantity",
                "Location": "Location",
                "BinType": "Bin Type",
                "Bins": "Bins",
                "PhyQty": "Physical Quantity",
                "SaveButton": "Save",
                "PrintButton": "Print",
                "CancelButton": "Back",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },
        "salesserviceinvoice": {
            "management": {
                "title": "Sales Service Invoice",
                "newbutton": "New",
                "editbutton": "Edit",
                "deletebutton": "Delete",
                "approvebutton": "Approve",
                "rejectbutton": "Reject",
                "viewbutton": "View",
                "exportbutton": "Export",
                "PrintButton": "Print",
                "ReverseButton": "Reverse",
                "RePostButton": "RePost",
                "gridheaders": {
                    "organisation": "Organization",
                    "docno": "Document No",
                    "docdate": "Document Date",
                    "CustomerName": "Customer Name",
                    "Status": "Status"
                }
            },
            "create": {
                "title": "Sales Service Invoice",
                "tabtitle1": "General",
                "tabtitle2": "Details",
                "TabTitle3": "Segment Details",
                "subtitle": "SubTitle",
                "PrintButton": "Print",
                "orgcode": "Org/SubOrg Code:*",
                "orgname": "Org/SubOrg Name:",
                "number": "Document No:*",
                "date": "Document Date:*",
                "duedate": "Due Date:*",
                "reftype": "With Reference To:*",
                "customercode": "Customer Code:*",
                "customername": "Customer Name:",
                "customertype": "Customer Type:*",
                "customergroup": "Customer Group:*",
                "billnumber": "Bill No:*",
                "billdate": "Bill Date:*",
                "billingaddress": "Billing Address:*",
                "currency": "Currency:*",
                "excgrate": "Exchange Rate:*",
                "remarks": "Remarks:",

                "refno": "Reference No.",
                "refdate": "Reference Date",
                "refstatus": "Status",

                "itemcode": "Item Code",
                "itemname": "Item Name",
                "uomcode": "UOM Code",
                "uomname": "UOM Name",
                "quantity": "Quantity",
                "price": "Service Price",
                "amount": "Amount",
                "remark": "Remarks",

                "sno": "SNo",
                "Code": "Segment Code",
                "Name": "Segment Name",
                "debitamount": "Debit Amount",
                "creditamount": "Credit Amount",

                "savebutton": "Save",
                "cancelbutton": "Back",
                "lastupdatedlabel": "Last Updated",
                "lastupdatedby": "Updated By"
            }
        },
        "salesproformainvoice": {
            "management": {
                "Title": "Sales Proforma Invoice",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "RefType": "Reference",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Sales Proforma Invoice",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "TabTitle3": "Item Details",
                "SubTitle": "SubTitle",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DivisionCode": "Division Code:*",
                "DivisionName": "Division Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "PurchaseCategory": "Purchase Category:*",
                "PurchaseType": "Purchase Type:*",
                "RefType": "Reference Type:*",
                "CustomerTypeCode": "Customer Type Code:*",
                "CustomerTypeName": "Customer Type Name:",
                "CustomerCode": "Customer Code:*",
                "CustomerName": "Customer Name:",
                "CustomerInvoiceNo": "Customer Invoice No:*",
                "CustomerInvoiceDate": "Customer Invoice Date:*",
                "FormType": "Form Type:*",
                "CreditDays": "Credit Days:*",
                "Currency": "Currency:*",
                "PaymentTerm": "Payment Term:*",
                "DueDate": "Due Date:*",
                "Narration": "Narration:*",

                "Amount": "Amount:*",
                "ComponentType": "Component Type:*",
                "ExchRate": "Exc. Rate:*",
                "ForexRate": "Forex Rate:",

                "ReferenceNo": "Reference No.",
                "ReferenceDate": "Reference Date",
                "StatusGrid": "Status",

                "SalesRoute": "Sales Route",
                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "ItemPartNo": "Item Part No",
                "UOMCode": "UOM Code",
                "RefQty": "Ref.Quantity",
                "RefPrice": "Ref.Price",
                "Quantity": "Quantity",
                "BalQty": "Bal.Quantity",
                "FreeQty": "Free Quantity",
                "Price": "Price",
                "AmountGrid": "Amount",
                "PrintButton": "Print",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },




        "advancerequest": {
            "management": {
                "Title": "Advance Payment Request",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Advance Payment Request",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "VendorCode": "Vendor Code:*",
                "VendorName": "Vendor Name:",
                "PurchaseOrderNo": "Purchase Order No:*",
                "PurchaseOrderDate": "Purchase Order Date:",
                "Currency": "Currency:*",
                "Remarks": "Remarks:",
                "ExchangeRate": "Exchange Rate:",
                "ExchangeDate": "Exchange Date:",


                "AdvancePaymentDate": "Advance Payment Date",
                "Percentage": "Percentage",
                "Amount": "Amount",

                "TotalPOAmount": "Total PO Amount",
                "AlreadyPaidAmount": "Already Paid Amount",
                "PresentAmount": "Present Amount",
                "PendingAmount": "Pending Amount",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "transferpromotion": {
            "management": {
                "Title": "Transfer or Promotion",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "EmployeeCode": "Employee Code",
                    "EmployeeName": "Employee Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Transfer or Promotion",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Organisation": "Organization:",
                "DocumentNo": "Document No:",
                "DocumentDate": "Document Date:",
                "EffectiveDate": "Effective Date:",
                "Employee": "Employee Name:",
                "PresentSubOrganisation": "Present SubOrganization:",
                "PresentDepartment": "Present Department:",
                "PresentDesignation": "Present Designation:",
                "PresentGrade": "Present Grade:",
                "PresentCategory": "Present Category:",
                "NewSubOrganisation": "New Sub Organization:",
                "NewDepartment": "New Department:",
                "NewDesignation": "New Designation:",
                "NewGrade": "New Grade:",
                "NewCategory": "New Category:",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }

        },


        "userusergroup": {
            "management": {
                "Title": "User - User Group",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "User Group Name",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "User - User Group",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No:*",
                "Documentdate": "Document Date:*",
                "ForMonth": "For Month:",
                "EmployeeCode": "Employee Code",
                "EmployeeName": "Employee Name",
                "Name": "User Name",
                "UserId": "User Id",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "report": {
                "Title": "User - User Group",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "User Group Name:*",
                "Documentdate": "Document Date:*",
                "ForMonth": "For Month:",
                "Name": "User Name",
                "UserId": "User Code",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "csvreportgeneration": {
            "management": {
                "Title": "CSV Report Export",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",

                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",

                "GridHeaders": {
                    "Org": "Organization",
                    "ModuleName": "Module Name",
                    "ReportName": "Report Name",
                    "spname": "SP Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "CSV Report Export",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "ModuleName": "Module Name:*",
                "ReportName": "Report Name:*",
                "SpName": "Sp Name:",
                "TransType": "Trans Type:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "report": {
                "Title": "User - User Group",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.:*",
                "Documentdate": "Document Date:*",
                "ForMonth": "For Month:",
                "EmployeeCode": "Employee Code",
                "EmployeeName": "Employee Name",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },

            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },
        "accounts": {
            "management": {
                "Title": "Accounts",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Orgname",
                    "Code": "Code",
                    "Name": "Name",
                    "Status": "Status"
                }
            },

            "create": {
                "Title": "Accounts",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "PrintButton": "Print",
                "OrgCode": "Org/SubOrg Code:*",
                "OrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Phone Number": "Phone Number:",
                "Mobile Number": "Mobile Number:*",
                "Fax": "Fax:",
                "EmailAddress": "Email Address:*",
                "AccountsType": "Accounts Type:*",
                "Industry": "Industry Name:*",
                "AnnualRevenue": "Annual Revenue:",
                "NoOfEmployees": "NoOf Employees:",
                "CampaignType": "Campaign Type:*",
                "Member Of": "Member Of:*",

                "Country": "Country:*",
                "State": "State:*",
                "City": "City:*",
                "Pincode": "Pin code:*",
                "BillingAddress": "Billing Address:",
                "ShippingAddress": "Shipping Address:",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "campaign": {
            "management": {
                "Title": "Campaign",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Orgname",
                    "Name": "Name",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },

            "create": {
                "Title": "Campaign",
                "Campaign": "General",
                "TargetList": "Details",
                "Title3": "Budget",
                "PrintButton": "Print",
                "OrgCode": "Org/SubOrg Code:*",
                "OrgName": "Org/SubOrg Name:",
                "Name": "Name:*",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "StartDate": "Start Date:*",
                "EndDate": "End Date:*",
                "AssignedTo": "Assigned To:*",
                "SalesDivision": "Sales Division:*",
                "SalesRegion": "Sales Region:*",
                "Description": "Description:",
                "CampaignType": "Campaign Type:",
                "MarketingPlanCode": "Marketing Plan Code:*",
                "Date": "Date:*",

                "TargetCode": "Target Code:",
                "TargetName": "Target Name:",
                "Type": "Type:",
                "TargetIn List": "TargetIn List:",

                "Currency": "Select Currency:*",
                "Impression": "Impression:*",
                "Budget": "Budget:*",
                "Expected Cost": "Expected Cost:*",
                "Actual Cost": "Actual Cost:*",
                "Expected Revanue": "Expected Revenue:*",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "Contacts": {
            "management": {
                "Title": "Contacts",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Orgname",
                    "DocumentName": "Document No",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },

            "create": {
                "Contacts": "Contacts",
                "PrintButton": "Print",
                "OrgCode": "Org/SubOrg Code:*",
                "OrgName": "Org/SubOrg Name:",
                "documentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "firstName": "First Name:*",
                "lastName": "Last Name:*",
                "phone": "Phone No:",
                "mobile": "Mobile No:*",
                "fax": "Fax:*",
                "emailAddress": "Email Address:*",
                "title": "Title:",
                "department": "Department Name:*",
                "accountName": "Account Name:*",
                "assignTo": "Assign To:*",
                "leadSource": "Lead Source:*",
                "reportTo": "Report To:",
                "campaign": "Campaign:*",
                "address": "Permanent Address:*",
                "altAddress": "Current Address:*",
                "description": "Description:",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "Documents": {
            "management": {
                "Title": "Documents",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Orgname",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },

            "create": {
                "Documents": "Documents",
                "PrintButton": "Print",
                "OrgCode": "Org/SubOrg Code:*",
                "OrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.:*",
                "DocumentFile": "Document File:*",
                "DocumentName": "Document Name:*",
                "DocumentDate": "Document Date:*",
                "DocumentType": "Select DocumentType:*",
                "PublishDate": "Publish Date:*",
                "ExpirationDate": "Expiration Date:*",
                "Revision": "Revision:*",
                "Category": "Select Category:*",
                "SubCategory": "Select Sub Category:*",
                "RelatedDoc": "Related Document:*",
                "RelatedDocRevision": "Related Document Revision:*",
                "AssignTo": "Assign To:*",
                "Description": "Description:*",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "Leads": {
            "management": {
                "Title": "Leads",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Orgname",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },

            "create": {
                "Title": "Leads",
                "TabTitle1": "General",
                "DetailsProduct": "Details Product",
                "CurrentProducts": "Current Products",
                "PrintButton": "Print",
                "OrgCode": "Org/SubOrg Code:*",
                "orgName": "Org/SubOrg Name:",
                "documentNo": "Document No.:*",
                "documentDate": "Document Date:*",
                "executive": "Select Executive:*",
                "title": "Title:*",
                "clientName": "Client Name:*",
                "jobTitile": "Job Titile:*",
                "phone": "Phone No:*",
                "mobile": "Mobile No:*",
                "fax": "Fax:",
                "email": "Email Address:*",
                "leadSource": "Lead Source:*",
                "followupDate": "Followup Date:*",
                "reference": "Write a Reference:",

                "ProductCode": "Product Code:",
                "ProductName": "Product Name:",
                "UOMCode": "UOM Code:",
                "UOMName": "UOM Name:",
                "Quantity": "Quantity:",
                "Remarks": "Remarks:",
                "Brand": "Brand:",
                "Exchange": "Exchange:",
                "Age": "Age:",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "Opportunity": {
            "management": {
                "Title": "Opportunity",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Orgname",
                    "OpportunityName": "Name:",
                    "ExpectedCloseDate": "Date",
                    "Status": "Status"
                }
            },

            "create": {
                "Title": "Opportunity",
                "PrintButton": "Print",
                "OrgCode": "Org/SubOrg Code:*",
                "OrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "OpportunityName": "Opportunity Name:*",
                "AccountsName": "Accounts Name:*",
                "Currency": "Select Currency:*",
                "Campaign": "Select Campaign:*",
                "Type": "Select Business:*",
                "ExpectedCloseDate": "Expected Close Date:*",
                "OpportunityAmount": "Opportunity Amount:*",
                "SalesStage": "Sales Stage:*",
                "LeadSource": "Lead Source:*",
                "Probability": "Probability:*",
                "NextStep": "Next Step:*",
                "AssignTo": "Assign To:*",
                "Description": "Description:*",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "Project": {
            "management": {
                "Title": "Project",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Orgname",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },

            "create": {
                "Title": "Project",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "PrintButton": "Print",
                "OrgCode": "Org/SubOrg Code:*",
                "OrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "ProjectName": "Project Name:*",
                "ProjectManager": "Project Manager:*",
                "StartDate": "Start Date:*",
                "EndDate": "End Date:*",
                "Priority": "Select Priority:*",

                "TaskName": "Task Name:",
                "Duration": "Duration:",
                "AssignTo": "Assign To:",
                "Complete": "Complete:",
                "Milestone": "Milestone:",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "Surveys": {
            "management": {
                "Title": "Survey",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Orgname",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },

            "create": {
                "Title": "Survey",
                "Surveys": "General",
                "SurveysList": "Details",
                "PrintButton": "Print",
                "OrgCode": "Org/SubOrg Code:*",
                "orgName": "Org/SubOrg Name:",
                "documentNo": "Document No.:*",
                "documentDate": "Document Date:*",
                "name": "Name:*",
                "description": "Description:*",
                "AssignedTo": "Assigned To:*",
                "submitText": "Submit Text:*",
                "neitherText": "Neither Text:*",
                "satisfiedText": "Satisfied Text:*",
                "desatisfiedText": "Disatisfied Text:*",

                "Text": "Text:",
                "Type": "Type:",
                "Action": "Action:",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "target": {
            "management": {
                "Title": "Target",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Orgname",
                    "FirstName": "First Name",
                    "LastName": "LastName",
                    "Status": "Status"
                }
            },

            "create": {
                "Target": "Target",
                "PrintButton": "Print",
                "OrgCode": "Org/SubOrg Code:*",
                "OrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No:*",
                "DocumentDate": "Document Date:*",
                "FirstName": "First Name:*",
                "LastName": "Last Name:*",
                "PhoneNumber": "Phone No:",
                "MobileNumber": "Mobile No:*",
                "Fax": "Fax:",
                "JobTitle": "Job Title:*",
                "DepartmentName": "Department Name:*",
                "accountName": "Account Name:",
                "PrimaryAddress": "Primary Address:",
                "AlternateAddress": "Alternate Address:",
                "Description": "Description:",
                "Do Not Call": "Don't Call:",
                "AssignedTo": "Assigned To:*",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "aradjustment": {
            "management": {
                "Title": "AR Adjustment",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "AR Adjustment",
                "SubTitle": "SubTitle",

                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "CustomerCode": "Customer Code:*",
                "CustomerName": "Customer Name:",
                "TotalOutstandingAmount": "Total Outstanding Amount:",
                "aradjustmentDate": "Adjustment Date:*",

                "DocNo": "Document No.",
                "DocDate": "Document Date",
                "Type": "Type",
                "ValueAmount": "Value Amount",
                "NetAmount": "Adjusted Amount",
                "AdjAmount": "Adj. Amount",
                "BalAmount": "Balance Amount",
                "FS": "FS",
                "DueDate": "Due Date",
                "OrgCode": "Org Code",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "aradjustment2": {
            "management": {
                "Title": "AR Adjustment",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "AR Adjustment",
                "SubTitle": "SubTitle",

                "TabTitle1": "General",
                "TabTitle2": "Details",

                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "CustomerCode": "Customer Code:*",
                "CustomerName": "Customer Name:",
                "TotalOutstandingAmount": "Total Outstanding Amount:",
                "aradjustmentDate": "Adjustment Date:*",

                "DocNo": "Document No.",
                "DocDate": "Document Date",
                "Type": "Type",
                "ValueAmount": "Value Amount",
                "NetAmount": "Adjusted Amount",
                "AdjAmount": "Adj. Amount",
                "BalAmount": "Balance Amount",
                "FS": "FS",
                "DueDate": "Due Date",
                "OrgCode": "Org Code",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "empadjustment": {
            "management": {
                "Title": "Employee Adjustment",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Employee Adjustment",
                "SubTitle": "SubTitle",

                "TabTitle1": "General",
                "TabTitle2": "Details",

                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "EmployeeCode": "Employee Code:*",
                "EmployeeName": "Employee Name:",
                "TotalOutstandingAmount": "Total Outstanding Amount:",
                "aradjustmentDate": "Adjustment Date:*",

                "DocNo": "Document No.",
                "DocDate": "Document Date",
                "Type": "Type",
                "ValueAmount": "Value Amount",
                "NetAmount": "Adjusted Amount",
                "AdjAmount": "Adj. Amount",
                "BalAmount": "Balance Amount",
                "FS": "FS",
                "DueDate": "Due Date",
                "OrgCode": "Org Code",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "chequereturn": {
            "management": {
                "Title": "Cheque Return",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Orgname",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },

            "create": {
                "Title": "Cheque Return",
                "PrintButton": "Print",
                "OrgCode": "Org/SubOrg Code:*",
                "OrgName": "Org/SubOrg Name:",
                "Name": "Name:*",
                "DocumentNo": "Document No:*",
                "DocumentDate": "Document Date:*",
                "BankCode": "Bank Code:*",
                "BankName": "Bank Name:",
                "InstrumentNo": "Instrument No:*",
                "InstrumentDate": "Instrument Date:*",
                "PaymentDocNo": "Payment Document No:",
                "PostingDate": "Posting Date:*",
                "Amount": "Amount:*",
                "ReturnDate": "Return Date:*",
                "Charges": "Charges:",
                "Reason": "Reason:",
                "BankCashCode": "Bank Account No:*:",
                "BankCashName": "Bank Account Name:",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },
        "consolidationmapping": {
            "management": {
                "Title": "Mapping",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Orgname",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },

            "create": {
                "Title": "Mapping",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "ConsolidationDefinitionCode": "Code:*",
                "ConsolidationAccountGroup": "Account Group:*",
                "AccountId": "Account Id:*",
                "AccountCode": "Account Code:*",
                "AccountName": "Account Name:*",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "budgetlineaccountmapping": {
            "management": {
                "Title": "Budget Line Account Mapping",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Orgname",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "BudgetLineName": "BudgetLine Name",
                    "ConAccountGroupName": "Account Group Name",
                    "ItemType": "Item Type",
                    "Status": "Status"
                }
            },

            "create": {
                "Title": "Budget Line Account Mapping",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "BudgetLineCode": "Budget Line Code:*",
                "ConsolidationAccountGroup": "Account Group:*",
                "Type": "Type:*",
                "SegmentCode": "Segment Code:*",
                "AccountId": "Account Id:*",
                "AccountCode": "Account Code:*",
                "AccountName": "Account Name:*",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "vacancygroup": {
            "management": {
                "Title": "Vacancy Group",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Orgname",
                    "VacancyCode": "Vacancy Code",
                    "Date": "Date",
                    "Status": "Status"
                }
            },

            "create": {
                "Title": "Vacancy Group",
                "PrintButton": "Print",
                "VacancyCode": "Vacancy Code:*",
                "Date": "Date:*",
                "Reference": "Reference:*",
                "ReferenceCode": "Reference Code:*",
                "OrgCode": "Org/SubOrg Code:*",
                "Department": "Department:*",
                "Description": "Description:",

                "Category": "Category:",
                "Position": "Position:",
                "Vacancy": "Vacancy:",
                "DateDuration": "Date Duration:",
                "Qualification": "Qualification:",
                "Experience": "Experience:",
                "Skill": "Skill:",

                "Phases": "Phases:",
                "Venue": "Venue:",
                "Time": "Time:",
                "Duration": "Duration:",
                "AuthorizedPerson": "Authorized Person:",
                "Remark": "Remark:",
                "TabTitle1": "General",
                "TabTitle2": "Vacancy Details:",
                "TabTitle3": "Selection Phases",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "selectionstatusupdation": {
            "management": {
                "Title": "Selection Status Updation",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "StatusUpdationCode": "Status Updation Code",
                    "SelectionDate": "Selection Date",
                    "Status": "Status"
                }
            },

            "create": {
                "Title": "Selection Status Updation",
                "PrintButton": "Print",
                "StatusUpdationCode": "Status Updation Code:*",
                "VacancyCode": "Vacancy Code:*",
                "SelectionDate": "Selection Date:*",
                "InterviewPhase": "Interview Phase:*",
                "InterviewDate": "Interview Date:*",
                "Venue": "Venue:*",
                "Time": "Time:*",
                "Duration": "Duration:*",
                "InterviewerName": "Interviewer Name:*",
                "Remarks": "Remarks:",
                "TabTitle1": "General",
                "TabTitle2": "Details:",
                "CVCode": "CV Code:",
                "CandidateName": "Candidate Name:",
                "Status": "Status:",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "joboffergeneration": {
            "management": {
                "Title": "Job Offer Generation",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "VacancyCode": "Vacancy Code",
                    "Date": "Date",
                    "Status": "Status"
                }
            },

            "create": {
                "Title": "Job Offer Generation",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "PrintButton": "Print",
                "StatusUpdationCode": "Status Updation Code:*",
                "VacancyCode": "Vacancy Code:*",
                "OrgCode": "Org/SubOrg Code:*",
                "Department": "Department:*",
                "Date": "Date:*",
                "Currency": "Currency:*",

                "Name": "Candidate Name:",
                "Designation": "Designation:",
                "Wage": "Wage:",
                "OfferDate": "Offer Date:",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "pretraining": {
            "management": {
                "Title": "Pre Training",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "PreTrainingCode": "Pre Training Code",
                    "Date": "Date",
                    "Status": "Status"
                }
            },

            "create": {
                "Title": "Pre Training",
                "PrintButton": "Print",
                "OrgCode": "Org/SubOrg Code:*",
                "OrgName": "Org/SubOrg Name:",
                "PreTrainingCode": "Document No.:*",
                "PreTrainingDate": "Document Date:*",
                "TrainingType": "Training Type:*",
                "TrainerName": "Trainer Name:",
                "TrainerOrganization": "Trainer Organization:",
                "WorkExperience": "Work Experience:",
                "ConceptualKnowledge": "Conceptual Knowledge:",
                "TrainingExperience": "Training Experience:",
                "PreviousTrainingFeedbacks": "Previous Training Feedbacks:",
                "Description": "Description:",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "trainingplan": {
            "management": {
                "Title": "Training Plan",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "PlanCode": "Plan Code",
                    "Date": "Date",
                    "Status": "Status"
                }
            },

            "create": {
                "Title": "Training Plan",
                "PrintButton": "Print",
                "OrgCode": "Org/SubOrg Code:*",
                "OrgName": "Org/SubOrg Name:",
                "PlanCode": "Plan Code:*",
                "PlanDate": "Plan Date:*",
                "PlanFrom": "Plan From:*",
                "PlanTo": "Plan To:*",
                "Budget": "Budget:*",
                "Description": "Description:",

                "TrainingType": "Training Type:",
                "TrainingFrom": "Training From:",
                "TrainingTo": "Training To:",
                "Title1": "Title:",
                "Prerequisites": "Prerequisites:",
                "CourseInfo": "Course Info:",
                "Objectives": "Objectives:",
                "AssessmentMethod": "Assessment Method:",
                "TrainerName": "Trainer Name:",
                "TrainerOrganization": "Trainer Organization:",
                "NoofPersons": "No of Persons:",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "trainingrequisition": {
            "management": {
                "Title": "Training Requisition",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "RequisitionCode": "Requisition Code",
                    "Date": "Date",
                    "Status": "Status"
                }
            },

            "create": {
                "Title": "Training Requisition",
                "PrintButton": "Print",
                "TabTitle1": "General",
                "TabTitle2": "Training Details",
                "TabTitle3": "Employee Details",
                "OrgCode": "Org/SubOrg Code:*",
                "OrgName": "Org/SubOrg Name:",
                "DepartmentCode": "Department Code:*",
                "DepartmentName": "Department Name:",
                "RequisitionCode": "Requisition Code:*",
                "RequisitionDate": "Requisition Date:*",
                "ReferenceType": "Reference Type:*",
                "PlanCode": "Plan Code:*",
                "PlanDate": "Plan Date:*",
                "Description": "Description:",

                "TrainingType": "Training Type:*",
                "Priority": "Priority:*",
                "TrainingFrom": "Training From:*",
                "TrainingTo": "Training To:*",

                "TrainingCource": "Training Cource:",
                "TrainingTitle": "Training Title:",
                "Prerequisites": "Pre requisites:",
                "Objectives": "Objectives:",
                "AssessmentMethod": "Assessment Method:",
                "TrainerName": "Trainer Name:",
                "TrainerOrganization": "Trainer Organization:",
                "Budget": "Budget:*",
                "NoofPersons": "No of Persons:*",
                "TrainingCourse": "Training Course:",
                "NoOfPersons": "No Of Persons:",

                "EmployeeName": "Employee Name:",
                "EmployeeCode": "Employee Code:",

                "ItemCode": "Item Code:",
                "ItemName": "Item Name:",
                "ConfirmedStartDate": "Confirmed Start Date:",
                "ConfirmedEndDate": "Confirmed End Date:",
                "ConfirmedQuantity": "Confirmed Quantity:",
                "OrderSystem": "Order System:",
                "OrderMethod": "Order Method:",
                "EOQ": "EOQ:",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },
        "trainingschedule": {
            "management": {
                "Title": "Training Schedule",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "ScheduleCode": "Schedule Code",
                    "Date": "Date",
                    "Status": "Status"
                }
            },

            "create": {
                "Title": "Training Schedule",
                "TabTitle1": "General",
                "TabTitle2": "Schedule Details",
                "TabTitle3": "Employee Details",
                "PrintButton": "Print",
                "OrgCode": "Org/SubOrg Code:*",
                "OrgName": "Org/SubOrg Name:",
                "DepartmentCode": "Department Code:*",
                "DepartmentName": "Department Name:",
                "ScheduleCode": "Schedule Code:*",
                "ScheduleDate": "Schedule Date:*",
                "RequisitionCode": "Requisition Code:*",
                "RequisitionDate": "Requisition Date:*",
                "Description": "Description:",

                "TrainingType": "Training Type:*",
                "Priority": "Priority:*",
                "TrainingFrom": "Training From:*",
                "TrainingTo": "Training To:*",

                "Date": "Date:",
                "FromTime": "From Time:",
                "ToTime": "To Time:",
                "Remarks": "Remarks:",

                "EmployeeCode": "Employee Code:",
                "EmployeeName": "Employee Name:",
                "Department": "Department:",
                "Designation": "Designation:",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "trainingexecution": {
            "management": {
                "Title": "Training Execution",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "ExecutionCode": "Execution Code",
                    "Date": "Date",
                    "Status": "Status"
                }
            },

            "create": {
                "Title": "Training Execution",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "PrintButton": "Print",
                "OrgCode": "Org/SubOrg Code:*",
                "OrgName": "Org/SubOrg Name:",
                "ExecutionCode": "Document No.:*",
                "ExecutionDate": "Document Date:*",
                "RequisitionCode": "Requisition Code:*",
                "RequisitionDate": "Requisition Date:*",
                "ScheduleCode": "Schedule Code:*",
                "ScheduleDate": "Schedule Date:*",
                "Budget": "Budget:*",
                "Expenditure": "Expenditure:*",
                "Description": "Description:",

                "TrainingType": "Training Type:*",
                "Priority": "Priority:*",

                "EmployeeCode": "Employee Code:",
                "EmployeeName": "Employee Name:",
                "Attendance": "Attendance:",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "trainingevaluation": {
            "management": {
                "Title": "Training Evaluation",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "EvaluationCode": "Evaluation Code",
                    "Date": "Date",
                    "Status": "Status"
                }
            },

            "create": {
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "Title": "Training Evaluation",
                "PrintButton": "Print",
                "OrgCode": "Org/SubOrg Code:*",
                "OrgName": "Org/SubOrg Name:",
                "EvaluationCode": "Document No.:*",
                "EvaluationDate": "Document Date:*",
                "RequisitionCode": "Requisition Code:*",
                "RequisitionDate": "Requisition Date:*",
                "Description": "Description:",

                "EmployeeCode": "Employee Code:",
                "EmployeeName": "Employee Name:",
                "Status": "Status:",
                "Remarks": "Remarks:",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "trainingfeedbackanalysis": {
            "management": {
                "Title": "Training Feedback Analysis",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "FeedbackAnalysisCode": "Document No.",
                    "Date": "Date",
                    "Status": "Status"
                }
            },

            "create": {
                "Title": "Training Feedback Analysis",
                "PrintButton": "Print",
                "OrgCode": "Org/SubOrg Code:*",
                "OrgName": "Org/SubOrg Name:",
                "FeedbackAnalysisCode": "Document No:*",
                "FeedbackAnalysisDate": "Document Date:*",
                "RequisitionCode": "Requisition Code:*",
                "RequisitionDate": "Requisition Date:*",
                "TrainerName": "Trainer Name:*",
                "TrainerOrganization": "Trainer Organization:*",
                "Description": "Description:",
                "Analysis": "Analysis:*",
                "Learnings": "Learnings:*",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "trainingcertificate": {
            "management": {
                "Title": "Training Certificate",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "CertificateCode": "Document No.",
                    "Date": "Date",
                    "Status": "Status"
                }
            },

            "create": {
                "Title": "Training Certificate",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "PrintButton": "Print",
                "OrgCode": "Org/SubOrg Code:*",
                "OrgName": "Org/SubOrg Name:",
                "CertificateCode": "Document No.:*",
                "CertificateDate": "Document Date:*",
                "RequisitionCode": "Requisition Code:*",
                "RequisitionDate": "Requisition Date:*",
                "Description": "Description:",

                "EmployeeCode": "Employee Code:",
                "EmployeeName": "Employee Name:",
                "Grade": "Grade:",
                "Remarks": "Remarks:",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "groupvisa": {
            "management": {
                "Title": "Group Visa",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "GroupVisaCode": "Group Visa Code",
                    "Date": "Date",
                    "Status": "Status"
                }
            },

            "create": {
                "Title": "Group Visa",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "PrintButton": "Print",
                "OrgCode": "Org/SubOrg Code:*",
                "OrgName": "Org/SubOrg Name:",
                "GroupVisaCode": "Group Visa Code:*",
                "GroupVisaDate": "Group Visa Date:*",
                "Description": "Description:",

                "Nationality": "Nationality:",
                "NoOfVisaApplied": "No Of Visa Applied:",
                "NoOfVisaSanctioned": "No Of Visa Sanctioned:",
                "IssuedVisa": "Issued Visa:",
                "Remarks": "Remarks:",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "hrmvisaapplication": {
            "management": {
                "Title": "Visa Application",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "ApplicationCode": "Application Code",
                    "Date": "Date",
                    "Status": "Status"
                }
            },

            "create": {
                "Title": "Visa Application",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "PrintButton": "Print",
                "OrgCode": "Org/SubOrg Code:*",
                "OrgName": "Org/SubOrg Name:",
                "ApplicationCode": "Application Code:*",
                "ApplicationDate": "Application Date:*",
                "GroupVisaCode": "Group Visa Code:*",
                "GroupVisaDate": "Group Visa Date:*",
                "Nationality": "Nationality:*",
                "NoOfVisas": "No Of Visa:*",
                "Description": "Description:",

                "Reference": "Reference:",
                "CVCode": "CV Code:",
                "CandidateName": "Candidate Name:",
                "Wage": "Wage:",
                "Designation": "Designation:",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "visareceipt": {
            "management": {
                "Title": "Visa Receipt",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "ReceiptCode": "Document No.",
                    "Date": "Date",
                    "Status": "Status"
                }
            },

            "create": {
                "Title": "Visa Receipt",
                "PrintButton": "Print",
                "OrgCode": "Org/SubOrg Code:*",
                "OrgName": "Org/SubOrg Name:",
                "ReceiptCode": "Document No.:*",
                "ReceiptDate": "Document Date:*",
                "GroupVisaCode": "Group Visa Code:*",
                "Nationality": "Nationality:*",
                "CandidateCode": "Candidate Code:*",
                "CandidateName": "Candidate Name:*",

                "Designation": "Designation:",
                "Wage": "Wage:",
                "DateOfBirth": "Date Of Birth:",
                "Qualification": "Qualification:",
                "Address": "Address:",
                "WorkExperience": "Work Experience:",
                "Agent": "Agent:",
                "PassportNumber": "Passport Number:",
                "PlaceOfIssue": "Place Of Issue:",
                "ValidUpto": "Valid Upto:",

                "VisaApplicationDate": "Visa Application Date:",
                "LabourApprovalDate": "Labour Approval Date:",
                "VisaReceiptDate": "Visa Receipt Date:",
                "VisaExpiryDate": "Visa Expiry Date:",
                "WorkingOrgCode": "Working Org/SubOrg Code:",
                "WorkingOrgName": "Working Org/SubOrg Name:",
                "Description": "Description:",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "visareplacement": {
            "management": {
                "Title": "Visa Replacement",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "ReplacementCode": "Document No.",
                    "Date": "Date",
                    "Status": "Status"
                }
            },

            "create": {
                "Title": "Visa Replacement",
                "PrintButton": "Print",
                "OrgCode": "Org/SubOrg Code:*",
                "OrgName": "Org/SubOrg Name:",
                "ReplacementCode": "Document No.:*",
                "ReplacementDate": "Document Date:*",
                "GroupVisaCode": "Group Visa Code:*",
                "Nationality": "Nationality:*",
                "OriginalCandidate": "Original Candidate:*",
                "OriginalCandidateName": "Original Candidate Name:*",
                "ReceiptCode": "Receipt Code:",
                "ReceiptDate": "Receipt Date:",
                "ReplaceCandidate": "Replace Candidate:*",
                "ReplaceCandidateName": "Replace Candidate Name:*",

                "Designation": "Designation:",
                "Wage": "Wage:",
                "DateOfBirth": "Date Of Birth:",
                "Qualification": "Qualification:",
                "Address": "Address:",
                "WorkExperience": "Work Experience:",
                "Agent": "Agent:",
                "PassportNumber": "Passport Number:",
                "PlaceOfIssue": "Place Of Issue:",
                "ValidUpto": "Valid Upto:",

                "VisaApplicationDate": "Visa Application Date:",
                "LabourApprovalDate": "Labour Approval Date:",
                "VisaReceiptDate": "Visa Receipt Date:",
                "VisaExpiryDate": "Visa Expiry Date:",
                "WorkingOrgCode": "Working Org/SubOrg Code:",
                "WorkingOrgName": "Working Org/SubOrg Name:",
                "Description": "Description:",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },



        "companymapping": {
            "management": {
                "Title": "Company Mapping",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "OrgName": "Org Name",
                    "Code": "Code",
                    "COAccountName": "COA Account Name",
                    "Status": "Status"
                }
            },

            "create": {
                "Title": "Company Mapping",
                "PrintButton": "Print",
                "OrgCode": "Org/SubOrg Code:*",
                "OrgName": "Org/SubOrg Name:",
                "COAccount": "COA Account:*",


                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "Requisition": {
            "management": {
                "Title": "Employee Requisition",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Department": "Department",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },

            "create": {
                "Title": "Employee Requisition",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "PrintButton": "Print",
                "organisation": "Org/SubOrg Code:*",
                "department": "Department:*",
                "documentNo": "Document No:*",
                "documentDate": "Document Date:*",
                "noOfRequirement": "No Of Requirement:*",
                "requirementPeriod": "Requirement Period:*",
                "priority": "Priority:*",
                "description": "Description:",

                "Designation": "Designation:",
                "Category": "Category:",
                "NoOfPositions": "No Of Positions:",
                "VaccancyType": "VacancyType:",
                "Duration": "Duration:",
                "Qualification": "Qualification:",
                "MinExpYears": "MinExpYears:",
                "Skills": "Skills:",
                "Remarks": "Remarks:",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "ResumeBank": {
            "management": {
                "Title": "Resume Bank",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },

            "create": {
                "Title": "Resume Bank",
                "TabTitle1": "Resume Bank",
                "TabTitle2": "Qualification",
                "TabTitle3": "Skill Details",
                "TabTitle4": "Employee History",
                "TabTitle5": "Reference",
                "TabTitle6": "Passport Information",
                "PrintButton": "Print",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "FirstName": "First Name:*",
                "MiddleName": "Middle Name:*",
                "LastName": "Last Name:*",
                "DateOfBirth": "Date Of Birth:*",
                "Gender": "Gender:*",
                "ExpInYears": "Exp In Years:*",
                "Photo": "Upload Photo:",
                "Resume": "Upload Resume:",
                "Nationality": "Nationality:*",
                "Department": "Department:*",
                "PostAppliedFor": "Post Applied For:*",
                "MajorAchievements": "Major Achievements:*",
                "RefEmployee": "Ref Employee:*",
                "RefEmployeeName": "Ref Employee Name:",
                "Refdepartment": "Ref Department:*",
                "RefDesignation": "Ref Designation:*",

                "Qualification": "Qualification:",
                "Specialization": "Specialization:",
                "Duration": "Duration:",
                "StartDate": "Start Date:",
                "EndDate": "End Date:",
                "Percentage": "Percentage:",
                "University": "University:",

                "Skill": "Skill:",
                "SkillName": "Skill Name:",

                "FromDate": "From Date:",
                "ToDate": "To Date:",
                "JoinDesignationId": "Join Designation:",
                "LeavDesignationId": "Leave Designation:",
                "SalaryAtJoin": "Salary At Join:",
                "SalaryAtLeav": "Salary At Leave:",
                "JobType": "Job Type:",
                "ReportingToId": "Reporting To:",
                "CompanyName": "Company Name:",
                "CompanyAddress": "Company Address:",
                "CompanyUrl": "Company Url:",
                "CountryId": "Country:",

                "ModeOfCollection": "Mode Of Collection:",
                "ReferenceName": "Reference Name:",
                "Address1": "Address1:",
                "Address2": "Address2:",
                "Country": "Country:",
                "State": "State:",
                "City": "City:",
                "PhoneNo": "Phone No:",
                "Mobile": "Mobile No:",
                "Fax": "Fax:",
                "Email": "Email Address:",
                "Website": "Website:",

                "PassportNumber": "Passport No.:*",
                "IssuedAt": "Issued At:*",
                "ExpiryDate": "Expiry Date:*",
                "DrivingLic": "Driving Lic:",
                "WillingToTravel": "Willing To Travel:",
                "Hobbies": "Hobbies:*",
                "Availability": "Availability:*",
                "ExpectedSalary": "Expected Salary:*",
                "NoticePeriod": "Notice Period:*",

                "AddressType": "Address Type:",
                "Province": "Province:",
                "ZipCode": "Zip Code:",
                "IPPhoneNo": "IP Phone No:",
                "MobileNo": "Mobile No:",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "ResumeShortlist": {
            "management": {
                "Title": "Resume Shortlist",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Department": "Department",
                    "ListCode": "List Code",
                    "VacancyCode": "Vacancy Code",
                    "Status": "Status"
                }
            },

            "create": {
                "Title": "Resume Shortlist",
                "PrintButton": "Print",
                "Oganisation": "Org/SubOrg Code:*",
                "listCode": "List Code:*",
                "VacancyCode": "Vacancy Code:*",
                "Department": "Department:*",
                "Skills": "Skills:*",
                "Qualification": "Qualification:*",
                "expFrom": "Exp From:*",
                "expTo": "Exp To:*",

                "CV": "CV:",
                "Name": "Name:",
                "ExpInYears": "Exp In Years:",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },





        "travelrequisition": {
            "management": {
                "Title": "Travel Requisition",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Date": "Date",
                    "Status": "Status"
                }
            },

            "create": {
                "Title": "Travel Requisition",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "PrintButton": "Print",
                "OrgCode": "Org/SubOrg Code:*",
                "OrgName": "Org/SubOrg Name:",
                "Code": "Document No.:*",
                "Date": "Document Date:*",
                "FromDate": "From Date:*",
                "ToDate": "To Date:*",
                "TravelType": "Travel Type:*",
                "PurposeofTravel": "Purpose of Travel:",

                "EmployeeName": "Employee Name:",
                "EmployeeCode": "Employee Code:",
                "Designation": "Designation:",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "travelplan": {
            "management": {
                "Title": "Travel Plan",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Document No",
                    "Date": "Document Date",
                    "Status": "Status"
                }
            },

            "create": {
                "Title": "Travel Plan",
                "TabTitle1": "General",
                "TabTitle2": "Employee Details",
                "TabTitle3": "Other Details",
                "PrintButton": "Print",
                "OrgCode": "Org/SubOrg Code:*",
                "OrgName": "Org/SubOrg Name:",
                "Department": "Department:*",
                "Reference": "Reference:*",
                "RequisitionCode": "Requisition Code:*",
                "Code": "Document No.:*",
                "Date": "Document Date:*",
                "FromDate": "From Date:*",
                "ToDate": "To Date:*",
                "TravelType": "Travel Type:*",
                "PurposeofTravel": "Purpose of Travel:",

                "EmployeeName": "Employee Name:",
                "EmployeeCode": "Employee Code:",
                "Designation": "Designation:",

                "TravelDetails": "Travel Details:",
                "PlaceDetails": "Place Details:",
                "AccomodationDetails": "Accomodation Details:",
                "MaterialDetails": "Material Details:",
                "ActivityDetails": "Activity Details:",
                "AdvanceDetails": "Advance Details:",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "movementorder": {
            "management": {
                "Title": "Movement Order",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Document No.",
                    "Date": "Document Date",
                    "Status": "Status"
                }
            },

            "create": {
                "Title": "Movement Order",
                "TabTitle1": "General",
                "TabTitle2": "Employee Details",
                "TabTitle3": "Other Details",
                "PrintButton": "Print",
                "OrgCode": "Org/SubOrg Code:*",
                "OrgName": "Org/SubOrg Name:",
                "Department": "Department:*",
                "TravelPlanCode": "Travel Plan Code:*",
                "TravelPlanDate": "Travel Plan Date:",
                "Code": "Document No.:*",
                "Date": "Document Date:*",
                "FromDate": "From Date:*",
                "ToDate": "To Date:*",
                "TravelType": "Travel Type:*",
                "PurposeofTravel": "Purpose of Travel:",

                "EmployeeName": "Employee Name:",
                "EmployeeCode": "Employee Code:",
                "Designation": "Designation:",

                "TravelDetails": "Travel Details:",
                "PlaceDetails": "Place Details:",
                "AccomodationDetails": "Accomodation Details:",
                "MaterialDetails": "Material Details:",
                "ActivityDetails": "Activity Details:",
                "AdvanceDetails": "Advance Details:",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "travelreport": {
            "management": {
                "Title": "Travel Report",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Document No.",
                    "Date": "Document Date",
                    "Status": "Status"
                }
            },

            "create": {
                "Title": "Travel Report",
                "PrintButton": "Print",
                "OrgCode": "Org/SubOrg Code:*",
                "OrgName": "Org/SubOrg Name:",
                "EmployeeName": "Employee Name:*",
                "EmployeeCode": "Employee Code:",
                "Designation": "Designation:*",
                "Department": "Department:*",
                "Reference": "Reference:*",
                "MovementOrderCode": "Movement Order Code:*",
                "Code": "Document No.:*",
                "Date": "Document Date:*",
                "FromDate": "From Date:*",
                "ToDate": "To Date:*",
                "TravelType": "Travel Type:*",
                "PurposeofTravel": "Purpose of Travel:",

                "TravelDetails": "Travel Details:",
                "PlaceDetails": "Place Details:",
                "AccomodationDetails": "Accomodation Details:",
                "MaterialDetails": "Material Details:",
                "ActivityDetails": "Activity Details:",
                "AdvanceDetails": "Advance Details:",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "travelfeedback": {
            "management": {
                "Title": "Travel Feedback",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Date": "Date",
                    "Status": "Status"
                }
            },

            "create": {
                "Title": "Travel Feedback",
                "PrintButton": "Print",
                "EmployeeName": "Employee Name:*",
                "EmployeeCode": "Employee Code:",
                "Designation": "Designation:*",
                "Department": "Department:*",

                "TravelFeedbackCode": "Travel Feedback Code:*",
                "Satisfaction": "Satisfaction:*",
                "Ticket": "Ticket:*",
                "Accomodation": "Accomodation:*",
                "TravelModeEntitled": "Travel Mode Entitled:*",
                "TravelModeAvailed": "Travel Mode Availed:*",
                "AccomodationEntitled": "Accomodation Entitled:*",
                "AccomodationAvailed": "Accomodation Availed:*",
                "Feedback": "Feedback:",
                "Suggestion": "Suggestion:",
                "Remarks": "Remarks:",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },
        "overtime": {
            "management": {
                "Title": "Over Time",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "FromDate": "From Date",
                    "ToDate": "To Date",
                    "Status": "Status"
                }
            },

            "create": {
                "Title": "Over Time",
                "PrintButton": "Print",
                "OrgName": "Org/SubOrg Name:",
                "ForThePeriod": "For The Period:*",
                "fromDate": "From Date:*",
                "toDate": "To Date:*",

                "EmployeeName": "Employee Name:",
                "EmployeeCode": "Employee Code:",
                "Designation": "Designation:",
                "NormalOT": "Normal OT:",
                "HolidayOT": "Holiday OT:",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "overtime2": {
            "management": {
                "Title": "Over Time",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "FromDate": "From Date",
                    "ToDate": "To Date",
                    "Status": "Status"
                }
            },

            "create": {
                "Title": "Over Time",
                "PrintButton": "Print",
                "OrgCOde": "Org/SubOrg Code:*",
                "OrgName": "Org/SubOrg Name:",
                "EmployeeCode": "Employee Code:*",
                "DepartmentCode": "Department Code:*",
                "fromDate": "From Date:*",
                "toDate": "To Date:*",

                "Days": "Days",
                "Dates": "Dates",
                "Reason": "Reason",
                "InTime": "In Time",
                "OutTime": "Out Time",
                "NoOfHours": "No Of Hours",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "OverTimePolicy": {
            "management": {
                "Title": "Over Time Policy",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "EffectedFrom": "Effected From",
                    "EffectedTo": "Effected To",
                    "Status": "Status"
                }
            },

            "create": {
                "Title": "Over Time Policy",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "PrintButton": "Print",
                "OrgCOde": "Org/SubOrg Code:*",
                "OrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "EffectedFrom": "Effected From:*",
                "EffectedTo": "Effected To:*",
                "Shift": "Shift:*",
                "RoundOff": "Round Off:*",
                "RoundOffType": "Round Off Type:*",
                "NormalOTHrs": "Normal OT Hrs:*",
                "HolidayOTHrs": "Holiday OT Hrs:*",

                "CategoryCode": "Category Code",
                "CategoryName": "Category Name",
                "NoOfNROTHRS": "No of Normal OT Hours",
                "NoOfHOLOTHRS": "No of Holiday OT Hours",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },


        "machinecategory": {
            "management": {
                "Title": "Machine Category",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name:",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Machine Category",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "plant": {
            "management": {
                "Title": "Plant",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name:",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Plant",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },
        "bomgroup": {
            "management": {
                "Title": "Production Bom Group",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name:",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Production Bom Group",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "wipwarehouse": {
            "management": {
                "Title": "WIP Warehouse",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name:",
                    "PlantName": "Plant Name ",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "WIP Warehouse",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "Plantname": "Plant Name:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },
        "workcenterlocation": {
            "management": {
                "Title": "Work Center Location",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name:",
                    "PlantName": "Plant Name ",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Work Center Location",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "Plantname": "Plant Name:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "routinggroup": {
            "management": {
                "Title": "Routing Group",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "Code": "Code",
                    "Name": "Name:",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Routing Group",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "ProductionBom": {
            "management": {
                "Title": "Production BOM",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Org Name",
                    "Code": "Code",
                    "Name": "Name:",
                    "ItemCode": "Item Code",
                    "TypeName": "Type",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Production BOM",

                "TabTitle1": "General",
                "TabTitle2": "BOM Item Details",
                "TabTitle3": "Output Item Details",
                "TabTitle4": "Bom Tree View",

                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "PlantId": "Plant Code:*",
                "PlantName": "Plant Name:*",
                "BomGroupId": "Bom Group Code:*",
                "BomGroupName": "Bom Group Name:*",

                "DocumentNo": "Code:*",
                "Name": "Name:*",
                "ShortName": "Short Name:*",
                "ItemId": "Item Code:*",
                "ItemName": "Item Name:*",
                "UOMId": "UOM:*",
                "ScrapFactor": "Scrap Factor:",
                "Description": "Description:",

                "SNo": "SNo:*",
                "ItemCode": "Item Code:*",
                "UOMCode": "UOM Code:*",
                "Quantity": "Quantity:*",


                "OutputType": "Output Type:*",



                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },


        "ProductionOperation": {
            "management": {
                "Title": "Operations",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Org Name",
                    "Code": "Code",
                    "Name": "Name:",
                    "TypeName": "Type Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Operations",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:*",
                "PlantId": "Plant Code:*",
                "PlantName": "Plant Name:*",
                "Code": "Code",
                "Name": "Name:",
                "ShortName": "Short Name:*",

                "TypeName": "Type Name:*",
                "WorkCenterId": "Work Center Code:*",
                "WorkCenterName": "Work Center Name",
                "ManOccupation": "Man Occupation",
                "MachineOccupation": "Machine Occupation:*",
                "Description": "Description:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },
        "ProductionRouting": {
            "management": {
                "Title": "Production Routing",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Org Name",
                    "Code": "Code",
                    "Name": "Name:",
                    "TypeName": "Type",
                    "Status": "Status"
                }
            },

            "create": {
                "Title": "Production Routing",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "PlantId": "Plant Code:*",
                "PlantName": "Plant Name:",
                "RoutingGroupId": "Routing Group Code:*",
                "RoutingGroupName": "Routing Group Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "ShortName": "Short Name:*",
                "TypeName": "Type:*",
                "Description": "Description:",

                "SNo": "SNo",
                "Overlap": "Overlap",
                "Lag": "Lag",
                "OperationCode": "Operation Code",
                "OperationName": "Operation Name",
                "WorkCenterCode": "Work Center Code",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "ItemWiseRouting": {
            "management": {
                "Title": "Item Wise Routing",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Org Name",
                    "PlantCode": "Plant Code",
                    "ItemCode": "Item Code",
                    "ItemName": "Item Name",
                    "Status": "Status"
                }
            },

            "create": {
                "Title": "Item Wise Routing",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "PlantId": "Plant Code:*",
                "PlantName": "Plant Name:",
                "ItemCode": "Item Code:*",
                "ItemName": "Item Name:",

                "SNo": "SNo",
                "RoutingCode": "Routing Code",
                "RoutingName": "Routing Name",
                "RoutingType": "Routing Type",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "Resource": {
            "management": {
                "Title": "Resource",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Org Name",
                    "Code": "Code",
                    "Name": "Name:",
                    "TypeName": "Type Name",
                    "Status": "Status"
                }
            },

            "create": {
                "Title": "Resource",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "PlantId": "Plant Code:*",
                "PlantName": "Plant Name:",
                "TypeName": "Type Name:*",
                "Code": "Code:*",
                "Name": "Name:*",
                "ShortName": "Short Name:*",
                "BasicDayCapacityhrs": "Basic Day Capacity hrs:*",
                "BasicWeekCapacityhrs": "Basic Week Capacity hrs:*",
                "Criticality": "Criticality:*",
                "ResourceUOMId": "Resource UOM:*",
                "UnitCost": "Unit Cost:*",
                "UOM": "UOM:*",
                "CurrencyId": "Currency Code:*",
                "SkillSetId": "Skill Set:*",
                "SkillTypeId": "Skill Type:*",
                "Description": "Description:",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },


        "WorkCenter": {
            "management": {
                "Title": "Work Center",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Org Name",
                    "Code": "Code",
                    "Name": "Name:",
                    "TypeName": "Type Name",
                    "Status": "Status"
                }
            },

            "create": {
                "Title": "Work Center",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "PlantId": "Plant Code:*",
                "PlantName": "Plant Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "ShortName": "Short Name:*",
                "WorkCenterTypeName": "Work Center Type:*",
                "WorkCenterId": "Work Center:*",
                "WIPWarehouseId": "WIP Warehouse:*",
                "Noofworkhrs": "No of work Hours:*",
                "ProductionRateApplicabilityId": "Production Rate Applicability:*",
                "LineBalanced": "Line Balanced:*",
                "BasicDayCapacityhrs": "Basic Day Capacity hrs:*",
                "BasicWeekCapacityhrs": "Basic Week Capacity hrs:*",
                "Criticality": "Criticality:*",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },
        "RoutingBom": {
            "management": {
                "Title": "Routing-BOM Mapping",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Org Name",
                    "ItemCode": "Item Code",
                    "BOMCode": "BOM Code",
                    "RoutingCode": "Routing Code",
                    "Status": "Status"
                }
            },

            "create": {
                "Title": "Routing-BOM Mapping",
                "TabTitle1": "General",
                "TabTitle2": "Input Item Details",
                "TabTitle3": "Output Item Details",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "PlantId": "Plant Code:*",
                "PlantName": "Plant Name:",
                "ItemId": "Item Code:*",
                "ItemName": "Item Name:*",
                "ProductionBomId": "Production Bom Code:*",
                "ProductionBomName": "Production Bom Name:*",
                "RoutingId": "Routing Code:*",
                "RoutingName": "Routing Name:*",
                "OperationCode": "Operation Code:*",
                "OperationName": "Operation Name:*",
                "Type": "Type:*",
                "ItemCode": "Item Code:*",
                "ItemNameDet": "Item Name:*",
                "Quantity": "Quantity:*",
                "UOMCode": "UOM Code:*",


                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },
        "ProductionScheduling": {
            "management": {
                "Title": "Production Scheduling",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Org Name",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "RefType": "Ref Type",
                    "Status": "Status"
                }
            },

            "create": {
                "Title": "Production Scheduling",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "PlantId": "Plant Code:*",
                "PlantName": "Plant Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "StartDate": "Start Date:*",
                "EndDate": "End Date:*",
                "PlanTypeCode": "Plan Type Code:*",
                "PlantCode": "Plant Code:*",
                "ReferenceTypeCode": "Reference Type Code:*",

                "ReferenceNo": "Reference No",
                "ReferenceDate": "Reference Date",
                "StatusGrid": "Status Grid",
                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "UOMCode": "UOM Code",
                "RefQty": "Ref Quantity",
                "Quantity": "Quantity",

                "TabTitle1": "General",
                "TabTitle2": "Item Details",
                "TabTitle3": "Item Details",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "PlannedOrderRelease": {
            "management": {
                "Title": "Planned Order Release",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Org Name",
                    "PlantCode": "Plant Code",
                    "PlanCode": "Plan Code",
                    "Status": "Status"
                }
            },

            "create": {
                "Title": "Planned Order Release",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "PlantId": "Plant Code:*",
                "PlantName": "Plant Name:",
                "PlanId": "Plan Code:*",

                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "UOMCode": "UOM Code",
                "StartDate": "Start Date",
                "EndDate": "End Date",
                "Quantity": "Quantity",

                "TabTitle1": "General",
                "TabTitle2": "MFG Details",
                "TabTitle3": "Proc Details",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "ProductionOrder": {
            "management": {
                "Title": "Production Order",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Org Name",
                    "Code": "Document No",
                    "Name": "Name:",
                    "ItemCode": "Item Code",
                    "Status": "Status"
                }
            },

            "create": {
                "Title": "Production Order",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "PlantId": "Plant Code:*",
                "PlantName": "Plant Name:",
                "PlanId": "Plan Code:*",
                "DocumentNo": "Document No.:*",
                "StartDate": "Start Date:*",
                "EndDate": "End Date:*",
                "ItemId": "Item Code:*",
                "ItemName": "Item Name",
                "RoutingCode": "Routing Code:*",
                "UOMId": "UOM:*",
                "SONumber": "SO Number:",
                "RequiredQuantity": "Required Quantity:",
                "POType": "PO Type:*",
                "FGItemId": "FG Item Code:*",
                "FGItemName": "FG Item Name:*",

                "OperationCode": "Operation Code",
                "WorkCenterCode": "Work Center Code",
                "ItemCode": "Item Code",
                "GridItemName": "Item Name",
                "UOMCode": "UOM Code",
                "Quantity": "Quantity",
                "RequiredOn": "Required On",

                "TabTitle1": "General",
                "TabTitle2": "Item Details",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },
        "Machine": {
            "management": {
                "Title": "Machine",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Org Name",
                    "Code": "Code",
                    "Name": "Name:",
                    "Status": "Status"
                }
            },

            "create": {
                "Title": "Machine",
                "TabTitle1": "General",
                "TabTitle2": "Resource Details",
                "TabTitle3": "Operation Details",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "PlantId": "Plant Code:*",
                "PlantName": "Plant Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "ShortName": "Short Name:*",
                "MachineType": "Machine Type:*",
                "MachineCategoryId": "Machine Category:*",
                "WorkCenterId": "Work Center:*",

                "DayCapacityinHrs": "Day Capacity In Hrs:*",
                "WeekCapacityinHrs": "Week Capacity In Hrs:*",
                "Utilisation": "Utilization:*",
                "MachineHourRate": "Machine Hour Rate:*",
                "CurrencyId": "Currency:*",
                "Description": "Description:",

                "SNo": "SNo",
                "ResourceCode": "Resource Code",
                "ResourceName": "Resource Name",
                "Quantity": "Quantity",
                "OperationCode": "Operation Code",
                "OperationName": "Operation Name",
                "Priority": "Priority",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "vehicle": {
            "management": {
                "Title": "Car",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Organization",
                    "Code": "Code ",
                    "MakeName": "Make Name",
                    "ModelName": "Model Name",
                    "BrandName": "Brand Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Car",
                "SubTitle": "SubTitle",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "CounterReading": "Counter Reading:",
                "MakeName": "Make Name:*",
                "ModelName": "Model Name:*",
                "BrandName": "Brand Name:*",
                "AssetName": "Asset Name:",


                "fuelType": "Fuel Type:*",
                "licenceNo": "Licence No:*",
                "year": "Model Year:",
                "rinNo": "Vin No:*",
                "owingLocation": "Owing Location:*",
                "seatingCapacity": "Seating Capacity:",
                "vehicleStatus": "Vehicle Status:*",
                "vehicleType": "Vehicle Type:*",
                "transmission": "Transmission:",
                "color": "Color:*",
                "tantSize": "Tank Size:*",
                "odometer": "Odometer:",
                "Remarks": "Remarks:",
                "PrintButton": "Print",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"

            }
        },

        "vehicleRegistration": {
            "management": {
                "Title": "Vehicle Registration",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Organization",
                    "Code": "Code ",
                    "Name": "Name",
                    "chassisNumber": "Chassis Number",
                    "RegistrationNumber": "Registration No",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Vehicle Registration",
                "SubTitle": "SubTitle",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "chassisNumber": "Chassis Number:*",
                "registrationNumber": "Registration Number:*",
                "mark": "Mark:*",
                "site": "Site:*",
                "provider": "Provider:*",
                "providerName": "Provider Name:*",
                "fromDate": "From Date:*",
                "toDate": "To Date:*",
                "counterReading": "Counter Reading:*",
                "firstReleaseDate": "First Release Date:*",
                "previousRegistrationNo": "Previous Registration No:*",
                "address": "Address:*",
                "council": "Council:*",
                "loanOnVehicle": "Loan On Vehicle:*",
                "typeOfVehicle": "Type Of Vehicle:*",
                "model": "Model:*",
                "body": "Body:*",
                "recordNo": "RecordNo:*",
                "methodOfPropulsion": "Method Of Propulsion:*",
                "engineCapacity": "Engine Capacity:*",
                "noOfSeat": "No Of Seat:*",
                "officialHPRating": "Official HP Rating:*",
                "totalAuthorisedLoad": "Total Authorised Load:*",
                "netWeight": "Net Weight:*",
                "carryingCapacity": "Carrying Capacity:*",
                "ssdCaster": "SSDCaster:*",
                "sNo": "SNo:*",

                "PrintButton": "Print",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"

            }
        },

        "JobCard": {
            "management": {
                "Title": "Job Card",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Job Card",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "SubTitle",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "PlantId": "Plant Code:*",
                "PlantName": "Plant Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "OperationId": "Operation Code:*",
                "OperationName": "Operation Name:",
                "JobCardType": "Job Card Type:",
                "POCode": "PO Code:*",
                "ItemCode": "Item Code:*",
                "ItemName": "Item Name:*",
                "WorkCenterCode": "Work Center Code:*",
                "MachineCode": "Machine Code:*",
                "OperationCode": "Operation Code:*",
                "UOMCode": "UOM Code:*",
                "Quantity": "Quantity:*",
                "PrintButton": "Print",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"

            }
        },

        "ProductionOrderSettlement": {
            "management": {
                "Title": "Production Order Settlement",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "Code": "Document No",
                    "Name": "Document Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Production Order Settlement",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "SubTitle",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "PlantId": "Plant Code:*",
                "PlantName": "Plant Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "ToWareHouse": "To WareHouse:*",
                "OperationId": "Operation Code:*",
                "OperationName": "Operation Name:",
                "POCode": "PO Code:*",
                "ItemCode": "Item Code:*",
                "ItemName": "Item Name:*",
                "RefQuantity": "Ref Quantity:*",
                "BalQuantity": "Balance Quantity:*",
                "Location": "Location:*",
                "UOMCode": "UOM Code:*",
                "Quantity": "Quantity:*",
                "PrintButton": "Print",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"

            }
        },

        "carrentingrates": {
            "management": {
                "Title": "Car Renting Rates",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Organization",
                    "BrandName": "Brand Name",
                    "ModelName": "Model Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Car Renting Rates",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "SubTitle",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "BrandName": "Brand Name:*",
                "ModelName": "Model Name:",
                "CurrencyName": "Currency Name:*",
                "MonthlyRate": "Monthly Rate:*",
                "WeeklyRate": "Weekly Rate:*",
                "DailyRate": "Daily Rate:",
                "HalfDayRate": "Half Day Rate:*",
                "HourlyRate": "Hourly Rate:*",
                "DailyKMAllowed": "Daily KM Allowed:*",
                "WeeklyKMAllowed": "Weekly KM Allowed:*",
                "MonthlyKMAllowed": "Monthly KM Allowed:*",
                "KMCharge": "Per KM Charges:*",
                "ExtraKMCharges": "Extra KM Charges:*",
                "NonLocalKMCharges": "Non Local KM Charges:*",
                "PrintButton": "Print",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"

            }
        },

        "CarReservation": {
            "management": {
                "Title": "Car Reservation",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Organization",
                    "DocumentNo": "Document No",
                    "BrandName": "Brand Name",
                    "ModelName": "Model Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Car Reservation",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "SubTitle",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "BrandName": "Brand Name:*",
                "Name": "Name:*",
                "checkInDate": "Check In Date:*",
                "checkOutDate": "Check Out Date:*",
                "checkInLocation": "Check In Location:*",
                "checkOutLocation": "Check Out Location:*",
                "reservationType": "Reservation Type:*",
                "phoneNo": "Phone No:*",
                "documentNo": "Document No.:",
                "documentDate": "Document Date:*",
                "ModelName": "Model Name:*",
                "vehicleType": "Vehicle Type:",
                "vehicleId": "Vehicle No.:",
                "CurrencyName": "Currency Name:*",
                "customerId": "Customer Name:*",
                "ratesType": "Rates Type:*",
                "totalDays": "Total Days:*",
                "hourly": "No. of Hours:*",
                "hourlyRate": "Hourly Rate:",
                "halfDay": "No of Half Days:*",
                "halfDayRate": "Half Day Rate:*",
                "daily": "No of Days:*",
                "dailyRate": "Daily Rate:*",
                "weekly": "No of Weeks:*",
                "weeklyRate": "Weekly Rate:*",
                "monthly": "No of Months:*",
                "monthlyRate": "Monthly Rate:*",
                "dailyKMAllowed": "Daily KM Allowed:*",
                "weeklyKMAllowed": "Weekly KM Allowed:*",
                "monthlyKMAllowed": "Monthly KM Allowed:*",
                "kmCharges": "Km Charges:*",
                "extraKMCharges": "Extra KM Charges:*",
                "nonLocalKMCharges": "Non Local KM Charges:*",
                "package": "Package:*",
                "totalRateCharges": "Total Rate Charges:*",
                "discount": "Discount:*",
                "finalBaseRate": "Final Base Rate:*",
                "otherCharges": "Other Charges:*",
                "tax": "Tax:*",
                "amount": "Amount:*",
                "misCharges": "Miscellaneous Charges:*",
                "total": "Total:*",
                "currencyId": "Currency Name:*",
                "exchangeRate": "Exchange Rate:*",

                "exchangeDate": "Exchange Date:*",
                "unlimitedKMPerDay": "Unlimited KM Per Day:*",
                "unlimitedKMPerWeek": "Unlimited KM Per Week:*",
                "unlimitedKMPerMonth": "Unlimited KM Per Month:*",

                "PrintButton": "Print",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"

            }
        },

        "CarAgreement": {
            "management": {
                "Title": "Car Agreement",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Organization",
                    "DocumentNo": "Document No",
                    "BrandName": "Brand Name",
                    "ModelName": "Model Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Car Agreement",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "SubTitle",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name :",
                "BrandName": "Brand Name:*",
                "Name": "Name:*",
                "checkInDate": "Check In Date:*",
                "checkOutDate": "Check Out Date:*",
                "checkInLocation": "Check In Location:*",
                "checkOutLocation": "Check Out Location:*",
                "reservationType": "Reservation Type:*",
                "phoneNo": "Phone No:*",
                "documentNo": "Document No.:",
                "documentDate": "Document Date:*",
                "ModelName": "Model Name:*",
                "vehicleType": "Vehicle Type:",
                "vehicleId": "Vehicle No.:",
                "CurrencyName": "Currency Name:*",
                "customerId": "Customer Name:*",
                "ratesType": "Rates Type:*",
                "totalDays": "Total Days:*",
                "hourly": "No. of Hours:*",
                "hourlyRate": "Hourly Rate:",
                "halfDay": "No of Half Days:*",
                "halfDayRate": "Half Day Rate:*",
                "daily": "No of Days:*",
                "dailyRate": "Daily Rate:*",
                "weekly": "No of Weeks:*",
                "weeklyRate": "Weekly Rate:*",
                "monthly": "No of Months:*",
                "monthlyRate": "Monthly Rate:*",
                "dailyKMAllowed": "Daily KM Allowed:*",
                "weeklyKMAllowed": "Weekly KM Allowed:*",
                "monthlyKMAllowed": "Monthly KM Allowed:*",
                "kmCharges": "Km Charges:*",
                "extraKMCharges": "Extra KM Charges:*",
                "nonLocalKMCharges": "Non Local KM Charges:*",
                "package": "Package:*",
                "totalRateCharges": "Total Rate Charges:*",
                "discount": "Discount:*",
                "finalBaseRate": "Final Base Rate:*",
                "otherCharges": "Other Charges:*",
                "tax": "Tax:*",
                "amount": "Amount:*",
                "misCharges": "Miscellaneous Charges:*",
                "total": "Total:*",
                "currencyId": "Currency Name:*",
                "exchangeRate": "Exchange Rate:*",

                "exchangeDate": "Exchange Date:*",
                "unlimitedKMPerDay": "Unlimited KM Per Day:*",
                "unlimitedKMPerWeek": "Unlimited KM Per Week:*",
                "unlimitedKMPerMonth": "Unlimited KM Per Month:*",



                "destination": "Destination:*",
                "fuelLevel": "Fuel Level:*",
                "odometerOut": "Odometer Out:*",
                "driverId": "Driver Code:*",
                "driverName": "Driver Name:*",
                "insuranceCompanyId": "Insurance Company:*",
                "policyNo": "Policy No:*",
                "expiryDate": "Expiry Date:*",
                "claimNo": "Claim No:*",
                "claimName": "Claim Name:*",
                "referenceType": "Reference Type:*",
                "referenceId": "Reference No:*",
                "reservationDate": "Reservation Date:*",
                "contactName": "Contact Name:*",
                "address": "Address:*",
                "description": "Description:",



                "PrintButton": "Print",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"

            }
        },

        "userwarehousemapping": {
            "management": {
                "Title": "User-Warehouse Mapping",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "Name": "Name",
                    "Username": "User Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "User-Warehouse Mapping",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "SubTitle",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "username": "User Name:*",
                "name": "Name:",
                "warehousecode": "Warehouse Code",
                "warehousename": "Warehouse Name",
                "locationcode": "Location Code",
                "locationname": "Location Name",
                "PrintButton": "Print",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"

            }
        },

        "useraccountmapping": {
            "management": {
                "Title": "User-Account Mapping",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "Name": "Name",
                    "Username": "User Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "User-Account Mapping",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "SubTitle",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "username": "User Name:*",
                "name": "Name:",
                "accountcode": "Account Code*",
                "accountname": "Account Name",
                "PrintButton": "Print",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"

            }
        },


        "userbankcashaccountmapping": {
            "management": {
                "Title": "User-Bank/Cash Account Mapping",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "Name": "Name",
                    "Username": "User Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "User-Bank/Cash Account Mapping",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "SubTitle",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "UserName": "User Name:*",
                "name": "Name:",
                "Type": "Type:",
                "accountcode": "Account Code*",
                "accountname": "Account Name",
                "PrintButton": "Print",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"

            }
        },
        "salesroute": {
            "management": {
                "Title": "Sales Route",
                "SubTitle": "Address",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Code": "Code",
                    "Name": "Name",
                    "Orgname": "OrgName",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Sales Route",
                "SubTitle": "Address",
                "Code": "Code:",
                "Name": "Name:",
                "OriginName": "Origin Name:",
                "DestinationName": "Destination Name:",
                "Distance": "Total Distance:",

                "Remarks": "Remarks:",
                "Longitude": "Longitude:",
                "Latitude": "Latitude:",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "PrintButton": "Print",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Last Updated By"
            }
        },


        "silocode": {
            "management": {
                "Title": "Silo Code",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Org": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Silo Code",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
        },

        "flourtype": {
            "management": {
                "Title": "Flour Type",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Org": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Flour Type",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
        },


        "flouraspect": {
            "management": {
                "Title": "Flour Aspect",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Org": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Flour Aspect",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
        },



        "quotation": {
            "management": {
                "Title": "Quotation",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Quotation",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "ValidFrom": "Valid From:*",
                "ValidTo": "Valid To:*",
                "SalesPersonCode": "Sales Person Code:*",
                "SalesPersonName": "Sales Person Name:",
                "CustomerCode": "Customer Code:*",
                "CustomerName": "Customer Name:",
                "VATAddition": "VAT addition in MF Invoice:*",
                "MultipleInvoice": "Multiple Invoices Allowed:*",
                "DeductShortage": "Deductions:*",
                "DeductRate": "Deduction Rate:*",
                "NoOfInvoice": "No. of Invoice copies:*",
                "Currency": "Currency:*",
                "PaymentTerms": "Payment Terms:*",
                "InvoicingInterval": "Invoicing Interval:*",
                "Narration": "Narration:",

                "RouteCode": "Route Code",
                "RouteName": "Route Name",
                "ConsignType": "Consignment Type",
                "CommodityCode": "Commodity Code",
                "CommodityName": "Commodity Name",
                "Weight": "Weight",
                "Distance": "Distance",
                "UOM": "UOM",
                "Quantity": "Quantity",
                "RateType": "Rate Type",

                "CostperTon": "Rate",
                "LoadingCharge": "Loading Charge",
                "UnloadingCharge": "Unloading Charge",

                "TotalTripExpenses": "Total Trip Expenses",
                "ProfitPercentage": "Profit Percentage",
                "CostperTrip": "Rate Per Trip",
                "RateperUOM": "Rate per UOM/Km",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },
        "commercialtruckmaster": {
            "management": {
                "Title": "Commercial Truck",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Organization",
                    "Code": "Code ",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Commercial Truck",
                "SubTitle": "SubTitle",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "AssetName": "Asset Name:",
                "PlateNo": "Plate No:*",
                "NoOfAxles": "No Of Axles:*",
                "BrandName": "Brand Name:*",
                "ModelName": "Model Name:",
                "TrailerType": "Trailer Type:*",
                "TrailerCategory": "Trailer Category:*",
                "TareWeight": "Tare Weight:*",
                "TonnageCapacity": "Tonnage Capacity:*",
                "Length": "Length:",
                "Breadth": "Breadth:*",
                "Height": "Height:*",
                "KM Run": "KM Run:*",
                "ChassisNo": "Chassis No:*",
                "Remarks": "Remarks:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"

            }
        },



        "transportcharges": {
            "management": {
                "Title": "Transport Charges",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Transport Charges",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "FromDate": "From Date:*",
                "ToDate": "To Date:*",
                "TransType": "Payment Type:*",
                "ReferenceType": "Transaction Type:",
                "DocumentType": "Document Type",
                "Charges": "Charges:*",
                "RefCharges": "Ref Charges",
                "PartyName": "Details:",
                "Currency": "Currency:*",
                "Transporter": "Transporter:*",
                "Telephone": "Telephone:",
                "Beneficiary": "Beneficiary:",
                "NICNumber": "NICNumber:",
                "PaymentType": "Payment Type:",
                "Remarks": "Remarks:",
                "ExchangeRate": "Exchange Rate:",
                "ExchangeDate": "Exchange Date:",
                "PaymentTermCode": "Payment Term Code:*",
                "PaymentTermName": "Payment Term Name:",
                "Payment": "Payment",
                "Details": "Details",

                "GrossAmount": "Gross Amount",
                "TaxAmount": "Tax Amount",
                "TotalLoadingCharge": "Total Amount",
                "AdvanceAmount": "Advance Amount",
                "RemainingAmount": "Remaining Amount",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "transporteradjustmentinvoice": {
            "management": {
                "Title": "Transporter Adjustment Invoice",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Transporter Adjustment Invoice",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "FromDate": "From Date:*",
                "ToDate": "To Date:*",
                "TransType": "Payment Type:*",
                "ReferenceType": "Transaction Type:",
                "DocumentType": "Document Type",
                "Charges": "Charges:*",
                "RefCharges": "Ref Charges",
                "PartyName": "Details:",
                "Currency": "Currency:*",
                "Transporter": "Transporter:*",
                "Telephone": "Telephone:",
                "PaymentType": "Payment Type:",
                "Remarks": "Remarks:",
                "ExchangeRate": "Exchange Rate:",
                "ExchangeDate": "Exchange Date:",
                "PaymentTermCode": "Payment Term Code:*",
                "PaymentTermName": "Payment Term Name:",
                "Payment": "Payment",
                "Details": "Details",

                "GrossAmount": "Gross Amount",
                "TaxAmount": "Tax Amount",
                "TotalLoadingCharge": "Total Amount",
                "AdvanceAmount": "Advance Amount",
                "RemainingAmount": "Remaining Amount",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },


        "vendorpaymentrequest": {
            "management": {
                "Title": "Vendor Payment Request",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "ReverseButton": "Reverse",
                "RePostButton": "RePost",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "VendorName": "Vendor Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Vendor Payment Request",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "VendorCode": "Vendor Code:*",
                "TransType": "Payment Type:*",
                "ReferenceType": "Reference Type:",

                "DocumentNo1": "Document No",
                "DocumentDate1": "Document Date",
                "SupplierBillNo": "Supplier Bill No",
                "SupplierBillDate": "Supplier Bill Date",
                "GrossAmount": "Gross Amount",
                "TaxAmount": "Tax Amount",
                "NetAmount": "Net Amount",
                "TotalNetAmount": "Total Net Amount",

                "ExpenditureTypeCode": "Expenditure Type Code",
                "ExpenditureTypeName": "Expenditure Type Name",
                "Description": "Description",
                "Amount": "Amount",

                "Remarks": "Remarks:",
                "ExchangeRate": "Exchange Rate:",
                "ExchangeDate": "Exchange Date:",
                "PaymentTermCode": "Payment Term Code:*",
                "PaymentTermName": "Payment Term Name:",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },


        "loadingrequest": {
            "management": {
                "Title": "Loading/Offloading Charges",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Loading/Offloading Charges",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "FromDate": "From Date:*",
                "ToDate": "To Date:*",
                "TransType": "Payment Type:*",
                "ReferenceType": "Transaction Type:",
                "Charges": "Charges:*",
                "PartyName": "Details",
                "Currency": "Currency:*",
                "Remarks": "Remarks:",
                "LoadingAmount": "Amount:",
                "ExchangeRate": "Exchange Rate:",
                "ExchangeDate": "Exchange Date:",
                "PaymentTermCode": "Payment Term Code:*",
                "PaymentTermName": "Payment Term Name:",

                "GrossAmount": "Gross Amount",
                "TaxAmount": "Tax Amount",
                "TotalLoadingCharge": "Total Amount",
                "AdvanceAmount": "Advance Amount",
                "NetAmount": "Net Amount",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },
        "containercontract": {
            "management": {
                "Title": "Container Contract",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No.",
                    "DocumentDate": "Document Date",
                    "CustomerName": "Customer Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Container Contract",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",

                "DocumentNo": "DocumentNo:*",
                "DocumentDate": "Document Date:*",
                "ValidFrom": "Valid From:*",
                "ValidTo": "Valid To:*",
                "SalesPersonCode": "Sales Person Code:*",
                "SalesPersonName": "Sales Person Name:",
                "CustomerCode": "Customer Code:*",
                "CustomerName": "Customer Name:",
                "Currency": "Currency:*",
                "PaymentTerms": "Payment Terms:*",
                "HandlingCharges": "Re Handling Charges:*",
                "rehandlingCharges": "Rehandling Charges:*",
                "transportCharges": "Transport Charges:*",
                "Narration": "Narration:",


                "TransportationCharges": "Transport Charges By:*",


                "containerSizeCode": "Container Size Code",
                "containerSizeName": "Container Size Name",
                "tariff": "Handling Charges",
                "freeTime": "Free Time",
                "chargesAfterFreeTime": "Charges After Free Time",
                "containerState": "Container State",

                "UserName": "User Name:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },
        "workflowmaster": {
            "management": {
                "Title": "Work Flow",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "TransactionName": "Transaction Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Work Flow",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "TransactionName": "Transaction Name:*",
                "Level": "Level:*",
                "UserName": "User Name:*",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },
        "featuretableinformation": {
            "management": {
                "Title": "Feature Table Information",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "FeatureName": "Feature Name",
                    "Table1": "Table 1",
                    "Table2": "Table 2",
                    "Table3": "Table 3",
                    "Table4": "Table 4",
                    "Table5": "Table 5",
                    "Table6": "Table 6",
                }
            },
            "create": {
                "Title": "Feature Table Information",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "FeatureName": "Feature Name:*",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },
        "userreportfeature": {
            "management": {
                "Title": "User Report Feature",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Code": "Code",
                    "ModuleName": "Module Name",
                    "Title": "Title",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "User Report Feature",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "Code": "Code:*",
                "ModuleName": "Module Name:",
                "Title1": "Title:*",
                "ReportUrl": "ReportUrl:*",
                "IsFromToDate": "Is From To Date:*",
                "IsAsOnDate": "Is As On Date:*",
                "IsDefaultParam": "Is Default Param:*",

                "Label": "Label",
                "Placeholder": "Placeholder",
                "FieldName": "Field Name",
                "Type": "Type",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "dashboardreportpanelmapping": {
            "management": {
                "Title": "Dashboard Report Panel Mapping",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "PanelName": "Panel Name",
                    "ReportTitle": "Report Title",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Dashboard Report Panel Mapping",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "PanelName": "Panel Name:*",
                "ReportTitle": "Report Title:",
                "ReportSp": "Report SP:*",
                "Color1": "Color 1:*",
                "Color2": "Color 2:*",
                "Color3": "Color 3:*",
                "Color4": "Color 4:*",
                "Color5": "Color 5:*",
                "Param1": "Param 1:*",
                "Param2": "Param 2:*",
                "Param3": "Param 3:*",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "containerreceiptnote": {
            "management": {
                "Title": "Container Receipt Note",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "ContainerNo": "Container No",
                    "CustomerName": "Customer Name",
                    "ContainerSize": "Container Size",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Container Receipt Note",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.*",
                "DocumentDate": "Document Date*",
                "ValidFrom": "Valid From*",
                "ValidTo": "Valid To:*",
                "CustomerCode": "Customer Code:*",
                "CustomerName": "Customer Name:",
                "Currency": "Currency:",
                "ReferenceType": "Reference Type*",
                "ReferenceId": "Contract No.:*",
                "TruckPlateNo": "Truck Plate No:*",
                "DriverId": "Driver Id:*",
                "DriverName": "Driver Name:*",
                "DriverPhoneNo": "Driver Phone No:",
                "DriverCompanyName": "Driver Company Name:",
                "TruckOwner": "Truck Owner:*",
                "SalesPersonId": "Sales Person Id:*",
                "PaymentType": "Payment Type:*",
                "PartOperatorId": "Park Operator:*",
                "PaymentTermsId": "Payment Terms:*",
                "StorageLocationId": "Storage Location:*",
                "HandlingType": "Handling Type:*",

                "handlingCharges": "Handling Charges",
                "transportCharges": "Transport Charges",
                "overStayCharges": "OverStay Charges",
                "rehandlingCharges": "Rehandling Charges",
                "WeighBridge": "Weigh Bridge",

                "Narration": "Narration",
                "CRVNO": "CRV NO:",
                "TrailerPlateNo": "Trailer Plate No",

                "containerSizeCode": "Container Size Code",
                "containerSizeName": "Container Size Name",
                "containerNo": "Container No",
                "plateNo": "Seal No",
                "containerInfoCode": "Container Info Code",
                "activityCode": "Activity",
                "shippingLineCode": "Shipping Line Code",
                "exportInfoCode": "Export Info",
                "commodityCode": "Commodity",
                "unitCharges": "Unit Charges",
                "containerState": "Container State",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "containerfillingatcontainerpark": {
            "management": {
                "Title": "Container Filling At Container Park",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "ContainerNo": "Container No",
                    "CustomerName": "Customer Name",
                    "ContainerSize": "Container Size",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Container Filling At Container Park",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.*",
                "DocumentDate": "Document Date*",
                "CustomerCode": "Customer Code:*",
                "CustomerName": "Customer Name:",
                "Currency": "Currency:",
                "CRNId": "CRN No.:*",
                "CRNDate": "CRN Date*",
                "handlingCharges": "Handling Charges",
                "transportCharges": "Transport Charges",
                "overStayCharges": "OverStay Charges",
                "rehandlingCharges": "Rehandling Charges",

                "Narration": "Narration",

                "containerSizeCode": "Container Size Code",
                "containerSizeName": "Container Size Name",
                "containerNo": "Container No",
                "plateNo": "Seal No",
                "containerInfoCode": "Container Info Code",
                "activityCode": "Activity",
                "shippingLineCode": "Shipping Line Code",
                "exportInfoCode": "Export Info",
                "commodityCode": "Commodity",
                "unitCharges": "Unit Charges",
                "containerState": "Container State",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "containerdeliverynote": {
            "management": {
                "Title": "Container Delivery Note",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "ReferenceNo": "Reference No",
                    "ReferenceDate": "Reference Date",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "CustomerName": "Customer Name",
                    "ContainerSize": "Container Size",
                    "ContainerNo": "Container No",
                    "ContainerInvoiceNo": "Invoice No",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Container Delivery Note",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.*",
                "DocumentDate": "Document Date*",
                "ReferenceId": "Reference No.*",
                "ReferenceDate": "Reference Date*",
                "ValidFrom": "Valid From*",
                "ValidTo": "Valid To:*",
                "CustomerCode": "Customer Code:*",
                "CustomerName": "Customer Name:",
                "ReferenceType": "Reference Type*",
                "StorageLocationId": "Storage Location.:*",
                "ExitDate": "Exit Date:*",
                "DriverId": "Driver Id:*",
                "DriverName": "Driver Name:*",
                "DriverPhoneNo": "Driver Phone No:",
                "DriverCompanyName": "Driver Company Name:",
                "ExitTruckNo": "Exit Truck No:*",
                "ExitTrailerNo": "Exit Trailer No:*",
                "PaymentType": "Payment Type:*",
                "PartOperatorId": "Part Operator:*",
                "PaymentTermsId": "Payment Terms:*",
                "NoofDays": "No of Days:*",
                "HandlingType": "Handling Type:*",
                "CRVNO": "CRV NO:*",

                "Narration": "Narration",

                "containerSizeCode": "Container Size Code",
                "containerSizeName": "Container Size Name",
                "containerNo": "Container No",
                "plateNo": "Seal No",
                "containerInfoCode": "Container Info",
                "activityCode": "Activity",
                "shippingLineCode": "Shipping Line",
                "exportInfoCode": "Export Info",
                "commodityCode": "Commodity",
                "unitCharges": "Unit Charges",
                "containerState": "Container State",
                "handlingCharges": "Handling Charges",
                "transport": "Transport Charges",
                "overStay": "OverStay Charges",
                "rehandling": "Rehandling Charges",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },
        "productionreceipts": {
            "management": {
                "Title": "Production Receipt",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ActiveButton": "Approve",
                "InActiveButton": "Reject",
                "ViewButton": "View",
                "ReverseButton": "Reverse",
                "RePostButton": "RePost",
                "PrintButton": "Print",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Remarks": "Remarks",
                    "Status": "Status",
                    "Location": "Location"
                }
            },
            "create": {
                "Title": "Production Receipt",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "SubTitle",
                "ToStationCode": "Org/SubOrg Code:*",
                "ToStationName": "Org/SubOrg Name:*",
                "CustomerTypeCode": "Customer Type Code:*",
                "CustomerTypeName": "Customer Type Name:",
                "CustomerCode": "Customer Code:*",
                "CustomerName": "Customer Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "PaymentType": "Payment Type:*",
                "RefType": "Reference Type:*",
                "IssueNo": "Reference No:*",
                "BalQty": "Balance Quantity:*",
                "IssueDate": "Reference Date:",
                "ToWareHouse": "To WareHouse:*",
                "Remarks": "Remarks:",
                "PrintButton": "Print",
                "SalesRoute": "Sales Route",
                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "ItemPartNo": "Item Part No",
                "UOMCode": "UOM Code",
                "UOMName": "UOM Name",
                "LocationHead": "Location",
                "IssueQty": "Issue Quantity",
                "Qty": "Quantity",
                "Price": "Price",
                "Amount": "Amount",

                "OffLoadingCharges": "OffLoading Charges",

                "SaveButton": "Save",
                "CancelButton": "Back",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "containerinvoice": {
            "management": {
                "Title": "Container Invoice",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "CustomerName": "Customer Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Container Invoice",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "TabTitle3": "Due Payment Details",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "CustomerCode": "Customer Code:*",
                "CustomerName": "Customer Name:",
                "ContractNo": "Contract No.:*",
                "ContractDate": "Contract Date:",
                "Currency": "Currency:*",
                "Remarks": "Remarks:",
                "ExchangeRate": "Exchange Rate:",
                "ExchangeDate": "Exchange Date:",
                "PaymentTermCode": "Payment Term Code:*",
                "PaymentTermName": "Payment Term Name:",

                "RefType": "Reference Type:*",
                "ContainerContractId": "Contract No.:",

                "crnNo": "CRN No.",
                "cdnNo": "CDN No.",
                "dateIn": "Date In",
                "dateOut": "Date Out",
                "containerNo": "Container No",
                "plateNo": "Seal/Plate No",
                "unitAmount": "Unit Amount",

                "CreditDays": "Credit Days",

                "TotalContainers": "No. of Containers:",
                "GrossAmount": "Gross Amount:",
                "TaxAmount": "Tax Amount:",
                "TotalNetAmount": "Total Net Amount:",

                "SNO": "Sl.No.",
                "Payment": "Payment %",
                "Amount": "Amount",
                "DueDate": "Due Date",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },
        "containerproformainvoice": {
            "management": {
                "Title": "Container Proforma Invoice",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "CustomerName": "Customer Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Container Proforma Invoice",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "CustomerCode": "Customer Code:*",
                "CustomerName": "Customer Name:",
                "ExitDate": "Exit Date:",
                "Currency": "Currency:*",
                "Remarks": "Remarks:",
                "ExchangeRate": "Exchange Rate:",
                "PaymentTermsId": "Payment Term Code:*",

                "CRNNo": "CRN No",
                "ContainerNo": "Container No",
                "SealNo": "Seal No",
                "ContainerEntryDate": "Container Entry Date",
                "ContainerState": "Container State",
                "CommodityCode": "Commodity Code",
                "NoOfDays": "No Of Days",
                "HandlingCharges": "Handling Charges",
                "TotalContainers": "No. of Containers:",
                "OverStay": "OverStay",
                "Transport": "Transport",
                "Amount": "Total",
                "GrossAmount": "GrossAmount",
                "TotalNetAmount": "TotalNetAmount",
                "TaxAmount": "Tax Amount",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "machinerental": {
            "management": {
                "Title": "Machine Rental",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "CustomerName": "Customer Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Machine Rental",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "RentalType": "UOM:",
                "StartingDateTime": "Starting Date Time:*",
                "EndingDateTime": "Ending Date Time:*",
                "HRSDuration": "Hour/Day/Month:*",
                "MinDuration": "Minutes:*",
                "Machine": "Machine:*",
                "MachineRatePerHour": "Machine Rate:*",
                "PriceperUnitLoaded": "Price Per Unit Loaded:*",
                "NoofUnitsLoaded": "No of Units Loaded:*",
                "CustomerCode": "Customer Code:*",
                "CustomerName": "Customer Name:",
                "Currency": "Currency:*",
                "Remarks": "Remarks:",
                "Amount": "Amount:",
                "ExitFlatRate": "Exit Flat Rate:",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "machinerentalinvoice": {
            "management": {
                "Title": "Machine Rental Invoice",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "CustomerName": "Customer Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Machine Rental Invoice",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "CustomerCode": "Customer Code:*",
                "CustomerName": "Customer Name:",
                "Currency": "Currency:*",
                "Remarks": "Remarks:",
                "ExchangeRate": "Exchange Rate:",
                "ExchangeDate": "Exchange Date:",
                "PaymentTermCode": "Payment Term Code:*",

                "sno": "SNo",
                "machinedocumentNo": "Document No",
                "machinedocumentDate": "Document Date",
                "rentalType": "Type",
                "noofHours": "Nos",
                "noofMinutes": "No of Minutes",
                "ratePerHour": "Rate Per Hour",
                "noofUnitsLoaded": "Loaded Units",
                "loadedChargesPerUnit": "Loaded Charges/Unit",
                "exitFlatRate": "ExitFlatRate",
                "amount": "Total",

                "TotalItem": "No. of Item:",
                "GrossAmount": "Gross Amount:",
                "TotalNetAmount": "Total Net Amount:",
                "TaxAmount": "Tax Amount",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "stockreceipts": {
            "management": {
                "Title": "Stock Receipt",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ActiveButton": "Approve",
                "InActiveButton": "Reject",
                "ViewButton": "View",
                "ReverseButton": "Reverse",
                "RePostButton": "RePost",
                "PrintButton": "Print",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Stock Receipt",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "SubTitle",
                "ToStationCode": "Org/SubOrg Code:*",
                "ToStationName": "Org/SubOrg Name:*",
                "CustomerTypeCode": "Customer Type Code:*",
                "CustomerTypeName": "Customer Type Name:",
                "CustomerCode": "Customer Code:*",
                "CustomerName": "Customer Name:",
                "ToWareHouse": "To WareHouse:*",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "PaymentType": "Payment Type:*",
                "BalQty": "Balance Quantity:*",
                "Remarks": "Remarks:",
                "PrintButton": "Print",
                "SalesRoute": "Sales Route",
                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "ItemPartNo": "Item Part No",
                "UOMCode": "UOM Code",
                "UOMName": "UOM Name",
                "LocationHead": "Location",
                "IssueQty": "Issue Quantity",
                "Qty": "Quantity",
                "Price": "Price",
                "Amount": "Amount",

                "OffLoadingCharges": "OffLoading Charges",

                "SaveButton": "Save",
                "CancelButton": "Back",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "excessreceiptinventory": {
            "management": {
                "Title": "Excess Receipt Inventory",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ActiveButton": "Approve",
                "InActiveButton": "Reject",
                "ViewButton": "View",
                "ReverseButton": "Reverse",
                "RePostButton": "RePost",
                "PrintButton": "Print",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Remarks": "Remarks",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Excess Receipt Inventory",
                "TabTitle1": "General",
                "TabTitle2": "Details",
                "SubTitle": "SubTitle",
                "ToStationCode": "Org/SubOrg Code:*",
                "ToStationName": "Org/SubOrg Name:*",
                "CustomerTypeCode": "Customer Type Code:*",
                "CustomerTypeName": "Customer Type Name:",
                "CustomerCode": "Customer Code:*",
                "CustomerName": "Customer Name:",
                "ToWareHouse": "To WareHouse:*",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "PaymentType": "Payment Type:*",
                "BalQty": "Balance Quantity:*",
                "Remarks": "Remarks:",
                "PrintButton": "Print",
                "SalesRoute": "Sales Route",
                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "ItemPartNo": "Item Part No",
                "UOMCode": "UOM Code",
                "UOMName": "UOM Name",
                "LocationHead": "Location",
                "IssueQty": "Issue Quantity",
                "Qty": "Quantity",
                "Price": "Price",
                "Amount": "Amount",

                "OffLoadingCharges": "OffLoading Charges",

                "SaveButton": "Save",
                "CancelButton": "Back",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "charecteristics": {
            "management": {
                "Title": "Characteristics",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Org": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Characteristics",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "hasAspects": "Has Aspects",
                "Optional": "Optional",
                "FixedValue": "Fixed Value",
                "MeasurementUnit": "Measurement Unit",
                "Description": "Description:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
        },

        "aspect": {
            "management": {
                "Title": "Aspect",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Org": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Aspect",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "CharecteristicsCode": "Charecteristics Code:*",
                "CharecteristicsName": "Charecteristics Name:*",
                "Description": "Description:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
        },

        "samplesize": {
            "management": {
                "Title": "Sample Size",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Org": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Sample Size",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "LowerLimit": "Lower Limit:",
                "UpperLimit": "Upper Limit:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
        },

        "samplingmethod": {
            "management": {
                "Title": "Sampling Method",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Org": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Sampling Method",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "SampleSizeCode": "Sample Size Code:",
                "SampleSizeName": "Sample Size Name:",
                "SamplingMethodType": "Sampling Method Type:",
                "RandomPickingQuantity": "Random Picking Quantity:",
                "AcceptanceQuantity": "Acceptance Quantity:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
        },

        "chemicalphysicalanalysis": {
            "management": {
                "Title": "Chemical Physical Analysis",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Org Name",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Chemical Physical Analysis",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No:*",
                "DocumentDate": "Document Date:*",
                "Flour": "Flour:*",
                "Pekar": "Pekar:*",
                "Prot": "Prot%:*",
                "Moist": "Moist%:*",
                "Zel": "Zel:*",
                "Gran": "Gran:*",
                "WAbs": "W.Abs:*",
                "Ash": "Ash%:*",
                "FN": "FN:*",
                "AA": "AA:*",
                "FE": "FE:*",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
        },

        "chemicalanalysis": {
            "management": {
                "Title": "Chemical Analysis",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Org Name",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Chemical Analysis",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No:*",
                "DocumentDate": "Document Date:*",
                "Flour": "Flour:*",
                "FN": "FN:*",
                "Pekar": "Pekar:*",
                "WGlutten": "W.Glutten:*",
                "DGlutten": "D.Glutten:*",
                "GIndex": "G.Index:*",
                "WaterB": "Water B:*",
                "Moiture": "Moiture%:*",
                "Ash": "Ash%:*",
                "DStarch": "D.Starch:*",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
        },

        "chemicalphysicalanalysis2": {
            "management": {
                "Title": "Chemical Physical Analysis",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Org Name",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Chemical Physical Analysis",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No:*",
                "DocumentDate": "Document Date:*",
                "Flour": "Flour:*",
                "Pekar": "Pekar:*",
                "Moist": "Moist%:*",
                "Prot": "Prot%:*",
                "Ash": "Ash%:*",
                "WGlu": "W.Glu:*",
                "Lstar": "L*:*",
                "Wno": "W:*",
                "DStarch": "D.Starch:*",
                "WAbs": "W.Abs:*",
                "Zeleny": "Zeleny:*",
                "AAcid": "A.Acid:*",
                "Iron": "Iron:*",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
        },

        "wheattransferanalysissheet": {
            "management": {
                "Title": "Wheat Transfer Analysis Sheet",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Org Name",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Wheat Transfer Analysis Sheet",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No:*",
                "DocumentDate": "Document Date:*",
                "WheatType": "Wheat Type:*",
                "VessalName": "Vessal Name:*",
                "Humidite": "Humidite%:*",
                "Proteine": "Proteine%:*",
                "GH": "GH%:*",
                "Wout": "W:*",
                "Zeleny": "Zeleny(ml):*",
                "Mitadin": "Mitadin:*",
                "PS": "PS(kg/hl):*",
                "FN": "FN(s):*",
                "PlaceOfOrigin": "Place Of Origin:*",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
        },

        "productionbatch": {
            "management": {
                "Title": "Production Batch",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Org Name",
                    "Code": "Code",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Production Batch",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "DocumentDate": "Document Date:*",
                "Shift": "Shift:*",
                "Miller": "Miller:*",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
        },

        "flourbrand": {
            "management": {
                "Title": "Flour Brand",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Flour Brand",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "ItemCode": "Item Code:*",
                "ItemName": "Item Name:*",
                "MixingRatio": "Mixing Ratio:*",
                "SoftWheat": "Soft Wheat:*",
                "HardWheat": "Hard Wheat:*",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
        },

        "wheatcleaningconditioning": {
            "management": {
                "Title": "Wheat Cleaning Conditioning",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Org Name",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Wheat Cleaning Conditioning",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No:*",
                "DocumentDate": "Document Date:*",
                "SiloCode": "Silo Code:*",
                "Humidity": "Humidity:*",
                "TypeBLC": "Type BLC:*",
                "Narire": "Narire:*",
                "Quantity": "Quantity:*",
                "EQU": "EQU:*",
                "HumidityAC": "Humidity A/C:*",
                "SiliCode_2": "Silo Condition:*",
                "RestTime": "Rest Time(Hrs):*",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
        },

        "millcheckup": {
            "management": {
                "Title": "Mill Check Up",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Org Name",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Mill Check Up",
                "SubTitle": "SubTitle",
                "Tab1": "General",
                "Tab2": "Details",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No:*",
                "DocumentDate": "Document Date:*",
                "MillerCode": "Miller Code:*",
                "WaterInTank": "Water In Tank:*",
                "WaterPump": "Water Pump:*",
                "EPI": "EPI:*",
                "Separator": "Separator:*",
                "Magnet": "Magnet:*",
                "Epomteuse": "Epomteuse:*",
                "Epirreur": "Epirreur:*",
                "Compressor": "Compressor:*",

                "SiloCode": "Silo Code",
                "SiloName": "Silo Name",
                "Reading": "Reading",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
        },

        "charsamplingmethod": {
            "management": {
                "Title": "Char Sampling Method",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Org": "Org Name",
                    "CharacteristicCode": "Characteristic Code",
                    "CharacteristicName": "Characteristic Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Char Sampling Method",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "CharecteristicsCode": "Charecteristics Code:",
                "CharecteristicsName": "Charecteristics Name:",
                "SamplingMethodCode": "Sampling Method Code",
                "SamplingMethodName": "Sampling Method Name",
                "RandomPickingQuantity": "Random Picking Quantity",
                "AcceptanceQuantity": "Acceptance Quantity",
                "SampleSizeCode": "Sample Size Code",
                "SampleSizeName": "Sample Size Name",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
        },

        "frequency": {
            "management": {
                "Title": "Frequency",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Org": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Frequency",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "FrequencyValue": "Frequency Value:",
                "FrequencyInterval": "Frequency Interval:",
                "FrequencyType": "Frequency Type:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
        },

        "testplan": {
            "management": {
                "Title": "Test Plan",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Org": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Test Plan",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "Description": "Description:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
        },


        "WheatCleaningTest": {
            "management": {
                "Title": "Wheat Cleaning Test",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Org": "Org Name",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Wheat Cleaning Test",

                "Tab1": "General",
                "Tab2": "Details",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "DocumentNo": "Document No:",
                "DocumentDate": "Document Date:",
                "SiloCode": "Dirty Wheat Silo Code:",
                "SiloName": "Dirty Wheat Silo Name:",
                "CleanWheatSiloCode": "Clean Wheat Silo Code:",
                "CleanWheatSiloName": "Clean Wheat Silo Name:",
                "flourTypeCode": "Flour Type Code",
                "flourTypeName": "Flour Type Name",
                "percentage": "%",
                "proteinValue": "Protein Value",
                "moistureValue": "Moisture Value",

                "dateAndTime": "Date And Time",
                "flourAspectCode": "Flour Aspect Code",
                "flourAspectName": "Flour Aspect Name",
                "improverControl": "Improver Control",
                "humidityDateTime": "Humidity Date Time",
                "humidityValue": "Humidity Value",

                "BatchNo": "Batch No:",
                "PlantCode": "Plant Code:",
                "WaterQuantity": "WaterQuantity",
                "WheatQuantity": "Wheat Quantity:",
                "TemperingTime": "Tempering Time:",
                "Remarks": "Remarks:",
                "Narration": "Remarks:",
                "TemperingHour": "Tempering Time(Hours):",
                "TemperingMinute": "Tempering Time(Minutes):",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
        },


        "testgroup": {
            "management": {
                "Title": "Test Group",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Org": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Test Group",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
        },

        "MillingTest": {
            "management": {
                "Title": "Milling Test",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Org": "Org Name",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Milling Test",
                "SubTitle": "SubTitle",
                "Tab1": "General",
                "Tab2": "Aspect Details",
                "Tab3": "Humidity[B1] Details",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No:*",
                "DocumentDate": "Document Date:*",

                "SiloCode": "Silo Code:*",
                "SiloName": "Silo Name:*",
                "BatchNo": "Batch No:*",
                "PlantCode": "Plant Code:*",
                "FlourTypeCode": "Flour Type Code:*",
                "FlourTypeName": "Flour Type Name:*",
                "FlourProducerRate": "Flour Production Rate[KG/H]:*",
                "ImproverFeedingRate": "Improver Feeding Rate[Gram/H]:*",
                "humidityDateTime": "Date and Time:*",
                "humidityValue": "Value:*",
                "Narration": "Narration:*",
                "dateAndTime": "Date And Time*",
                "flourAspectCode": "Flour Aspect Code*",
                "flourAspectName": "FlourAspect Name*",
                "improverControl": "improverControl*",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
        },
        "productpricing": {
            "management": {
                "Title": "Product Pricing",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No.",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Product Pricing",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "Remarks": "Remarks:",
                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "UOMCode": "UOM Code",
                "UOMName": "UOM Name",
                "ProductCost": "Product Cost",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "report": {
                "Title": "Product Pricing",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "JobCardNo": "Job Card No",
                "DocumentNo": "Document No.",
                "DocumentDate": "Document Date",
                "Remarks": "Remarks",
                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "UOMCode": "UOM Code",
                "UOMName": "UOM Name",
                "ProductCost": "Product Cost",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },


        "RheologyTest": {
            "management": {
                "Title": "Rheology Test",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Org": "Org Name",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Rheology Test",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No:*",
                "DocumentDate": "Document Date:*",

                "BatchNo": "Batch No:*",
                "PlantCode": "Plant Code:*",

                "WaterAsorption": "Water Absorption:*",
                "Stability": "Stability:*",
                "Weakening": "Weakening:*",
                "Resistance": "Resistance:*",
                "ResistanceMax": "Resistance Max:*",
                "Extensibility": "Extensibility:*",
                "Energy": "Energy:*",
                "RatioNumber": "Ratio Number:*",
                "RatioNumberMax": "RatioNumber Max:*",

                "Remarks": "Remarks:*",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
        },

        "LabTest": {
            "management": {
                "Title": "Lab Test",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Org": "Org Name",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Lab Test",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No:*",
                "DocumentDate": "Document Date:*",

                "BatchNo": "Batch No:*",
                "PlantCode": "Plant Code:*",
                "FlourType": "Flour Type:*",

                "AverageProteinContent": "Average Protein Content:*",
                "AverageAshContent": "Average Ash Content:*",
                "averageHumidity": "Average Humidity:*",
                "averageFallingNo": "Average Falling No:*",
                "averageWetGluten": "Average Wet Gluten:*",
                "averageDryGluten": "Average Dry Gluten:*",
                "averageZeleny": "Average Zeleny:*",
                "glutenIndex": "Gluten Index:*",

                "particleSize500M": "Particle Size 500M:*",
                "particleSize250M": "Particle Size 250M:*",
                "particleSize200M": "Particle Size 200M:*",
                "particleSize180M": "Particle Size 180M:*",
                "particleSize160M": "Particle Size 160M:*",
                "particleSize150M": "Particle Size 150M:*",
                "particleSize125M": "Particle Size 125M:*",

                "Remarks": "Remarks:*",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By",

                "separator1": "Partich Size"
            },
        },

        "BreadBakingTest": {
            "management": {
                "Title": "Bread Baking Test",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Org": "Org Name",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Bread Baking Test",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No:*",
                "DocumentDate": "Document Date:*",

                "BatchNo": "Batch No:*",
                "PlantCode": "Plant Code:*",
                "FlourType": "Flour Type:*",

                "waterAsorptionRatio": "Water Asorption Ratio:*",
                "breadValume2H30M": "Bread Valume[2H30M]:*",
                "breadValume3H00M": "Bread Valume[3H00M]:*",
                "bloomId": "Bloom:*",
                "colourId": "Colour:*",
                "elasticityId": "Elasticity:*",
                "consistancyId": "Consistancy:*",

                "Remarks": "Remarks:*",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
        },

        "DoughnutTest": {
            "management": {
                "Title": "Doughnut Test",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Org": "Org Name",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Doughnut Test",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No:*",
                "DocumentDate": "Document Date:*",

                "BatchNo": "Batch No:*",
                "PlantCode": "Plant Code:*",
                "FlourType": "Flour Type:*",

                "flourDrinksId": "Flour Drinks:*",
                "flourDrinksValue": "Flour Drinks Value:*",
                "doughPullsId": "Dough Pulls:*",
                "doughPullsValue": "Dough Pulls Value:*",
                "doughSwellsId": "Dough Swells:*",
                "doughSwellsValue": "Dough Swells Value:*",

                "doughBecomeFluentId": "Dough Become Fluent:*",
                "doughBecomeFluentValue": "Dough Become Fluent Value:*",

                "veryRoundDoughNutsId": "Very Round DoughNuts:*",
                "veryRoundDoughNutsValue": "Very Round DoughNuts Value:*",

                "wellColourDoughNutsId": "Well Colour DoughNuts:*",
                "wellColourDoughNutsValue": "Well Colour DoughNuts Value:*",

                "crumbTestureId": "Crumb Testure:*",
                "crumbTestureValue": "Crumb Testure Value:*",

                "tasteId": "Taste:*",
                "tasteValue": "Taste Value:*",

                "oilAbsorptionId": "Oil Absorption:*",
                "oilAbsorptionValue": "Oil Absorption Value:*",

                "crustAspectId": "Crust Aspect:*",
                "crustAspectValue": "Crust Aspect Value:*",

                "Remarks": "Remarks:*",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By",

                "separator1": "Quality Appriciater A Doughnut/170",
                "separator2": "Quality Appriciater A Doughnut/130"
            },
        },

        "testdefinition": {
            "management": {
                "Title": "Test Definition",
                "SubTitle": "SubTitle",

                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Org": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "RevisionNo": "Revision No",
                    "RevisionDate": "Revision Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Test Definition",
                "SubTitle": "SubTitle",
                "Tab1": "General",
                "Tab2": "Details",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:",
                "Type": "Type:",
                "InspectionTime": "Inspection Time(min):",
                "GroupCode": "Group Code:",
                "GroupName": "Group Name:",
                "PlanCode": "Plan Code:",
                "PlanName": "Plan Name:",
                "FrequencyCode": "Frequency Code:",
                "FrequencyName": "Frequency Name:",
                "RevisionNo": "Revision No.:*",
                "RevisionDate": "RevisionDate:*",
                "Description": "Description:*",

                "CharCode": "Char Code",
                "CharName": "Char Name",
                "InspectionTime1": "Inspection Time(min)",
                "FixedValue": "Fixed Value",
                "OptionalChar": "Optional Char",

                "AspectCode": "Aspect Code",
                "AspectName": "Aspect Name",
                "SpecificationUSL": "Specification USL",
                "SpecificationLSL": "Specification LSL",
                "MeasurementUnit": "Measurement Unit",
                "InspectionMethod": "Inspection Method",
                "InspectionType": "Inspection Type(%)",
                "SamplingPlan": "Sampling Plan",
                "NoOfTimes": "No Of Times",
                "SamplingMethodCode": "Sampling Method Code",
                "SamplingMethodName": "Sampling Method Name",
                "Destructive": "Destructive",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "itemwisetest": {
            "management": {
                "Title": "Item Wise Test",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Org": "Org Name",
                    "ItemCode": "Item Code",
                    "ItemName": "Item Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Item Wise Test",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "ItemCode": "Item Code:",
                "ItemName": "Item Name:",
                "TestDefinitionCode": "Test Definition Code",
                "TestDefinitionName": "Test Definition Name",
                "RevisionNo": "Revision No",
                "RevisionDate": "Revision Date",
                "Optional": "Optional",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
        },

        "calibrationdefinition": {
            "management": {
                "Title": "Calibration Definition",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Org": "Org Name",
                    "Code": "Code",
                    "Name": "Name",
                    "RevisionNo": "Revision No",
                    "RevisionDate": "Revision Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Calibration Definition",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:",
                "Name": "Name:",
                "MeasurementUnit": "Measurement Unit:",
                "CalibrationTime": "Calibration Time(min):",
                "RevisionNo": "Revision No:",
                "RevisionDate": "Revision Date:",
                "ExpectedResult": "Expected Result:",
                "CalibrationSetup": "Calibration Setup:",
                "ProcessRequirements": "Process Requirements:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
        },

        "speedviolation": {
            "management": {
                "Title": "Speed Violation",
                "SubTitle": "Speed Violation",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "UnitName": "UnitName",
                    "DriverName": "Driver Name",
                    "Speed": "Speed",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Speed Violation",
                "SubTitle": "Speed Violation",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "unitId": "Unit No:",
                "driverId": "Driver Code:",
                "speed": "Speed:",
                "DocumentDate": "Document Date:",
                "fleetOfficerId": "Fleet Officer Code:",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
        },

        "wheatcleaningtest": {
            "management": {
                "Title": "Wheat Cleaning Test",
                "SubTitle": "Wheat Cleaning Test",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Org Name",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Wheat Cleaning Test",
                "SubTitle": "Wheat Cleaning Test",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",

                "DocumentNo": "Document No:",
                "DocumentDate": "Document Date:",
                "SiloCode": "Silo Code:",
                "SiloName": "Silo Name:",
                "BatchNo": "Batch No:",
                "PlantCode": "Plant Code:",
                "WaterQuantity": "Water Quantity:",
                "WheatQuantity": "Wheat Quantity:",
                "TemperingHour": "Tempering Hour:",
                "TemperingMinute": "Tempering Minute:",
                "Narration": "Narration:",

                "flourTypeCode": "Flour Type Code",
                "flourTypeName": "Flour Type Name",
                "percentage": "Percentage",
                "proteinValue": "Protein Value",
                "moistureValue": "Moisture Value",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By",

                "Tab1": "General",
                "Tab2": "Details"
            },
        },

        "pricelist": {
            "management": {
                "Title": "Price List",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Vendor": "Vendor",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Price List",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "VendorCode": "Vendor Code:*",
                "VendorName": "Vendor Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "Remarks": "Remarks:",
                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "UOMCode": "UOM",
                "UOMName": "UOM Name",
                "Price": "Price",
                "MinimumOrderQuantity": "Minimum Order Quantity",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "report": {
                "Title": "Price List",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "VendorCode": "Vendor Code",
                "VendorName": "Vendor Name",
                "DocumentNo": "Document No.",
                "DocumentDate": "Document Date",
                "Remarks": "Remarks",
                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "UOMCode": "UOM Code",
                "UOMName": "UOM Name",
                "ExpDate": "Expiry Date",
                "Quantity": "Quantity",
                "ExeQuantity": "Executed Quantity",
                "BalQuantity": "Balance Quantity",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },

        "miscellaneouspricelist": {
            "management": {
                "Title": "Miscellaneous Price List",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Vendor": "Vendor",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Miscellaneous Price List",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "VendorCode": "Vendor Code:*",
                "VendorName": "Vendor Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "Remarks": "Remarks:",
                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "UOMCode": "UOM",
                "UOMName": "UOM Name",
                "LoadingCharges": "Loading Charges",
                "UnloadingCharges": "Unloading Charges",
                "TransportationCharges": "Transportation Charges",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
        },

        "productcosting": {
            "management": {
                "Title": "Product Costing",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Product Costing",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "Remarks": "Remarks:",
                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "UOMCode": "UOM",
                "UOMName": "UOM Name",
                "Price": "Price",
                "MinimumOrderQuantity": "Minimum Order Quantity",
                "WarehouseCode": "Warehouse Code",
                "WarehouseName": "Warehouse Name",
                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "report": {
                "Title": "Product Costing",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.",
                "DocumentDate": "Document Date",
                "Remarks": "Remarks",
                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "UOMCode": "UOM Code",
                "UOMName": "UOM Name",
                "ExpDate": "Expiry Date",
                "Quantity": "Quantity",
                "ExeQuantity": "Executed Quantity",
                "BalQuantity": "Balance Quantity",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            },
            "print": {
                "OrgSubOrgName": "Org/SubOrg Name:",
                "OrgSubOrgCode": "Org/SubOrg Code:",
                "Code": "Code:",
                "Name": "Name:",
                "Remarks": "Remarks:"
            }
        },


        "salescontract": {
            "management": {
                "Title": "Sales Price List",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "SalesRegion": "Sales Region",
                    "SalesPerson": "Sales Person",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Sales Price List",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "VendorCode": "Vendor Code:*",
                "VendorName": "Vendor Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "SalesRegion": "Sales Region*:",
                "SalesPerson": "Sales Person*:",
                "Remarks": "Remarks:",

                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "UOMCode": "UOM",
                "UOMName": "UOM Name",
                "Price": "Price",
                "MinimumOrderQuantity": "Minimum Order Quantity",

                "SalesDestinationCode": "Sales Destination Code",
                "SalesDestinationName": "Sales Destination Name",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "salepricelist": {
            "management": {
                "Title": "Sale Price List",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "SalesRegion": "Sales Region",
                    "SalesPerson": "Sales Person",
                    "salesDestinationName": "Destination Name",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Sale Price List",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "VendorCode": "Vendor Code:*",
                "VendorName": "Vendor Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "SalesRegion": "Sales Region*:",
                "SalesPerson": "Sales Person*:",
                "Remarks": "Remarks:",

                "ItemCode": "Item Code",
                "ItemName": "Item Name",
                "UOMCode": "UOM",
                "UOMName": "UOM Name",
                "Price": "Price",
                "MinimumOrderQuantity": "Minimum Order Quantity",

                "SalesDestinationCode": "Sales Destination Code*:",
                "SalesDestinationName": "Sales Destination Name*:",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "salestarget": {
            "management": {
                "Title": "Sales Target",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Organisation": "Organization",
                    "DocumentNo": "Document No",
                    "DocumentDate": "Document Date",
                    "TargetYear": "TargetYear",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Sales Target",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "DocumentNo": "Document No.:*",
                "DocumentDate": "Document Date:*",
                "TargetYear": "Target Year*:",
                "Remarks": "Remarks:",

                "SalesPersonName": "Sales Person",
                "SalesRegionName": "Sales Region",
                "Jan": "Jan",
                "Fab": "Fab",
                "Mar": "Mar",
                "Apr": "Apr",
                "May": "May",
                "Jun": "Jun",
                "Jul": "Jul",
                "Aug": "Aug",
                "Sep": "Sep",
                "Oct": "Oct",
                "Nov": "Nov",
                "Dec": "Dec",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "salesdestination": {
            "management": {
                "Title": "Sales Destination",
                "SubTitle": "SubTitle",
                "NewButton": "New",
                "EditButton": "Edit",
                "DeleteButton": "Delete",
                "ApproveButton": "Approve",
                "RejectButton": "Reject",
                "ActiveButton": "Active",
                "InActiveButton": "InActive",
                "PrintButton": "Print",
                "ViewButton": "View",
                "ExportButton": "Export",
                "GridHeaders": {
                    "Orgname": "Organization",
                    "Code": "Code",
                    "Name": "Name",
                    "SalesRegionName": "Sales Region",
                    "Status": "Status"
                }
            },
            "create": {
                "Title": "Sales Destination",
                "SubTitle": "SubTitle",
                "PrintButton": "Print",
                "OrgSubOrgCode": "Org/SubOrg Code:*",
                "OrgSubOrgName": "Org/SubOrg Name:",
                "Code": "Code:*",
                "Name": "Name:*",
                "SalesRegionName": "Sales Region*:",
                "Remarks": "Remarks:",

                "SaveButton": "Save",
                "CancelButton": "Back to the List",
                "LastUpdatedLabel": "Last Updated",
                "LastUpdatedBy": "Updated By"
            }
        },

        "analyticreport": {
            "printbutton": "Print",
            "ExportButton": "Export",
            "organisation": "Organisation",
            "suborganisation": "Sub Organisation",
            "daterange": "Date Range",
            "fromdate": "From Date",
            "todate": "To Date",
            "reportCode": "Report Name",
            "asondate": "As On Date",
            "UnitId": "Unit Id",
            "validations": {
                "required": "This field is required."
            }
        },
        "formgroup": {
            "printbutton": "Print",
            "organisation": "Organisation",
            "suborganisation": "Sub Organisation",
            "daterange": "Date Range",
            "fromdate": "From Date",
            "todate": "To Date",
            "reportCode": "Report Name",
            "asondate": "As On Date",
            "validations": {
                "required": "This field is required.",
                "typeheadrequired": "{{ fieldname }} is have to select.",
                "minlength": "This field should have at least {{ value }} symbols.",
                "min": "This field should have minimum {{ value }} value.",
                "max": "This field should have maximum {{ value }} value."
            }
        },
        "modalpopup": {
            "cancelbutton": "Cancel",
            "oKbutton": "OK",
            "columnfiltermodal": {
                "title": "Advance Filter",
            },
            "employee-modal": {
                "title": "Employee",

                "id": "Id",
                "Code": "Code",
                "Name": "Name",

                "cancelbutton": "Cancel",
                "oKbutton": "OK"
            },
            "transporter-modal": {
                "title": "Transporter",

                "id": "Id",
                "Code": "Code",
                "Name": "Name",
            },
            "employee-model": {
                "title": "Employee",

                "Code": "Code",
                "Name": "Name"
            },
            "account-treeview-model": {
                "title": "Account Group TreeView",
                "cancelbutton": "Cancel"
            },
            "adjustment-modal": {
                "title": "Adjustments",

                "docno": "Doc.No.",
                "docdate": "Doc.Date",
                "type": "Type",
                "amount": "Amount",
                "netamount": "NetAmount",
                "adjamount": "Adj.Amount",
                "balamount": "Bal.Amount",
                "FS": "FS",
                "OrgCode": "OrgCode",

                "cancelbutton": "Close",
                "okbutton": "Ok"
            },
            "item-model": {
                "title": "Day Type",

                "Code": "Code",
                "Name": "Name",

                "cancelbutton": "Close",
                "oKbutton": "Ok"
            },
            "assetforopenbalmodal": {
                "title": "Asset",

                "Code": "Code",
                "Name": "Name",

                "cancelbutton": "Cancel",
                "oKbutton": "Ok"
            },
            "assetforrevaluationmodal": {
                "title": "Asset",

                "Code": "Code",
                "Name": "Name",

                "cancelbutton": "Cancel",
                "oKbutton": "Ok"
            },
            "assetforsalesordiscardmodal": {
                "title": "Asset",

                "Code": "Code",
                "Name": "Name",

                "cancelbutton": "Cancel",
                "oKbutton": "Ok"
            },
            "bank-cash-acc-modal": {
                "title": "Bank Cash Account",

                "id": "Id",
                "Code": "Code",
                "Name": "Name",

                "cancelbutton": "Cancel",
                "oKbutton": "OK"
            },
            "bank-modal": {
                "title": "Bank",

                "id": "Id",
                "Code": "Code",
                "Name": "Name",

                "cancelbutton": "Cancel",
                "oKbutton": "OK"
            },
            "budgetlinemodal": {
                "title": "Budget Line",

                "id": "Id",
                "Code": "Code",
                "Name": "Name",

                "cancelbutton": "Cancel",
                "oKbutton": "OK"
            },
            "cheque-register-modal": {
                "title": "Cheque Book Register",

                "sno": "Sno",
                "chequeno": "Cheque No's",
                "status": "Status",

                "cancelbutton": "Cancel",
                "oKbutton": "OK"
            },
            "commodity-modal": {
                "title": "Commodity",

                "id": "Id",
                "Code": "Code",
                "Name": "Name",

                "cancelbutton": "Cancel",
                "oKbutton": "OK"
            },
            "containerReceiptNoteModal": {
                "title": "Container Receipt Note",

                "id": "Id",
                "refno": "ReferenceNo",
                "refdate": "ReferenceDate",
                "containerno": "ContainerNo",
                "customername": "CustomerName",

                "cancelbutton": "Cancel",
                "oKbutton": "OK"
            },
            "contractmodal": {
                "title": "Contract",

                "id": "Id",
                "docno": "Document No",
                "docdate": "Document Date",
                "customercode": "Customer Code",
                "customername": "Customer Name",

                "cancelbutton": "Cancel",
                "oKbutton": "OK"
            },
            "controlaccountcustomermodal": {
                "title": "Customer",

                "id": "Id",
                "Code": "Code",
                "Name": "Name",
                "controlaccountcode": "Control Account Code",

                "cancelbutton": "Cancel",
                "oKbutton": "OK"
            },
            "control-account-employee-modal": {
                "title": "Employee",

                "id": "Id",
                "Code": "Code",
                "Name": "Name",
                "controlaccountcode": "Control Account Code",

                "cancelbutton": "Cancel",
                "oKbutton": "OK"
            },
            "control-account-vendor-modal": {
                "title": "Vendor",

                "id": "Id",
                "Code": "Code",
                "Name": "Name",
                "controlaccountcode": "Control Account Code",

                "cancelbutton": "Cancel",
                "oKbutton": "OK"
            },
            "customermodal": {
                "title": "Customer",

                "id": "Id",
                "Code": "Code",
                "Name": "Name",

                "cancelbutton": "Cancel",
                "oKbutton": "OK"
            },
            "accountgroupmodal": {
                "title": "Account Group Modal",

                "id": "Id",
                "Code": "Code",
                "Name": "Name",

                "cancelbutton": "Cancel",
                "oKbutton": "OK"
            },
            "customermodalv1-model": {
                "title": "Customer",

                "Code": "Code",
                "Name": "Name"
            },
            "segmentmodalv1-model": {
                "title": "Segment",

                "Code": "Code",
                "Name": "Name"
            },
            "rptcustomermodalv1-model": {
                "title": "Customer",

                "Code": "Code",
                "Name": "Name"
            },
            "rptvendormodalv1-model": {
                "title": "Vendor",

                "Code": "Code",
                "Name": "Name"
            },
            "defineTaxFormulaModal": {
                "title": "Define Formula",

                "sno": "Sno",
                "definetax": "DefineTax"
            },
            "division-modal": {
                "title": "Division",

                "id": "Id",
                "Code": "Code",
                "Name": "Name"
            },
            "driver-modal": {
                "title": "Driver",

                "id": "Id",
                "Code": "Code",
                "Name": "Name"
            },
            "employee-modal-V1": {
                "title": "Employee",

                "Code": "Code",
                "Name": "Name",
            },
            "feature-item-modal": {
                "title": "Master/Transaction Page List",

                "id": "Id",
                "Code": "Code",
                "Name": "Name",
            },
            "financeaccountmodalsingleselection": {
                "title": "Finance Accounts",

                "Code": "Code",
                "Name": "Name",
            },
            "financeaccountmodal": {
                "title": "Finance Accounts",

                "Code": "Code",
                "Name": "Name",
            },
            "generalPaymentRefNoModal": {
                "title": "General Payment Reference",

                "id": "Id",
                "requestno": "RequestNo",
                "Name": "Name",
                "requestdate": "RequestDate",
            },
            "manifestOnUnitModal": {
                "title": "Manifest No",

                "id": "Id",
                "documentNo": "Document No",
                "documentDate": "Document Date",
                "customer": "Customer",
                "finalDestination": "Final Destination",
            },
            "generalReceiptRefNoModal": {
                "title": "General Receipt Reference",

                "id": "Id",
                "requestno": "RequestNo",
                "Name": "Name",
                "requestdate": "RequestDate",
            },
            "incomeanddeductionmodel": {
                "title": "Income and Deduction",

                "Code": "Code",
                "Name": "Name",
            },
            "issued-cheque-register-modal": {
                "title": "Cheque Book Register",

                "sno": "Sno",
                "chequeno": "Cheque No's",
                "status": "Status"
            },
            "issue-location-modal": {
                "title": "Locations",

                "id": "Id",
                "warehouse": "Warehouse",
                "location": "Location",
                "bintype": "Bintype",
                "bin": "Bin",
                "phyqty": "Phy. Qty",
                "issuedqty": "Issued Qty"
            },
            "issue-location-modal-v1": {
                "title": "Locations",

                "id": "Id",
                "warehouse": "Warehouse",
                "location": "Location",
                "bintype": "Bintype",
                "bin": "Bin",
                "phyqty": "Phy. Qty",
                "lotno": "LotNo",
                "expirydate": "ExpiryDate",
                "quantity": "Quantity"
            },
            "istissue-location-modal-v1": {
                "title": "Locations",

                "id": "Id",
                "warehouse": "Warehouse",
                "location": "Location",
                "bintype": "Bintype",
                "bin": "Bin",
                "phyqty": "Phy. Qty",
                "lotno": "LotNo",
                "expirydate": "ExpiryDate",
                "quantity": "Quantity"
            },
            "ItemModalSingleSelection": {
                "title": "Items",

                "Code": "Code",
                "Name": "Name",
                "itempartno": "Item Part No"
            },
            "ItemTypeModal": {
                "title": "Item Type",

                "id": "Id",
                "Code": "Code",
                "Name": "Name",

                "cancelbutton": "Cancel",
                "oKbutton": "OK"
            },
            "ItemCategoryModal": {
                "title": "Item Category",

                "id": "Id",
                "Code": "Code",
                "Name": "Name",

                "cancelbutton": "Cancel",
                "oKbutton": "OK"
            },
            "ItemGroupModal": {
                "title": "Item Group",

                "id": "Id",
                "Code": "Code",
                "Name": "Name",

                "cancelbutton": "Cancel",
                "oKbutton": "OK"
            },
            "itemPurchaseRequisitionModal": {
                "title": "Items",

                "Code": "Code",
                "Name": "Name",
                "itempartno": "Item Part No"
            },
            "mechanicmodel": {
                "title": "Mechanic",

                "Code": "Code",
                "Name": "Name"
            },
            "navigateConfirmation": {
                "confirm": "Confirm"
            },
            "posettlementitemmodel": {
                "title": "Items",

                "itemcode": "Item Code",
                "itemname": "Item Name",
                "uomcode": "Uom Code",
                "refquantity": "Ref Quantity",
                "balquantity": "Bal Quantity"
            },
            "productionordermodal": {
                "title": "Prod Order Items",

                "salesorder": "Sales Order",
                "fgitemcode": "FG Item Code",
                "machinecode": "Machine Code",

                "prodordercode": "prod Order Code",
                "itemcode": "Item Code",
                "itemname": "Item Name",
                "uom": "Uom",
                "qty": "Qty",
                "fgitemname": "FG Item Name"
            },
            "purchase-order-modal": {
                "title": "Purchase Orders",

                "id": "Id",
                "orderno": "Order No",
                "orderdate": "Order Date"
            },
            "sales-order-modal": {
                "title": "Sales Orders",

                "id": "Id",
                "refno": "Order No",
                "refdate": "Order Date",
                "finalDestination": "Final Destination",
                "customerName": "Customer Name"
            },
            "billofexchangemodal": {
                "title": "Bill Of Exchange",

                "id": "Id",
                "documentNo": "Document No",
                "documentDate": "Document Date",
            },
            "delivery-note-sales-invoice-modal": {
                "title": "Reference",

                "id": "Id",
                "documentNo": "Document No",
                "documentDate": "Document Date"
            },
            "receipt-location-modal": {
                "title": "Locations",

                "id": "Id",
                "warehouse": "Warehouse",
                "location": "Location",
                "bintype": "Bintype",
                "bin": "Bin",
                "recqty": "Rec. Qty"
            },
            "receipt-location-modal-v1": {
                "title": "Locations",

                "id": "Id",
                "warehouse": "Warehouse",
                "location": "Location",
                "bintype": "Bintype",
                "bin": "Bin",
                "lotno": "LotNo",
                "expirydate": "ExpiryDate",
                "quantity": "Quantity"
            },
            "istreceipt-location-modal-v1": {
                "title": "Locations",

                "id": "Id",
                "warehouse": "Warehouse",
                "location": "Location",
                "bintype": "Bintype",
                "bin": "Bin",
                "lotno": "LotNo",
                "expirydate": "ExpiryDate",
                "quantity": "Quantity"
            },
            "opening-balance-modal": {
                "title": "Opening Balance",

                "id": "Id",
                "controlAccountName": "Control Account",
                "transType": "Trans Type",
                "transNo": "Trans No",
                "transDate": "Trans Date",
                "dueDate": "Due Date",
                "debit": "Debit",
                "credit": "Credit",
            },
            "route-modal": {
                "title": "Route",

                "Code": "Code",
                "ntName": "ntName"
            },
            "segment-modal": {
                "title": "Segments",

                "sno": "S.No",
                "type": "Type",
                "Code": "Code",
                "Name": "Name",
                "debitamount": "Debit Amount",
                "creditamount": "Credit Amount"
            },
            "sessiontimeoutmodal": {
                "sessiontimeOut": "Session TimeOut",
                "logout": "Logout",
                "stay": "Stay"
            },
            "stock-reconciliation-location-modal": {
                "title": "Locations",

                "id": "Id",
                "warehouse": "Warehouse",
                "location": "Location",
                "bintype": "Bintype",
                "bin": "Bin",
                "systemqty": "System Qty",
                "lotno": "LotNo",
                "expirydate": "ExpiryDate",
                "physicalqty": "Physical Qty",
                "reconcileqty": "Reconcile Qty"
            },
            "task-model": {
                "title": "Task",

                "Code": "Code",
                "Name": "Name"
            },
            "tax-component-modal": {
                "title": "Tax Components",

                "Code": "Code",
                "reqqty": "Req.Qty",
                "price": "Price",
                "taxformula": "Tax Formula",
                "amount": "Amount",

                "components": "Components",
                "component": "Component",
                "profile": "Profile",
                "value": "Value",
                "mode": "Mode",
                "plusminus": "+/-",
                "credit": "Credit",

                "transactionformula": "Transaction Formula",
                "itemwisetotal": "Item Wise Total",
                "itemcomponentstotal": "Item Components Total",
                "nettotal": "Net Total",
                "transactionwisetotal": "Transaction Wise Total",
                "grosstotal": "Gross Total"
            },
            "tyre-model-location-wise": {
                "title": "Items",

                "Code": "Code",
                "Name": "Name"
            },
            "tyre-model": {
                "title": "Tyre Master",

                "Code": "Code",
                "Name": "Name"
            },
            "tyre-model-unoperational": {
                "title": "Items",

                "Code": "Code",
                "Name": "Name"
            },
            "unit-modal": {
                "title": "Unit",

                "id": "Id",
                "Code": "Code",
                "Name": "Name"
            },
            "unit-modalV1": {
                "title": "Unit",

                "Code": "Code",
                "Name": "Name"
            },
            "uommodel": {
                "title": "UOM",

                "Code": "Code",
                "Name": "Name"
            },
            "usermodal-model": {
                "title": "Users",

                "username": "User Name",
                "Name": "Name"
            },
            "usermodalsingleselection": {
                "title": "Users",

                "username": "User Name",
                "Name": "Name"
            },
            "vendor-modal": {
                "title": "Vendor",

                "id": "Id",
                "Code": "Code",
                "Name": "Name"
            },
            "lender-modal": {
                "title": "Lender/Borrower",

                "id": "Id",
                "Code": "Code",
                "Name": "Name"
            },
            "question-modal": {
                "title": "Question",

                "id": "Id",
                "Code": "Code",
                "Name": "Name"
            },
            "vendormodalv1-model": {
                "title": "Vendor",

                "Code": "Code",
                "Name": "Name"
            },
            "analyticsreportmodel": {
                "title": "Analytics Reports",

                "id": "Id",
                "Code": "Code",
                "modulename": "Module Name",
                "reportname": "Report Name"
            },
            "from-location-modal": {
                "title": "From Locations",

                "id": "Id",
                "warehouse": "Warehouse",
                "location": "Location",
                "bintype": "Bintype",
                "bin": "Bin",
                "physicalqty": "Physical Qty",
                "lotno": "LotNo",
                "expirydate": "ExpiryDate",
                "quantity": "Quantity"
            },
            "location-transfer-modal": {
                "title": "To Locations",

                "id": "Id",
                "warehouse": "Warehouse",
                "location": "Location",
                "bintype": "Bintype",
                "bin": "Bin",
                "lotno": "LotNo",
                "expirydate": "ExpiryDate",
                "recqty": "Rec. Qty"
            },
            "showRegisterModal": {
                "title": "Cheque Book Register",

                "bankcode": "Bank Code",
                "bankaccountno": "Bank Account No",
                "chequebookname": "Cheque Book Name",

                "sno": "Sno",
                "chequeno": "Cheque No's",
                "status": "Status",
                "chequevalue": "Cheque Value",
                "date": "Date",
                "reason": "Reason"
            },
            "recentmanifestmodal": {
                "title": "Recent Manifest",

                "drivername": "Driver name",
                "unitcode": "Unit code",
                "routecode": "Route code",
                "searchtext": "Document No",

                "manifestno": "Manifest No",
                "manifestdate": "Manifest Date",
                "customername": "Customer Name",

                "buttonSearch": "Search"
            },
            "warehousemodal": {
                "title": "Warehouse",

                "id": "Id",
                "Code": "Code",
                "Name": "Name"
            },
            "salesDestination-modal": {
                "title": "Sales Destination",

                "id": "Id",
                "Code": "Code",
                "Name": "Name"
            },
            "finalDestination-modal": {
                "title": "Final Destination",

                "id": "Id",
                "Code": "Code",
                "Name": "Name"
            },
            "jobcard-modal": {
                "title": "JobCard",
                "id": "Id",
                "jobCardNo": "JobCard No",
                "jobCardDate": "JobCard Date"
            },
            "taskdetails-modal": {
                "title": "Task Details",
                "id": "Id",
                "TaskDetailsNo": "Task Details No",
                "TaskDetailsDate": "Task Details Date"
            },
            "TaskListFromDetailsModal": {
                "title": "Task List",
                "id": "Id",
                "TaskCode": "TaskCode",
                "TaskName": "TaskName"
            }
        },
        "ActionMessage": {
            "SaveSuccessTransaction": "Data has been saved successfully!",
            "SaveUpdateTransaction": "Data has been updated successfully!",
            "TransactionApprovedSuccessfully": "Transaction has been approved successfully.",
            "TransactionReverseSuccessfully": "Transaction has been reversed successfully.",
            "TransactionRePostSuccessfully": "Transaction has been repost successfully.",
            "RecordUpdatedSuccessfully": "Record has been updated successfully.",
            "UnauthorizedForApproving": "Unauthorized for approving.",
            "TransDocExist": "Document No is already exist.",
            "TransPurOrderExist": "Purchase Order is already exist.",
            "TransCodeNameExist": "Code or Name is already exist.",
            "ContainerConfigurationCodeNameExist": "ContainerConfiguration code or name is already exist.",
            "TransVendorCodeNameExist": "Vender Master code or name is already exist.",
            "TransSomethingWrong": "Something went wrong.",
            "SelectOneLineItems": "Select at least one line item to submit the form.",
            "CreateOneLineItems": "Create at least one line item to submit the form.",
            "PaymentRequestAmountpending": "Payment request amount should not exceed pending amount.",
            "ChargesAmountCantBlank": "Charges amount can't be blank in line item.",
            "RequisitionQtyNotExceedQty": "Requisition Quantity should not exceed the Item Quantity",
            "QuantityCantBlank": "Quantity can't be blank in line item.",
            "PriceCantBlank": "Price can't be blank in line item.",
            "CreateOneLocationForm": "Create at least one Location for submit the form.",
            "ReturnQtyNotExceedRefQty": "Return Qty not be exceed to Ref Qty.",
            "ReturnQtyNotExceedPhysicalQty": "Return Qty not be exceed to Physical Qty.",
            "QtyCantBlankMinMaxOrder": "Quantity can't be blank min order and max order.",
            "TaxComponentFirst": "Please Set Tax Component first.",
            "TotalPaymentNotGreaterPaymentLineItem": "Total Payment % not greater than 100% in payment due line item.",
            "PaymentNotGreater": "Payment % not greater than 100%.",
            "QtyNotExceedBalQty": "Quantity should not exceed the Balance Quantity.",
            "SelectedPeriodClosedFinCal": "Selected Period is Closed or Financial Calendar is not created.",
            "LocQtyNotNull": "Location Quantity Should Not be Null.",
            "QtyNotExceedRefQty": "Quantity should not exceed the Reference Quantity.",
            "QuantityCantBlankZero": "Quantity can't be blank or 0.",
            "PriceCantBlankZero": "Price can't be blank or 0.",
            "LocationCodeNameExist": "Location code or name is already exist.",
            "CreditDaysCantBlankLineItem": "Credit Days can't be blank in line item.",
            "LoadingAmountCantBlank": "Loading amount can't be blank in line item.",
            "CostPerTonCantBlank": "CostPerTon can't be blank in line item.",
            "ReceivedQtyCantBlank": "Received quantity can't be blank in line item.",
            "AcceptedQtyCantBlank": "Accepted quantity can't be blank in line item.",
            "INVRecQtyLessBalQty": "INV Received Qty must be less than Bal. Qty.",
            "RecQtyLessBalQty": "Received Qty must be less than Bal. Qty.",
            "AcceptedQtyLessRecQty": "Accepted Qty must be less than Rec. Qty.",
            "AcceptedQtyNotExceedBalQty": "Accepted Qty not be exceed to Bal. Qty.",
            "IssueNoExist": "Issue No is already exist.",
            "FillQtyLineItems": "Fill the quantity in line items.",
            "FillPriceLineItems": "Fill the price in line items.",
            "FillReceivedLineItems": "Fill the Received quantity in line items.",
            "FillAcceptedLineItems": "Fill the Accepted quantity in line items.",
            "FillQtyPriceLineItems": "Fill the quantity or price in line items.",
            "FillRemainingLineItems": "Fill the remaining fields in line items.",
            "IssueQtyNotExceedIndentQty": "Issue Qty not be exceed to Indent Qty",
            "IssueQtyNotExceedPhysicalQty": "Issue Qty not be exceed to Physical Qty.",
            "IssueQtyNotExceedIssuedQty": "Issue Qty not be exceed to Issued Qty.",
            "IssueQtyNotExceedReceiptQty": "Issue Qty not be exceed to Receipt Qty.",
            "IssueQtyNotExceedBalQty": "Issue Qty not be exceed to Balance Quantity.",
            "InstallmentsCantBlankLineItem": "Installments can't be blank in line item.",
            "ReceiptQtyNotExceedBalQty": "Receipt Quantity not be exceed to Balance Quantity.",
            "FreeQtyNotExceedBalFreeQty": "Free Qty not be exceed to Balance Free Qty.",
            "TransferQtyMisMatched": "Transfer Qty total is Mis-matched.",
            "ReceiptQtyNotGreaterBalQty": "Receipt Qty should not be greater than Balance Qty.",
            "NumberAlreadyExist": "Number is already exist.",
            "RecordAlreadyExist": "Record already exist.",
            "NameAlreadyExist": "Name is already exist.",
            "FillTonnageLineItems": "Fill the tonnage in line items.",
            "CreateOneLineAdvance": "Create at least one line advance to submit the form.",
            "CreateOneLineManifest": "Create at least one item in manifest to submit the form.",
            "CreateOneLineFuelSlip": "Create at least one item in fuelslip to submit the form.",
            "FillItemInsertData": "fill at least one line item to insert data.",
            "TotalAmountDidNotMatch": "Total amount did not match.",
            "DebitCreditGreater": "Debit or Credit should be greater than 0.",
            "FirstSelectAccount": "Please First select account.",
            "CreditDebitNotEmpty": "Credit or Debit not be empty.",
            "SelectSubOrganisation": "Select your sub organisation.",
            "FillCriteriaLoadReport": "Fill the criteria to load the report.",
            "EmployeeAlreadyExist": "Employee is already exist.",
            "DateCantBlankLineItem": "Date can't be blank in line item.",
            "FromDateToDateCantBlank": "FromDate or ToDate can't be blank in line item.",
            "DepartmenDesignationCantBlank": "Department and Designation can't be blank in line items.",
            "UsernameAlreadyExist": "Username is already exist.",
            "AlreadyExist": "Already exist.",
            "FileNotExist": "File does not exist.",
            "CreditAmountNotMatchLineTotal": "Credit Amount did not match to line items total.",
            "RemainingCantBlankLineItem": "Remaining can't be blank in line item.",
            "CreateOneLineTyreSubmitForm": "Create at least one line tyre to submit the form.",
            "CreateOneLineEmployee": "Create at least one line employee to submit the form.",
            "RemainingfieldsCantBlank": "Remaining fields can't be blank in line items.",
            "UnitSalesPriceCantBlank": "Unit Sales Price can't be blank or 0 in line item.",
            "SomethingIsMissing": "Something is missing.",
            "SelectLineItems": "Select line items.",
            "SegmentNotExceedDebit": "Segment value should not exceed Debit.",
            "SegmentNotExceedCredit": "Segment value should not exceed Credit.",
            "DebitAmountCreditAmountNotMatch": "Debit Amount and Credit Amount did not match to line items total.",
            "DebitAmountNotMatchLineTotal": "Debit Amount did not match to line items total.",
            "ControlAccountSelectedVendor": "Control Account must be Selected in all the Vendor",
            "ControlAccountSelectedCustomer": "Control Account must be Selected in all the Customer",
            "ControlAccountSelectedEmployee": "Control Account must be Selected in all the Employee",
            "AllFieldsRequiredGrid": "All Fields are required in grid.",
            "ChequebookCodeNameExist": "Chequebook code or name is already exist.",
            "SalesConfigurationAlreadyExist": "SalesConfiguration is already exist.",
            "NoYearlyBudgetSelectedDuration": "No yearly budget of selected duration.",
            "SaleCantBlankLineItem": "Sale Value can't be blank in line item.",
            "SegmentTotalSelected": "Segment Total Should be Selected.",
            "PercentageCantBlankLineItem": "Percentage can't be blank in line item.",
            "IssueQuantityCantBlank": "Issue Quantity can't be blank in line item.",
            "ReceivedQuantityCantBlank": "Received Quantity can't be blank in line item.",
            "TotalAdjustmentAmountNotExistInvoiceBal": "Total Adjustment Amount does not exist more than Invoice Balance Amount.",
            "AdjustmentNotExistBalanceAmount": "Adjustment Amount does not exist more than Balance Amount.",
            "FeatureNameExist": "Feature Name is already exist.",
            "WarehouseIdShouldSelected": "WarehouseId Should be selected.",
            "ReconcilePriceCantBlankLineItem": "Reconcile Price can't be blank in line item.",
            "PhysicalQtyNotExceedSystemQty": "Physical Qty isn't be exceed to System Qty.",
            "NoofLeavesCantblankLineItems": "NoofLeaves can't be blank in line items.",
            "NoofInstallmentsCantBlankLineItems": "No of Installments can't be blank in line items.",
            "SelectDesignationLineItem": "Select Designation in line item.",
            "OTCantBelankLineItem": "OT Can't be blank in line item.",
            "EmployeeCodeNameExist": "Employee code or name is already exist.",
            "RequestAmountBalanceAmount": "Request Amount is less than Balance Amount.",
            "DebitGreaterthanZero": "Debit should be greater than 0.",
            "AmountNotBalanceAmount": "Amount should not greater than balance amount.",
            "AmountCantBlankLineItem": "Amount can't be blank or 0 in line item.",
            "PanelAndReportTitleExist": "Panel and Report Title is already exist.",
            "CreateLineExpressionDefinition": "Create at least one line Expression Definition to submit the form.",
            "CurrentBudgetCantBlankLineItem": "Current Budget can't be blank in line item.",
            "AdjustmentAmtNotBalanceAmount": "Adjustment Amt should not greater than Balance amt",
            "ObjectiveKpisCodeNameExist": "Objective Kpis code or name is already exist.",
            "TripNoExist": "Trip No. is already exist.",
            "AdvanceAmountBalAmount": "Advance Amount must be less than Balance Amount.",
            "AdjustmentAmtNotReceiptNetAmount": "Adjustment amount should not greater than Receipt amount or Net amount.",
            "TotalAdjustAmtModalNotReceiptBalAmt": "Total Adjustment Amount of Modal Popup does not exist more than Receipt Balance Amount.",
            "DiscountConfigurationExist": "DiscountConfiguration is already exist.",
            "NewPasswordConfirmPasswordSame": "New Password and Confirm Password Must Be Same.",
            "PasswordResetSuccessfully": "Password has been reset Successfully.",
            "NoRecordsFound": "No Records Found",
            "FillCriteriaExport": "Fill the criteria to Export.",
            "OrgSubOrgExist": "Org/SubOrg Code is already exist.",
            "PresentKMMustPreviousKM": "Present KM Must be Greater than Previous KM.",
            "PresentKMMustGreEquPreviousKM": "Present KM Must be greater than or equal to Previous KM.",
            "TotalWeightEqual100": "Total Weight should be equal to 100",
            /*"TotalGradeEqual100": "Total Grade should be equal to 100",*/
            "ToDateGreaterFromDate": "ToDate must be greater than FromDate.",
            "OTHourCantLineItem": "OT Hour can't be blank in line item.",
            "StatusCantLineItem": "Status can't be blank in line item.",
            "AttendanceCantLineItem": "Attendance can't be blank in line item.",
            "ItemLocationMappingAlreadyExist": "Item Location Mapping is already exist.",
            "PurchaseServiceItemCantBlank": "Purchase service item can't be blank.",
            "AtLeastCommissioningRequired": "At least one commissioning required.",
            "CurrentBudgetNotExistBal": "Current budget should not exist more than balance.",
            "SelectOrganizationDate": "Select organization and Date.",
            "SelectFromToDate": "Select from and to date.",
            "LevelCantBlankLineItem": "Level can't be blank in line item.",
            "TyrePositionCantBlankItem": "Tyre Position can't be blank in line item.",
            "QuantityLessEqualCurrent": "Quantity Must be less than or equal to current qty.",
            "CustomerOutStanding": "OutStanding Less than equal to zero.",
            "DebitCreditSegmentAmountSame": "Debit/Credit amount should be same to segment Debit/Credit amount.",
            "ScoreCanNotMorethenGrade": "Score Must be less than or equal to Grade.",
        }
    }
};